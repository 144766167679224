import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Row, Card, CardBody, Nav, NavItem, NavLink, } from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import '../../../../scss/dashboardStyles.css';
import '../../../../scss/app.css'
import { DataTable } from 'primereact/datatable';
import { Calendar } from 'primereact/calendar';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { ProgressBar } from 'primereact/progressbar'
// import config from '../../../../config/config';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import NotesModal from '../../../Cruds/CommonModals/NotesModal'
import ConfirmaionModels from '../../../Cruds/CommonModals/isApplyFilter';
import EditorComponent from '../components/PropertyEditor'
import ConfirmaionModelorSave from '../../../Cruds/CommonModals/ConfirmationModal'
import { CSVLink } from "react-csv";
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import KanbanModal from '../../../Cruds/CommonModals/KanbanModal';
//Modals
import ContactNoticeModal from '../../../Cruds/CommonModals/NoticeModal';
import ShowHideColumnsModal from '../../../Cruds/CommonModals/ShowHideColumnsModal';
import DeleteRowModal from '../../../Cruds/CommonModals/DeleteRowModal';
import SummaryStatsModal from '../../../Cruds/CommonModals/SummaryStatsModal';
import SessionExpiryModal from '../../../Cruds/CommonModals/SessionexpiryModal';
import CreateTaskModel from '../../../Cruds/CommonModals/CreateTaskModal'
import PropertyFilter from '../components/PropertyFilters'
import RolePermissions from '../../../Cruds/CommonModals/Permissions'
import SessionWarningModal from '../../../Cruds/CommonModals/SessionWarningModal';
import ExpiredSessionModal from '../../../Cruds/CommonModals/ExpiredSessionModal';
// config
import fetchMethodRequest from '../../../../config/service';
import config from '../../../../config/config';
import configImages from '../../../../config/configImages';
import configMessages from '../../../../config/configMessages';
import apiCalls from '../../../../config/apiCalls';
// Toaster message
// import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
// import TextAutocomplete from '../../../shared/components/form/TextAutocomplete'
// Date Formate
import dateFormats from '../../../UI/FormatDate/formatDate';
// import HighValuePropertyFilters from '../../../Cruds/HighValuePropertyFilters/index';
//Loader
import Loader from '../../../App/Loader';
import ExcelReact from '../../../Cruds/CommonDataTable/ExcelReact'
import PaginatorComponent from '../../CommonDataTable/PaginatorComponent';
import { faCheck, faCheckCircle, faBuilding, faExternalLinkAlt, faMinus, faCircle, faTimes, faToggleOff, faCity, faFolder, faUsers, faExpand, faClipboardList, faHistory } from '@fortawesome/free-solid-svg-icons';
import Reload from '../../../reload'

import { faCopy } from '@fortawesome/free-regular-svg-icons'

// const countriesSF = require('./countries.json')
let timeout
class PEDatatable extends React.Component {
  constructor(props) {
    super(props);
    this.closeMultiSelectDropdown = React.createRef();
    let now = new Date();
    let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    let end = moment(start).add(1, "days").subtract(1, "seconds");
    this.closeMultiSelectDropdown = React.createRef();
    let dueStartDate = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    let dueEndDate = moment(start).add(1, "days").subtract(1, "seconds");
    const urlSearchParams = new URLSearchParams(window.location.search);
    this.paramsData = Object.fromEntries(urlSearchParams.entries());
    this.state = {
      totalSelectedRows: 0,
      allUsersData: this.props.data ? this.props.data : [],
      deleteRowDataId: {},
      claimNotesEdit: false,
      isFrozenColumns: false,
      tableFields: this.props.getTableFields(),
      loginData: {},
      dueStartDate: dueStartDate,
      dueEndDate: dueEndDate,
      originalTableFields: this.props.getTableFields(),
      tablefieldsToShow: this.props.getTableFields(),
      isOpenShowHideColumnsModal: false,
      isAppliedColFilter: false,
      selectedvalueforStatus: this.props.selectedStatusFilter ? this.props.selectedStatusFilter : "",
      end: end,
      first: 0,
      postBodyFile: {},
      selectedButtonForAvailableMap: this.props.selectedButtonForAvailableMap,
      selectedCountrie: "US",
      isPreviousWeek: false,
      criteriaData: { criteria: [] },
      copiedA: false,
      isignoremultiSortField: false,
      isRedirectedToProp: false,
      lastScoredDate: "",
      // city: "Private",
      columnOrderError: false,
      isEditedClicked: false,
      addFilters: ["isExcludeRoyalties"],
      // clickCount: 0,
      validatedArray: [],
      rows: this.props.Limit ? this.props.Limit : localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : 10,
      isShowTable: false,
      sortified: false,
      showError: false,
      Properties: true,
      removeDataSource: false,
      confirmation: true,
      showErrorMessage: false,
      exportHeaders: [],
      sellerEditType: "",
      isDisabledTab: false,
      direction: 'asc',
      redirectSellerId: null,
      negativePeriod: null,
      downloadData: [],
      PendingCount: 0,
      ConfirmedCount: 0,
      MissingCount: 0,
      columns: [

      ],
      propertyEditorCreateOptions: [{ label: "New Claim", value: "New Claim" }, { label: "Research Request", value: "Research Request" }, { label: "Seller Negative Notice", value: "Seller Negative Notice" }, { label: "General Task", value: "General Task" }, { label: "Site Confirmation", value: "Site Confirmation" }, { label: "Claim Bifurcation (OPP Initiated)", value: "OPP Initiated Claim Bifurcation" }, { label: "Claim Bifurcation (State UP Initiated)", value: "State UP Initiated Claim Bifurcation" }, { label: "Claim Merge (State Initiated)", value: "State UP Initiated Claim Merge Bifurcation" }],
      propertyEditorExistingOptions: [{ label: "Existing Claim", value: "Existing Claim" }, { label: "Research Request", value: "Research Request" }, { label: "Seller Negative Notice", value: "Seller Negative Notice" }, { label: "General Task", value: "General Task" }, { label: "Site Confirmation", value: "Site Confirmation" }, { label: "Claim Bifurcation (OPP Initiated)", value: "OPP Initiated Claim Bifurcation" }, { label: "Claim Bifurcation (State UP Initiated)", value: "State UP Initiated Claim Bifurcation" }, { label: "Claim Merge (State Initiated)", value: "State UP Initiated Claim Merge Bifurcation" }],
      // dynamicColumns: [],
      selectedStatus: ["Not Started", "In Progress", "Paused", "Completed"],
      filterCriteria: {
        limit: this.props.Limit ? this.props.Limit : localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : 10,
        page: 1,
        criteria: this.props.criteria ? this.props.criteria : [],
        sortfield: (this.props.type === "Property Status" || this.props.type === "Property Reason" ? "Status" :
          this.props.type === "Claims" ? "EstateName,SourceState,BusinessName" :
            this.props.sortField ? this.props.sortField : "created"),
        direction: (this.props.type === "Labels" || this.props.type === "Departments" || this.props.type === "Purposes" || this.props.type === "Investor" || this.props.type === "OPRA2 Property Editor" ?
          'asc'
          : this.props.sortDirection ? this.props.sortDirection : "desc")
      },
      collapsefilter: false,
      selectednote: "",
      stats: [],
      selectedRows: '',
      activeTab: this.props.activeTab,
      selectedRowsId: '',
      selectedViewType: "Default",
      dataChangeOptionswithFilter: [
        { "label": "Unsort", "value": 'unsort' },
        { "label": "Sort by ASC", "value": 'asc' },
        { "label": "Sort by DSC", "value": 'desc' },
        { "label": "Filter", "value": 'filter' },
        { "label": "Clear Filter", "value": 'clear_filter' },
        { "label": "Hide Column", "value": 'hide' },
        { "label": "Show Column", "value": 'show' },
      ],
      dataChangeOptionswithoutSortwithFilter: [
        { "label": "Unsort", "value": 'unsort' },
        { "label": "Sort by ASC", "value": 'asc' },
        { "label": "Sort by DSC", "value": 'desc' },
        { "label": "Filter", "value": 'filter' },
        { "label": "Clear Filter", "value": 'clear_filter' },
        { "label": "Hide Column", "value": 'hide' },
        { "label": "Show Column", "value": 'show' },
        { "label": "Freeze first 3", "value": 'freeze' },
        { "label": "UnFreeze first 3", "value": 'unfreeze' },
      ],
      dataChangeOptionswithoutSortwithoutFilter: [
        { "label": "Hide Column", "value": 'hide' },
        { "label": "Show Column", "value": 'show' },
        { "label": "Freeze first 3", "value": 'freeze' },
        { "label": "UnFreeze first 3", "value": 'unfreeze' },
      ],
      dataChangeOptionswithoutFilter: [
        { "label": "Unsort", "value": 'unsort' },
        { "label": "Sort by ASC", "value": 'asc' },
        { "label": "Sort by DSC", "value": 'desc' },
        { "label": "Hide Column", "value": 'hide' },
        { "label": "Show Column", "value": 'show' },
      ],
      dataChangeOptionswithoutFilterPE: [
        { "label": "Unsort", "value": 'unsort' },
        { "label": "Sort by ASC", "value": 'asc' },
        { "label": "Sort by DSC", "value": 'desc' },
        { "label": "Hide Column", "value": 'hide' },
        { "label": "Show Column", "value": 'show' },
        { "label": "Freeze first 3", "value": 'freeze' },
        { "label": "UnFreeze first 3", "value": 'unfreeze' },
      ],
      dataChangeOptionswithFilterPE: [
        { "label": "Unsort", "value": 'unsort' },
        { "label": "Sort by ASC", "value": 'asc' },
        { "label": "Sort by DSC", "value": 'desc' },
        { "label": "Filter", "value": 'filter' },
        { "label": "Clear Filter", "value": 'clear_filter' },
        { "label": "Hide Column", "value": 'hide' },
        { "label": "Show Column", "value": 'show' },
        { "label": "Freeze first 3", "value": 'freeze' },
        { "label": "UnFreeze first 3", "value": 'unfreeze' },
      ],


      totalRecords: [],
      exportData: [],
      city: "Private",
      analysttype: null,
      sellertype: null,
      selectFilterValue: "",
      isOpenFormModal: false,
      openViewModal: false,
      isLoading: false,
      isOpenRolesModal: false,
      isFilterShown: true,
      searchedCompany: "",
      isOpenConfirmationModal: false,
      isApplyFilter: false,
      selectedScreenPermission: '',
      loginRole: 'Admin',
      startDate: '',
      endDate: '',
      progress: 0,
      displayErrorMessage: false,
      unassignedOnly: false,
      isShowunAssign: false,
      // isTopFilter: true,
      apiUrl: this.props.apiUrl,
      totalRecordsLength: 0,
      tabType: this.props.tabType,
      sessionExpiryModal: false,
      mobileListFields: [],
      dateFormat: '',
      filteredAnalysts: [],
      investorCompany: [],
      openTemplateModal: false,
      isOpenProductsModal: false,
      selectschool: '',
      roleType: '',
      openId: 1,
      openActionDropDown: false,
      redirecToLogin: false,
      openDeleteModal: false,
      blockFamily: false,
      selectActions: '',
      viewType: 'list',
      claimID: "",
      issellerModalView: false,
      actions: '',
      displayViewOfForm: this.props.displayViewOfForm,
      displayBreadCrumbField: '',
      displayBreadCrumbValue: '',
      width: window.innerWidth,
      isChangeHit: false,
      height: window.innerHeight,
      globalSearch: '',
      showMultiSelectDropDown: false,
      multiSelectTypes: '',
      actionsTypes: '',
      userStatus: '',
      selectedAutoCompleteValue: '',
      suggestions: [],
      confirmModalText: '',
      confirmType: '',
      openUserPasswordResetModal: false,
      iskanbanModal: false,
      errorResponse: false,
      successResponse: false,
      errorMessage: '',
      successMessage: '',
      editedrowData: {},
      sessionWarning: false,
      forceLogout: false,
      iscontactNoticeModal: false,
      dateQuery: {},
      addRow: false,
      companyFilterType: "sw",
      propertyEditorTabs: [
        { name: "Green", color: "green", tooltip: "Green" },
        { name: "Blue", color: "blue", tooltip: "Blue" },
        { name: "Yellow", color: "#ff9700", tooltip: "Yellow" },
        { name: "Brown", color: "brown", tooltip: "Brown" },
        { name: "Black", color: "black", tooltip: "Black" },
        { name: "SP", color: "blue", tooltip: "Similarly Validated Properties For This Deal" },
        { name: "PV", color: "black", tooltip: "All Positively properties for this Deal" },
        { name: "OR", color: "brown", tooltip: "All Reviewed properties for this Deal" },

      ],
      IssueCodes: {},
      ocFilters: [],
      sheets: [],
      csvOrderforPE: ["estateName", "companyID", "claimID", "propertyID", "propertyDataSource", "sourceState", "statePropertyId", "businessName", "propertyName", "businessAddressLine1", "businessCity", "businessState", "businessZipCode", "propertyAddressLine1", "PropertyAddressLine2", "propertyCity", "propertyState", "propertyZipCode", "propertyDescription", "holderName", "propertyMinValueAmount", "propertyMaxValueAmount", "escheatmentDate", "dateOfLastContact", "propertyStatus", "matchStatusReason", "matchStatusNotes", "fileFor", "propertyAlternateId", "alternateID", "prospectiveStatus", "nameIssueCode", "nameMatchScore", "addressLine1", "MatchScore", "cityStateZipScore", "batchName", "bucket", "scoreDate", "scoreUpdateDate", "isValidated", "validatedDate", "validatedBy", "hasPropertyCoOwner", "userEmailID", "opraValidatedFlag", "createdDate", "createdByUser", "createdBy", "updatedDate", "dateIndexedInElastic", "opraAddedDate", "activeproperty", "ResidentialFlag", "nameId", "addressId", "oprA_AddressDetailID", "_id", "note", "businessNameID", "addressLine1MatchScore", "propertyResidentialFlag", "active"],
      showAddButton: false
    };
  }


  componentDidUpdate(prevProps) {
    let editedRecordsLength = document.getElementsByClassName("p-row-editor-cancel").length
    var errorResponseTimeout, errorResponseHighPropertyTimeout, successMessageTimeout, showSelectederrorTimeout
    if (this.state.errorResponse) {
      if (errorResponseTimeout) {
        clearTimeout(errorResponseTimeout)
      }
      errorResponseTimeout = setTimeout(() => this.setState({ errorResponse: false, errorMessage: '', isCopiedCellValue: false }), this.state.isCopiedCellValue ? 2000 : 5000);
    }
    if (this.state.errorResponseHighProperty) {
      if (errorResponseHighPropertyTimeout) {
        clearTimeout(errorResponseHighPropertyTimeout)
      }
      errorResponseHighPropertyTimeout = setTimeout(() => this.setState({ errorResponseHighProperty: false, errorResponseHighPropertyMessage: '' }), 5000);
    }
    if (this.state.copiedA) {
      setTimeout(() => this.setState({ copiedA: false }), 5000);
    }
    if (!this.state.errorResponse && localStorage.getItem('serverError') === "true") {
      localStorage.removeItem('serverError')
    }
    if (this.state.successMessage) {
      if (successMessageTimeout) {
        clearTimeout(successMessageTimeout)
      }
      successMessageTimeout = setTimeout(() => this.setState({ successMessage: false, successResponse: '' }), 5000);
    }
    if (this.state.showSelectederror) {
      if (showSelectederrorTimeout) {
        clearTimeout(showSelectederrorTimeout)
      }
      showSelectederrorTimeout = setTimeout(() => this.setState({ showSelectederror: false, errormsg: '', errorColor: false }), 5000);
    }
    if (this.state.progress === 100) {
      setTimeout(() => this.setState({ progress: 0 }), 200);
    }
    if (this.state.isEditedClicked && editedRecordsLength === 0) {
      this.setState({
        isEditedClicked: false
      })
    }
    if (prevProps.doSearch !== this.props.doSearch) {
      this.getDataFromServer(this.state.filterCriteria, '', '', '', 'docTopFilters');
    }

  }

  /**
   * 
   * @returns to return the filter criteria into other files
   */
  getFiltercriteria = () => {
    return this.state.filterCriteria
  }

  /**
   * 
   * @returns the data group source arrray
   */
  getDataGroupSources = async () => {
    let StatusArray = [], dataArray = [];
    let filterCriteria = {}
    let loginCredentials = JSON.parse(localStorage.getItem("loginCredentials"));
    let loginRole = loginCredentials.roleName
    let StateDataSource = []
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq' }, { key: "roles", value: loginRole, type: "eq" }];
    await fetchMethodRequest('GET', `${apiCalls.DataSource}?type=group&filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["dataSources"] && response["dataSources"].length > 0) {
        let propertyStatuses = response["dataSources"]
        for (let status of propertyStatuses) {
          StatusArray.push({ label: status.group, value: status.dataSourceNames })
          dataArray.push({ label: status.group, value: status.group })
          if (status.group === "State UP Data" || status.group === "CA-N" || status.group === "SEC" || status.group === "Empty") {
            StateDataSource = [...StateDataSource, ...status.dataSourceNames]
          }
        }
        this.setState({ dataSourceGroups: StatusArray, dataArray: dataArray, StateDataSource: StateDataSource })
      }
    })
    return this.state.settingsData;
  }

  /**
   * Returns the screen permissions from local storage to set edit functionality
   */
  getScreenPermissions = async () => {
    let screenPermissions
    screenPermissions = RolePermissions.screenPermissions("Property Editor V2");
    await this.setState({
      screenPermissions: screenPermissions
    })
  }

  /**
   * 
   * @param {String} value 
   * @returns the trimmed value trimming spaces
   */
  trimmedValue = (value) => {
    if (value === "true" || value === "false") {
      return JSON.parse(value)
      // return true
    } else {
      let val = value.trim()
      if (val.includes(".")) {
        return parseFloat(val)
      }
      return parseInt(val)
    }
  }

  getTabChanges = async (filterCriteria, type) => {
    let multiSortNotNeeded = this.props.type === "Property Editor V2" ? this.props.handleGetisMultisortAppliedorNot : []
    let isApplyResultsTab = await this.props.handleCheckisInlineFilterApplied(filterCriteria)
    await this.setState({ isAppliedColFilter: isApplyResultsTab === "Remove" ? false : true, })
    var arrTabs = this.state.propertyEditorTabs
    var isMultiSortResultsBucketInclusion

    console.log(this.state.isAppliedColFilter)
    console.log(multiSortNotNeeded.includes(this.state.activeTab))
    console.log(isMultiSortResultsBucketInclusion)
    if (this.state.isAppliedColFilter && !multiSortNotNeeded.includes(this.state.activeTab)) {
      let index = arrTabs.findIndex(obj => obj.name === "Results")
      if (index === -1) { arrTabs.splice(5, 0, { name: "Results", color: "#008080", tooltip: "Results" }); }
      this.toggletab("Results")
    } else if (this.state.isAppliedColFilter && multiSortNotNeeded.includes(this.state.activeTab)) {
      this.toggletab(this.state.activeTab)
    } else {
      let index = arrTabs.findIndex(obj => obj.name === "Results")
      if (index !== -1) { arrTabs.splice(index, 1); }
      this.props.removeMultiSortFilter()
      if (!type) this.toggletab("Green")
    }

    await this.setState({
      propertyEditorTabs: arrTabs,
      filterCriteria: filterCriteria
    })
  }

  componentDidMount = async () => {
    let tabType = this.state.tabType;
    tabType = this.state.activeTab;
    await this.setState({ activeTab: tabType, showDataTable: true });
    var notcallingApiatinitial = ["Property Editor V2"]
    if (await localStorage.getItem("succ")) {
      this.getSuccessMessage(await localStorage.getItem("succ"))
    }
    Reload.reload()//for testing new Changes are present
    var arrTabs = this.state.propertyEditorTabs
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    await this.getLoginRole();
    await this.getScreenPermissions()
    await this.getDataGroupSources()
    let loginCredentials = localStorage.loginCredentials ? JSON.parse(localStorage.loginCredentials) : false;
    localStorage.removeItem("ISEdited")

    const noContext = document.getElementsByClassName('tableCardBody');
    noContext[0].addEventListener("contextmenu", (e) => {
      e.preventDefault();
      this.copyContentonRightClick(e)
    });
    window.addEventListener('resize', this.updateDimensions);
    window.addEventListener("storage", (event) => {
      this.updateTaskStatus()
    });
    // document.addEventListener('mousedown', this.handleClickOutside)
    //showing the data only other than default role
    if (!loginCredentials) {
      await this.setState({ redirecToLogin: true })
    } else {
      var refreshevery30Sec
      clearInterval(refreshevery30Sec)
      if (loginCredentials.roleName === "Analyst") {
        await this.setState({
          iscontactNoticeModal: true
        });
      }
      arrTabs = this.state.propertyEditorTabs
      let userName = loginCredentials.displayName
      let filterCriteria = this.state.filterCriteria
      filterCriteria["limit"] = loginCredentials.pageLimit ? loginCredentials.pageLimit :
        localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption"))
          : 10
      await this.setState({
        rows: this.props.Limit ? this.props.Limit : loginCredentials.pageLimit ? loginCredentials.pageLimit :
          localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption"))
            : 10,
        filterCriteria: filterCriteria,
        redirecToLogin: false,
        iscontactNoticeModal: false,
        // userRole: loginCredentials.roleName,
        loginCredentials: loginCredentials,
        displayViewOfForm: this.props.displayViewOfForm,
      })
    }
    // hari check where this should go
    if (this.props.getTableFields && !this.props.preset) {
      //let tablefields = await this.props.getTableFields();
      this.setState({
        tablefields: this.props.getTableFields(),
        originalTableFields: this.props.getTableFields(),
        // isLoading: false,
        mobileListFields: this.props.getTableFields(),
        searchedCompany: localStorage.getItem('SearchedCompany') ? localStorage.getItem('SearchedCompany') : "",
      }, async () => {
        let colOrder = localStorage.getItem(`${this.props.type}_column_order`);
        let columns = await JSON.parse(colOrder);
        if (columns) {
          await this.getTableFieldsOrder();
        } else {
          await this.updateColumnsOrder(null);
        }
      });
    }
    if (document.getElementsByClassName("layout layout--collapse").length === 0) {
      document.getElementsByClassName("topbar__button topbar__button--desktop")[0].click();
    }
    await this.sendColumnDataToServer("", "GET");
    if (this.props.type && !notcallingApiatinitial.includes(this.props.type)) {
      this.setState({ isLoading: false })
    } else {
      var url;
      if (localStorage.getItem("nspropertiesparamsUrl")) {
        url = new URL(localStorage.getItem("nspropertiesparamsUrl"));
      } else {
        url = new URL(window.location.href);
      }
      var c = url.searchParams.get("sellerName");
      if (c || url.searchParams.get("propertyId")) {
        this.getFilteredValues()
      }
    }

    this.setState({
      formFields: this.props.formFields,
      limit: loginCredentials.pageLimit,
      propertyEditorTabs: arrTabs,
      showAddButton: true
    });

    // this.updatePropertyEditorBucketTabs()
  }

  updatePropertyEditorBucketTabs = () => {
    let filterCriteria = {};
    filterCriteria['criteria'] = [{ key: 'scoringType', value: "Regular", type: 'eq', add_Filter: true }];
    fetchMethodRequest('GET', `${apiCalls.scoringBucketName}?filter=${JSON.stringify(filterCriteria)}`)
      .then(response => {

        this.setState({ propertyEditorTabs: response.scoringBucketNameConfigurations });
      })
      .catch(error => {
        console.error('There was an error getting the progress!', error);
      });
  };

  endOfWeek(date) {
    var lastday = date.getDate() - (date.getDay() - 1) + 4;
    return new Date(date.setDate(lastday));
  }

  setDate = async (date, format) => {
    const chicagoDate = new Date().toLocaleString("en-US", { timeZone: "America/Chicago" });
    const chicagoOffset = new Date(chicagoDate).getTimezoneOffset(); // returns the offset in minutes from UTC
    const offsetHours = chicagoOffset / 60; // convert minutes to hours
    // Call the function
    let dateConverted = moment(date).utcOffset(offsetHours);
    return dateFormats.formatDate(
      dateConverted,
      format ? format : "yyyy/MM/DD");
  }

  /**
   * 
   * @param {String} value 
   * @param {String} type 
   * @param {Object} view 
   * sending the user  preference col order to save and reset it on coloumn reorder
   */
  sendColumnDataToServer = async (value, type, view) => {
    let method = type;
    let url = "";
    let keyname = `${this.props.type}_column_order`;
    if (type === "PUT") {
      await this.setState({
        isLoading: true
      })
      this.handlePercentage("1")
      url = `${apiCalls.Users}/preferences/${this.state.loginData._id}?key=${keyname}`;
      let listPreferences = {};
      listPreferences[keyname] = value;
      let body = { listPreferences: listPreferences };
      if (view) {
        body.view = view.views
      }
      await fetchMethodRequest(method, url, body).then(async (resp) => {
        if (resp) {
          if (resp.respCode) {
            this.getErrorMessage(resp.respMessage, "", "success")
            // this.setState({ columnOrderError: true, columnOrderMsg: resp.respMessage })
            return;
          } else if (resp.errorMessage) {
            // showToasterMessage(resp.errorMessage, "error");
          }
        }
      });
    } else {
      url = `${apiCalls.Users}/preferences/${this.state.loginData._id}?key=${keyname}`;
      await fetchMethodRequest(method, url).then(async (resp) => {
        if (resp) {
          if (resp) {
            if (resp.details) {
              localStorage.setItem(
                `${this.props.type}_column_order`,
                JSON.stringify(resp)
              );
              this.getErrorMessage(resp.respMessage, "", "success")
              // this.setState({ columnOrderError: true, columnOrderMsg: resp.respMessage })
              this.scrollWin();
              // hari check where this should go
              if (this.props.getTableFields) {
                this.setState(
                  {
                    tablefields: this.props.getTableFields(),
                    originalTableFields: this.props.getTableFields(),
                    isLoading: false,
                    mobileListFields: this.props.getTableFields(),
                    displayViewOfForm: this.props.displayViewOfForm,
                  },
                  async () => {

                    let colOrder = resp.details[this.state.selectedViewType];
                    if (colOrder && colOrder.length > 0) {
                      await this.getTableFieldsOrder();
                    } else {
                      await this.updateColumnsOrder(null);
                    }
                  }
                );
              }
            } else {
              if (this.props.getTableFields) {
                this.setState(
                  {
                    tablefields: this.props.getTableFields(),
                    originalTableFields: this.props.getTableFields(),
                    isLoading: false,
                    mobileListFields: this.props.getTableFields(),
                    displayViewOfForm: this.props.displayViewOfForm,
                  },
                  async () => {
                    await this.updateColumnsOrder(null);
                  }
                );
              }
            }
          } else if (resp.errorMessage) {
            this.getErrorMessage(resp.errorMessage, "", "error")
            // showToasterMessage(resp.errorMessage, "error");
          }
        }
      });
    }
  };

  /**
   * 
   * @param {String} type 
   * @param {Object} columnFields 
   * @param {Array} changedTableFields 
   * Closing the show hide model 
   */
  // Called When Clicked on Cancel or Confirm in ShowHideColumnsModal based on confirmation
  closeShowHideColumnsModal = async (type, columnFields, changedTableFields) => {
    if (type && type === 'confirm' && columnFields) {
      let updatedOrder = [];
      let fields = [...changedTableFields];

      this.setState({
        isOpenShowHideColumnsModal: false,
      })

      fields.map(async item => {
        Object.entries(columnFields).forEach(async ([key, value]) => {
          if (item.field === key) {
            return item.show = value;
          }
        });
      })

      fields.map(async col => {
        updatedOrder.push(
          {
            field: col.field,
            show: col.show,
            header: col.header,
            width: col.width
          }
        )
      });
      await this.sendColumnDataToServer(updatedOrder, "PUT", columnFields);
      await this.sendColumnDataToServer("", "GET");
      // localStorage.setItem(`${this.props.type}_column_order`, JSON.stringify(updatedOrder));
      await this.getTableFieldsOrder(type, this.state.originalTableFields);
    } else if (type && type === 'confirm') {
      let fields = [...changedTableFields];
      this.setState({
        isOpenShowHideColumnsModal: false,
      })
      await this.sendColumnDataToServer(fields, "PUT", columnFields);
      await this.sendColumnDataToServer("", "GET");
      // localStorage.setItem(`${this.props.type}_column_order`, JSON.stringify(fields));
      await this.getTableFieldsOrder(type, this.state.originalTableFields, "isLoading");
    } else {
      this.setState({
        isOpenShowHideColumnsModal: false,
        // isOpenConfirmColumnModel: true
      })
    }
  }


  /**
   * Removing the dropdown values on the top if we click out side of the column
   */
  handleClickOutside = (event) => {
    if (
      this.closeMultiSelectDropdown.current &&
      !this.closeMultiSelectDropdown.current.contains(event.target)
    ) {
      this.setState({
        showMultiSelectDropDown: false
      });
    }
  };

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }

    window.removeEventListener('resize', this.updateDimensions);
    window.removeEventListener('contextmenu', this.copyContentonRightClick);
    document.removeEventListener('mousedown', this.handleClickOutside)
  }
  /**
   * Setting up the window size based on the icon 
   */
  updateDimensions = async () => {
    // let windowHeight = document.documentElement.clientHeight
    // let topSectionHeight = document.getElementsByClassName("top_section")[0].clientHeight
    // let scrollHeight = windowHeight - topSectionHeight - 250
    // await this.setState({ width: window.innerWidth, height: windowHeight, scrollHeight: scrollHeight.toString() });
  };

  /**
   * Copy the content on right click
   */
  copyContentonRightClick = async (event) => {
    var timeoutforRightClick
    if (event && event.target) {
      event.preventDefault();
      if (event.target.closest('td') && event.target.innerText.length !== 0) {
        this.setState({ isCopiedCellValue: true })
        this.clipToCopyBoard(event.target.innerText)
        this.getErrorMessage('Copied', "", "success")
        if (timeoutforRightClick) {
          clearTimeout(timeoutforRightClick)
        }
        timeoutforRightClick = setTimeout(() => this.getErrorMessage('', "", "success"), 2000)
      }
    }
  };


  /**
   * 
   * @param {String} type 
   * @param {Array} test 
   * @param {string} loading 
   * Setting up the table fields order to hide and show and arranging in sequence
   */
  getTableFieldsOrder = async (type, test, loading) => {
    this.setState({
      tablefieldsToShow: [],
      isLoading: true,
    })
    this.handlePercentage('1')
    let colOrder = localStorage.getItem(`${this.props.type}_column_order`);
    let columns = JSON.parse(colOrder);
    if (columns && columns.details) {
      columns = columns.details[this.state.selectedViewType];
    }
    if (this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor") {
      let iconIndex = columns.findIndex(a => a.field === 'taskIcon')
      iconIndex !== -1 && columns.splice(iconIndex, 1)
    }
    if (this.state.screenPermissions !== "Edit") {
      const findIndex = columns.findIndex(a => a.field === 'Actions')
      findIndex !== -1 && columns.splice(findIndex, 1)
    }
    let tempTableFields = [];
    let frozenWidth = 0;
    let newTableFields = [];
    let staticTableFields = [...this.state.originalTableFields];

    //adding newly added fields directly to list prefereneces of user
    if (columns) {
      let newArrayFields = []
      for (let k = 0; k < staticTableFields.length; k++) {
        let y = columns.findIndex(u => u.field === staticTableFields[k].field)
        if (y === -1) {
          newArrayFields.push({
            field: staticTableFields[k].field,
            header: staticTableFields[k].header,
            width: staticTableFields[k].width,
            show: true,
            fieldType: staticTableFields[k].fieldType

          })
        }
      }
      if (!this.props.isPresets) {
        columns = [...columns, ...newArrayFields]
      }
      let columns1 = JSON.parse(colOrder);
      if (columns1 && columns1.details) {
        columns1.details[this.state.selectedViewType] = columns;
      }
      localStorage.setItem(`${this.props.type}_column_order`, JSON.stringify(columns1))

      let showFrozen = [];
      for (let m = 0; m < columns.length; m++) {
        if (columns[m].show) {
          showFrozen.push(m)
        }

      }
      for (let i = 0; i < columns.length; i++) {
        for (let j = 0; j < staticTableFields.length; j++) {
          if (columns[i].field === staticTableFields[j].field && this.state.viewType === 'list') {
            let pushItem = staticTableFields[j];
            pushItem.show = typeof columns[i].show === "string" && columns[i].show === "true" ? true
              : typeof columns[i].show === "string" && columns[i].show === "false" ? false :
                columns[i].show;
            pushItem.width = columns[i].width ? columns[i].width : staticTableFields[j].width;
            pushItem.displayInSettings = columns[i].displayInSettings;
            if ((this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor") && this.state.isFrozenColumns && (i == 0 || i == showFrozen[1] || i === showFrozen[2] || i === showFrozen[3])) {
              pushItem.frozen = true
              frozenWidth = frozenWidth + columns[i].width
            } else {
              pushItem.frozen = false
              // frozenWidth = 0
            }

            tempTableFields.push(pushItem);
            break;
          }
          if (columns[i].field === staticTableFields[j].field && this.state.viewType === 'grid') {
            let pushItem = staticTableFields[j];
            pushItem.width = columns[i].width ? columns[i].width : staticTableFields[j].width;
            pushItem.displayInSettings = columns[i].displayInSettings;
            pushItem.mobile = columns[i].mobile;
            if ((this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor") && this.state.isFrozenColumns && (i == 0 || i == 1 || i === showFrozen[2] || i === showFrozen[3])) {
              pushItem.frozen = true
              frozenWidth = frozenWidth + columns[i].width
            } else {
              pushItem.frozen = false
              frozenWidth = frozenWidth
            }
            tempTableFields.push(pushItem);
            break;
          }
        }
      }
      if (this.props.type === "Property Editor V2" || this.props.type === "HighValueProperties") {
        let iconIndex = tempTableFields.findIndex(a => a.field === 'taskIcon')
        iconIndex !== -1 && tempTableFields.splice(iconIndex, 1)
        let taskicon = {
          "show": true,
          "textAlign": "left",
          "width": 50,
          "field": "taskIcon",
          "fieldType": "openConnectedTasks",
          "mobile": true,
          // "header": "Tasks",
          "displayInSettings": true,
          "filter": false,
          "sortable": false,
          frozen: this.state.isFrozenColumns ? true : false,

        }
        tempTableFields.splice(1, 0, taskicon);
        frozenWidth = this.state.isFrozenColumns ? frozenWidth + 50 : frozenWidth

      }

    } else {
      tempTableFields = this.state.originalTableFields
    }
    newTableFields = tempTableFields;

    let TableFieldsToShow = [];
    if (newTableFields && newTableFields.length > 0) {
      // newTableFields.map(item => {
      newTableFields.forEach(item => {

        // TableFieldsToShow.push(item)
        if (item.show && item.show === true && this.state.viewType === 'list') {
          TableFieldsToShow.push(item)
        }
        if (item.mobile && this.state.viewType === 'grid') {
          TableFieldsToShow.push(item)
        }
      })
    }

    frozenWidth = frozenWidth
    this.setState({
      frozenWidth: frozenWidth.toString() + "px",
      originalTableFields: this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor" ? TableFieldsToShow : this.state.originalTableFields,
      tablefieldsToShow: this.props.type === "Company" && this.state.city === "Public" ? this.props.getPublicCompanies() : TableFieldsToShow,
    })

    //get call is not calling then need to restrict the loading
    var notcallingApiatinitial = ["Property Editor V2 V2", "FactSet", "Deal Sizing Property Editor", "OpenCorporateBranch", "OpenCorporateBranchBatch", "OpenCorporateSearch", "Name Lookup", "Address Lookup", "Negative Notice List Viewer", "Deal Statistics", "Site Confirmation Editor", "Properties", "Property Editor V2", "Property Editor V2 V2", "OPRA2 Property Editor", "Deal Sizing-Properties", "Deal Sizing Report For Companies", "Deal Sizing Report For Private Equity Portfolio", "AddPropertiesFromFile"]
    if (notcallingApiatinitial.includes(this.props.type) || loading || this.props.loading || this.state.isLoading) {
      this.setState({
        isLoading: false,
      })
    }
    this.scrollWin();
  }
  /**
   * 
   * @param {string} edited 
   * function to save the note is edited or not for slecting and unselecting records
   */
  commentEditedOrNo = async (edited) => {
    await this.setState({ edited: edited })
    if (this.propertyFilterRef && this.propertyFilterRef.commentEditedOrNo) { this.propertyFilterRef.commentEditedOrNo(edited) }

  }
  /**
   * applying the inline filters based on the table order and applying the values for filter
   */

  custumApplyFilter = () => {
    let criteria = JSON.parse(localStorage.getItem("sellerFilter"))
    if (criteria && criteria.criteria.length > 0) {
      for (let crt of criteria.criteria) {
        var shownField = document.getElementsByClassName(`p-column-filter-Deal Mgmt${crt.key}`)[0]
        if (shownField) {
          shownField.style.display = "block"
          shownField.children[0].value = crt.type//for type
          shownField.children[1].value = crt.value//for value
        }
        if (crt.type && crt.type.includes("date")) {
          document.getElementsByClassName(`p-column-filterMode-${crt.key}`)[0].selectedIndex = crt.type === "dateeq" ? 0 : crt.type === "datelt" ? 1 : crt.type === "dategt" ? 2 : crt.type === "datelte" ? 3 : crt.type === "dategte" ? 4 : crt.type === "datenin" ? 5 : crt.type === "datein" ? 6 : null
          document.getElementsByClassName(`p-column-filter p-column-${crt.key}`).value = new Date(decodeURIComponent(crt.value))
        }
      }
    }
    if (criteria && criteria.globalSearch) {
      document.getElementById("globalSearch").value = ""
    }
  }
  /**
   * 
   * @returns calling the confirmation model 
   */
  getConfirmaionModel() {
    return (
      <ConfirmaionModels
        openConfirmationModal={this.state.isApplyFilter}
        closeConfirmationModal={this.isConfirmFilter}
        onFilterChange={this.onFilterChange}
        filterInput={this.state.filteredCol}
        handleFilterInputField={this.handleFilterInputField}

      />
    )
  }



  showDatatable = async (type) => {
    if (type) {
      await this.isShowTable(true)
    } else {
      await this.isShowTable(false)
    }
  }
  clearData = async () => {
    await this.setState({
      uppropConfirmationMessage: false, uppropPropertyIds: [],
    })
  }

  //getting Error Message from formModa;
  getErrorMessage = async (errorMessage, type, color) => {
    if (type === "removingSeller") {
      let counts = {
        Green: 0, Blue: 0, Teal: 0, Brown: 0, Yellow: 0, Black: 0, All: 0, PV: 0, SP: 0
      }
      await this.setState({
        allUsersData: [],
        PendingCount: 0,
        isAppliedColFilter: false,
        ConfirmedCount: 0,
        MissingCount: 0,
        Counts: counts,
        fileForOptions: [],
        lastScoredDate: "",
        IssueCodes: {},
        redirectSellerId: null,
        negativePeriod: null,
        hasPropertyCoOwner: "",
        propertyStatus: "",
        propertyStatusReason: "",
        confirmedOnsite: "",
        effectiveFromDate: "",
        reportedMissing: "",
        propertyStatus: "",
        matchMatchStatus: "",
        matchStatusReason: "",
        propertyReason: "",
        isAppliedColFilter: type === "removingSeller" ? false : this.state.isAppliedColFilter,
        propertyEditorTabs: [{ name: "Green", color: "green", tooltip: "Green" },
        { name: "Blue", color: "blue", tooltip: "Blue" },
        { name: "Yellow", color: "#ff9700", tooltip: "Yellow" },
        { name: "Brown", color: "brown", tooltip: "Brown" },
        { name: "Black", color: "black", tooltip: "Black" },
        { name: "SP", color: "blue", tooltip: "Similarly Validated Properties For This Deal" },
        { name: "PV", color: "black", tooltip: "All Positively properties for this Deal" },
        { name: "OR", color: "brown", tooltip: "All Reviewed properties for this Deal" },
        ]

      })
      if (this.props.type !== "Up Prop Confirmation" && this.editorComponentRef) {
        this.editorComponentRef.handlePropertiesEditChange("", "Cancel", "removingSeller")
      }

      this.isShowTable(false)
    }
    if (errorMessage && errorMessage.length > 0) {
      await this.setState({
        errorMessage: errorMessage,
        errorResponse: true,
        isLoading: false,
        errorcolor: color === "error" ? "red" : "green"
      })
    } else {
      // await this.setState({
      //   errorMessage: errorMessage,
      //   errorResponse: false,
      //   isLoading: false,
      //   errorcolor: color === "error" ? "red" : "green"
      // })
    }
    if (type === "selectedSeller" || type === "claimName") {
      await this.setState({
        errorMessage: "",
        errorResponse: false,
        isLoading: false,

      })
    }
  }

  getSuccessMessage = async (successMessage, color) => {
    await this.setState({
      successMessage: successMessage,
      successResponse: true,
      color: "green"
    })
    if (localStorage.getItem("succ")) {
      localStorage.removeItem("succ")
    }
  }

  changeViewType = async (viewType) => {
    await this.setState({ viewType: viewType })
    await this.getTableFieldsOrder()
  }

  // Handle Table fields order of display in DataTable
  updateColumnsOrder(currentOrder) {
    this.setState({
      isLoading: true,
    })
    this.handlePercentage('1')
    let originalTableFields = [...this.state.originalTableFields];
    //  [...this.state.originalTableFields];
    let order = currentOrder ? currentOrder : null;
    let updatedOrder = [];
    let unmatchedTableFields = [...this.state.originalTableFields];

    if (order && order.length > 0) {
      order.map(async item => {
        if (item && item.props && item.props.field) {
          // originalTableFields.map(col => {
          originalTableFields.forEach(col => {
            if (item.props.field === col.field) {
              updatedOrder.push(
                {
                  field: col.field,
                  show: col.show,
                  width: col.width,
                  header: col.header,
                  displayInSettings: col.displayInSettings,
                  mobile: col.mobile,
                }
              )
            }
          })
        }
      })

      order.map(async item => {
        if (item && item.props && item.props.field) {
          for (let i = 0; i < unmatchedTableFields.length; i++) {
            if (item.props.field === unmatchedTableFields[i].field) {
              unmatchedTableFields.splice(i, 1)
            }
          }
        }
      })

      if (unmatchedTableFields && unmatchedTableFields.length > 0) {
        // unmatchedTableFields.map(col => {
        unmatchedTableFields.forEach(col => {
          updatedOrder.push(
            {
              field: col.field,
              show: false,
              width: col.width,
              header: col.header,
              displayInSettings: col.displayInSettings,
              mobile: false,
            }
          )
        })
      }
    } else {
      originalTableFields.map(async col => {
        updatedOrder.push(
          {
            field: col.field,
            show: col.show,
            width: col.width,
            header: col.header,
            displayInSettings: col.displayInSettings,
            mobile: col.mobile,
          }
        )
      });
    }
    localStorage.setItem(`${this.props.type}_column_order`, JSON.stringify(updatedOrder));
    this.getTableFieldsOrder(this.state.originalTableFields);
  }


  getLoginRole() {
    let loginData = localStorage.getItem("loginCredentials");
    if (loginData) {
      loginData = JSON.parse(loginData);
      if (loginData && loginData.roleName) {
        this.setState({
          loginRole: loginData.roleName,
          loginData: loginData,
        });
      }
    }
  }

  updateReason(itemdata) {
    let modifiedData = [];
    itemdata.forEach((item, index) => {
      if (item.ClaimStatus) {
        let ReasonsArray = [];
        let filterCriteria = {};
        filterCriteria['criteria'] = [{ key: 'Status', value: item.ClaimStatus, type: 'eq', add_Filter: true }, { key: 'activeIndicator', value: true, type: 'eq', add_Filter: true }];
        return fetchMethodRequest('GET', `${apiCalls.ClaimReasons}?filter=${JSON.stringify(filterCriteria)}`)
          .then(async (response) => {
            if (response && response["claimReasons"].length > 0) {
              let claimReasons = response["claimReasons"]
              for (let reason of claimReasons) {
                ReasonsArray.push({ label: reason.reason, value: reason.reason })
              }
              item.claimReasonsRow = ReasonsArray
              modifiedData.push(item);
            } else if (response && response['errorMessage'] === configMessages.sessionWarning) {
              await this.setState({
                sessionWarning: true,

              })
            } else {
              item.claimReasonsRow = []
              modifiedData.push(item);
            }
          }).catch((err) => {
            return err;
          })
      } else {
        item.claimReasonsRow = []
        modifiedData.push(item);
      }
    })
    return modifiedData
  }
  updateSlNoToData(itemdata) {
    let modifiedData = [];
    itemdata.forEach((item, index) => {
      if (this.props.flags) {
        let flags = this.props.flags
        if (item[flags['label']] === flags['value']) {
          modifiedData.push(item);
        }
      } else {
        if (this.state.first) {
          item.Sno = (index + 1) + this.state.first;
        } else {
          item.Sno = index + 1;
        }
        modifiedData.push(item);
      }
    });
    return modifiedData;
  }
  setTeam = async (DepartmentArray, departMentIdArray) => {
    await this.setState({ departMentIdArray: departMentIdArray })

  }


  updateDateFormat(itemdata, dateFormat, from) {
    let tableFields = this.props.tabOptions ? this.props.getTableFields(this.state.activeTab) : this.props.getTableFields();
    let modifiedData = [];
    for (let i = 0; i < itemdata.length; i++) {
      for (let k = 0; k < tableFields.length; k++) {
        if ("Date" === tableFields[k]['fieldType']) {
          if (this.props.type !== "Property Editor V2" && this.props.type !== "RFI View") {
            if (itemdata[i][tableFields[k]['field']] !== null) {
              let time = moment(itemdata[i][tableFields[k]['field']]).utcOffset(dateFormats.isDstObserved(itemdata[i][tableFields[k]['field']]) ? '-05:00' : '-06:00');
              itemdata[i][tableFields[k]['field']] =
                this.props.type === "Company" || this.props.type === "List View" || this.props.type === "RFI View" ?
                  dateFormats.formatDate(
                    itemdata[i][tableFields[k]['field']],
                    tableFields[k]['dateFormat'] && !from ? tableFields[k]['dateFormat'] : dateFormat, "NS")
                  :
                  dateFormats.formatDate(
                    time,
                    tableFields[k]['dateFormat'] && !from ? tableFields[k]['dateFormat'] : dateFormat);
            }
          } else {
            if (itemdata[i][tableFields[k]['field']] !== null) {
              itemdata[i][tableFields[k]['field']] =
                dateFormats.formatDate(
                  itemdata[i][tableFields[k]['field']],
                  !from ? "MM/DD/yyyy" : dateFormat, 'NS');
            }
          }
        } else if ("Time" === tableFields[k]["fieldType"]) {
          let val = itemdata[i][tableFields[k]["field"]];
          if (val) {
            let time = moment(val).utcOffset(dateFormats.isDstObserved(val) ? '-05:00' : '-06:00');
            let dd = dateFormats.formatDate(time, !from ? "MM/DD/YYYY hh:mm a" : dateFormat);
            itemdata[i][tableFields[k]["field"]] = dd;
          }

        }
      }
      modifiedData.push(itemdata[i])
    }
    return modifiedData;
  }

  handlePercentage = async (Val) => {
    let counter = 0;
    const interval = setInterval(async () => {
      if (this.state.isLoading) {
        counter = counter + 15;
        await this.setState({
          progress: counter,
        })
      } else {
        if (!this.state.isLoading) {
          clearInterval(interval);
        }
      }
    }, 100);
  }
  setDepartement = async (DepartmentArray, departMentIdArray) => {
    await this.setState({ selectedDepartment: DepartmentArray, departMentIdArray: departMentIdArray, Department: DepartmentArray })
  }

  /**
   * 
   * @param {Object} filterCriteria 
   * @param {string} type 
   * @param {bool} setGoButton 
   * @param {string} url 
   * @returns Getting the data from server after applying all the criteria
   */
  getDataAfterCriteria = async (filterCriteria, type, setGoButton, url) => {
    let apiUrl;
    if (type === 'refresh') {
      let tablefieldsToShow = this.state.tablefieldsToShow;
      if (tablefieldsToShow && tablefieldsToShow.length > 0) {
        for (let i = 0; i < tablefieldsToShow.length; i++) {
          let options = tablefieldsToShow[i].options;
          if (options && options.length) {
            for (let j = 0; j < options.length; j++) {
              options[j]['checkIcon'] = false
            }
          }
        }
      }
      this.setState({
        globalSearch: '',
        allUsersData: [],
        teamFilter: false,
        apiUrlforExport: "",
        status: '',
        roleType: '',
        // selectFilterValue: '',
        tablefieldsToShow: tablefieldsToShow
      });
    }
    if (!filterCriteria || !filterCriteria['criteria']) {
      // let filterCriteria = {};
      let limit = localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : 10
      filterCriteria = {
        limit: limit, page: 1, criteria: [], sortfield: this.props.sortField ? this.props.sortField : "created", direction: this.props.type === "Weekly Recovery Plan" ? 'asc' : 'desc'
      };
      if (this.props.noLimit) {
        delete filterCriteria['limit']
      }
      if (this.props.Limit) {
        filterCriteria['limit'] = this.props.Limit
      }
    }
    let reportTypeEmpty = this.state.reportTypeEmpty
    if (type === 'refresh' && filterCriteria) {
      filterCriteria['criteria'] = this.props.type === "List View" || this.props.type === "Research Request Viewer" || this.props.type === "Seller Negative Notice Viewer" || this.props.type === "RFI View" ? this.state.filterCriteria.criteria : [];
      // delete filterCriteria.globalSearch;
      if (this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing-Properties") {
        filterCriteria = await this.getAdditionalCriteriaafterRefresh(filterCriteria);
      }
      if (this.props.noLimit) {
        delete filterCriteria['limit']
      }
      if (this.props.Limit) {
        filterCriteria['limit'] = this.props.Limit
      }
      filterCriteria['direction'] = this.state.filterCriteria.direction;
      if (this.props.type === "Company" && localStorage.getItem('SearchedCompany')) {
        localStorage.removeItem('SearchedCompany');
      }
    }
    if (type === "globalSearch") {
      if (this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing-Properties") {
        filterCriteria = await this.getAdditionalCriteriaafterRefresh(filterCriteria);
      }
      delete filterCriteria.globalSearch;
      this.setState({
        selectFilterValue: '',
      })
    }
    if (this.paramsData && window.location.pathname !== "/propertyEditorV2") {
      let params = this.paramsData
      for (let i in params) {
        if (i) {
          let obj = {}
          obj['key'] = i
          obj['value'] = params[i]
          obj['type'] = i === "id" ? "eq" : 'regexOr'
          filterCriteria['criteria'].push(obj)
        }
      }
      this.paramsData = null
    }
    //applyng filter when a row item is deleted 
    if (filterCriteria === undefined) {
      filterCriteria = { limit: localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : 10, page: 1, criteria: [], direction: 'desc' };
      filterCriteria['sortfield'] = this.props.sortField ? this.props.sortField : "created";
      if (this.props.noLimit) {
        delete filterCriteria['limit']
      }
      if (this.props.Limit) {
        filterCriteria['limit'] = this.props.Limit
      }
      apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
    }
    if (this.props.filterExtension) {
      apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}&&${this.props.filterExtension}`
    }
    if (this.props.hasQueryInCall) {
      apiUrl = `${url}&filter=${JSON.stringify(filterCriteria)}`;
    }
    else {
      if (this.props.noLimit) {
        delete filterCriteria['limit']
        delete filterCriteria['page']
      }
      if (this.props.Limit) {
        filterCriteria['limit'] = this.props.Limit
      }
      var dataurl, obj;
      if (localStorage.getItem("nspropertiesparamsUrl")) {
        dataurl = new URL(localStorage.getItem("nspropertiesparamsUrl"));
      } else {
        dataurl = new URL(window.location.href);
      }
      if (this.state.PropertyData && this.state.PropertyData.groupMessage && this.state.PropertyData.groupFlag) {
        let flag = this.state.PropertyData.groupFlag.slice(0, 6);
        filterCriteria.sortfield = `${flag}ParentPropertyID, PropertyReviewedFlag, ${this.state.PropertyData.groupFlag}, PropertyName, PropertyAddressLine1`
      }
      if (this.state.PropertyData && this.state.PropertyData.groupMessage && !this.state.PropertyData.groupFlag) {
        filterCriteria.sortfield = "ParentPropertyID, PropertyName, PropertyAddressLine1"
      }
      apiUrl = `${url}?filter=${JSON.stringify(filterCriteria)}`;
      apiUrl = await this.getUrlbasedonrequirements(apiUrl, filterCriteria, url)
    }
    if (this.props.additionalFlag && this.state.activeTab === "Results" && !this.state.isignoremultiSortField) {
      apiUrl = `${apiUrl}` + `${this.state.additionalFlag ? this.state.additionalFlag : this.props.additionalFlag}`
    }
    await this.setState({
      apiUrlforExport: apiUrl
    })

    return apiUrl;
  }

  /**
   * 
   * @param {Object} filterCriteria 
   * @param {string} type 
   * @param {bool} setGoButton 
   * @param {Object} bodyData 
   * @returns get table list data from server with filters if any
   */
  getDataFromServer = async (filterCriteria, type, setGoButton, bodyData) => {
    if (this.state.PropertyData && (this.state.PropertyData.selectedProperty_Id || this.state.PropertyData.selectedOpraStId)) {
      bodyData = {
        propertyId: this.state.PropertyData.selectedProperty_Id ? this.state.PropertyData.selectedProperty_Id.toString() : null,
        statePropertyId: this.state.PropertyData.selectedOpraStId ? this.state.PropertyData.selectedOpraStId.toString() : null
      }
    }
    let url;
    url = this.getAPIUrl();

    if (type === "dateQuery") {
      await this.setState({
        dateQuery: filterCriteria
      })
    } else if (type === "resetDateQuery") {
      await this.setState({
        dateQuery: null
      })
    }
    let SearchCompanyID = localStorage.getItem("SearchedCompany")
    if (SearchCompanyID && SearchCompanyID.length > 0) {
      document.getElementById("globalSearch").value = SearchCompanyID
    }
    localStorage.removeItem("ISEdited")
    if (this.props.type === "Company" && SearchCompanyID && SearchCompanyID.length > 0 && SearchCompanyID !== undefined) {
      filterCriteria.globalSearch = {
        value: encodeURIComponent(SearchCompanyID),
        type: this.props.globalSearchFieldName
      };
      await this.setState({
        hidePropValues: false
      })
    }
    if (url) {
      let apiUrl, Counts = [];
      this.setState({
        progress: 0,
        // errorMessage: "",
        // errorResponse: false,
        isLoading: true,
        apiUrlforExport: "",
        selectedRows: '',
        isPendingConfirmation: "",
      });
      await this.handlePercentage('1')

      apiUrl = await this.getDataAfterCriteria(filterCriteria, type, setGoButton, url)

      if (bodyData && bodyData.departMentIdArray) {
        if (this.props.tabCarrying && this.props.tabCarrying === "available") {
          let params = apiUrl.split("&")
          let queryparams = params.filter(elem => elem.includes("selectedDepartment=") === false)
          apiUrl = queryparams.join("&")
        }
        apiUrl = apiUrl + `&selectedDepartment=${encodeURIComponent(bodyData.departMentIdArray.join(", "))}`
      }
      let user = JSON.parse(localStorage.getItem('loginCredentials'));
      if ((this.props.type === "Ready To Review" || this.props.type === "Ready To File" || this.props.type === "On Hold" || this.props.type === "Seller Negative Notice" || this.props.type === "CA-N Properties") && this.state.departMentIdArray && this.state.departMentIdArray.length > 0 && !(bodyData && bodyData.departMentIdArray) && user && user.roleName && !user.roleName.includes("CoreOps")) {
        apiUrl = apiUrl + `&selectedDepartment=${encodeURIComponent(this.state.departMentIdArray.join(", "))}`
      } else if ((this.props.type === "Recovery Planner V2" || this.props.type === "Ready To Review" || this.props.type === "AvailableToMap" || this.props.type === "AvailableToReMap" || this.props.type === "Grouped By Deal" || this.props.type === "Grouped By Deal/State" || this.props.type === "UP Opportunity – V2" || this.props.type === "AvailableToMap" || this.props.type === "Required Evidence" || this.props.type === "HighValueProperties") && this.state.departMentIdArray && this.state.departMentIdArray.length > 0 && !(bodyData && bodyData.departMentIdArray)) {
        apiUrl = apiUrl + `&selectedDepartment=${encodeURIComponent(this.state.departMentIdArray.join(", "))}`
      } else if (this.props.type === "Resource Needed" && this.state.resourceNeededDepartment && this.state.resourceNeededDepartment.length > 0 && !(bodyData && bodyData.departMentIdArray) && user && user.roleName && !user.roleName.includes("CoreOps")) {
        apiUrl = apiUrl + `&selectedDepartment=${encodeURIComponent(this.state.resourceNeededDepartmentId.join(", "))}`
      }
      if (this.state.departmentIdArray && this.state.departmentIdArray.length > 0) {
        const urlSearchParams = new URLSearchParams(apiUrl);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (!params.selectedDepartment) {
          apiUrl = apiUrl + `&selectedDepartment=${encodeURIComponent(this.state.departmentIdArray.join(", "))}`
        }
      }
      if (this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor") {
        let index = filterCriteria && filterCriteria["criteria"] ? filterCriteria["criteria"].findIndex(obj => obj.type === "fuzzyregexOr") : null
        if (index !== -1) {
          apiUrl = `${apiUrl}&wildCard=${true}`
        }
      }

      let method, body = bodyData ? bodyData : {};
      method = "POST"
      if (body && body.hitGoButton) {
        delete body.hitGoButton
      }
      body = body

      this.setState({
        apiUrlforExport: apiUrl,
        postBodyFile: body
      });

      if (apiUrl && apiUrl.length) {
        return fetchMethodRequest(method, apiUrl, body)
          .then(async (response) => {
            if (setGoButton) {
              if (this.propertyFilterRef) { this.propertyFilterRef.setGoButton() }
              if (this.dealSizingpropertyFilterRef) { this.dealSizingpropertyFilterRef.setGoButton() }
            }
            let apiResponseKey = this.props.apiResponseKey;
            this.updateDimensions()
            console.log("1516")
            let sessionexpired = await localStorage.getItem('sessionexpired')
            if (sessionexpired === "true") { await this.setState({ sessionExpiryModal: true }) }
            let responseData = [], totalRecordsLength = this.state.totalRecordsLength;
            if (response && response.details && response.details.length > 0) {
              responseData = response.details;
              totalRecordsLength = response.details.length;
            } else if (response && response['errorMessage']) {
              await this.getErrorResponseFromServer(response)
              this.setState({
                isLoading: false
              })
            }
            console.log("1529")

            let IssueCodes = {}, defaultIssueNames = []
            if (this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor") {
              let nameIssueCodes = [], newNameIssueCodes = []
              if (response.nameIssueCodes && response.nameIssueCodes.length > 0) {
                for (var item of response.nameIssueCodes) {
                  nameIssueCodes.push({ label: item === null ? "Blank" : item, value: item })

                }
              }
              if (response.newNameIssueCodes && response.newNameIssueCodes.length > 0) {
                for (var obj of response.newNameIssueCodes) {
                  newNameIssueCodes.push({ label: obj === null ? "Blank" : obj, value: obj })
                }
              }
              IssueCodes.nameIssueCodes = nameIssueCodes
              IssueCodes.newNameIssueCodes = newNameIssueCodes
              IssueCodes.defaultIssueNames = []
            }

            if (apiResponseKey) {
              console.log("1552")

              if (response && response[apiResponseKey] && response[apiResponseKey].length && response[apiResponseKey].length >= 0) {
                if (response.pagination && response.pagination.totalCount) {
                  totalRecordsLength = response.pagination.totalCount;
                }
                this.isShowTable(true)
                responseData = response[apiResponseKey]
                if (this.state.PropertyData && this.state.PropertyData.groupFlag && this.state.activeTab === "SP") {
                  responseData = await this.props.getUpdatedResponseforParent(responseData, this.state.PropertyData.groupFlag);
                }

                // console.log("gcg",git branch)
                responseData = this.updateSlNoToData(responseData);
                responseData = this.updateDateFormat(responseData, this.state.dateFormat);
              }
              console.log("1567",responseData)

              if (response.pagination && (response.pagination.totalCount || response.pagination.totalCount === 0)) {
                totalRecordsLength = response.pagination.totalCount;
                if (this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor") {
                  if (this.propertyFilterRef) { this.propertyFilterRef.setGoButton() }
                  if (this.dealSizingpropertyFilterRef) { this.dealSizingpropertyFilterRef.setGoButton() }
                }
              }
            }
            let colunHeight
            if (this.props.frozen && this.state.isFrozenColumns) {
              colunHeight = document.getElementsByClassName("p-datatable-thead")[1].clientHeight
            }
            console.log("1581")

            await this.applyCustomChanges(responseData, response)
            if (this.paramsData && this.paramsData.taskID && responseData && responseData[0] && this.paramsData.taskID && window.location.pathname !== "/nsproperties" && this.props.type !== "Negative Notice List Viewer" && this.props.type !== "Site Confirmation Editor") {
              window.history.pushState(null, null, "propertyEditorV2");

              this.setState({
                formType: "edit",
                selectedObj: responseData[0],
                isOpenRFIModal: true
              })
              this.paramsData = null
            }
            if (this.props.noLimit) {
              this.setState({ rows: totalRecordsLength })
            }
            // if (response && response.lastScoredDate) {
            //   const date = new Date(response.lastScoredDate);
            //   const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            //   const formattedDate = date.toLocaleString('en-US', {
            //     timeZone: userTimezone,
            //     year: 'numeric',
            //     month: 'long',
            //     day: 'numeric',
            //     hour: 'numeric',
            //     minute: 'numeric',
            //     second: 'numeric'
            //   });

            //   console.log(formattedDate);
            // }
            this.setState({
              progress: 100,
              responsesaveData: JSON.parse(JSON.stringify(responseData)),
              IssueCodes: IssueCodes,
              colunHeight: colunHeight,
              allUsersData: responseData,
              response: response,
              isLoading: false,
              disableIcon: false,
              isDisabledTab: false,
              defaultExclusionNote: response.defaultExclusionNote ? response.defaultExclusionNote.replace('Inclusions / Exclusions Appear here :', '') : "",
              lastScoredDate: await this.setDate(response.lastScoredDate, "MM/DD/YYYY hh:mm A"),
              totalRecordsLength: totalRecordsLength,
              showAddButton: true
            })
            if ((type === null || type !== "column_filter") && this.state.activeTab !== "SP" && !this.state.isAppliedColFilter) {
              this.clickCount(response, this.props.type)
            }
            return responseData;
          }).catch((err) => {
            return err;
          });
      }
    } else {
      this.setState({
        isLoading: false,
      })
    }
  }



  /**
   * 
   * @param {array} t 
   * expanding the rows  
   */
  expandAll(t) {
    let rowDataValues = this.expandDataAll(t)
    this.setState({
      expandedRows: rowDataValues
    });
  }
  /**
   * 
   * @param {array} t 
   * closing the rows
   */
  closeAll = async (t) => {
    let rowDataValues = await this.closeDataAll(t)
    this.setState({
      expandedRows: rowDataValues
    });
  }
  /**
   * 
   * @param {array} t 
   * @returns making every individual row with special id to close
   */
  closeDataAll = async (t) => {
    let expandedRows = {};
    t.forEach(element => {
      if (element.ChildCount > 0) {

        // expandedRows[`${element.DealID}`] = true;
        expandedRows = null;
      }
    });
    return expandedRows;
  }
  /**
   * 
   * @param {array} t 
   * @returns making every individual row with special id to expand
   */
  expandDataAll(t) {
    let expandedRows = {};
    t.forEach(element => {
      if (element.ChildCount > 0) {
        // expandedRows[`${element.DealID}`] = true;
        expandedRows[`${element.OppClaimID}`] = true;
      }
    });
    return expandedRows;
  }


  /**
   * 
   * @param {Array} responseData 
   * @param {objects} response 
   * setting up the counts based on response for property editor
   */
  applyCustomChanges = async (responseData, response) => {
    if (this.state.softWarning) {
      setTimeout(() => this.setState({ softMessage: "", softWarning: false }), 30000);
    }
    if ((this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "Queue") && response.counts) {
      let counts = response.counts
      counts.Brown = counts.Brown !== undefined ? counts.Brown : ">100"
      counts.Black = counts.Black !== undefined ? counts.Black : ">100"
      counts.Results = this.state.isAppliedColFilter ? counts.Results : 0

      await this.setState({
        Counts: response.counts
      })
    }
    localStorage.setItem('responsesaveData', JSON.stringify(responseData))
  }


  /**
   * return Counts
   */
  getCountstoreturn = () => {
    return this.state.Counts
  }


  /**
   * 
   * @param {object} response 
   * Setting up the error message for dispalying and disabling the datatable view
   */
  getErrorResponseFromServer = async (response) => {
    if (response && response['errorMessage'] === configMessages.warningMessage) {
      this.setState({
        sessionWarning: true,
        errorMessage: null
      })
    } else if (response && response['errorMessage'] === configMessages.tokenMessage || response['errorMessage'] === configMessages.sessionExpired) {
      this.setState({
        sessionExpiryModal: true,
        errorMessage: null
      })
    } else if (response && response['errorMessage']) {
      this.getErrorMessage(response.errorMessage, "", "error")
      let mess = response.errorMessage
      if (!mess.includes("Results Threshold")) {
        this.isShowTable(false)
      }
      if (this.props.type === "Property Editor V2") {
        await this.setState({
          totalRecordsLength: 0,
          allUsersData: [],
        })
      }
    }
  }

  /**
   * 
   * @param {object} filterCriteria 
   * @returns Setting up the additional criteria for the property editor  for buckets 
   */
  getAdditionalCriteriaafterRefresh = async (filterCriteria) => {
    let index = filterCriteria["criteria"].findIndex(obj => obj.key === "bucket" && obj.add_Filter === true)
    if (index !== -1) filterCriteria["criteria"].splice(index, 1)
    filterCriteria['criteria'].push({
      key: "bucket",
      value: this.state.activeTab,
      type: "eq",
      add_Filter: true
    })
    if (this.state.PropertyData && this.state.PropertyData.selectedState) {
      let index = filterCriteria["criteria"].findIndex(obj => obj.key === "sourceState")
      if (index !== -1) filterCriteria["criteria"].splice(index, 1)
      filterCriteria['criteria'].push({
        key: this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "OPRA2 Property Editor" ? "sourceState" : this.props.type === "Deal Sizing-Properties" ? "sourceState" : "property_StateId",
        value: this.state.PropertyData.selectedState,
        type: "in"
      })
    }

    return filterCriteria;
  }

  /**
   * 
   * @param {string} apiUrl 
   * @param {Object} filterCriteria 
   * @param {string} url 
   * @returns Setting up the criteria to send to api
   */
  getUrlbasedonrequirements = async (apiUrl, filterCriteria, url) => {
    apiUrl = await this.setFlagsforProperties(apiUrl)

    await this.setState({
      apiUrlforExport: apiUrl
    })
    return apiUrl;
  }

  /**
   * 
   * @param {bool} e 
   * disabling and enabling the datatable by sending the flag
   */

  isShowTable = (e) => {
    let counts = {
      Green: 0, Blue: 0, Teal: 0, Brown: 0, Yellow: 0, Black: 0, All: 0, PV: 0, SP: 0
    }

    this.setState({
      Counts: e === false ? counts : this.state.Counts,
      isShowTable: e,
    })

    if (e === false) {
      this.setState({
        hasPropertyCoOwner: "",
        propertyStatus: "",
        propertyStatusReason: "",
        confirmedOnsite: "",
        effectiveFromDate: "",
        reportedMissing: "",
        propertyStatus: "",
        matchMatchStatus: "",
        matchStatusReason: "",
        propertyReason: ""
      })

    }
  }

  /**
   *hits when ever the task changed in top navigation
   */
  updateTaskStatus = () => {
    if (this.props.type === "List View" || this.props.type === "RFI View" || this.props.type === "Research Request Viewer" || this.props.type === "Seller Negative Notice Viewer") {
      if (localStorage.getItem("isTaskUpdatedfromTopbar")) {
        localStorage.removeItem("isTaskUpdatedfromTopbar")
        this.getDataFromServer(this.state.filterCriteria);
      }
    }
  }

  /**
   * 
   * @param {string} apiUrl 
   * @returns Setting up the query parameters for the api url 
   */
  setFlagsforProperties = async (apiUrl) => {
    if ((this.props.type === "Properties" || this.props.type === "Negative Notice List Viewer" || this.props.type === "Site Confirmation Editor" || this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Report For Companies" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing Property Editor") && this.state.PropertyData && this.state.PropertyData.selectedSeller && this.state.PropertyData.selectedName && this.state.PropertyData.selectedName.length > 0) {
      apiUrl = `${apiUrl}&sellerID=${this.state.PropertyData.selectedSeller.EstateID ? this.state.PropertyData.selectedSeller.EstateID : this.state.PropertyData.selectedSeller}&nameID=${encodeURIComponent(this.state.PropertyData.selectedName)}`
    } if ((this.props.type === "Properties" || this.props.type === "Negative Notice List Viewer" || this.props.type === "Site Confirmation Editor" || this.props.type === "Deal Sizing Report For Companies" || this.props.type === "Property Editor V2" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing Property Editor") && this.state.PropertyData && this.state.PropertyData.selectedSeller && (!this.state.PropertyData.selectedName || this.state.PropertyData.selectedName.length === 0)) {
      apiUrl = `${apiUrl}&sellerID=${this.state.PropertyData.selectedSeller.EstateID}`
    } if ((this.props.type === "Property Editor V2" || this.props.type === "Negative Notice List Viewer" || this.props.type === "Site Confirmation Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing-Properties") && this.state.PropertyData && this.state.PropertyData.selectedProperty_Id) {
      apiUrl = `${apiUrl}`
    } if ((this.props.type === "Property Editor V2" || this.props.type === "Negative Notice List Viewer" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Site Confirmation Editor" || this.props.type === "Deal Sizing-Properties") && this.state.PropertyData && this.state.PropertyData.selectedOpraStId) {
      apiUrl = `${apiUrl}`
    }
    if (this.state.PropertyData && this.state.PropertyData.groupFlag) {
      let key = this.state.PropertyData.groupFlag
      apiUrl = `${apiUrl}&redirecting=${key}`
    }
    if (this.state.PropertyData && this.state.PropertyData.reportType) {
      let key = this.state.PropertyData.reportType
      apiUrl = `${apiUrl}&reportType=${key}`
    }
    if (this.state.PropertyData && this.state.PropertyData.confirmedOnsite) {
      let key = this.state.PropertyData.confirmedOnsite
      apiUrl = `${apiUrl}&confirmedOnsite=${key}`
    }

    if (this.state.PropertyData && this.state.PropertyData.taskID) {
      apiUrl = `${apiUrl}&taskID=${this.state.PropertyData.taskID}`
    }
    if ((this.props.type === "Properties" || this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing Property Editor") && this.state.PropertyData && this.state.PropertyData.selectedBucket) {
      apiUrl = `${apiUrl}&matchbucket=${this.state.PropertyData.selectedBucket}`
    }
    if (this.state.isAppliedColFilter && (this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor")) {
      apiUrl = `${apiUrl}&isResultsRequired=true`
    }
    if (this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "Deal Sizing Report For Companies" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing Property Editor") {
      if (this.state.PropertyData) {
        if (this.state.PropertyData.isValidate) {
          apiUrl = `${apiUrl}&validated=true`
        }
        if (this.state.PropertyData.isexcludeValidated) {
          apiUrl = `${apiUrl}&excludeValidated=true`
        }
        if (this.state.PropertyData.isexcludePossiblyPaid) {
          apiUrl = `${apiUrl}&isexcludePossiblyPaid=true`
        }
        if (this.state.PropertyData.isexcludeProperties) {
          apiUrl = `${apiUrl}&excludeProperties=${this.state.PropertyData.isexcludeProperties}`
        }
        if (this.state.PropertyData.excludeCoOwners) {
          apiUrl = `${apiUrl}&excludeCoOwners=true`
        }
        if (this.state.PropertyData.excludeNotConfirmedOnSite) {
          apiUrl = `${apiUrl}&excludeNotConfirmedOnSite=true`
        }
        if (this.state.PropertyData.isexcludeRoyalties) {
          apiUrl = `${apiUrl}&excludeRoyalties=true`
        }
        if (this.state.PropertyData.selectedRanges) {
          let index = this.state.PropertyData.selectedRanges.indexOf("All");
          if (index === -1) {
            apiUrl = `${apiUrl}&ranges=${this.state.PropertyData.selectedRanges}`
          } else {
            apiUrl = `${apiUrl}&ranges=All`
          }
        }
      }
    }

    return apiUrl;
  }


  /**
   * 
   * @param {Object} obj 
   * @returns Function to return the lowest count value 
   */
  lowestValueAndKey = (obj) => {
    if (obj) {
      var [lowestItems] = Object.entries(obj).filter(a => a[1] > 0).sort(([, v1], [, v2]) => v1 - v2);
      if (lowestItems && lowestItems[0]) {
        return lowestItems[0]
      }
      else {
        return "Green"
      }
    }
  }

  /**
   * 
   * @param {object} response 
   * @param {string} type 
   * Checking the condition and redirecting to required bucket
   */
  clickCount = async (response, type) => {
    // if (response && response.counts && type === "Property Editor V2" && this.state.isChangeHit) {
    var lowestBucket;
    let tabs = this.state.propertyEditorTabs
    if (response && response.counts) {
      lowestBucket = this.lowestValueAndKey(response.counts)
    }
    if (response && response.counts && this.state.isChangeHit) {
      if (response.counts.Green === 0) {
        if (response.counts.Blue > 0) {
          if (response.counts.Blue > 10000) {
            this.toggletab(lowestBucket)
          } else {
            this.toggletab("Blue")
          }
        } else if (response.counts.Yellow > 0) {
          if (response.counts.Yellow > 10000) {
            this.toggletab(lowestBucket)
          } else {
            this.toggletab("Yellow")
          }
        } else if (response.counts.Brown > 0) {
          if (response.counts.Brown > 10000) {
            this.toggletab(lowestBucket)
          } else {
            this.toggletab("Brown")
          }
        } else if (response.counts.Black > 0) {
          if (response.counts.Black > 10000) {
            this.toggletab(lowestBucket)
          } else {
            this.toggletab("Black")
          }
        }
        else if (response.counts.PV > 0) {
          if (response.counts.PV > 10000) {
            this.toggletab(lowestBucket)
          } else {
            this.toggletab("PV")
          }
        } else if (response.counts.OR > 0) {
          if (response.counts.OR > 10000) {
            this.toggletab(lowestBucket)
          } else {
            this.toggletab("OR")
          }
        } else if (response.counts.SP > 0) {
          if (response.counts.SP > 10000) {
            this.toggletab(lowestBucket)
          } else {
            this.toggletab("SP")
          }
        } else {
          this.setState({
            activeTab: "Green"
          })
          // this.toggletab("Green")
          console.log("No Records to call API")
        }
      }
      await this.setState({ isChangeHit: false })
    }
  }

  /**
   * 
   * @returns the api url from props
   */
  getAPIUrl() {
    return this.props.apiUrl;
  }
  /**
   * Calling the files to set the file for options
   */
  getDataForMatch = () => {
    if (this.props.getFileOptionsforMatching) {
      this.props.getSellerName(this.state.PropertyData.selectedSeller)
      this.props.getFileOptionsforMatching(this.state.fileForOptions)
    }
  }

  /**
   * 
   * @param {object} obj 
   * getting the updated and not updated list for up property confirmation
   */
  sendUpPropConfirmation = async (obj) => {
    let body = {
      // PropertyIds: obj.coutntValue ? obj.coutntValue.split(',').map(Number) : [],
      StatePropertyIds: obj.coutntValue ? obj.coutntValue : [],
      CompanyID: obj.selectedSeller.EstateID,
      SourceState: obj.selectedState
    }
    await this.setState({ isLoading: true })
    fetchMethodRequest('POST', 'NsScoredProperties/checkProperties', body)
      .then(async (response) => {
        if (response) {
          this.setState({
            uppropConfirmationMessage: true, uppropPropertyIds: response.notUpdatedList, upconfirmProperties: response
          })
          let notUpdateArray = []
          for (let id of response.notUpdatedList) {
            notUpdateArray.push({ id: id, clicked: false })
          }
          await this.setState({ notUpdateArray: notUpdateArray })
        } else if (response && response.errorMessage) {
          this.getErrorMessage(response.errorMessage, "", "error")
        }
        await this.setState({ isLoading: false })


      }).catch((err) => {
        return err;
      });
    // `Out of ${response.totalPropertyCount} properties, ${response.updated} were update as Confirmed on site.${response.notUpdated} Properties were NOT found in the OPRA2, below are those properties: Please lot these properties in OPRA manually`
  }
  /**
   * Disabling the up prop confirmation and ids
   */
  clearOnsitemessage = async () => {
    await this.setState({ uppropConfirmationMessage: false, uppropPropertyIds: [] })
  }

  /**
   * 
   * @param {Object} obj 
   * @param {object} criteria 
   * @param {bool} changeHit 
   * @param {bool} setGoButton 
   * @param {Array} fileForOptions 
   * Setting up the filters after redirection from any tab
   */

  getFilteredValues = async (obj, criteria, changeHit, setGoButton, fileForOptions) => {
    let modifiedLabels = []
    if (changeHit) {
      await this.setState({ isChangeHit: true })
    }
    if (fileForOptions) {
      for (let name of fileForOptions) {
        modifiedLabels.push({
          label: name.companyName,
          value: {
            labelName: name.companyName,
            id: name.nameID,
          },
        })
      }
    } else {
      fileForOptions = []
    }
    if (obj && criteria) {
      await this.setState({
        PropertyData: obj,
        isAppliedColFilter: obj.selectedSeller.EstateName === this.state.PropertyData && this.state.PropertyData.selectedSeller.EstateName ? this.state.isAppliedColFilter : false,
        fileForOptions: this.props.type === "ClaimsManagement" ? modifiedLabels : fileForOptions,
        first: 0,
        redirectSellerId: null,
        negativePeriod: null,
        isRedirectedToProp: false
      })
      await this.getDataForMatch()
      criteria.page = "1"
      if (this.state.PropertyData && (this.state.PropertyData.selectedSeller && !this.state.PropertyData.selectedSeller.EstateID || this.state.PropertyData.selectedSeller && this.state.PropertyData.selectedSeller.EstateID === "")) {
        delete criteria.globalSearch
        await this.setState({
          selectFilterValue: "",
          isShowTable: false,
          totalRecordsLength: 0
        })
      }
      if (obj.selectedClaims && this.editorComponentRef) {
        this.editorComponentRef.getFileForEditor(obj.selectedClaims.EstateID)
        this.editorComponentRef.getFileForEditorForSellerRelevanceName(obj.selectedClaims.EstateID)


        // let PropertyStatusindex = criteria.criteria.findIndex(obj => obj.key === "PropertyStatus")
        // if (PropertyStatusindex !== -1) criteria.criteria.splice(PropertyStatusindex, 1)

      } else {
        if (this.editorComponentRef) {
          this.editorComponentRef.getFileForEditor(this.state.PropertyData.selectedSeller.EstateID)
          this.editorComponentRef.getFileForEditorForSellerRelevanceName(this.state.PropertyData.selectedSeller.EstateID)
        }
      }
      if ((this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor") && this.state.PropertyData.selectedSeller) {
        // this.toggletab("Green", setGoButton)
        this.getTabChanges(criteria)

        this.getNegativeNoticeSellers()
      } else {
        if (this.state.PropertyData && this.state.PropertyData.isValidate) {
          this.getTableFieldsOrder();

        }

        this.getDataFromServer(criteria)
        this.getNegativeNoticeSellers()

      }
    } else {
      var url, obj;
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (localStorage.getItem("nspropertiesparamsUrl") && params && params.from && params.from !== "AvailableToMap") {
        url = new URL(localStorage.getItem("nspropertiesparamsUrl"));
      } else {
        url = new URL(window.location.href);
      }

      obj = await this.getObjectFromCopiedUrl(url);
      let groupMessage = this.props && this.props.tabDescriptionArray ? this.props.tabDescriptionArray.filter(object => {
        return object.value.flagName === obj.groupFlag
      }) : []

      obj.groupMessage = groupMessage[0] ? groupMessage[0].value.description : ""
      obj.groupedflag = groupMessage[0] ? groupMessage[0].value.name : ""
      obj.redirectedGroup = groupMessage[0]
      let selectedProp = localStorage.getItem("matchedProperties") ? localStorage.getItem("matchedProperties") : obj.selectedProperty_Id ? obj.selectedProperty_Id : null
      let selectedstateProp = localStorage.getItem("matchedStateProperties") ? localStorage.getItem("matchedStateProperties") : obj.selectedOpraStId ? obj.selectedOpraStId : null
      localStorage.removeItem("matchedProperties")
      localStorage.removeItem("matchedStateProperties")
      obj.selectedProperty_Id = selectedProp
      obj.selectedOpraStId = selectedstateProp
      await this.setState({
        PropertyData: obj,
        isShowTable: true,
        isRedirectedToProp: params ? true : false,
        activeTab: obj.bucket,
        isChangeHit: true
      })
      this.getNegativeNoticeSellers()
      if (this.editorComponentRef) {
        this.editorComponentRef.getFileForEditor(this.state.PropertyData.selectedSeller.EstateID)
        this.editorComponentRef.getFileForEditorForSellerRelevanceName(this.state.PropertyData.selectedSeller.EstateID)
      }
      await this.getDataForMatch()
      let filtercriteria = this.state.filterCriteria
      if (this.state.activeTab === "All") {
        filtercriteria.sortfield = "updatedDate"
      }
      let index = filtercriteria["criteria"].findIndex(obj => obj.key === "bucket" && obj.add_Filter === true)
      if (index !== -1) filtercriteria["criteria"].splice(index, 1)
      if (this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor") {
        filtercriteria["criteria"].push({
          key: "bucket",
          value: this.state.activeTab,
          type: "eq",

          // type: "eq",
          add_Filter: true
        })
      }
      if (this.state.removeDataSource && obj && obj.reportType && !obj.reportType.includes("SiteConfirmationReport")) {
        let indexDataSource = filtercriteria["criteria"].findIndex(obj => obj.key === "PropertyDataSource")
        if (indexDataSource !== -1) filtercriteria["criteria"].splice(indexDataSource, 1)
      }
      let index2 = filtercriteria["criteria"].findIndex(obj => obj.key === "businessState" && obj.type === "in")
      if (index2 !== -1) filtercriteria["criteria"].splice(index, 1)
      if (obj.states && obj.states !== "null") {
        filtercriteria["criteria"].push({
          key: this.props.type === "Deal Sizing-Properties" ? "state" : "sourceState",
          value: obj.states.split(","),
          type: "in"
        })
      }
      if (obj.fiftyk && obj.fiftyk !== "null") {
        filtercriteria["criteria"].push({
          key: "propertyMaxValueAmount",
          value: 50000,
          type: "gt"
        })
      } if (obj.unknown && obj.unknown !== "null") {
        filtercriteria["criteria"].push({
          key: "propertyMaxValueAmount",
          value: 0,
          type: "nexists"
        })
      }
      if (obj.Count10kTo50k && obj.Count10kTo50k !== "null") {
        filtercriteria["criteria"].push(
          { key: "propertyMaxValueAmount", value: 10000, type: "gte" }, { key: "propertyMaxValueAmount", value: 50000, type: "lte" }
        )
      }

      if (obj.SellerProof && obj.SellerProof !== "null") {
        filtercriteria["criteria"].push(
          { key: "FileFor", value: obj.FileFor, type: "eq" }, { key: "SellerProof", value: "", type: "nexists" }
        )
      }
      if (obj.ClaimId) {
        filtercriteria["criteria"].push(
          { key: "claimID", value: obj.ClaimId, type: "eq" },
        )
      }
      if (obj.TotalProps && obj.TotalProps !== "null") {
        filtercriteria["criteria"].push(
          { key: "FileFor", value: obj.FileFor, type: "eq" }, { key: "AllProof", value: "", type: "nexists" }
        )
      }
      if (obj.ClaimId) {
        filtercriteria["criteria"].push(
          { key: "claimID", value: obj.ClaimId, type: "eq" },
        )
      }
      if (obj.NameProof && obj.NameProof !== "null") {
        filtercriteria["criteria"].push(
          { key: "FileFor", value: obj.FileFor, type: "eq" }, { key: "NameProof", value: "", type: "nexists" }
        )
      } if (obj.AddressProof && obj.AddressProof !== "null") {
        filtercriteria["criteria"].push(
          { key: "FileFor", value: obj.FileFor, type: "eq" }, { key: "AddressProof", value: "", type: "nexists" }
        )
      } if (obj.FEINProof && obj.FEINProof !== "null") {
        filtercriteria["criteria"].push(
          { key: "FileFor", value: obj.FileFor, type: "eq" }, { key: "FEINProof", value: "", type: "nexists" }
        )
      } if (obj.HolderProof && obj.HolderProof !== "null") {
        filtercriteria["criteria"].push(
          { key: "FileFor", value: obj.FileFor, type: "eq" }, { key: "HolderProof", value: "", type: "nexists" }
        )
      }

      let matchStatusIndex = filtercriteria["criteria"].findIndex(obj => (obj.key === "PropertyStatus" || obj.key === "propertyStatus") && obj.type === "in")
      if (matchStatusIndex !== -1) filtercriteria["criteria"].splice(matchStatusIndex, 1)
      if (obj.selectedPropertyStatus && obj.selectedPropertyStatus !== "null") {
        filtercriteria["criteria"].push({
          key: "propertyStatus",
          value: obj.selectedPropertyStatus.split(","),
          type: "in"
        })
      }
      if (obj.selectedPropertyReasons && obj.selectedPropertyReasons !== "null") {
        filtercriteria["criteria"].push({
          key: "PropertyStatusReason",
          value: obj.selectedPropertyReasons.split(","),
          type: "in",
          add_Filter:true
        })
      }
      if (obj.newNameIssueCode) {
        let splitIssueCode = obj.newNameIssueCode.split(",")
        if (splitIssueCode.length > 0) {
          const newArr = splitIssueCode.map(item => item === "Blank" ? "" : item);
          obj.newNameIssueCode = newArr
        }
        filtercriteria["criteria"].push({
          key: "newNameIssueCode",
          value: obj.newNameIssueCode,
          type: "in",
          
        })
        obj.newNameIssueCode = obj.newNameIssueCode.join(",")
      }
      if (obj.legacyNameIssueCode) {
        let splitLegacyIssueCode = obj.legacyNameIssueCode.split(",")
        if (splitLegacyIssueCode.length > 0) {
          const LegacyNewArr = splitLegacyIssueCode.map(item => item === "Blank" ? "" : item);
          obj.legacyNameIssueCode = LegacyNewArr
        }
        filtercriteria["criteria"].push({
          key: "nameIssueCode",
          value: obj.legacyNameIssueCode,
          type: "in"
        })
        obj.legacyNameIssueCode = obj.legacyNameIssueCode.join(",")
      }
      if (obj.ConfirmedOnsiteDate) {
        filtercriteria["criteria"].push({
          key: "confirmedOnsiteDate",
          value: new Date(obj.ConfirmedOnsiteDate),
          type: "dategte",
          add_Filter:true
        })
      }
      if (obj.ScoreDate) {
        filtercriteria["criteria"].push({
          key: "ScoreDate",
          value: new Date(obj.ScoreDate),
          type: "dategte",
          add_Filter:true
        })
      }

      if (obj.selectedPropertyReasonsForResource && obj.selectedPropertyReasonsForResource !== "null") {
        filtercriteria["criteria"].push({
          key: "PropertyStatusReason",
          value: obj.selectedPropertyReasonsForResource.split(","),
          type: "in",
          add_Filter:true
        })
      }
      if (obj.maxValueAmount) {
        filtercriteria["criteria"].push(
          { key: "PropertyMaxValueAmount", value: obj.maxValueAmount, type: "gte" }
        )
      }
      if (obj.nameMatchScore) {
        filtercriteria["criteria"].push(
          { key: "nameMatchScore", value: obj.nameMatchScore, type: "gte" }
        )
      }
      if (obj.dataSourceArrayNew && obj.dataSourceArrayNew.length > 0) {
        filtercriteria["criteria"].push(
          { key: "PropertyDataSource", value: obj.dataSourceArrayNew.split(","), type: "in" }
        )
      } else {
        let indexDataSource = filtercriteria["criteria"].findIndex(obj => obj.key === "PropertyDataSource")
        if (indexDataSource !== -1) filtercriteria["criteria"].splice(indexDataSource, 1)
      }

      if (obj.FileFor && obj.FileFor !== "null" && obj.FileFor.length > 0) {
        filtercriteria["criteria"].push({
          key: "fileFor",
          value: obj.FileFor,
          type: "eq"
        })
      } else if (obj.reportType && (obj.reportType === "ReadyToFile" || obj.reportType === "OnHold" || obj.reportType === "SellerNegativeNotice" || obj.reportType === "ReadyToReview") && obj.FileFor && obj.FileFor == "null") {
        filtercriteria["criteria"].push({
          key: "fileFor",
          value: null,
          type: "eq"
        })
      } else if (obj.reportType && (obj.reportType === "ReadyToFile" || obj.reportType === "OnHold" || obj.reportType === "SellerNegativeNotice" || obj.reportType === "ReadyToReview")) {
        filtercriteria["criteria"].push({
          key: "fileFor",
          value: "",
          type: "eq"
        })

      }
      if (obj.FileFor === "null") {
        obj.FileFor = null
      }

      if (obj.states && obj.states !== "null") {

      } else if (obj.reportType && (obj.reportType === "ReadyToFile" || obj.reportType === "OnHold" || obj.reportType === "SellerNegativeNotice") && obj.states && obj.states == "null") {
        await this.setState({ sourceStateNull: true, reportTypeEmpty: obj.reportType })

      } else if (obj.reportType && (obj.reportType === "ReadyToFile" || obj.reportType === "OnHold" || obj.reportType === "SellerNegativeNotice")) {
        await this.setState({ sourceStateEmpty: true, reportTypeEmpty: obj.reportType })

      }
      if (obj.HolderName && obj.HolderName !== "null") {
        filtercriteria["criteria"].push({
          key: "holderName",
          value: obj.HolderName,
          type: "eq"
        })
      } else if (obj.reportType && obj.reportType === "CA-N" && obj.HolderName && obj.HolderName === "null") {
        filtercriteria["criteria"].push({
          key: "holderName",
          value: null,
          type: "eq"
        })
      } else if (obj.reportType && obj.reportType === "CA-N") {
        filtercriteria["criteria"].push({
          key: "holderName",
          value: "",
          type: "eq"
        })
      }
      if (obj.HolderName === "null") {
        obj.HolderName = null
      }
      if (obj.states === "null") {
        obj.states = null
      }
      if (this.propertyFilterRef) { this.propertyFilterRef.getData(obj) }
      if (this.dealSizingpropertyFilterRef) { this.dealSizingpropertyFilterRef.getData(obj) }
      let body = {
        propertyId: this.state.PropertyData.selectedProperty_Id ? this.state.PropertyData.selectedProperty_Id.toString() : null,
        statePropertyId: this.state.PropertyData.selectedOpraStId ? this.state.PropertyData.selectedOpraStId.toString() : null
      }
      this.setState({
        postBodyFile: body
      })
      // this.getTabChanges(this.state.filterCriteria)
      this.getDataFromServer(this.state.filterCriteria, '', '', body)
      localStorage.removeItem("nspropertiesparamsUrl")
      window.history.pushState(null, null, `propertyEditorV2`);
    }

  }

  /**
   * @param {Array} fileOptions 
   * Setting the file for options for inline edit of claims management
   */
  getFileOptions = async (fileOptions) => {
    await this.setState({
      fileForOptions: fileOptions
    })
  }

  /**
   * 
   * @param {String} url 
   * @returns Getting the values from the redirected url and setting in object
   */
  getObjectFromCopiedUrl = async (url) => {
    var sellerName = url.searchParams.get("sellerName");
    var sellerId = url.searchParams.get("sellerId");
    var nameId = url.searchParams.get("nameId");
    var isValidate = url.searchParams.get("isValidated")
    var isBucket = url.searchParams.get("bucket")
    var selectedStates = url.searchParams.get("state")
    var Count10kTo50k = url.searchParams.get("10kto50k")
    var SellerProof = url.searchParams.get("SellerProof")
    var ClaimId = url.searchParams.get("ClaimId")
    var TotalProps = url.searchParams.get("TotalProps")
    var NameProof = url.searchParams.get("NameProof")
    var AddressProof = url.searchParams.get("AddressProof")
    var FileFor = url.searchParams.get("FileFor")
    var FEINProof = url.searchParams.get("FEINProof")
    var HolderProof = url.searchParams.get("HolderProof")
    var taskID = url.searchParams.get("taskID")
    var fiftyk = url.searchParams.get("50k")
    var reportType = url.searchParams.get("reportType")
    var confirmedOnsite = url.searchParams.get("confirmedOnsite")
    var unknown = url.searchParams.get("unknown")
    var HolderName = url.searchParams.get("HolderName")
    var Property_Id = url.searchParams.get("propertyId")
    var maxValueAmount = url.searchParams.get('maxValueAmount')
    var dataSourceArrayNew = url.searchParams.get("dataSourceArrayNew")
    var state_property_Id = url.searchParams.get("statePropertyId")
    var is_excludeValidated = url.searchParams.get("isexcludeValidated")
    var dataSource = url.searchParams.get("dataSource")
    var is_excludeProperties = url.searchParams.get("isexcludeProperties")
    var exclude_CoOwners = url.searchParams.get("excludeCoOwners")
    var isexcludePossiblyPaid = url.searchParams.get("isexcludePossiblyPaid")
    var selectedPropertyStatus = url.searchParams.get("selectedPropertyStatus")
    var selectedPropertyReasons = url.searchParams.get("selectedPropertyReasons")
    var newNameIssueCode = url.searchParams.get("newNameIssueCode")
    var legacyNameIssueCode = url.searchParams.get("legacyNameIssueCode")
    var from = url.searchParams.get("from")
    var ConfirmedOnsiteDate = url.searchParams.get("ConfirmedOnsiteDate")
    var ScoreDate = url.searchParams.get("ScoreDate")
    var selectedPropertyReasonsForResource = url.searchParams.get("selectedPropertyReasonsForResource")
    var isexcludeRoyalties = url.searchParams.get("excludeRoyalties")
    var selectedRanges = url.searchParams.get("ranges")
    var excludeNotConfirmedOnSite = url.searchParams.get("excludeNotConfirmedOnSite")
    var ClaimId = url.searchParams.get("ClaimId")
    var groupFlag = url.searchParams.get("GroupFlag")
    var nameMatchScore = url.searchParams.get("nameMatchScore")
    let obj = {}
    let SellerfromURL = {
      EstateName: sellerName,
      EstateID: sellerId
    }
    obj.selectedSeller = SellerfromURL
    if (nameId !== "null") {
      obj.selectedName = nameId
      obj.nameId = nameId
    }
    obj.ClaimId = ClaimId
    obj.selectedPropertyStatus = selectedPropertyStatus
    obj.selectedPropertyReasons = selectedPropertyReasons
    obj.newNameIssueCode = newNameIssueCode
    obj.legacyNameIssueCode = legacyNameIssueCode
    obj.ConfirmedOnsiteDate = ConfirmedOnsiteDate
    obj.ScoreDate = ScoreDate
    obj.selectedPropertyReasonsForResource = selectedPropertyReasonsForResource
    obj.selectedProperty_Id = Property_Id
    obj.maxValueAmount = maxValueAmount
    obj.taskID = taskID
    obj.bucket = isBucket
    obj.from = from
    obj.SellerProof = SellerProof
    obj.ClaimId = ClaimId
    obj.TotalProps = TotalProps
    obj.nameMatchScore = nameMatchScore
    obj.reportType = reportType
    obj.confirmedOnsite = confirmedOnsite
    obj.NameProof = NameProof
    obj.AddressProof = AddressProof
    obj.dataSourceArrayNew = dataSourceArrayNew
    obj.dataSourceRedirect = dataSource
    obj.HolderName = HolderName
    obj.FileFor = encodeURIComponent(FileFor)
    obj.FEINProof = FEINProof
    obj.HolderProof = HolderProof
    obj.Count10kTo50k = Count10kTo50k
    obj.fiftyk = fiftyk
    obj.unknown = unknown
    obj.isexcludeValidated = is_excludeValidated
    obj.selectedOpraStId = state_property_Id
    obj.isexcludeProperties = is_excludeProperties
    obj.isexcludeRoyalties = isexcludeRoyalties
    obj.isexcludePossiblyPaid = isexcludePossiblyPaid
    obj.excludeCoOwners = exclude_CoOwners
    obj.selectedRanges = selectedRanges
    obj.excludeNotConfirmedOnSite = excludeNotConfirmedOnSite
    obj.groupFlag = groupFlag
    if (selectedStates) {
      obj.states = selectedStates
    }
    await this.setState({ removeDataSource: true })

    if (isValidate === "true") {
      obj.isValidate = isValidate
    }
    return obj;
  }


  goBack = async () => {
    localStorage.setItem("hitback", "yes")
    await this.setState({ goBackToClaims: true })
  }
  /**
   * 
   * @returns return api url from props
   */
  getFilterUrl() {
    return this.props.apiUrl;
  }

  /**
   * 
   * @param {Array} data 
   * @returns Changing the data 
   * for change the format for the downloadig data through export
   */
  formatChange = (data) => {
    data.forEach((item) => {
      var notearry = []
      for (let key in item) {
        if (item[key] && item[key]) {
        } else {
          item[key] = ""
        }
        if (item[key] && item[key].name) {
          item[key] = item[key].name;
        }
        if (key === "matchStatusNotes") {
          if (item[key] && item[key].length > 0) {
            for (var obj of item[key]) {
              if (obj.note) {
                notearry.push(obj.note)
              }
            }
            if (data.length > 0) {
              item[key] = this.getArrayVal(JSON.stringify(notearry))
            }
          }

        } else if (typeof item[key] == "object") {
          item[key] = this.getArrayVal(JSON.stringify(item[key]))
        }
        let formatValues = ["PropertyEstimatedMaxValueAmountSum", "BlueEstimatedValue", "GreenEstimatedValue", "SP3EstimatedValue", "SP2EstimatedValue", "SP1EstimatedValue"]
        if (formatValues.includes(key)) {
          item[key] = parseInt(item[key] ? item[key].toFixed(0) : 0)
        }
        let DealValues = ["company"]
        if (DealValues.includes(key) && item[key].includes(" - ") && item[key].includes(" (")) {
          item[key] = this.changeDealNameFormatForExcel(item[key])
        }

      }
    });
    return data
  }
  changeDealNameFormatForExcel = (val) => {
    var y
    if (val) {
      var split = val.split(" - ")
      let x = split[1]
      y = x.split(" (")
    }
    return y[0]
  }

  /**
   * 
   * @returns Component that is used for Download excel sheet
   */
  getExcelDownload = () => {
    let todaydate = dateFormats.formatDate(
      new Date(),
      "MM/DD/YYYY", "nhg")
    return (
      <span className='excel-React d-none'>
        <ExcelReact
          EstateName={this.props.tabOptions && this.state.activeTab !== this.props.type ? `${this.state.activeTab}-${this.props.type}` : `${this.props.type}`}
          sheets={this.state.sheets}
          type="Sellers"
        />
      </span>
    )
  }

  handleSelectedAction = async () => {
    this.setState({ isLoading: true })
    this.handlePercentage()
    var exportedData
    let apiUrl = this.props.apidataforExport ? this.props.apidataforExport : `${this.state.apiUrlforExport}`;
    let responsekey = this.props.apiResponseKey
    let method = ""
    let bodyData = {}
    bodyData = this.state.postBodyFile
    method = "POST"
    fetchMethodRequest(method, `${apiUrl}&type=exportToCsv`, bodyData)
      .then(async (response) => {
        exportedData = response[responsekey]
        await this.formatforExcel(exportedData)
      })

  }

  formatforExcel = async (exportedData, headersData) => {
    if (exportedData && exportedData.length > 0) {
      let headers = []
      let columns
      if (this.props.type !== "RFI View") {
        columns = this.props.tabOptions ? this.props.getTableFields(this.state.activeTab) : JSON.parse(localStorage.getItem(`${this.props.type}_column_order`));
      } else {
        columns = this.state.tablefieldsToShow
      }
      if (columns && columns.details) {
        columns = columns.details[this.state.selectedViewType];
        if (columns === undefined) {
          columns = this.props.getTableFields()
        }
      }
      for (let key of columns) {
        let index = headers.findIndex(obj => obj.key === key.header)
        if (index === -1 && key.field.length > 0 && key.field !== "downloadBtn" && key.field !== "Actions") {
          headers.push(
            { label: key.header ? key.header.charAt(0).toUpperCase() + key.header.slice(1) : key.field, field: key.field }
          )
        }
      }
      exportedData = this.updateDateFormat(exportedData, "yyyy/MM/DD", "exportExcel");
      let obj = [{
        name: await this.removeSpecialCharactersAndWhitespace(this.props.type, "notencode"),
        headers: headersData ? headersData : headers,
        data: await this.formatChange(exportedData)
      }]
      await this.setState({
        sheets: obj,
        isLoading: false
      })
      document.getElementsByClassName("excel-React")[0].children[0].click()
    } else {
      await this.setState({
        sheets: [],
        errorMessage: "No Records To Download",
        errorResponse: true,
        errorColor: "red",
        isLoading: false
      })
    }
  }


  //getAll Call
  /**
   * Getting the attributes from api call to send to form modal
   */
  attributesgetCall = async () => {
    fetchMethodRequest('GET', apiCalls.getAll)
      .then(async (response) => {
        if (response) {
          await this.setState({
            getAllresponse: response
          })
        }
      }).catch((err) => {
        return err;
      });
  }

  /**
   * 
   * @param {Object} item 
   * Method to get the notes based on id for showing as a model
   */
  showNotesData = async (item) => {
    fetchMethodRequest('GET', `NsScoredProperties/propertyNotes?propertyId=${item.propertyID}&companyId=${this.state.PropertyData && this.state.PropertyData.selectedSeller ? this.state.PropertyData.selectedSeller.EstateID : item.companyID}`)
      .then(async (response) => {
        if (response) {
          await this.setState({
            validatedNotesId: item.propertyID,
            getNotesresponse: response.notes,
            isValidData: true
          })
        }
      }).catch((err) => {
        return err;
      });
  }
  /**
   * 
   * @param {Object} item 
   * @returns opening the seller in new tab based on icon beside edit
   */
  openSellerInNewTab = async (item) => {
    let url = `${window.location.protocol}//${window.location.host}/sellersEdit/deal/${item.DealID}`
    return <a href={url} target="_blank"> </a>
  }
  openErrorIcon = async (item) => {
    await this.propertyLoaderRef.setErrorFilesBasedOnRowClick(item)
  }
  /**
   * Opening the form modal and setting up the values required
   */

  openFormModal = async (rowData, type, claimType, paymentItem, index) => {
    if (this.props.customForm) {
      this.props.customForm(rowData)
    } else {
      if (rowData === 'add') {
        await this.setState({
          formType: 'add',
        });
      }
      if (this.props.type !== "List View" && this.props.type !== "Site Confirmation Editor" && this.props.type !== "RFI View" && this.props.type !== "Negative Notice List Viewer" && this.props.type !== "HighValueProperties" && this.props.type !== "Research Request Viewer" && this.props.type !== "Seller Negative Notice Viewer" && (this.props.type !== "Property Editor V2" || this.props.type === "Deal Sizing Property Editor") && this.props.type !== "Recovery Planner V2" && this.props.type !== "Sellers" && this.props.type !== "crMatrix" && this.props.type !== "Rules Configuration Workflow" && this.props.type !== 'Deal Mgmt') {
        await this.setState({
          isOpenFormModal: true,
          formType: type ? type : 'add',
          selectedRows: ''
        });
      }
      if (type === 'view') {
        if (this.state.isOpenFormModal && this.formModalRef) {
          await this.formModalRef.getViewData(rowData);
        }
      }

      if (type === 'edit') {
        if (this.state.isOpenFormModal && this.formModalRef) {
          await this.setState({
            selectedInfo: rowData

          })
          await this.formModalRef.getRowData(rowData, 'edit', paymentItem ? paymentItem : "", index);
        }

        var data = rowData && rowData.title ? rowData && rowData.title.includes("RFI") : false
        if (this.props.type === "List View" || this.props.type === "HighValueProperties" || this.props.type === "Site Confirmation Editor" || this.props.type === "Research Request Viewer" || this.props.type === "Seller Negative Notice Viewer" || this.props.type === "RFI View" || this.props.type === "Negative Notice List Viewer" || this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "Recovery Planner V2" || this.props.type === "Rules Configuration Workflow" && type) {
          await this.setState({
            taskrowData: rowData,
            selectedObj: rowData,
            formType: type ? type : 'add',
            iskanbanModal: data === true ? false : true,
            isOpenRFIModal: data === true ? true : false,
          });
        }
      }

      if (this.props.type === "List View" || this.props.type === "HighValueProperties" || this.props.type === "RFI View" || this.props.type === "Research Request Viewer" || this.props.type === "Seller Negative Notice Viewer" || this.props.type === "Negative Notice List Viewer" || this.props.type === "Recovery Planner V2" || this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "Rules Configuration Workflow") {
        var data = rowData && rowData.title ? rowData.title.includes("RFI") : false
        await this.setState({
          selectedObj: {},
          formType: type ? type : 'add',
          iskanbanModal: data === true ? false : true,
          isOpenRFIModal: data === true ? true : false,
        });
      }
    }
  }

  //close form modal
  /**
   * method to disable all atributes to close edit model
   */
  closeFormModal = async () => {
    this.setState({
      isOpenFormModal: false,
      iskanbanModal: false,
      createTaskModal: false,
      issellerModal: false,
      createTask: "",
      iskanbanModalForHighProperties: false,
      createTaskAdd: "",
      iskanbanModalForHighProperties: false,
      iscrMatrixModal: false,
      sellerEditType: ""
    })
    if (this.state.isOpenTable && this.tableModalRef) {
      this.tableModalRef.getTaskDetails()
    }
    if (this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor") {
      this.getDataFromServer(this.state.filterCriteria)
    }
    if (this.props.clearValues) {
      this.props.clearValues()
    }

  }
  /**
   * 
   * @param {String} e 
   * Closing the task model and display message for already connected
   */
  cancelCreateTaskModal = async (e) => {
    let propertyIds = this.state.selectedRows.filter(u => u.connectedTaskID != null && u.connectedTaskID.includes(e)).map(u => u.propertyID).toString()
    if (this.props.clearValues) {
      this.props.clearValues()
    }
    await this.setState({ createTaskModal: false, createTask: "", createTaskAdd: "" })
    this.getErrorMessage(`Properties are already connected to selected Task with Opra property ids ${propertyIds}`, "", "error")
  }
  /**
   * 
   * @param {String} e 
   * Closing the kanban model and display message for already connected
   */
  cancelKanbanModal = async (e) => {
    let propertyIds = this.state.selectedRows.filter(u => u.connectedTaskID != null && u.connectedTaskID.includes(e)).map(u => u.propertyID).toString()
    if (this.props.clearValues) {
      this.props.clearValues()
    }
    await this.setState({ iskanbanModal: false, iskanbanModalForHighProperties: false, createTask: "", createTaskAdd: "" })
    this.getErrorMessage(`Properties are already connected to selected Task with Opra property ids ${propertyIds}`, "", "error")
  }

  handleunassigned = async (e) => {
    await this.setState({
      unassignedOnly: this.state.unassignedOnly === true ? false : true
    })
    if (this.state.unassignedOnly) {
      await this.setState({
        first: 0
      })
      this.state.filterCriteria.page = 1
    }
    this.getDataFromServer(this.state.filterCriteria)
  }
  onChangeDepartment = async (e, type, departmentIdArray) => {
    let departmentArr = {
      departMentIdArray: departmentIdArray,
      selectedDepartment: e,
    }
    await this.setState({
      [type]: e,
      departmentIdArray: departmentIdArray,
      departmentArr: departmentArr
    })
    // await this.getDataFromServer(this.state.filterCriteria)
  }

  getStateFIlingValues = async (filter, subCategory, category, stateSubCategories, stateDealType, UpState, type) => {
    if (!filter.page) {
      filter.page = 1
    }
    if (type === "clear") {
      this.getremoveFilters()
    }
    if (!filter.limit) {
      filter.limit = this.state.rows
    }
    this.setState({
      isLoading: true, reasonByRow: false
    })
    if (filter && filter.criteria.length > 0) {
      return fetchMethodRequest('GET', `StateUpFiling?filter=${JSON.stringify(filter)}`)
        .then(async (response) => {
          if (response && response.stateUpFilings && response.stateUpFilings.length > 0) {
            await this.setState({
              allUsersData: JSON.parse(JSON.stringify(response.stateUpFilings)),
            })
            // await this.showDatatable("true")
          } else if (response && response.stateUpFilings && response.stateUpFilings.length === 0) {
            await this.setState({
              editedindex: null,
              attributeFields: [],
              allUsersData: []
            })
            await this.showDatatable("true")
          } else if (response && response.errorMessage) {
            await this.setState({
              editedindex: null,
              attributeFields: [],
            })
          }
          await this.setState({
            progress: 100,
            responsesaveData: JSON.parse(JSON.stringify(response.stateUpFilings)),
            isLoading: false,
            disableIcon: false,
            isDisabledTab: false,
            filterCriteria: filter,
            stateSubCategory: subCategory,
            stateCategory: category,
            stateDealType: stateDealType,
            UpState: UpState,
            stateSubCategories: stateSubCategories,
            totalRecordsLength: response.pagination.totalCount,
          })
        }).catch((err) => {
          return err;
        });
    }



  }
  OpenConfirmaionModelorSave = async () => {
    await this.setState({
      isopenConfirmaionModelforSave: true
    })
  }

  closeConfirmationModalforSave = async (type) => {
    await this.setState({
      isopenConfirmaionModelforSave: false
    })
    if (type === "close") {
      await this.getDataFromServer()
      this.CancelMultiLine()
    } else {
      this.handleCheckValidations()
    }
  }

  selectedOpra = async (val) => {
    await this.setState({ selectedOpra: val })
    if (this.props.selectedOpra) {
      await this.props.selectedOpra(val)
    }
    if (this.state.PropertyData && this.state.PropertyData.selectedSeller && this.state.PropertyData.selectedSeller.EstateID && this.editorComponentRef) {
      this.editorComponentRef.getFileForEditor(this.state.PropertyData.selectedSeller.EstateID)
      this.editorComponentRef.getFileForEditorForSellerRelevanceName(this.state.PropertyData.selectedSeller.EstateID)

    }
  }
  makeNoteEditedFalse = async () => {
    await this.setState({ edited: "no" })
    if (this.editorComponentRef.makeNoteEditedFalse) {
      await this.editorComponentRef.makeNoteEditedFalse()
    }
  }
  getpropertyFilters() {
    return (
      <>
        <PropertyFilter
          onRef={(ref) => this.propertyFilterRef = ref}
          updateDimensions={this.updateDimensions}
          filterCriteria={this.state.filterCriteria}
          closeSaveModelPopUp={this.closeSaveModelPopUp}
          makeNoteEditedFalse={this.makeNoteEditedFalse}
          openFormModal={this.openFormModal}
          groupMessage={this.state.groupMessage}
          OpenConfirmaionModelorSave={this.OpenConfirmaionModelorSave}
          getFilteredValues={this.getFilteredValues}
          getremoveFilters={this.getremoveFilters}
          defaultExclusionNote={this.state.defaultExclusionNote}
          getTableFieldItem={this.getTableFieldItem()}
          sendUpPropConfirmation={this.sendUpPropConfirmation}
          isDisabledTab={this.state.isDisabledTab}
          openShowHideColumnsModal={this.openShowHideColumnsModal}
          allUsersData={this.state.allUsersData}
          tabDescriptionArray={this.props.tabDescriptionArray}
          loggedUserName={this.state.loginData.displayName}
          clearData={this.clearData}
          selectedOpra={this.selectedOpra}
          isShowTable={this.isShowTable}
          getUrlbyParams={this.getUrlbyParams}
          PropertySharebody={this.state.PropertySharebody}
          lastScoredDate={this.state.lastScoredDate}
          getErrorMessage={this.getErrorMessage}
          type={this.props.type}
          activeTab={this.state.activeTab}
          missingOPRAPropertyIds={this.state.response && this.state.response.missingOPRAPropertyIds ? this.state.response.missingOPRAPropertyIds : []}
          missingStatePropertyIds={this.state.response && this.state.response.missingStatePropertyIds ? this.state.response.missingStatePropertyIds : []}
          selectedViewType={this.selectedViewType}
          getFileOptions={this.getFileOptions}
          settingsData={this.props.settingsData}
          getErrorResponseFromServer={this.getErrorResponseFromServer}
          uniqueAddressLines={this.state.uniqueAddressLines}
          uniquePropertyStatuses={this.state.uniquePropertyStatuses}
          uniqueStates={this.state.uniqueStates}
          getDataFromServer={this.getDataFromServer}
          IssueCodes={this.state.IssueCodes} />

      </>
    )
  }

  getSearchedCompany = async => {
    return this.state.searchedCompany
  }

  getdataTableFields = async () => {
    let tableFields = this.props.getTableFields();
    await this.setState({
      tableFields: tableFields,
      originalTableFields: tableFields,
      tablefieldsToShow: tableFields,
    })
  }

  getConfirmaionModelforSave() {
    return (
      <ConfirmaionModelorSave
        openConfirmationModal={this.state.isopenConfirmaionModelforSave}
        closeConfirmationModal={this.closeConfirmationModalforSave}
        shownText={"Are you sure you would like to undo the updated changes for claims?"}
      // confirm={this.handleCheckValidations}
      />
    )
  }

  submitActionsData = async (method, url, body) => {
    return fetchMethodRequest(method, url, body)
      .then(async (response) => {
        let sessionexpired = localStorage.getItem('sessionexpired')
        if (sessionexpired === "true") {
          this.setState({ sessionExpiryModal: true })
        }
        await this.setState({
          openDeleteModal: false,
          actions: '',
          selectedRows: '',
          selectedRowsId: ''
        });
        if (response && response.respCode) {
          // showToasterMessage(response.respMessage, 'success');
          this.getDataFromServer();
        } else if (response && response.errorMessage) {
          //showToasterMessage(response.errorMessage, 'error');
        }
      }).catch((err) => {
        return err;
      });
  }

  handleActions = async (apiType) => {
    let apiUrl = this.state.apiUrl;
    let method, url, body = {}
    if (this.state.confirmType === 'Delete' || this.state.confirmType === 'Block' || this.state.confirmType === 'UnBlock') {
      url = `${apiUrl}/${apiType}`;
      method = 'POST';
      body = {
        selectedIds: this.state.selectedRowsId
      }
    }
    this.submitActionsData(method, url, body)
  }

  // delete selected row
  deleteSelectedRow = async () => {
    let apiUrl = this.state.apiUrl;
    let method, url, body = {}
    let id = this.state.deleteRowDataId._id ? this.state.deleteRowDataId._id : this.state.deleteRowDataId
    url = `${apiUrl}/${id}`;
    // hari why this crud
    if (this.props.type === "Deal Sizing Queue" || this.props.type === "Regular Scoring Queue") {
      method = "PUT"
      this.state.deleteRowDataId.status = "Cancel"
      body = this.state.deleteRowDataId
    } else {
      method = 'DELETE';
      body = ''
    }
    return fetchMethodRequest(method, url, body)
      .then(async (response) => {
        let sessionexpired = localStorage.getItem('sessionexpired')
        if (sessionexpired === "true") {
          this.setState({ sessionExpiryModal: true })
        }
        await this.setState({
          openDeleteModal: false
        });
        if (response && response.respCode) {
          //showToasterMessage(response.respMessage, 'success');
          this.getDataFromServer(this.state.filterCriteria);
        } else if (response && response.errorMessage) {
          // showToasterMessage(response.errorMessage, 'error');
          this.getErrorMessage(response.errorMessage, "", "error")
        }
      }).catch((err) => {
        return err;
      });
  }

  //close delete modal
  closeDeleteModal = async () => {
    this.setState({
      openDeleteModal: false,
      actions: ''
    })
  }

  // conformation for delete item
  deleteConfirmAction = async (rowData, selectActions) => {
    let selectedRowsId = [];
    let selectedRows = [...this.state.selectedRows]
    if (selectedRows && selectedRows.length && selectedRows.length > 0) {
      selectedRows.forEach((item, index) => {
        selectedRowsId.push(item._id)
      })
      await this.setState({
        selectedRowsId: selectedRowsId,
        openDeleteModal: true,
        selectActions: selectActions,
      })
    }
    if (rowData) {
      this.setState({
        deleteRowDataId: rowData,
        openDeleteModal: true,
        selectActions: selectActions,
      });
    }
  }

  /**
   * return column obj
   */
  tablefieldsforGrid = async (columnObj) => {
    if (columnObj) {
      await this.setState({
        tablefieldsToShow: columnObj
      })
    } else {
      return this.state.tablefieldsToShow
    }
  }

  //change dropdown elememt
  changeDropDownElement = (event) => {
    this.dt.filter(event.target.value, event.target.name, 'equals');
    this.setState({
      [event.target.name]: event.value
    });
  }

  getTableFieldItem(field) {
    for (let i = 0; i < this.state.tablefieldsToShow.length; i++) {
      if (this.state.tablefieldsToShow[i].field === field) {
        return this.state.tablefieldsToShow[i];
      }
      if (this.state.tablefieldsToShow[i].fieldType === field) {
        return this.state.tablefieldsToShow[i]['field'];
      }
    }
    return null;
  }

  getColorFromOptions(options, name) {
    if (options) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].value === name) {
          return options[i].color;
        }
      }
    }
    return 'success';
  }

  getUserData(_id, type) {
    let data = this.state.allUsersData;
    for (let i = 0; i < data.length; i++) {
      if (data[i]['_id'] === _id) {
        return i
      }
    }
  }

  getIconValue(rowData, labelKey) {
    if (labelKey && labelKey.options && labelKey.options.length > 0) {
      for (let i = 0; i < labelKey.options.length; i++) {
        if (labelKey.options[i].value === rowData[labelKey.field]) {
          return labelKey.options[i].displayText;
        }
      }
    }
    return '';
  }

  async getAUserData(_id, type) {
    let filterCriteria = { "limit": 20, "page": "1", "criteria": [], "sortfield": "CompanyName", "direction": "asc", "globalSearch": { "value": _id, "type": "user" } }
    let apiUrl = `${apiCalls.Companies}?filter=${JSON.stringify(filterCriteria)}`
    return fetchMethodRequest('GET', apiUrl)
      .then(async (response) => {
        await this.setState({ filterData: response.companies[0] })
      })
  }

  openViewModal = async (rowData, type, screen) => {
    if (screen && screen === "dashboard") {
      await this.getAUserData(rowData['_id'], '_id')
      rowData = this.state.filterData
    }
    if (this.props.type === "Company") {
      window.history.pushState(null, null, `${this.props.routeTo}`);
    }
    if (rowData) {
      await this.setState({
        redirectCompanyId: rowData["CompanyID"] ? rowData["CompanyID"] : rowData["CIK"],
        CompanyDetailView: true
      })
    }
    else {
      await this.setState({
        // redirectCompanyId: id,
        CompanyDetailView: false
      })
    }
  }

  closeViewModal = async () => {
    this.setState({
      openViewModal: false
    });
  }

  //on changing pagination
  onPageChange = async (event) => {
    let newArray = this.checkDataisEdited()
    if (newArray && newArray.length > 0) {
      this.OpenConfirmaionModelorSave()
    } else {
      let filterCriteria = this.state.filterCriteria;
      if (event && event.rows) {
        let currentPage = event.page + 1;
        filterCriteria['limit'] = event.rows;
        filterCriteria['page'] = currentPage;
        this.setState({
          rows: event.rows,
          page: event.page,
          first: event.first,
          filterCriteria: filterCriteria,
          isLoading: true
        })
      }
      this.handlePercentage("1")
      if (this.props.isMatchproperties) {
        this.props.getResponseforSelectedPropertyData(filterCriteria)

      } else if (this.props.type === "State Attributes") {
        await this.getStateFIlingValues(this.state.filterCriteria, this.state.stateSubCategory, this.state.stateCategory, this.state.stateSubCategories, this.state.stateDealType, this.state.UpState)

      } else {
        this.getDataFromServer(filterCriteria)
      }
    }

  }

  scrollWin() {
    if (this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor") {
      let filterCriteria = this.state.filterCriteria;
      if (filterCriteria.limit > 30)
        window.scrollTo(0, document.body.scrollHeight);
    }
  }


  //sorting fields
  sortChange = (event) => {
    let data = this.props.type === "Labels" || this.props.type === "Departments" || this.props.type === "Purposes" || this.props.type === "Investor" || this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "OPRA2 Property Editor" ?
      'asc'
      : this.props.sortDirection ? this.props.sortDirection : "desc";
    this.setState({ selectedRows: '' })
    let sortCount = this.state.sortCount;
    if (event && event['sortField']) {
      sortCount = sortCount === 0 ? sortCount + 1 : 0;
      let sortField
      if (this.state.activeTab === "SP") {
        sortField = `ParentPropertyID, PropertyReviewedFlag, ${event['sortField']}`
      } else {
        sortField = event['sortField'];
      }
      let filterCriteria = {
        direction: event.selectedOption === "unsort" ? data : event.selectedOption,
        sortfield: event.selectedOption === "unsort" ? this.props.sortField ? this.props.sortField : "created" : sortField,
        limit: this.state.filterCriteria.limit,
        page: this.state.filterCriteria.page,
        criteria: this.state.filterCriteria['criteria']
      }
      if (this.state.filterCriteria && this.state.filterCriteria.globalSearch) {
        filterCriteria.globalSearch = this.state.filterCriteria.globalSearch
      }
      this.setState({
        sortCount: sortCount,
        isSortField: event.selectedOption === "unsort" ? "" : sortField,
        isLoading: true,
        filterCriteria: filterCriteria,
        isignoremultiSortField: true
      });
      this.handlePercentage('1')
      if (this.props.type === "State Attributes") {
        this.getStateFIlingValues(filterCriteria, this.state.stateSubCategory, this.state.stateCategory, this.state.stateSubCategories, this.state.stateDealType, this.state.UpState)
      } else {
        this.getDataFromServer(filterCriteria)
      }
    }
  }

  cancelSessionWarningModal = async () => {
    await this.setState({
      sessionWarning: false
    })
  }
  cancelexpiredSessionModal = async () => {
    await this.setState({
      expiredSessionModal: false
    })
  }
  getSessionWraningModal() {
    return (
      <SessionWarningModal
        openConfirmationModal={this.state.sessionWarning}
        cancelSessionWarningModal={this.cancelSessionWarningModal}
        getData={this.getDataFromServer}
        filterCriteria={this.state.filterCriteria}

      />
    )
  }
  expiredSessionModal() {
    return (
      <ExpiredSessionModal
        openConfirmationModal={this.state.expiredSessionModal}
        cancelSessionWarningModal={this.cancelexpiredSessionModal}
        getData={this.getDataFromServer}

      />
    )
  }

  toggleActions(id, name) {
    this.setState(prevState => (
      {
        dropdownOpen: !prevState.dropdownOpen
        , rowName: name
      }));
  }

  isConfirmFilter = async (val, type) => {
    if (val && val.filters) {
      let fieldName = Object.keys(val.filters)[0];
      if ((val.filters[fieldName].value.length === 0 || val.filters[fieldName].value.length === undefined) && this.props.type !== "OpenCorporateSearch") {
        await this.setState({ isApplyFilter: true, filteredCol: val })
        this.getConfirmaionModel()
      } else {
        let ocFilters = this.state.ocFilters;
        let index = ocFilters.findIndex(u => u.fieldName == fieldName);
        if (this.props.type === "OpenCorporateSearch" && this.state.responsesaveData && this.state.responsesaveData.length) {
          let data = this.state.ocFilters && this.state.ocFilters.length ? this.state.allUsersData : this.state.responsesaveData, data2 = [];
          if (val.filters[fieldName].value.length !== 0 && val.filters[fieldName].value.length !== undefined) {
            for (let obj of data) {
              if (obj[fieldName] && obj[fieldName].toLowerCase().includes(val.filters[fieldName].value.toLowerCase())) {
                data2.push(obj);
              }
            }
            if (index == -1) {
              ocFilters.push({ fieldName: fieldName, value: val.filters[fieldName].value.toLowerCase() });
            } else {
              ocFilters[index].value = val.filters[fieldName].value.toLowerCase();
            }
          } else {
            let details = await this.getFilteredData(fieldName);
            ocFilters = details.ocFilters ? details.ocFilters : [];
            data2 = details.filteredData ? details.filteredData : [];
          }

          this.setState({
            progress: 100,
            ocFilters: ocFilters,
            allUsersData: data2,
            isLoading: false,

          })
        } else {
          await this.onFilterChange(val)
        }
      }
    } else {
      await this.setState({ isLoading: false, isApplyFilter: false, })
      if (type) {
        this.onSeletFilterType(val, type)
        //  this.handleFilterInputField(val, "clear")
      }
    }
  }

  addBuckettoCriteria = (tab) => {
    let filtercriteria = this.state.filterCriteria
    let index = filtercriteria["criteria"].findIndex(obj => obj.key === "bucket" && obj.add_Filter === true)
    if (index !== -1) filtercriteria["criteria"].splice(index, 1)
    filtercriteria["criteria"].push({
      key: "bucket",
      value: tab,
      type: "eq",
      add_Filter: true
    })
    return filtercriteria;
  }

  // on search get data from server
  onFilterChange = async (event, type, screen) => {
    if (event && event.persist) {
      event.persist();
    }
    await this.setState({
      progress: 0,
      isLoading: true,
      selectedRows: '',
      selectedKey: event.key === "Enter" || event.filters ? true : false,
      isDisabledTab: true,
      isChangeHit: true,
    })
    await this.getisLoading()
    let fieldName = '', filterCriteria = this.state.filterCriteria ? this.state.filterCriteria : { criteria: [] },
      selectedFilterValue, selectedFilter, selecterFilterType, isDateFilter = false;
    if (event) {
      if (event.filters && !type) {
        if (Object.keys(event.filters) && Object.keys(event.filters)[0]) {
          fieldName = Object.keys(event.filters)[0];
        }
        let field = event.filters;
        selectedFilter = field[fieldName];
        selecterFilterType = this.state.addedMatchforCompany ? selectedFilter.matchMode : document.getElementsByClassName(`p-column-filterMode-${fieldName}`)[0] ? document.getElementsByClassName(`p-column-filterMode-${fieldName}`)[0].value :
          "eq"
        selectedFilterValue = selectedFilter && selectedFilter.value ? selectedFilter.value : selectedFilter ? selectedFilter : null;
        if (selecterFilterType === "multi") {//for multiSelect encode is not needed
          selectedFilterValue = selectedFilter.value
        }

        if (selecterFilterType === "nin" || selecterFilterType === "in") {
          selectedFilterValue = "nexists"
        }
        if (event && event.filters && event.filters[fieldName].matchMode === "clear") {//for clearing column filter menu
          selectedFilterValue = null
        }
        if ((fieldName === 'date' || fieldName === 'LastUpdated' || fieldName === 'updated' || fieldName === 'TimestampUTC' || fieldName === 'MostRecent10KFormDate' || fieldName === 'created' || fieldName === 'updated' || fieldName.includes("Date") && fieldName !== "nextActionDateDaysDefault" || fieldName === "DWDateInserted" || (fieldName.includes("date") && fieldName !== "validatedBy" && field !== "updatedBy" && fieldName !== "lastUpdatedBy") || fieldName.includes('Time')) && fieldName !== 'UpdatedBy') {
          if (selecterFilterType !== "nin" && selecterFilterType !== "in") {
            selecterFilterType = "date" + selecterFilterType
          }
          isDateFilter = true;

        }
        if ((this.props.isSql === "yes" && this.props.isSql !== undefined) || this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor") {
          if (selecterFilterType === "in") {
            selecterFilterType = "nexists"
          } else if (selecterFilterType === "nin") {
            selecterFilterType = "exists"
          }
        }

      } else {
        fieldName = event && event.target && event.target.name ? event.target.name : "globalSearch";
        selectedFilterValue = event
        type = "eq"
      }
      if (selectedFilterValue && (selecterFilterType === "fuzzyregexOr" || (selecterFilterType === "regexOr" && (this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor")))) {
        selectedFilterValue = await this.removeSpecialCharactersAndWhitespace(selectedFilterValue)
      }
      if (selectedFilterValue === null) {
        let index
        index = filterCriteria["criteria"].findIndex(obj => obj.key === fieldName && obj.add_Filter === false)
        if (index !== -1) filterCriteria.criteria.splice(index, 1);
        filterCriteria.page = "1"
        await this.setState({
          filterCriteria: filterCriteria,
          first: 0,
          page: 1,
          selectFilterValue: "",
        });
        if (this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "Deal Sizing-Properties") {
          filterCriteria = await this.addBuckettoCriteria(this.state.activeTab)
        }
        // if (this.state.selectedKey) {
        await this.getTabChanges(filterCriteria)
        // }
      } else if ((selectedFilterValue && selectedFilterValue.length && ((fieldName === "globalSearch" && selectedFilterValue.length > 0) || (fieldName !== "globalSearch" && selectedFilterValue.length >= 1)))) {
        if (fieldName === 'globalSearch') {
          await this.setState({
            selectFilterValue: event
          })
          if (filterCriteria) {
            filterCriteria.page = "1"
          }
          this.setState({ first: 0 })
          filterCriteria.globalSearch = {
            value: encodeURIComponent(selectedFilterValue),
            type: this.props.globalSearchFieldName
          };
          if (this.props.type === "Company") {
            localStorage.setItem("SearchedCompany", selectedFilterValue)
          };
          if (event && event.target && event.target.value && event.target.value.length === 0) {
            delete filterCriteria.globalSearch;
          };
        } else {
          let valdat
          if (this.props.isSql === "yes" && this.props.isSql !== undefined && isDateFilter) {
            valdat = ["", null, 0]
          } else if (this.props.isSql === undefined && !isDateFilter && event.filters[fieldName].matchMode === "num" && this.props.type !== "Property Editor V2" && this.props.type !== "Deal Sizing Property Editor") {
            valdat = ["", null, "0"]
          } else if (this.props.isSql === undefined && (this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor")) {
            valdat = [""]
          } else if (this.props.isSql === undefined && (this.props.type === "ClaimSummary" || this.props.type === "ClaimsManagement")) {
            valdat = [""]
          }
          if (filterCriteria && filterCriteria.page) {
            filterCriteria.page = 1
          }

          if (filterCriteria['criteria'].length === 0) {
            filterCriteria['criteria'].push({
              key: fieldName === "propertyNote" ? fieldName + ".note" : fieldName,//for claims we need add .note
              value: event.filters[fieldName].matchMode === "num" && !isDateFilter && selectedFilterValue !== "nexists" ? this.trimmedValue(decodeURIComponent(selectedFilterValue)) : selectedFilterValue === "nexists" ? ["", null] : selectedFilterValue,
              // value: [""],
              type: selecterFilterType === "multi" ? "in" : selecterFilterType,
              add_Filter: this.state.addedMatchforCompany ? true : false
            });
          } else {
            let obj, index;
            index = filterCriteria["criteria"].findIndex(obj => obj.key === fieldName && obj.add_Filter === false)
            if (index !== -1) {
              filterCriteria['criteria'].splice(index, 1)
            }
            filterCriteria['criteria'].push({
              key: fieldName === "propertyNote" ? fieldName + ".note" : fieldName,
              value: event.filters[fieldName].matchMode === "num" && !isDateFilter && selectedFilterValue !== "nexists" ? this.trimmedValue(decodeURIComponent(selectedFilterValue)) : selectedFilterValue === "nexists" && this.props.type === "State Attributes" ? ["", null] : selectedFilterValue === "nexists" && this.props.type !== "State Attributes" ? [""] : selectedFilterValue,
              // value: event.filters[fieldName].matchMode === "num" && !isDateFilter && selectedFilterValue !== "nexists" && selectedFilterValue !== "exists" ? parseInt(selectedFilterValue) : [""],
              type: selecterFilterType === "multi" ? "in" : selecterFilterType,
              add_Filter: this.state.addedMatchforCompany ? true : false
            });
            if (this.props.type === "RFI View" && isDateFilter && (selecterFilterType === "nexists" || selecterFilterType === "exists")) {
              let dateindex = filterCriteria["criteria"].findIndex(obj => obj.key === fieldName && obj.add_Filter === false)
              if (dateindex !== -1) {
                filterCriteria['criteria'].splice(index, 1)
              }
              filterCriteria['criteria'].push({ "key": fieldName, "value": null, "type": selecterFilterType === "nexists" ? "eq" : "ne", "add_Filter": false })

            }
            // }
          }

          await this.setState({
            filterCriteria: filterCriteria,
            page: 1,
            first: 0
          });
        }
        // if (this.state.selectedKey) {
        if (this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "Deal Sizing-Properties") {
          filterCriteria = await this.addBuckettoCriteria(this.state.activeTab)
        }
        this.state.filterCriteria["criteria"] = [...this.state.filterCriteria["criteria"], ...this.state.criteriaData["criteria"]]
        await this.getTabChanges(filterCriteria)
        // }
      } else {
        if (selectedFilterValue.length === undefined && !isDateFilter) {
          let obj = filterCriteria.criteria.find(x => x.key === fieldName && x.add_Filter === false);
          let index = filterCriteria.criteria.indexOf(obj);
          filterCriteria.criteria.splice(index, 1);
          // }
          if (this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "Deal Sizing-Properties" || this.props.type === "OPRA2 Property Editor") {
            filterCriteria = await this.addBuckettoCriteria(this.state.activeTab)
          }
          filterCriteria.page = "1"
          await this.setState({
            filterCriteria: filterCriteria,
            page: "1",
            first: 0,
            selectFilterValue: ""
          });
          // if (this.state.selectedKey) {
          if (this.props.type === "State Attributes") {
            await this.getStateFIlingValues(this.state.filterCriteria, this.state.stateSubCategory, this.state.stateCategory, this.state.stateSubCategories, this.state.stateDealType, this.state.UpState)
          } else {
            if (this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor") {
              await this.getTabChanges(filterCriteria)
            } else {
              await this.getDataFromServer(filterCriteria, "column_filter", "", fieldName)
            }
          }
        }
        // }

      }

    }
  }

  removeSpecialCharactersAndWhitespace = async (inputString, type) => {
    inputString = decodeURIComponent(inputString)

    inputString = inputString.split("").join(".*")

    // Remove special characters using a regular expression
    const withoutSpecialChars = inputString.replace(/[^\w\s]/gi, '');

    // Remove extra whitespace using another regular expression
    const withoutExtraWhitespace = withoutSpecialChars.replace(/\s+/g, ' ').trim();
    if (!type) {
      return encodeURIComponent(withoutExtraWhitespace);
    } else {
      return withoutExtraWhitespace;

    }
  }

  applydefaultTemplateForSelectedRows = async () => {
    let loginCredentials = JSON.parse(localStorage.getItem('loginCredentials'))
    const shortName = new Date().toLocaleString('en-US', { month: 'short' });
    const DateName = new Date().getDate();
    let selectednote
    if (this.props.type === "ClaimsManagement") {
      selectednote = `Seller: \nSeller Proof:\nName Proof:\nAddress Proof:\nFEIN Proof:\nHolder Proof:`
    } else {
      selectednote = null
    }
    return selectednote;
  }

  clearEditorSectionData = () => {
    this.editorComponentRef.handlePropertiesEditChange("", "Cancel")
  }

  getNotesUpdate = async (event) => {
    var optFields = true
    if (event && event.length > 1) {
      optFields = event.every((x) => x.fileFor === event[0].fileFor && x.relevantSellerName === event[0].relevantSellerName);
    }
    let statePropertyId = [], minValue = 0, opraPropertyId = [], states = [], previousTaskId = [];
    let unknownPropertyCount = []
    if (this.props.type) {
      let selectedRows = event
      this.setState({ selectedRows: selectedRows, totalSelectedRows: selectedRows.length })
      let z = selectedRows.find(elem => (elem.propertyStatus === "FILING-CLM FILED" ||
        elem.propertyStatus === "RESULT-APPROVED" ||
        elem.propertyStatus === "RESULT-OPP WITHDRAWN" ||
        elem.propertyStatus === "RESULT-NOT APPROVED" ||
        elem.propertyStatus === "POST-FILING-RFI-REFER TO SLRCOMMS/LGL" || elem.propertyStatus === "POST-FILING-RFI-RSRCH RQST"
      ))
      // if (z && (this.state.loginRole !== "Pod Lead" && this.state.loginRole !== "Admin" && this.state.loginRole !== config.roleNames.coreOpsManager)) {
      //   this.editorComponentRef.setFiledStatus("true")
      // } else {
      //   this.editorComponentRef.setFiledStatus("false")
      // }
      let propertyDisable = selectedRows.find(elem => (elem.propertyStatus === "FILING-CLM FILED" ||
        elem.propertyStatus === "RESULT-APPROVED"
        || elem.propertyStatus === "RESULT-OPP WITHDRAWN" ||
        elem.propertyStatus === "RESULT-NOT APPROVED" ||
        elem.propertyStatus === "POST-FILING-RFI-REFER TO SLRCOMMS/LGL" ||
        elem.propertyStatus === "POST-FILING-RFI-RSRCH RQST"))
      if (propertyDisable) {
        this.editorComponentRef.setFiledNote("true")
      } else if (this.props.type !== "HighValueProperties") {
        this.editorComponentRef.setFiledNote("false")
      }
      this.setState({ selectedRows: selectedRows, totalSelectedRows: selectedRows.length, optFields: optFields })
      // this.setState({ selectedRows: event.value })
      let selectednote = await this.applydefaultTemplateForSelectedRows()
      if (event && event.value && event.value.length === 0 && this.props.type !== "ClaimsManagement") {
        this.editorComponentRef.handlePropertiesEditChange("", "Cancel")
      }
      if (this.props.type === "HighValueProperties") {
        const unique = [...new Set(selectedRows.map((item) => item.companyID))];
        if (unique && unique.length > 1) {
          await this.setState({
            errorResponseHighPropertyMessage: "Unable to Create Task with Current Selection: More than 1 Deal is Selected",
            errorResponseHighProperty: true,
            disableCreateTask: true

          })
          return
        } else {
          await this.setState({
            errorResponseHighPropertyMessage: "",
            errorResponseHighProperty: false,
            disableCreateTask: false

          })
        }
      }
      // if (selectedRows.length === 1 && (selectedRows[0].matchStatusNotes && selectedRows[0].matchStatusNotes.length > 0 || selectedRows[0].PropertyNote && selectedRows[0].PropertyNote.length > 0)) {
      if (selectedRows.length === 1) {

        if (this.editorComponentRef) {
          this.editorComponentRef.handleUpdateValues(selectedRows[0])
        }
        for (let selected of selectedRows) {
          if (selected.statePropertyId !== 0) {
            statePropertyId.push(selected.statePropertyId)
          }
          minValue = minValue + selected.propertyMinValueAmount
          states.push(selected.sourceState)
          opraPropertyId.push(selected.propertyID)
          if (selected.connectedTaskID && selected.connectedTaskID.length > 0) {
            previousTaskId = [...previousTaskId, ...selected.connectedTaskID]
          }
          if (selected.propertyMaxValueAmount === null || selected.propertyMaxValueAmount === "") {
            unknownPropertyCount.push(selected.propertyID)
          }
        }
        await this.setState({ unknownPropertyCount: unknownPropertyCount, taskstatePropertyId: statePropertyId, taskminValue: minValue, taskstates: states, taskopraPropertyId: opraPropertyId, previousTaskId: previousTaskId })

        if (this.props.type !== "HighValueProperties") {
          this.editorComponentRef.enableCopyProof("true")
        } else {
          this.setState({ HighValueSeller: { EstateID: selectedRows[0].companyID } })
        }
        if (selectedRows[0].matchStatusNotes && selectedRows[0].matchStatusNotes.length > 0) {
          selectednote = selectedRows[0].matchStatusNotes[0].note
          await this.setState({
            selectednote: selectednote
          })
          if (this.editorComponentRef) {
            this.editorComponentRef.updatedNote(selectednote, "note")
          }
        }
      } else {
        await this.setState({
          selectednote: selectednote
        })
        if ((this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor") && this.editorComponentRef) {
          this.editorComponentRef.updateStatus('', "empty")
          this.editorComponentRef.updateReason('', "reason")
          this.editorComponentRef.updatedNote("", "note")
          this.editorComponentRef.updateClaimID()
          this.editorComponentRef.updatedselectedExclude("", "noteEmpty")
          this.editorComponentRef.updatedfileFor("", "noteEmpty");
          this.editorComponentRef.updatedrelevantSellerName("", "noteEmpty")
          this.editorComponentRef.enableCopyProof("false")
          if (selectedRows.length === 0) {
            this.editorComponentRef.removeCopiedFields()
            this.editorComponentRef.updatedselectedExclude("", "noteEmpty")
          }
          this.editorComponentRef.updateAddressProof("empty", "AddressProof")
          this.editorComponentRef.updateAddressProof("empty", "FeinProof")
          this.editorComponentRef.updateAddressProof("empty", "HolderProof")
          this.editorComponentRef.updateAddressProof("empty", "NameProof")
          this.editorComponentRef.updateAddressProof("empty", "SellerProof")


          for (let selected of selectedRows) {
            if (selected.statePropertyId !== 0) {
              statePropertyId.push(selected.statePropertyId)
            }
            minValue = minValue + selected.propertyMinValueAmount
            states.push(selected.sourceState)
            opraPropertyId.push(selected.propertyID)
            if (selected.connectedTaskID && selected.connectedTaskID.length > 0) {
              previousTaskId = [...previousTaskId, ...selected.connectedTaskID]
            }
            if (selected.propertyMaxValueAmount === null || selected.propertyMaxValueAmount === "") {
              unknownPropertyCount.push(selected.propertyID)
            }

          }
          await this.setState({ unknownPropertyCount: unknownPropertyCount, taskstatePropertyId: statePropertyId, taskminValue: minValue, taskstates: states, taskopraPropertyId: opraPropertyId, previousTaskId: previousTaskId })
        }
      }
    }
  }

  getreturnSelectedRows = (event) => {
    if (event) {
      this.setState({
        selectedRows: []
      })
    } else {
      return this.state.selectedRows
    }
  }
  // Delete Modal
  getDeleteRowModalForNotes() {
    return (
      <DeleteRowModal
        openDeleteModal={this.state.openUnsaveModal}
        closeDeleteModal={this.closeSaveModelPopUp}
        deleteSelectedRow={this.closeTaskAddModel}
        unSaved={true}
        notesUnsaved={true}
        confirmModalText={"You have unsaved notes."}
        type={this.props.type}
      />
    )
  }

  closeTaskAddModel = async () => {
    await this.setState({ openUnsaveModal: false, edited: "no" })
    await this.onSelectRowsUpdate(this.state.selectedEvent)
    if (this.editorComponentRef.setNoteEmpty) {
      await this.editorComponentRef.setNoteEmpty()
    }
  }
  closeSaveModelPopUp = async () => {
    await this.setState({ openUnsaveModal: false })
    await this.props.closeSaveModelPopUp()
  }
  checkDataisEdited = () => {
    var newArray = this.state.allUsersData.filter(function (el) {
      return el.edited === "yes"
    });
    return newArray
  }

  //select multiple rows to delete
  onSelectRowsUpdate = async (event) => {
    // if (this.state.edited === "yes") {
    //   //to open model if note is edited and trying to close by click on icon
    //   await this.setState({ openUnsaveModal: true, selectedEvent: event })
    //   return
    // }
    let selectedRows = event.value
    selectedRows = selectedRows
    this.setState({
      selectedRows: selectedRows,
      totalSelectedRows: selectedRows && selectedRows.length ? selectedRows.length : 0
    })
    this.getNotesUpdate(selectedRows)
  }

  //openConfirmationModal
  openConfirmationModal = async (rowData, status, type) => {
    this.setState({
      isOpenFormModal: false
    }, async () => {
      this.setState({
        isOpenFormModal: false,
        openConfirmationModal: true,
        leaveStatus: status,
        item: rowData,
        confirmText: type
      })
    })
  }

  closeConfirmationModal() {
    this.setState({
      openConfirmationModal: false
    })
  }
  changeNote = (Comments) => {
    this.setState({
      isnotesModal: true,
      Comments: Comments
    })
  }

  cancelNotes = async (type, id) => {
    this.setState({
      isnotesModal: false,
      openpaymentsModal: false,
      selectedRowPayments: ""
    })
    let responsesaveData = JSON.parse(JSON.stringify(this.state.responsesaveData))
    if (type === "Payments" && id) {
      let recordedData = await this.getSavedData(id)
      let allData = this.state.allUsersData
      let index = allData.findIndex(obj => obj["_id"].OppClaimID === id)
      allData[index].Payments = recordedData.Payments
      await this.setState({
        allUsersData: allData,
      })
    }
  }

  getNotesDetails(val, column, data, index) {
    return (
      <div style={{ display: "flex", width: column.bodyStyle.width }}>
        <div style={val.length > 0 ? { display: "flex", width: column.bodyStyle.width - 50 } : {}}>
          <span className="kanban_title" data-toggle="tool-tip"
            title={val}
          >
            {val}
          </ span >
        </div>
        <div className="d-flex" style={{ width: 50 }}>
          {val.length > 0 ?
            <span className="text-right" style={{ display: "flex" }}>
              <FontAwesomeIcon icon={faCopy}
                className='pl-1'
                data-toggle="tool-tip"
                // validate={[required]}
                title="click to copy this Notes"
                style={{ width: 20, color: "red" }}
                onClick={() => this.clipToCopyBoard(val[0].props.title)}
              />
              <FontAwesomeIcon icon={faExternalLinkAlt}
                className='pl-1'
                data-toggle="tool-tip"
                // validate={[required]}
                title="click to see all Notes"
                style={{ width: 20, color: "grey" }}
                onClick={() => this.changeNote(data)}
              />
            </span>
            : null}
        </div>
      </div>)
  }

  getNotesModal() {
    return (
      <NotesModal
        openNotesModal={this.state.isnotesModal}
        cancelReset={this.cancelNotes}
        saveClaimNotes={this.saveClaimNotes}
        claimNotesEdit={this.state.claimNotesEdit}
        notesSno={this.state.notesSno}
        Comments={this.state.Comments}
        type={this.props.type === "ClaimsManagement" ? "Notes1" : "Notes"}
      />
    )
  }


  getTooltipFromOtptions(options, name) {
    if (options) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].value === name) {
          return options[i].tooltip;
        }
      }
    }
    return '';
  }

  openLink = async (item, type) => {
    await this.setState({ openLinked: true, openCompanyId: item.CompanyID })
    // return <Link to={(`/companies/${JSON.stringify(item.CompanyID)}`)}></Link>
  }
  //Copy Function
  clipToCopyBoard(data) {
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(data)

    }
  }

  handleHourstominutes = (totalMinutes) => {
    if (totalMinutes) {
      return totalMinutes.toFixed(1);
    } else {
      return ""
    }
  }

  padToTwoDigits(num) {
    return num.toString().padStart(2, "0");
  }

  //change table body values//
  // hari need to move to derived class or controller
  changeFieldValues = (item, column) => {
    var propType = this.props.type
    let self = this, tableItem;
    tableItem = self.getTableFieldItem(column.field);
    if (tableItem && tableItem.fieldType) {
      if (tableItem.fieldType === "Link") {

        return <div className='textElipses'>
          <span
            onClick={this.props.type === "Investor" ? () => this.getCompanyDetails(item.InvestorID, 'view') : () => this.openViewModal(item, 'view')}>
            {item[column.field]}</span>
        </div >

      } else if (tableItem.fieldType === "tabRedirect") {
        return <span
          style={{
            color: '#0e4768',
            cursor: 'pointer'
          }}
          onClick={() => this.getSelectedTabTypeInfo(
            column.field === "CompanyName" ? "FactSet Entity Tree" : "FactSet Names-Address List For Scoring",
            column.field === "CompanyName" ? item["CompanyID"] : item["FactSet Entity ID"],
            null,
            true)}>
          {item[column.field]}
        </span>
      } else if (tableItem.fieldType === "badgePill") {
        return <div className='d-flex float-right'>
          <Badge
            color={item[column.field] === "In Progress" ? 'warning' : item[column.field] === "Failed" ? 'danger' : 'success'}
          >
            <FontAwesomeIcon icon={item[column.field] === "In Progress" ? faHistory : item[column.field] === "Failed" ? faTimes : faCheck}
              className='pl-1 mt-1'
              // color={item[column.field] === "InProgress"  ? 'green' : 'red'}
              style={{ width: 30, }}
            // onClick={() => this.clearSelectedFile()}
            />
            {item[column.field]}
          </Badge>

        </div >
      } else if (tableItem.fieldType === "progress") {
        return <div className=''>
          <ProgressBar value={item["progress"] === "In Progress" ? 50 : item["progress"] === "Failed" ? 0 : 100} style={{ height: '15px', fontSize: 12 }}></ProgressBar>
        </div >
      } else if (tableItem.fieldType === "fileName") {
        let val = item[column.field] ? item[column.field].slice(16) : item[column.field]
        // return <span>{val}</span>
        return <>{this.props.type === "Queue" ?
          <a href={`${config.multipleimgUrl}bulk/${item.fileName}`} id="names_error_file" download>{val}</a> :
          <span> {val}</span>}</>
      } else if (tableItem.fieldType === "openWindow") {
        return <div className='textElipses'>
          <a href="#"> <span
            onClick={() => this.openFormModal(item, 'edit')}>
            {item[column.field]}</span></a>
        </div >

      } else if (tableItem.fieldType === "openConnectedTasks") {
        let returnIconColor = this.props.getColorbasedonProp ? this.props.getColorbasedonProp(item) : "#354f6e"

        return <div className='textElipses'>
          {item.connectedTaskID && item.connectedTaskID.length > 0
            || item.connectedClaimID && item.connectedClaimID.length > 0
            ? <span><FontAwesomeIcon
              style={{ fontSize: "20px", float: "left", marginRight: 5, marginTop: 4, marginLeft: 4 }}
              icon={faClipboardList}
              onClick={() => this.props.openTableModel(item, this.state.selectedSeller)}
              color={returnIconColor}
              data-toggle="tool-tip"
              title={"Click to see table"}
            /></span> : null}
        </div >

      } else if (tableItem.fieldType === "completedCount") {
        return <span>
          <span>{`${item["completedCount"]} / ${item["totalCount"]}`}</span>
        </span>
      } else if (tableItem.fieldType === "StrArray") {
        let val = item[column.field]
        return <span data-toggle="tool-tip"
          title={val ? val.join(" , ") : ""}>
          {val ? val.join(" , ") : ""}
        </span >
      }
      else if (tableItem.fieldType === "profile") {
        return <span>
          <img
            src={item[column.field] ? `${config.imgUrl}/${item[column.field]} ` : configImages.defaultImg}
            alt=""
            className="appLogo" />
        </span >
      } else if (tableItem.fieldType === "nameIssue") {
        let val = item[column.field]
        return <span>
          {val === null ? "N/A" : val}
        </span >
      } else if (tableItem.fieldType === "ShowData") {
        return <div className='row ml-0'><span
          onClick={() => this.showNotesData(item)}
          style={{ color: "#354f6e", cursor: "pointer" }}
          data-toggle="tool-tip"
          title={this.state.getNotesresponse && this.state.getNotesresponse.length > 0 && this.state.getNotesresponse[0] && this.state.getNotesresponse[0].propertyid && this.state.getNotesresponse[0].propertyid === item.propertyID ? "Status:  " + this.state.getNotesresponse[0].MatchStatus + "\n\nNotes:  " + this.state.getNotesresponse[0].StatusNotes :
            this.state.isValidData && this.state.getNotesresponse && this.state.getNotesresponse.length === 0 && this.state.validatedNotesId === item.propertyID ? "This reccord has no Data" :
              "Please click before to see the status"}>
          {item[column.field]}
        </span>
          {
            this.state.getNotesresponse && this.state.getNotesresponse.length > 0 && this.state.getNotesresponse[0] && this.state.getNotesresponse[0].propertyid === item.propertyID ?
              <span><FontAwesomeIcon
                style={{ width: "14px", float: "left", marginRight: 5, marginTop: 4, marginLeft: 4 }}
                icon={faCopy}
                onClick={() => this.clipToCopyBoard(this.state.getNotesresponse[0].StatusNotes)}
                color={'red'}
                data-toggle="tool-tip"
                title={"Click to copy the notes"}
              /></span> : null
          }
        </div >

      } else if (tableItem.fieldType === "ActiveIndicator") {
        if (item[column.field] === "Yes" || item[column.field] === true) {
          return <span>
            {"True"}
          </span >
        } else if (item[column.field] === "No" || item[column.field] === false) {
          return <span>
            {"False"}
          </span >
        }
      } else if (tableItem.fieldType === "Boolean") {
        if (item[column.field] === true) {
          return <span>
            {"True"}
          </span >
        } else if (item[column.field] === false) {
          return <span>
            {"False"}
          </span >
        }
      }
      else if (tableItem.fieldType === "Percentage") {
        let val = Math.round(item[column.field] * 100)
        return <span>
          {val}
        </span >
      } else if (tableItem.fieldType === "StatesArray") {
        let val = item[column.field].length
        return <span>
          {val}
        </span >
      }
      else if (tableItem.fieldType === "StatesArray1") {
        let val = item[column.field] === 0 ? 0 : item[column.field]
        return <span>

          {val}
        </span >
      } else if (tableItem.fieldType === "ShowData1") {
        let val = item[column.field]
        return <span>
          {val === 0 ? 0 : val}
        </span >
      } else if (tableItem.fieldType === "formatCurrency") {
        let dollarUS = this.getFormattedCurrencyValue()
        let val
        if (item[column.field]) {
          val = item[column.field].toFixed(0)
          val = dollarUS.format(val)
          val = val.split(".")[0]
        } else {
          val = item[column.field]
        }
        return <span style={(column.field === "PropertyKnownMaxValueAmountSum" || column.field === "PropertyEstimatedMaxValueAmountSum") && this.props.type === "UP Opportunity – V2" && item["EstateName"] === "Total" ? { fontWeight: "bold" } : {}}>
          {item[column.field] !== undefined && item[column.field] !== null && item[column.field] !== "" && val !== "$NaN" ? val : "N/A"}
        </span >
      } else if (tableItem.fieldType === "formatCurrencywithdec") {
        let dollarUS = this.getFormattedCurrencyValue()
        let val
        val = dollarUS.format(item[column.field])
        // let val = item[column.field]
        return <span style={(column.field === "PropertyKnownMaxValueAmountSum" || column.field === "PropertyEstimatedMaxValueAmountSum") && this.props.type === "UP Opportunity – V2" && item["EstateName"] === "Total" ? { fontWeight: "bold" } : {}}>
          {item[column.field] !== undefined && item[column.field] !== null && item[column.field] !== "" && val !== "$NaN" ? val : "N/A"}
        </span >
      }
      else if (tableItem.fieldType === "redirectFormatCurrency") {
        let dollarUS = this.getFormattedCurrencyValue()
        let formatVal = Math.ceil(item[column.field])

        let val = dollarUS.format(formatVal)

        let value = val.split(".")
        value = value[0]
        // ?sellerName = ${item[column.field] }& sellerId=${ this.state.PropertyData.selectedSeller.EstateID }
        if (value === "$NaN") {
          return 0
        } else {
          return <span>
            {/* {this.props.type === "Deal Sizing Report For Companies" ? <a className="pl-0" onClick={() => this.getUrlbyParams(type === "US Opportunity" ?'summary':'deals',item)} href={`${window.location.protocol}//${window.location.host}/dealproperties?sellerName=${encodeURIComponent(this.state.PropertyData.selectedSeller.EstateName)}&sellerId=${this.state.PropertyData.selectedSeller.EstateID}&nameId=${encodeURIComponent(item.BusinessNameID)}`}> {item[column.field]}</a> : */}
            {value}
          </span>
        }
      }
      else if (tableItem.fieldType === "currency") {
        return <span>
          {item[column.field] > 0 ?
            <span>{item[column.field].toFixed(1)}</span> : <span>{column.field.includes("Hours") ? "" : 0}</span>}
        </span>
      }
      else if (tableItem.fieldType === "dateTime") {
        return <span>
          {item && item[column.field] ?
            <span>{dateFormats.formatDate(item[column.field], "MM/DD/YYYY")}</span> : ""}
        </span>
      }
      else if (tableItem.fieldType === "dateTimeFormat") {
        return <span>
          {item && item[column.field] ?
            <span>{dateFormats.formatDate(item[column.field], "MM/DD/YYYY hh:mm a")}</span> : ""}
        </span>
      }

      else if (tableItem.fieldType === "redirect") {
        var screenType = ["Ready To Review", "UP Opportunity", "UP Opportunity – V2", "AvailableToMap", "AvailableToReMap", "Ready To File", "On Hold", "Seller Negative Notice", "CA-N Properties", "Resource Needed", "Required Evidence", "HighValueProperties", "Grouped By Deal", "Grouped By Deal/State"]
        if ((this.props.type === "UP Opportunity – V2" || this.props.type === "AvailableToReMap") && item["EstateName"] === "Total") {
          return <span style={{ marginLeft: "19px", fontWeight: "bold" }
          }> {item[column.field]}</span >
        }
        // ?sellerName = ${item[column.field] }& sellerId=${ this.state.PropertyData.selectedSeller.EstateID }
        return <span>
          {/* {/ {this.props.type === "Deal Sizing Report For Companies" ? <a className="pl-0" onClick={() => this.getUrlbyParams(type === "US Opportunity" ?'summary':'deals',item)} href={`${window.location.protocol}//${window.location.host}/dealproperties?sellerName=${encodeURIComponent(this.state.PropertyData.selectedSeller.EstateName)}&sellerId=${this.state.PropertyData.selectedSeller.EstateID}&nameId=${encodeURIComponent(item.BusinessNameID)}`}> {item[column.field]}</a> : /} */}
          <a style={{ color: '#0e4768', cursor: 'pointer' }} target="_blank" href={this.state.PropertySharebody} onClick={() => this.getUrlbyParams(screenType.includes(propType) ? 'summary' : 'deals', item, column.field)} >{item[column.field]}</a>

        </span>
      }
      else if (tableItem.fieldType === "Badge") {
        let mcolor = item.status && item.status.includes("Paused") ? 'warning' : self.getColorFromOptions(tableItem.options, item.status);

        return <div className="row ml-0 mr-0" style={{ width: '100%', display: 'contents' }}>
          <span style={{ width: '10px', marginRight: 5 }}
            data-toggle="tool-tip"
            title={item.status}>
            <FontAwesomeIcon
              style={{ width: "9px", float: "left", marginRight: 5, marginTop: 4 }}
              icon={faCircle}
              color={mcolor === 'success' ? '#5cb85c' : mcolor === 'warning' ? 'red' : mcolor === 'danger' ? 'red' : ""}
              data-toggle="tool-tip"
              title={item.status}
            />
          </span>
          <span className="datatable_status_field">{item[column.field]}</span>
        </ div >
      } else if (tableItem.fieldType === "Role") {
        let mcolor = self.getColorFromOptions(tableItem.options, item[column.field]);
        return <Badge color={mcolor} style={tableItem.style}>{item[column.field]}</Badge>
      } else if (tableItem.fieldType === "icon") {
        let mcolor = self.getColorFromOptions(tableItem.options, item[column.field]);
        let tooltip = self.getTooltipFromOtptions(tableItem.options, item[column.field]);
        return <FontAwesomeIcon
          style={{ color: mcolor, cursor: tooltip ? 'pointer' : '' }}
          color='white'
          icon={tableItem.iconName}
          data-toggle="tool-tip"
          title={tooltip}
        />
      } else if (tableItem.fieldType === "Array") {
        let val;
        val = this.flattenArray(item[column.field]);
        if (tableItem.openNotes) {
          let data = this.getNotesDetails(val, column, item[column.field])
          return <>{data}</>
        } else {
          return <span style={tableItem.style} data-toggle="tool-tip"
            title={val}
          >
            {val}
          </ span >
        }

      } else if (tableItem.fieldType === "Object") {
        if (item && item.importResults && item.importResults[column.field]) {
          return <span style={tableItem.style} data-toggle="tool-tip"
            title={item.importResults[column.field]}
          >
            {item.importResults[column.field]}
          </ span >
        }
        if (item && item.uploadFileTracking && item.uploadFileTracking[column.field]) {
          return <span style={tableItem.style} data-toggle="tool-tip"
            title={item.uploadFileTracking[column.field]}
          >
            {item.uploadFileTracking[column.field]}
          </ span >
        }
      } else if (tableItem.fieldType === "slicedArray") {
        let val;
        val = this.slicedArray(item[column.field], column.field);
        return <span style={tableItem.style} data-toggle="tool-tip"
          title={item[column.field]}
        >
          {val}
        </ span >
      }
      else if (tableItem.fieldType === "splitVal") {
        let val = item[column.field].split("-");
        return <span style={tableItem.style} >
          <span>{val[0]}</span>
        </span>
        // }
      } else if (tableItem.fieldType === "label" && tableItem.field === "company") {
        // let val = item[column.field].split("-");
        return <span style={tableItem.style} >
          <span data-toggle="tool-tip" title={item[column.field]}><b style={{ fontSize: 14 }} >{item[column.field]}</b></span>
          {item.round ? <span> ({item.round}) </span> : null}
        </span>
        // }
      } else if (tableItem.fieldType === "label") {
        // let val = item[column.field].split("-");
        return <span style={tableItem.style} >
          <span data-toggle="tool-tip" title={item[column.field]}><b style={{ fontSize: 14 }} >{item[column.field]}</b></span>
        </span>
        // }
      }
      else if (tableItem.fieldType === "TextColor") {
        if (item[column.field] === "failed") {
          return <span style={{ color: "red" }} data-toggle="tool-tip" title={item[column.field]} >{item[column.field]}</span>;
        } else {
          return <span data-toggle="tool-tip" title={item[column.field]} >{item[column.field]}</span>;
        }
      }
      else if (tableItem.fieldType === "number") {
        let val = item[column.field]
        return <span >
          {val ? val : 0}
        </span>
      } else if (tableItem.fieldType === "BoolText") {
        let val = item.activeIndicator ? item.activeIndicator : item.ActiveIndicator ? item.ActiveIndicator : item.DWRowIsCurrent ? item.DWRowIsCurrent : false
        const value = val.toString()
        if (value) {
          return <div>< FontAwesomeIcon
            className="status_logo"
            color={value === "true" || value === true ? '#5cb85c' : 'red'}
            icon={faCircle}
            data-toggle="tool-tip"
            title={value === "true" ? 'Active' : 'Inactive'} />
            <span >{item[column.field]}</span></div>
        }
      } else if (tableItem.fieldType === "Complex") {
        let data = this.ObjectbyString(item, column.field)
        return <span>
          {data}
        </span>
      } else if (tableItem.fieldType === "hasPropertyCoOwner" || tableItem.fieldType === "confirmedOnSite" || tableItem.fieldType === "reportedMissing") {
        if (item[column.field]) {
          return <span>
            {"Yes"}
          </span>
        } else {

          return <span>
            {"No"}
          </span>
        }
      } else if (tableItem.fieldType === "Actions") {
        return (
          <div
            style={{ justifyContent: 'center' }}>
            {(this.props.editRequired && this.props.type !== "Weekly Recovery Plan") ?
              <>
                < Button
                  color="primary"
                  size="sm"
                  outline
                  disabled={this.props.type === "crMatrix" && item.Answers > 0 ? true : false}
                  className="mb-0 m-1 ml-0 p-1"
                  onClick={() => this.props.type === 'State Attributes' ? this.props.toggleSideBar(true, 'Edit', this.state.filterCriteria, item) : this.openFormModal(item, 'edit')}>
                  Edit
                </Button>
                {tableItem.showDelete &&
                  < Button
                    color="danger"
                    size="sm"
                    outline
                    className="mb-0 m-1 ml-0 p-1"
                    onClick={() => this.props.type === 'State Attributes' ? this.props.toggleSideBar(false, 'Delete', this.state.filterCriteria, item) : this.openFormModal(item, 'delete')}>
                    Delete
                  </Button>} </>
              : ''
            }
            {
              (this.props.deleteRequired) ?
                this.props.type === "Weekly Recovery Plan" ?
                  <FontAwesomeIcon
                    className="status_logo"
                    // color={value === "true" ? '#5cb85c' : 'red'}
                    style={{ cursor: "pointer" }}
                    icon={faMinus}
                    data-toggle="tool-tip"
                    // title={value === "true" ? 'Active' : 'Inactive'}
                    onClick={() => this.deleteConfirmAction(item._id, "Delete")}
                  />
                  :
                  < Button
                    color="danger"
                    size="sm"
                    outline
                    className="mb-0 m-1 p-1"
                    onClick={() => this.deleteConfirmAction(this.props.type === "Deal Sizing Queue" || this.props.type === "Regular Scoring Queue" ? item : item._id, "Delete")}>
                    {this.props.type === "Deal Sizing Queue" || this.props.type === "Regular Scoring Queue" ? "Cancel" : "Delete"}
                  </Button>
                : null

            }
          </div >
        )
      } else if (tableItem.fieldType === "Date") {
        let val = item[column.field]
        return <span >
          {val}
        </span>
      } else if (tableItem.fieldType === "Time") {
        let val = item[column.field]
        return <span >
          {val}
        </span>
      }
    } else {
      if ((item && item[column.field]) && typeof item[column.field] !== 'object') {

        return <span data-toggle="tool-tip" title={item[column.field]} >{item[column.field]}</span>;

      }
    }
  }

  clickConfirm() {
    this.closeConfirmationModal();
  }

  ObjectbyString = (o, s) => {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }

  rowClassName(item) {
    if (item && item.status) {
      if (item.status === 'Blocked') {
        return (
          { 'p-highlightRow': (item.status === 'Blocked') }
        )
      } else if (this.props.type === "PropertyLoader" && item.status === "failed") {
        return (
          { 'p-highlightRowRed': (item.status === 'failed') }
        )
      }
    } else if ((this.props.type === "Sellers" || this.props.type === "Deal Mgmt") && item && item.ScoringStatus && item.ScoringStatus !== null) {
      return {
        'p-disabledRow': (item.ScoringStatus !== null)
      }
    } else if (this.props.custumisedRowHighlights) {
      return this.props.custumisedRowHighlights(item)
    }
  }

  handleDateInterval(startDate, endDate) {
    if (startDate) {
      this.setState({
        startDate: startDate
      })
    }
    if (endDate) {
      this.setState({
        endDate: endDate
      })
    }
  }
  // ShowHideColumns Button Click Handler
  //Settings Modal open
  openShowHideColumnsModal = () => {
    this.setState({
      isOpenShowHideColumnsModal: true,
    })
  }

  getSettings = async () => {
    this.props.getSettings();
  }


  toggle = async (id) => {
    let openId = this.state.openId;
    this.setState({ openId: openId === id ? '' : id });
  }

  print = () => {
    window.print();
  }

  toogleMultiSelect = async () => {
    await this.setState({
      showMultiSelectDropDown: !this.state.showMultiSelectDropDown
    })
  }

  toggle = async (e) => {
    let element = document.getElementById(e);
    element.classList.add("active");
  }


  onFilterGlobalChange = (event) => {
    if (event.key === "Enter") {
      if (event.target.value.length > 0) {
        this.onFilterChange(event.target.value)
      } else {
        let filterCriteria = this.state.filterCriteria
        filterCriteria.globalSearch = {}
        delete filterCriteria.globalSearch
        this.setState({
          filterCriteria: filterCriteria,
          page: "1",
          first: 0,
        });
        this.getDataFromServer(filterCriteria)
      }
    }
  }



  getHeader() {
    // let screenPermissions = this.state.screenPermissions;
    const { t, handleSubmit } = this.props;
    let SelectedForOptions = [{ label: `Pending-${this.state.PendingCount}`, value: 'Pending' },
    { label: `Confirmed-${this.state.ConfirmedCount}`, value: "Confirmed" },
    { label: `Missing-${this.state.MissingCount}`, value: "Missing" }]
    return (
      <div className='top_section' ref={this.divRef} >
        <div>
          <div className="row ml-0 mr-0" style={this.props.type === "SearchTerms" || this.props.type === "searchTermOnSite" ? { display: "none" } : null} >
            <div className="col-12 px-0 pb-2 d-flex justify-content-between"
              style={this.props.dontShowTitle && this.props.type !== "Site Confirmation Editor" && this.props.type !== "Ready To Review" && this.props.type !== "Property Editor V2" && this.props.type !== "Deal Statistics" && this.props.type !== "Negative Notice List Viewer" ? { height: "20px" } : this.props.type === "Property Editor V2" ? { height: "0px", display: "none" } : { height: "auto" }}
            >
              <span className="float-left">
                <p className="d-flex">
                  {!this.props.dontShowTitle ?
                    this.props.fontAwesome ?
                      <FontAwesomeIcon
                        className={'tableheadericon mt-2'}
                        color="#354f6e"
                        icon={this.props.type === "Status" ? faToggleOff : this.props.type === "Properties" ? faCity : (this.props.type === "Sellers" || this.props.type === "Deal Mgmt") ? faUsers : this.props.type === "Process Documents" ? faFolder : this.props.type === "Property Editor V2" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing Property Editor" ? faBuilding : ""} /> :
                      this.props.dontShowTitle ? null : <span className={`tableheadericon mt-2 lnr lnr-${this.props.icon}`} />
                    : null}<b
                    >{this.props.dontShowTitle ? null : <Link to={(this.props.routeTo)} style={this.props.type === "Legal Entity Suffix" || this.props.type === "APA Type Configuration" || this.props.type === "Available To File - Statuses" || this.props.type === "State-UP Filing Sub Category" || this.props.type === "State-UP Filing Category" || this.props.type === "Legal Entity Abbreviation" || this.props.type === "Claim Status" || this.props.type === "Claim Reason" || this.props.type === "Property Status" || this.props.type === "Property Reason" || this.props.type === "Business Name Issue Code" ? { fontSize: "18px", marginRight: "auto", position: "initial" } : { fontSize: "18px", marginRight: "auto" }} onClick={this.closeFormModal} className="tableType" >
                      {t(this.props.type === "crMatrix" ? "CR Matrix" : this.props.type === "Departments" ? "Teams" : this.props.type === "Business Name Issue Code" ? "New Name Issue Code" : this.props.type === "ClaimsManagement" ? "Claims Management" : this.props.type === "Up Prop Confirmation" ? "UP Property – Site confirmation" : this.props.type === "HighValueProperties" ? "High Value Properties" : (this.props.type === "Sellers" || this.props.type === "Deal Mgmt") ? "Deal Mgmt" : this.props.type)}
                    </Link>
                    }{this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen' ? ` / ${this.state.formType} ` : null}
                  </b>
                  {this.props.type === "Deal Sizing Queue" || this.props.type === "Regular Scoring Queue" ? this.props.getHeaderforSchedule() : this.props.updatedDate ? <div className='mt-3'><b >{`(As of Date & Time(ct)) :`}</b> <span>{dateFormats.formatDate(moment(this.props.updatedDate).utcOffset('-06:00'), "MM/DD/YYYY hh:mm a")}</span></div> : null}
                </p>
              </span>
              <span

                className={this.props.type === "AvailableToMap" || this.props.type === "AvailableToReMap" ? "d-flex" : ""}
              >

                <span className="float-right d-flex" style={this.props.type === "Activity Details Reports" || this.props.type === "Required Evidence" ? { marginLeft: "4px" } : {}}>
                  <span className='d-flex'>
                    {/* {this.props.type==="Users"&&this.state.loginRole==="admin" */}
                    {this.props.type === "Grouped By Deal" || this.props.type === "Grouped By Deal/State" ?
                      < Button color="primary"
                        outline
                        size="sm"
                        style={{ height: 37 }}
                        className={this.props.type === "Users" ? "py-2 px-5 mb-0 mx-1 mr-2" : "py-2 px-5 mb-0 mx-1"}
                        onClick={(e) => this.changeCreateTaskFromReport({ value: "Site Confirmation" }, "add")}
                      >Create Task
                      </Button> : null}
                    {this.props.excelDownload ? <span><Button color="primary"
                      className='p-1 py-0 mb-0 ml-1' style={{ height: "38px" }}
                      size={'sm'} onClick={this.handleSelectedAction}
                      outline
                      disabled={!this.props.apidataforExport && !this.state.apiUrlforExport ? true : false}
                    >
                      <FontAwesomeIcon
                        icon='download'
                        data-toggle="tool-tip" title={"Export To excel"}
                        className='pl-1' size='lg' />
                    </Button>
                      {this.getExcelDownload()}</span>
                      : null}
                    {this.props.exportRequried &&
                      <Button color="primary" outline
                        className='px-2 ml-2 mb-0 mr-0'
                        style={{ height: 37 }}
                        // disabled={this.props.type ==="Manually Loaded Properties"}
                        size={'sm'} onClick={this.getDataToExport}>
                        <FontAwesomeIcon
                          icon='download'
                          data-toggle="tool-tip" title={t("Export To CSV")}
                          className='pl-1' size='lg' />
                      </Button>}
                    {this.props.exportRequried && <CSVLink
                      data={this.state.exportData}
                      headers={this.state.exportHeaders}
                      filename={this.props.type === "Ready To File" || this.props.type === "On Hold" || this.props.type === "Seller Negative Notice" || this.props.type === "CA-N Properties" || this.props.type === "Resource Needed" ? `Available To File-${this.props.type}.csv` : this.props.tabOptions ? `${this.state.activeTab}-${this.props.type}.csv` : `${this.props.type}.csv`}
                      className="hidden text-white p-0"
                      ref={(r) => this.csvLinkRef = r}
                      target="_blank" >
                    </CSVLink>}
                  </span>

                  {this.props.isClearFilters && this.props.type !== "ClaimsManagement" ?
                    < Button color="primary"
                      outline
                      size="sm"
                      style={{ height: 37 }}
                      className={this.props.type === "Users" ? "py-2 px-5 mb-0 mx-1 mr-2" : "py-2 px-5 mb-0 mx-1"}
                      onClick={() => this.getremoveFilters()}>Clear Column Filters
                    </Button> : null}
                  {this.props.sample ? (
                    <Button
                      color="primary"
                      size="sm"
                      className="p-1 ml-auto mt-1"
                      onClick={() => this.bulkUploadMoadal()}
                    >
                      <FontAwesomeIcon
                        icon="upload"
                        className="pl-1"
                        size="lg"
                        data-toggle="tool-tip"
                        title="Bulk Upload"
                        onClick={() => this.bulkUploadMoadal()}
                      />
                    </Button>
                  ) : null}
                  {this.props.settingsRequired ?
                    <Button color="primary"
                      size="sm"
                      className="p-1 ml-4 mb-0 mt-1"
                      onClick={() => this.openShowHideColumnsModal()}
                    >
                      <FontAwesomeIcon
                        icon='cog'
                        className='pl-1' size='lg'
                        data-toggle="tool-tip" title="Settings"
                        onClick={() => this.openShowHideColumnsModal()}
                      />
                    </Button>
                    : null}
                  {/* deal */}
                  <div className={this.props.isShownnFieldType ? "d-flex mt-n4 ml-2" : ""} style={this.props.isShownnFieldType ? { display: "block" } : { display: "none" }}>
                    <span >
                      <div
                        className={"mr-2 mt-2"}
                        style={this.props.isShownnFieldType ? { display: "grid" } : { display: "none" }}
                      >
                        <span>View By</span>
                        <Dropdown
                          value={this.state.selectedViewType}
                          // appendTo={document.body}
                          style={{ borderLeft: "1px solid lightgray", height: 34 }}
                          options={config.viewTypes}
                          onChange={(e) => this.selectedViewType(e.target.value)}
                        />
                      </div>
                    </span>
                    <div className="mb-0" >
                      {/* <span style={{ color: "white" }}>Group By</span> */}
                      <Button color="primary" outline
                        className="mb-0" style={this.props.isShownnFieldType ? { cursor: "pointer", marginTop: 29, marginLeft: 5 } : { display: "none" }}
                        onClick={() => this.openShowHideColumnsModal()}
                      >
                        Column Order
                      </Button>
                    </div>
                  </div>
                  {/* Add button */}
                  {this.props.addRequried && this.state.showAddButton && this.state.screenPermissions === "Edit" && !this.state.isPreviousWeek ?
                    <Button color="primary"
                      outline
                      style={{ height: 37 }}
                      // className="mb-0 mr-0 ml-2"
                      className={(this.props.type === "Sellers" || this.props.type === "Deal Mgmt") ? "mb-0 mr-0" : "mb-0 mr-0 ml-2"}
                      onClick={(e) => this.props.type === "Weekly Recovery Plan" ? this.addRow(this.state.allUsersData.length) : this.openFormModal('add')}
                    >
                      <FontAwesomeIcon
                        icon='plus'
                        className='Addbtn' size='sm'
                        data-toggle="tool-tip" title={t("Add")}
                        onClick={(e) => this.props.type === "Weekly Recovery Plan" ? this.addRow(this.state.allUsersData.length) : this.openFormModal('add')}
                      />
                      {this.props.type === "List View" || this.props.type === "Research Request Viewer" || this.props.type === "Seller Negative Notice Viewer" || this.props.type === "RFI View" || this.props.type === "Recovery Planner V2" ? 'Create Task' : (this.props.type === "Sellers" || this.props.type === "Deal Mgmt") ? 'Add New Deal' : this.props.type === "crMatrix" ? 'Add New CR Matrix ' : 'Add'}
                    </Button>
                    : null}
                </span>

                <span className="float-right pl-3">
                  <>
                    <div
                      className={`${this.props.type === "Users" ? `p-inputgroup searchField mt-1 ` : `p-inputgroup searchField`}`}
                    >
                      {this.props.globalSearch ? <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <span className="p-input-icon-right">
                          <InputText
                            type="search"
                            name='globalSearch'
                            id='globalSearch'
                            style={this.props.type ? { height: 35, paddingLeft: "2.5rem", marginTop: 2 } : { display: "none" }}
                            onKeyPress={(e) => this.onFilterGlobalChange(e)}
                            onChange={(e) => this.onFilterGlobalChange(e, 'globalSearch')}
                            // value={this.state.selectFilterValue}
                            placeholder={this.props.globalSearch ? this.props.globalSearch : 'Search'}
                            size={(this.props.type === "Company" || this.props.type === "Investor" || this.props.type === "List View" || this.props.type === "Research Request Viewer" || this.props.type === "Seller Negative Notice Viewer" || this.props.type === "RFI View") ? "25" : "20"} />

                        </span>
                      </span> : null}
                      {this.props.isEdit && !this.state.isPreviousWeek && this.props.type !== "ClaimsManagement" || this.props.type === "Recovery Planner V2" ? this.getSubmitBtnforEditedTable() : null}
                    </div>
                  </>
                </span>
              </span>
            </div>
          </div>
          {this.getpropertyFilters()}
          {this.getFilerField()}
        </div >

      </div >

    );
  }

  cancelUserPwdResetModal = async () => {
    await this.setState({
      openUserPasswordResetModal: false,
      actions: ''
    })
  }

  getFormattedCurrencyValue = () => {
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: config.currency,
    });
    return dollarUS
  }

  getisLoading = async (type, func) => {
    await this.setState({
      isLoading: type === "false" ? false : true,
      progress: 0
    }, () =>
      this.handlePercentage("1"),
    )
    if (func == "clear") {
      this.getremoveFilters()
    }
  }


  getValues(props) {
    let data = []
    if (props && props.rowData && props.rowData["analysts"]) {
      for (var analyst of props.rowData["analysts"]) {
        data.push(analyst)
      }
    }
    return data;
  }

  formatTime(props, item) {
    let d = new Date(); // Creates a Date Object using the clients current time
    let [hours, minutes, seconds] = props.value[props.rowIndex][item.field].split(':')
    d.setHours(+hours); // Set the hours, using implicit type coercion
    d.setMinutes(minutes); // can pass Number or String - doesn't really matter
    d.setSeconds(seconds);
    // If needed, you could also adjust date and time zone
    let rowValue = d.toString()
    let newhours = new Date(rowValue);

    return newhours
  }



  getSortedData(field, isAsc) {
    let allUsersData = this.props.getSortedData(this.state.allUsersData, field, isAsc)
    this.setState({
      allUsersData: allUsersData
    })
  }

  removeFrozenRecords = async (e, type) => {
    let columnsData = JSON.parse(localStorage.getItem(`${this.props.type}_column_order`));
    let columns
    if (columnsData && columnsData.details) {
      columns = columnsData.details[this.state.selectedViewType]
    } else {
      columns = columnsData
    }

    for (let i = 0; i < columns.length; i++) {
      columns[i].frozen = false
    }
    await localStorage.setItem(`${this.props.type}_column_order`, JSON.stringify(columnsData))
    await this.selectedViewType(e, "Frozen")
  }

  handleDataChangingOption = async (e) => {
    if (e && e.selectedOption) {
      if (e.selectedOption === "unsort" || e.selectedOption === "asc" || e.selectedOption === "desc") {
        if (this.props.type && this.props.type !== "UP Opportunity") {
          await this.sortChange(e)
        } else {
          await this.getSortedData(e.sortField, e.selectedOption)
        }
      } else if (e.selectedOption === "hide") {
        await this.columnHandlingfromHeader(e.selectedOption, e.sortField)
      } else if (e.selectedOption === "show") {
        this.openShowHideColumnsModal()
      } else if (e.selectedOption === "filter") {
        await this.handleFilterInputField(e.sortField)
        // this.AddingFilterInput(e.selectedOption, field)
      } else if (e.selectedOption === "clear_filter") {
        this.onColumnResizeEnd()
        await this.handleFilterInputField(e.sortField, "clear")
        // this.AddingFilterInput(e.selectedOption, field)
      } else if (e.selectedOption === "freeze") {
        await this.removeFrozenRecords("freeze", "clear")
        this.onColumnResizeEnd()
        // this.AddingFilterInput(e.selectedOption, field)
      } else if (e.selectedOption === "unfreeze") {
        await this.removeFrozenRecords("unfreeze")
        this.onColumnResizeEnd()
        // this.AddingFilterInput(e.selectedOption, field)
      }
    }
  }

  getFilteredData = async (fieldName) => {
    let ocFilters = this.state.ocFilters, data = this.state.responsesaveData, filteredData = [];
    let index = ocFilters.findIndex(u => u.fieldName == fieldName);
    if (index != -1) {
      ocFilters.splice(index, 1);
    }
    for (let obj in ocFilters) {
      for (let obj1 of data) {
        if (ocFilters[obj].fieldName) {
          if (obj1[ocFilters[obj].fieldName] && obj1[ocFilters[obj].fieldName].toLowerCase().includes(ocFilters[obj].value)) {
            filteredData.push(obj1);
          }
        }
      }
      if (ocFilters.length - 1 != obj) {
        data = [...filteredData];
        filteredData = [];
      }
    }
    return {
      ocFilters, filteredData
    }
  }

  async handleFilterInputField(val, type) {
    let filters = {}, newFil = {}, filteredObj = {};
    if (type) {
      newFil[val] = {
        value: "",
        matchMode: "clear"
      };
      filters[val] = newFil[val]
      filteredObj.filters = filters
      let value = this.props.isSettings ? (this.props.isSettings + val) : this.state.activeTab ? this.state.activeTab + val : val
      let data = document.getElementsByClassName(`p-column-filter-${value}`)
      if (data && data[0] && data[0].getElementsByClassName(`p-column-filter`) && data[0].getElementsByClassName(`p-column-filter`)[0] && data[0].getElementsByClassName(`p-column-filter`)[0].value) {
        data[0].getElementsByClassName(`p-column-filter`)[0].value = ""
      }
      if (data && data[0] && data[0].getElementsByClassName(`p-column-filter`) && data[0].getElementsByClassName(`p-column-filter`)[0] && data[0].getElementsByClassName(`p-column-filter`)[0].name) {
        let getName = data[0].getElementsByClassName(`p-column-filter`)[0].name
        this.setState({
          [getName]: ""
        })
      }
      // if (val === "hasPropertyCoOwner") {
      this.setState({
        [val]: ""
      })
      // }
      document.getElementsByClassName(`p-column-filterMode-${val}`)[0].selectedIndex = 0
      document.getElementsByClassName(`p-column-filter-${value}`)[0].style.display = "none"
      if (this.props.type === "OpenCorporateSearch" && this.state.responsesaveData && this.state.responsesaveData.length) {
        let details = await this.getFilteredData(val);
        this.setState({
          progress: 100,
          allUsersData: details.filteredData ? details.filteredData : [],
          isLoading: false,
          ocFilters: details.ocFilters ? details.ocFilters : [],
        })
      } else {
        this.onFilterChange(filteredObj);
      }
    } else {
      let value = this.props.isSettings ? (this.props.isSettings + val) : this.state.activeTab ? (this.state.activeTab + val) : val
      if ((document.getElementsByClassName(`p-column-filter-${value}`) && document.getElementsByClassName(`p-column-filter-${value}`)[0]) !== undefined) {
        if (document.getElementsByClassName(`p-column-filter-${value}`)[0].style.display === "none") {
          document.getElementsByClassName(`p-column-filter-${value}`)[0].style.display = "block"
        } else {
          document.getElementsByClassName(`p-column-filter-${value}`)[0].style.display = "none"
        }
      }

    }
    this.onColumnResizeEnd()
  }


  handlefiltersafterreorder = () => {
    let criteria = this.state.filterCriteria.criteria
    let gridFilters = criteria.filter(object => {
      return object.add_Filter !== true;
    });
    for (var obj of gridFilters) {
      let val = obj.key
      let value = this.props.isSettings ? (this.props.isSettings + val) : this.state.activeTab ? this.state.activeTab + val : val
      document.getElementsByClassName(`p-column-filter-${value}`)[0].style.display = "block"
      document.getElementsByClassName(`p-column-filter-${value}`)[0].children[0].value = obj.type === "nexists" ? "in" : obj.type === "exists" ? "nin" : obj.type.includes("date") ? obj.type.replace("date", "") : obj.type
      document.getElementsByClassName(`p-column-filter-${value}`)[0].children[1].value = obj.value
    }

  }

  columnHandlingfromHeader(val, field) {
    let colOrder = localStorage.getItem(`${this.props.type}_column_order`);
    let columns = JSON.parse(colOrder);
    if (columns && columns.details) {
      columns = columns.details[this.state.selectedViewType];

    } else {
      columns = columns
    }
    let index = columns.findIndex(obj => obj.field === field)
    if (val === "hide") {
      if (index !== -1) columns[index].show = columns[index].show === true ? false : true;
    }
    if (columns && columns.details) {
      columns.details[this.state.selectedViewType] = columns;
    }
    localStorage.setItem(`${this.props.type}_column_order`, JSON.stringify(columns))

    this.getTableFieldsOrder()
    this.setState({
      isLoading: false,
    })
  }


  // //Custum Date Column Filter Onchange
  onFilterdateChange = async (e, field, type, dropdown) => {
    let value
    if (!type && !dropdown && e.value !== null) {
      value = e.value.toLocaleDateString('zh-Hans-CN', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    } else {
      value = e
    }
    if (e && !type) {
      let filters = {}, newFil = {}, filteredObj = {};
      newFil[field] = {
        value: value,
        matchMode: dropdown ? dropdown : "string"
      };

      filters[field] = newFil[field]
      filteredObj.filters = filters
      await this.setState({
        [field]: e.value ? e.value : value ? value : ""
      })
      if (value && value.length === 0) {
        this.handleFilterInputField(field, "clear")

      } else {
        if (this.props.type === "Company" && field === "CompanyName") {
          let index = this.state.filterCriteria["criteria"].findIndex(obj => obj.key === "CompanyName" && obj.add_Filter === true)
          this.state.filterCriteria["criteria"].splice(index, 1)
          await this.setState({
            addedMatchforCompany: true
          })

        }
        await this.onFilterChange(filteredObj)
        await this.setState({
          addedMatchforCompany: false
        })
      }
    } else {
      await this.setState({
        [field]: e.value !== null ? e.value : e === "string" ? e : ""
      })

    }
  }

  CalenderComponent(item) {
    return (
      < >
        <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
          appendTo={document.body}
          monthNavigator={true}
          yearNavigator={true}
          yearRange="1940:2530"
          // onChange={(e) => this.setState({ [item.field]: e.value })}
          inputClassName={`p-column-filter p-column-${item.field}`}
          name={item.field}
          dateFormat="mm/dd/yy"
          value={this.state[item.field] ? this.state[item.field] : document.getElementsByClassName(`p-column-filter p-column-${item.field}`).value}
          onChange={(e) => this.onFilterdateChange(e, item.field, "change")}
          onSelect={(e) => this.onFilterdateChange(e, item.field)} />
      </>
    )
  }
  DropDownComponent(item) {
    return (
      <div >
        <Dropdown
          value={this.state[item.field]}
          appendTo={document.body}
          // options={[{ label: "Yes", value: "1" }, { label: "No", value: "0" }]}
          options={item.field === "confirmedOnsite" || item.field === "reportedMissing" ? [{ label: "Yes", value: "true" }, { label: "No", value: "false" }] : [{ label: "Yes", value: "1" }, { label: "No", value: "0" }]}
          // options={item.field === "crRound" ? selectRoundOptions : item.field === "dealType" ? dealTypes : item.field === "analysts" ? this.state.filteredAnalysts : this.props.businessNames}
          onChange={(e) => this.onFilterdateChange(e.value, item.field, "", "num")}
        // style={{ width: 150 }}
        // filter={true}
        // disabled={item.field === "crRound" && (props.rowData["dealType"] === "BK - Refresh" || props.rowData["dealType"] === "BK - New") ? true : false}


        // placeholder={item.header}
        />
        {/* <Select /> */}
        <div>
        </div>
      </div>)
  }

  MultiSelectComponent(item, delta) {
    return (
      <div >
        <MultiSelect
          value={this.state[item.field]}
          appendTo={document.body}
          options={item.field === "matchMatchStatus" ? this.props.settingsData :
            item.field === "matchStatusReason" ? this.props.shownreasons :
              item.field === "propertyStatus" ? this.props.propertyStatusArray :
                item.field === "propertyStatusReason" ? this.props.propertyStatusReasonArray :
                  item.filterOptions}
          style={{ width: item.width - 55, marginRight: 3 }}
          maxSelectedLabels={2}
          filter={true}
          // options={item.field === "crRound" ? selectRoundOptions : item.field === "dealType" ? dealTypes : item.field === "analysts" ? this.state.filteredAnalysts : this.props.businessNames}
          onChange={(e) => this.setState({ [item.field]: e.value })}
        />

        <FontAwesomeIcon
          color='green'
          icon={faCheckCircle}
          data-toggle="tool-tip"
          className='my-2 mx-0'
          onClick={(e) => this.onFilterdateChange(this.state[item.field], item.field, "", "string")}
        />
        {/* <Select /> */}
        <div>
        </div>
      </div>)
  }

  //after selecting type 
  onSeletFilterType(value, type) {
    value = this.props.isSettings ? (this.props.isSettings + value) : this.props.activeTab ? this.state.activeTab.toString() + value : value
    let selectedcolumn = document.getElementsByClassName(`p-column-filter-${value}`)
    if (selectedcolumn[0].getElementsByClassName(`p-column-filter`)[0]) {
      if (!type) {
        selectedcolumn[0].getElementsByClassName(`p-column-filter`)[0].focus()
      } else {
        selectedcolumn[0].getElementsByClassName(`p-column-filter`)[0].blur()
      }
    }
  }

  getFilterOptionValues(column) {
    if (this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor") {
      if (this.state.activeTab === "SP") {
        if (column.filter) {
          return this.state.dataChangeOptionswithoutSortwithFilter
        } if (!column.filter) {
          return this.state.dataChangeOptionswithoutSortwithoutFilter
        }
      } else {
        if (column.filter && !column.sorted) {
          return this.state.dataChangeOptionswithFilterPE
        } if (!column.filter && !column.sorted) {
          return this.state.dataChangeOptionswithoutFilterPE
        } if (column.sorted) {
          return this.state.dataChangeOptionswithoutSortwithoutFilter

        }
      }
    } else {
      if (column.filter) {
        return this.state.dataChangeOptionswithFilter
      } if (!column.filter) {
        return this.state.dataChangeOptionswithoutFilter
      }
    }

  }

  /**
   *   invokes when the column drag and drop happens 
   * @param {Object} e 
   */
  onColReorder = async (e) => {
    let tablefieldsToShow = this.state.tablefieldsToShow
    let removeObj = tablefieldsToShow[e.dragIndex]
    tablefieldsToShow.splice(e.dragIndex, 1);
    tablefieldsToShow.splice(e.dropIndex, 0, removeObj);
    tablefieldsToShow = tablefieldsToShow
    await this.setState({
      tablefieldsToShow: tablefieldsToShow
    })
    setTimeout(() =>
      this.handlefiltersafterreorder(), 600)
  }

  getColumns(e, d) {
    const { t } = this.props
    const self = this;
    self.e = e;
    self.d = d;
    if (this.state.tablefieldsToShow && this.state.tablefieldsToShow.length > 0) {
      return this.state.tablefieldsToShow.map((item, i) => {
        let column = (item.show &&
          <Column key={item.field + i}
            rowReorder={item.field === "reOrder" ? true : false}
            expander={item.fieldType === "expander" ? true : false}
            rowEditor={item.fieldType === "rowEditor" ? true : false}
            editor={null}
            style={{
              maxwidth: item.width,
              padding: 2,
            }}
            bodyStyle={{
              textOverflow: item.field === "image" || item.field === 'reOrder' ? 'none' : 'ellipsis',
              overflow: item.header === "Actions" ? 'initial' : 'hidden',
              whiteSpace: 'nowrap',
              width: item.width,
              fontSize: this.props.type === "Utilization - US Team" || this.props.type === "Utilization - Integrity" ? 14 : this.props.type === "Recovery Planner V2" ? 10 : 12,
              textAlign: item.fieldType === "Badge" ? "left" : item.textAlign,
              verticalAlign: this.props.type === "Utilization - US Team" || this.props.type === "Utilization - Integrity" ? "baseline" : "inherit",
              // display: item.fieldType === "rowEditor" ? "inline-flex" : "block"
            }
            }
            onColReorder={this.onColReorder}
            filterMatchMode={item.filterType === "num" ? "num" : "string"}
            isAllowInt={item.allowInt ? true : false}
            field={item.field}
            getID={item && item.getID ? item.getID : null
            }
            activeTab={this.props.isSettings ? this.props.isSettings : this.state.activeTab}
            editRequired={self.e}
            deleteRequired={self.d}
            shownField={this.state.shownFieldTab}
            dataChangeOptions={this.getFilterOptionValues(item)}
            header={t(item.header)}
            changeFieldName={item.changeFieldName}
            body={self.changeFieldValues}
            headerStyle={{
              height: this.state.colunHeight ? this.state.colunHeight : "auto",
              padding: "6px 15px",
              width: item.width,
              fontSize: this.props.type === "Utilization - US Team" || this.props.type === "Utilization - Integrity" ? 16 : 13,
              fontWeight: this.props.type === "Utilization - US Team" || this.props.type === "Weekly Recovery Plan" || this.props.type === "Weekly Recovery Report" || this.props.type === "Utilization - Integrity" ? 'bold' : '500',
              color: item.header === "Green" ? "green" : item.header === "Blue" ? "blue" : item.header === "SP1 Group Count" ? "#12853C" : item.header === "SP2 Group Count" ? "#F4A61E" : item.header === "SP3 Group Count" ? "#256BD1" : item.header === "Add Payment(+)" || item.header === "Blue" ? "white" : config.blackColor,
              backgroundColor: config.templateColor,
              textAlign: this.props.type === "Utilization - US Team" || this.props.type === "Utilization - Integrity" ? 'center' : 'left'
            }}
            frozen={
              item.frozen ? true : false
            }
            filter={item.filter ? item.filter : false}
            filterElementnonText={item.filterType === "dropdown" ? this.DropDownComponent(item) : item.filterType === "multiSelect" ? this.MultiSelectComponent(item) : item.fieldType === 'Date' || item.fieldType === 'Time' || item.fieldType === "scheduledTime" || item.fieldType === "dateTime" ? this.CalenderComponent(item) : null}
            filterElement={item.filter && this.props.type === "OpenCorporateSearch" ? <select onChange={(e) => this.onSeletFilterType(item.field)} name="viewroom" id="viewroom" className={`mb-2 p-1 p-column-filterMode p-column-filterMode-${item.field}`} style={{ width: "100%" }} >
              <option value="regexOr">Includes</option>
            </select> : item.filter && item.filterType === "num" ?
              <select className={`mb-2 p-1 p-column-filterMode p-column-filterMode-${item.field}`} style={{ width: "100%" }} name="viewroom" id="viewroom" onChange={(e) => this.onSeletFilterType(item.field)}>
                <option value="eq">Equals</option>
                <option value="lt">Less Than</option>
                <option value="gt">Greater Than</option>
                <option value="lte">Less Than or Equals</option>
                <option value="gte">Greater Than or Equals</option>
                {/* <option value="nin">Exists</option>
                <option value="in">Not Exists</option> */}
              </select> : item.filter && item.filterType === "Equals" ?
                <select onChange={(e) => this.onSeletFilterType(item.field)} name="viewroom" id="viewroom" className={`mb-2 p-1 p-column-filterMode p-column-filterMode-${item.field}`} style={{ width: "100%" }} >
                  <option value="eq">Equals</option>
                </select>
                : item.filter && item.filterType !== "num" ?
                  this.props.type !== "Property Editor V2" && this.props.type !== "Deal Sizing Property Editor" && this.props.type !== "Deal Statistics" ?
                    <select onChange={(e) => this.onSeletFilterType(item.field)} name="viewroom" id="viewroom" className={`mb-2 p-1 p-column-filterMode p-column-filterMode-${item.field}`} style={{ width: "100%" }} >
                      <option value="regexOr">Includes</option>
                      <option value="eq">Equals</option>
                      <option value="sw">Starts With</option>
                      <option value="ew">Ends With</option>
                      <option value="nregexOr">Excludes</option>
                      {/* <option value="nin">Exists</option>
                      <option value="in">Not Exists</option> */}
                    </select>
                    : item.filterType === "dropdown" || item.filterType === "multiSelect" ?
                      <select name="viewroom" id="viewroom" className={`mb-2 p-1 p-column-filterMode p-column-filterMode-${item.field}`} style={{ width: "100%" }} >
                        {item.filterType === "dropdown" ? <option value="eq">Equals</option> : <option value="multi">Includes</option>}
                      </select>
                      : <select onChange={(e) => this.onSeletFilterType(item.field)} name="viewroom" id="viewroom" className={`mb-2 p-1 p-column-filterMode p-column-filterMode-${item.field}`} style={{ width: "100%" }} >
                        {item.isFuzzy ? <option value="fuzzyregexOr">Includes Fuzzy</option> : null}
                        <option value="regexOr">Includes</option>

                        <option value="eq">Equals</option>
                        <option value="nregexOr">Excludes</option>
                        {/* <option value="nin">Exists</option>
                        <option value="in">Not Exists</option> */}
                      </select>

                  : null
            }
            sortable={item.sortable ? true : false}
            // sortable={false}
            filterPlaceholder={item.placeholder ? item.placeholder : ''}
            selectionMode={this.state.activeTab !== "Teal" && !this.state.isEditedClicked ? item.selectionMode : ""}
          />
        )

        return column;
      })
    }
  }


  handleColumnresize = async (e, type) => {
    this.onColumnResizeEnd(e)
  }

  onColumnResizeEnd = (e) => {
    let colunHeight, columnHeight1, largest;
    if (this.props.frozen && document.getElementsByClassName("p-datatable-thead").length === 2) {
      colunHeight = document.getElementsByClassName("p-datatable-thead")[1].clientHeight
      columnHeight1 = document.getElementsByClassName("p-datatable-thead")[0].clientHeight
      if (colunHeight >= columnHeight1) {
        largest = colunHeight;
      }
      else {
        largest = columnHeight1;
      }
    } else {
      largest = document.getElementsByClassName("p-datatable-thead")[0].clientHeight

    }
    if (this.state.columnHeight !== largest) {
      this.setState({
        colunHeight: largest,
      })
    }
    if (e && e.column.frozen) {
      let frozenwidth = this.state.frozenWidth
      this.setState({
        frozenwidth: frozenwidth + e.delta
      })
      document.getElementsByClassName("p-datatable-scrollable-view p-datatable-frozen-view")[0].style.width = this.state.frozenWidth
    }
  }


  getDataTable() {
    let self = this;
    self.editRequired = this.props.editRequried;
    self.deleteRequired = this.props.deleteRequried;

    const rowSpanrequired = ["Deal Sizing", "Claims", "Deal Sizing Queue", "Site Confirmation Editor", "Regular Scoring Queue", "Deal Sizing Scheduler", "Regular Scoring Scheduler", "ClaimsManagement", "State Attributes", "Recovery Planner V2"]
    return (

      <div
      >

        <DataTable

          onColumnResizeEnd={(e) => this.handleColumnresize(e, this.props.type)}
          reorderableColumns={true}
          // onColumnResizeEnd={(e) => this.onColumnResizeEnd(e)}
          rowGroupMode={"rowspan"}
          // this.props.type !== "Deal Sizing" && this.props.type !== "Claims" && this.props.type !== "Deal Sizing Queue" && this.props.type !== "Regular Scoring Queue" && this.props.type !== "Deal Sizing Scheduler" && this.props.type !== "Regular Scoring Scheduler" && this.props.type !== "ClaimsManagement" && this.props.type !== "State Attributes" && this.props.type !== "Recovery Planner V2" ? "rowspan" : ""}
          groupField={this.props.groupField}
          groupRowSpan="2"
          //Column Grop Props
          //edit props
          editMode="row"
          onColReorder={this.onColReorder}
          // dataKey={this.props.type !== "Property Editor V2" && this.props.type !== "Sellers" && this.props.type !== "Deal Sizing" && this.props.type !== "Claims" ? "id" : this.props.type === "Sellers" ? "DimSellerID" : this.props.type === "Deal Sizing" ? "Sno" : this.props.type === "Claims" ? "propertyID" : "_id"}
          dataKey={this.props.dataKey ? this.props.dataKey : "_id"}
          sortField={this.props.sortedField}
          frozenWidth={this.props.frozen && this.state.isFrozenColumns ? this.state.frozenWidth : ""}
          sortOrder={1}
          responsiveLayout="scroll"
          rowClassName={(e) => this.rowClassName(e)}
          ref={(el) => this.dt = el}
          value={this.state.allUsersData}
          expandedRows={this.state.expandedRows}
          onRowToggle={(e) => this.setState({ expandedRows: e.data })}
          // header={this.getHeader()}
          totalRecords={this.state.totalRecordsLength}
          paginator={false}
          lazy={true}
          resizableColumns={true}
          columnResizeMode="expand"
          onSort={this.handleDataChangingOption}
          globalFilter={this.state.globalFilter}
          onFilter={this.isConfirmFilter}
          scrollable={true}
          // selection={false}
          selectionMode={this.props.isSelectMode}
          onSelectionChange={(e) => this.onSelectRowsUpdate(e)}
          // onSelectionChange={(e) => this.setState({ selectedRows: e.value })} 
          selection={this.state.selectedRows}
          scrollHeight={this.state.scrollHeight ? this.state.scrollHeight + "px" : "1000px"}
          emptyMessage={this.state.errorResponse ? this.state.errorMessage : configMessages.noRecords}
          sortMode="single"
          metaKeySelection={false}
          loading={this.state.isLoading}
          style={this.state.allUsersData && this.state.allUsersData.length === 0 ?
            { textAlign: 'center', marginTop: 24 }
            : this.props.style ? this.props.style : { marginTop: 30 }}
        >
          {self.getColumns(self.editRequired, self.deleteRequired)}
        </DataTable>
        {this.props.type === "FactSet" ?
          <h5 style={{ paddingTop: "5px", textAlignLast: "right" }}>Total Count: {this.state.FactSetCount}</h5> : ""
        }
      </div >
    )
  }

  getTabInfo() {
    return null;
  }

  getPaginator() {
    let paginationNotneededScreens = ["UP Opportunity – V2", "AvailableToMap", "AvailableToReMap"]
    if (this.state.totalRecordsLength > 10 && this.state.isShowTable && !paginationNotneededScreens.includes(this.props.type))
      return (
        <span className='ml-auto'>
          <PaginatorComponent
            totalRecords={this.state.totalRecordsLength}
            first={this.state.first}
            rows={this.state.rows}
            onPageChange={this.onPageChange}
            isWeb={true}
          /></span>

      )

  }


  //for Sorting the Menu lists in Roles
  compare(a, b) {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  }

  editorTemplateForDeal(option) {
    return (`${option.DealType} - ${option.SellerNickName} (${option.DealYear ? option.DealYear : ""})`)
  }

  selectedViewType = async (e, type) => {
    if (type) {
      await this.setState({
        isFrozenColumns: e == "unfreeze" ? false : true,
        frozenWidth: e == "unfreeze" ? 0 : this.state.frozenWidth
      })
    } else {
      await this.setState({
        selectedViewType: e,
      })
    }

    // if (e === "All Validated") { this.getremoveFilters() }
    this.getTableFieldsOrder();
    this.scrollWin()
  }

  toggletab(tab, setGoButton) {
    let tagSwitch = false
    let filterCriteria = this.state.filterCriteria
    if (this.state.activeTab !== tab) {
      tagSwitch = true
      this.getisLoading()
      this.setState({
        activeTab: tab,
        page: 1,
        first: 0,
        isLoading: true,
        allUsersData: [],
        showSelectederror: false,
        validatedArray: [],
        errormsg: "",
        selectedReason: "",
        selectednote: "",
        claimID: "",
        selectedStatus: "",
        isDisabledTab: true,
        // tablefieldsToShow:tableFieldsToShow
      });
      this.handlePercentage("1")
    }
    if (tab === "SP" && this.state.PropertyData && this.state.PropertyData.groupFlag) {
      let flag
      if (!this.state.PropertyData.groupFlag.includes("ReMap")) {
        flag = this.state.PropertyData.groupFlag.slice(0, 6);
      } else if (this.state.PropertyData.groupFlag === "ReMapSPGroup3Flag") {
        flag = "reMapGroup3";
      } else if (this.state.PropertyData.groupFlag === "ReMapSPGroup2Flag") {
        flag = "reMapGroup2";
      } else if (this.state.PropertyData.groupFlag === "ReMapSPGroup1Flag") {
        flag = "reMapGroup1";
      }
      filterCriteria.sortfield = `${flag}ParentPropertyID, PropertyReviewedFlag, ${this.state.PropertyData.groupFlag}, PropertyName, PropertyAddressLine1`
      filterCriteria.direction = "desc"
    } else if (tab === "SP" && this.state.PropertyData && !this.state.PropertyData.groupFlag) {
      filterCriteria.sortfield = this.state.isSortField ? this.state.isSortField : "ParentPropertyID, PropertyName, PropertyAddressLine1"
      filterCriteria.direction = "desc"
    } else if (tab === "PV" || tab === "OR") {
      filterCriteria.sortfield = this.state.isSortField ? this.state.isSortField : "updatedDate"
    } else {
      let field = this.state.isSortField
      if (tab !== "SP" && field && field.length === 2) {
        field = field[2]
      }

      filterCriteria.sortfield = field ? field : tab === "SP" ? "ParentPropertyID, PropertyName, PropertyAddressLine1" : this.props.sortField
    }
    // filterCriteria=this.getDefaultSortwhenGroupFlag(filterCriteria)
    filterCriteria["page"] = 1
    if (this.editorComponentRef) {
      this.editorComponentRef.updatedfileFor()
      this.editorComponentRef.updateClaimID()
      this.editorComponentRef.updatedrelevantSellerName()
      this.editorComponentRef.handlePropertiesEditChange("", "Cancel")
    }
    let index = filterCriteria["criteria"].findIndex(obj => obj.key === "bucket" && obj.add_Filter === true)
    if (index !== -1) filterCriteria["criteria"].splice(index, 1)
    filterCriteria.criteria.push({
      key: "bucket",
      value: tab,
      type: "eq",
      add_Filter: true
    })
    let body = {}
    if (this.state.PropertyData) {
      body = {
        propertyId: this.state.PropertyData.selectedProperty_Id ? this.state.PropertyData.selectedProperty_Id.toString() : null,
        statePropertyId: this.state.PropertyData.selectedOpraStId ? this.state.PropertyData.selectedOpraStId.toString() : null
      }
    }
    if (this.state.Counts && tab != "Green" && tab != "Results" && tagSwitch && this.state.Counts[tab] > 10000) {
      this.setState({ isDisabledTab: true, totalRecordsLength: this.state.Counts[tab], isChangeHit: false, isLoading: false })
      this.isShowTable(this.state.isAppliedColFilter ? true : false)
    } else {
      this.setState({ showErrorMessage: false, totalRecordsLength: 0 })
      if (this.state.PropertyData) {
        this.getDataFromServer(filterCriteria, null, setGoButton, body)
      }
    }
  }

  getshowSelectederrormsg = async (errormsg, showSelectederror, color) => {
    await this.setState({
      errormsg: errormsg,
      showSelectederror: showSelectederror,
      errorcolor: color === "error" ? "red" : "green"
    })
  }
  changeCreateTask = async (e, type) => {
    if (type === "addToTask") {
      await this.setState({ createTaskAdd: e.value })
    } else {
      await this.setState({ createTask: e.value })
    }
    if (e && e.value.includes("Claim") && !e.value.includes("Bifurcation")) {
      let stateArray = [], connectedClaimIDArray = []
      await this.setState({ createclaimfromPE: true, Claimtype: type })
      for (var obj of this.state.selectedRows) {
        if (!stateArray.includes(obj.sourceState)) {
          stateArray.push(obj.sourceState)
        }
        if (obj.connectedClaimID !== null) {
          connectedClaimIDArray.push(obj.connectedClaimID)
        }
      }
      if (stateArray.length === 1) {
        if (connectedClaimIDArray.length === 0) {
          this.getErrorMessage("", false, "")
          this.props.getClaimsDetails(this.state.selectedSeller, this.state.selectedRows, type)
        } else {
          this.getErrorMessage("Few or all selected properties that you are trying to create a claim or add to claims are already exists", true, "error")
          this.setState({
            createTask: "",
            createTaskAdd: ""
          })

        }
      } else {
        this.setState({
          createTask: "",
          createTaskAdd: ""
        })
        this.getErrorMessage("The selected properties have different Source states. Properties with the same Source State must be selected to create or add to claims.", true, "error")
      }


    } else if (e.value.includes("Bifurcation")) {
      this.props.bifurcationConfirmation(e.value, this.state.selectedRows)
    } else {
      if (this.state.createTask === "General Task" || this.state.createTaskAdd === "General Task") {
        await this.setState({ iskanbanModal: true, formType: type })
      } else {
        await this.setState({ createTaskModal: true, formType: type })
      }
    }
  }

  changeCreateTaskFromReport = async (e, type) => {

    await this.setState({ createTask: e.value })
    await this.setState({ iskanbanModal: true, formType: type })

  }

  openSummaryStatsReport = async () => {
    await this.setState({ openSummaryStatsModal: true })
  }

  closeSummaryStatsReport = async () => {
    await this.setState({ openSummaryStatsModal: false })
  }

  getUrlbyParams = async (type, item, clickedField) => {
    await this.setState({ removeDataSource: true })
    let filtercriteria = this.state.filterCriteria
    let url, index, Subject, matchMatchStatusIndex, matchMatchReasonIndex;
    let screenName = "propertyEditorV2"
    index = filtercriteria && filtercriteria["criteria"] ? filtercriteria["criteria"].findIndex(obj => obj.key === "SourceState") : null
    matchMatchStatusIndex = filtercriteria && filtercriteria["criteria"] ? filtercriteria["criteria"].findIndex(obj => obj.key === "propertyStatus") : null
    matchMatchReasonIndex = filtercriteria && filtercriteria["criteria"] ? filtercriteria["criteria"].findIndex(obj => obj.key === "propertyStatusReason") : null
    if (this.state.PropertyData && this.state.PropertyData.selectedSeller) {
      if (this.props.type === "Property Editor V2") {
        if (this.state.PropertyData && this.state.PropertyData.selectedSeller) {
          url = `${window.location.protocol}//${window.location.host}/${screenName}?sellerName=${encodeURIComponent(this.state.PropertyData.selectedSeller.EstateName)}&sellerId=${this.state.PropertyData.selectedSeller.EstateID}&bucket=${this.state.activeTab ? this.state.activeTab : "Green"}${this.state.PropertyData.isValidate ? `&isValidated=` + "true" : ""}${this.state.PropertyData.isexcludeProperties ? `&isexcludeProperties=` + this.state.PropertyData.isexcludeProperties : ""}${this.state.PropertyData.isexcludePossiblyPaid ? `&isexcludePossiblyPaid=` + "true" : ""}${this.state.PropertyData.isexcludeValidated ? `&isexcludeValidated=` + "true" : ""}${this.state.PropertyData.excludeCoOwners ? `&excludeCoOwners=` + "true" : ""}${this.state.PropertyData.isexcludeRoyalties ? `&excludeRoyalties=` + "true" : ""}`
          if (this.state.PropertyData.selectedName) {
            url = `${url}&nameId=${encodeURIComponent(this.state.PropertyData.selectedName)}`
          }
          if (filtercriteria.criteria[index] && filtercriteria.criteria[index].value) {
            url = `${url}&state=${encodeURIComponent(filtercriteria.criteria[index].value)}`
          }
          if (filtercriteria.criteria[matchMatchStatusIndex] && filtercriteria.criteria[matchMatchStatusIndex].value) {
            url = `${url}&selectedPropertyStatus=${encodeURIComponent(filtercriteria.criteria[matchMatchStatusIndex].value)}`
          }
          if (filtercriteria.criteria[matchMatchReasonIndex] && filtercriteria.criteria[matchMatchReasonIndex].value) {
            url = `${url}&selectedPropertyReasons=${encodeURIComponent(filtercriteria.criteria[matchMatchReasonIndex].value)}`
          }
          if (this.state.PropertyData.excludeNotConfirmedOnSite) {
            url = `${url}&excludeNotConfirmedOnSite=true`
          }
          if (this.state.PropertyData.taskID) {
            url = `${url}&taskID=${this.state.PropertyData.taskID}`
          }
        } else if (this.state.PropertyData.selectedProperty_Id) {
          url = `${window.location.protocol}://${window.location.host}/${screenName}?propertyId=${this.state.PropertyData.selectedProperty_Id}`
        } else {
          url = `${window.location.protocol}//${window.location.host}/${screenName}`
        }
      }
      Subject = `Properties Sharing for Review`
    }

    let SharedUrl = {
      url: url,
      Subject: Subject,
      customerEmail: ""
    }

    await this.setState({
      PropertySharebody: url,
      ProertyShareSubject: Subject,
      customerEmail: ""
    })
    return SharedUrl
  }


  copyToClipboardProperties = async () => {
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(this.state.uppropPropertyIds.join("\n"))
    }

  }
  copyToClipboardIndividual = async (id) => {
    let notUpArray = this.state.notUpdateArray
    let z = notUpArray.find(elem => elem.id === id)
    if (z) {
      z.clicked = true
    }
    await this.setState({ notUpdateArray: notUpArray })
    return (navigator && navigator.clipboard ? navigator.clipboard.writeText(id) : null)

  }

  copyToClipboard = async () => {
    let filtercriteria = this.state.filterCriteria
    let url, index, matchMatchStatusIndex, matchMatchReasonIndex;
    matchMatchStatusIndex = filtercriteria["criteria"].findIndex(obj => obj.key === "propertyStatus")
    matchMatchReasonIndex = filtercriteria["criteria"].findIndex(obj => obj.key === "propertyStatusReason")

    // }
    if (this.props.type === "Deal Sizing-Properties") {
      index = filtercriteria["criteria"].findIndex(obj => obj.key === "sourceState")
    } else {
      index = filtercriteria["criteria"].findIndex(obj => obj.key === "sourceState")
    }
    if (this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor") {
      await this.getUrlbyParams()
      url = this.state.PropertySharebody
    } else {
      if (this.state.PropertyData && this.state.PropertyData.selectedSeller) {
        url = `${window.location.protocol}//${window.location.host}/dealproperties?sellerName=${encodeURIComponent(this.state.PropertyData.selectedSeller.EstateName)}&sellerId=${this.state.PropertyData.selectedSeller.EstateID}&bucket=${this.state.activeTab}${this.state.PropertyData.isValidate ? `&isValidated=` + "true" : ""}${this.state.PropertyData.isexcludeProperties ? `&isexcludeProperties=` + this.state.PropertyData.isexcludeProperties : ""}${this.state.PropertyData.isexcludePossiblyPaid ? `&isexcludePossiblyPaid=` + "true" : ""}${this.state.PropertyData.isexcludeValidated ? `&isexcludeValidated=` + "true" : ""}${this.state.PropertyData.excludeCoOwners ? `&excludeCoOwners=` + "true" : ""}${this.state.PropertyData.selectedProperty_Id && this.state.PropertyData.selectedProperty_Id.length > 0 ? `&propertyId=` + encodeURIComponent(this.state.PropertyData.selectedProperty_Id) : ""}${this.state.PropertyData.selectedOpraStId && this.state.PropertyData.selectedOpraStId.length > 0 ? `&statePropertyId=` + encodeURIComponent(this.state.PropertyData.selectedOpraStId) : ""}`
        if (filtercriteria.criteria[index] && filtercriteria.criteria[index].value) {
          url = `${url}&state=${encodeURIComponent(filtercriteria.criteria[index].value)}`
        }
        if (filtercriteria.criteria[matchMatchStatusIndex] && filtercriteria.criteria[matchMatchStatusIndex].value) {
          url = `${url}&selectedPropertyStatus=${encodeURIComponent(filtercriteria.criteria[matchMatchStatusIndex].value)} `
        }
        if (filtercriteria.criteria[matchMatchReasonIndex] && filtercriteria.criteria[matchMatchReasonIndex].value) {
          url = `${url}&selectedPropertyReasons=${encodeURIComponent(filtercriteria.criteria[matchMatchReasonIndex].value)} `
        }
      } else {
        url = `${window.location.protocol}://${window.location.host}/dealproperties?propertyId=${this.state.PropertyData.selectedProperty_Id}`
      }
    }
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(url)
    }
    await this.setState({ copiedA: true })
  }
  PropertyIdDisplay(rowData, column) {
    // var src = "showcase/demo/images/car/" + rowData.brand + ".png";
    return <span>{rowData}</span>
  }
  copyTemplate = (rowData, column) => {
    let z
    let notUpArray = this.state.notUpdateArray
    if (notUpArray) {
      z = notUpArray.find(elem => elem.id === rowData)
    }

    return (
      <div className='' >
        <FontAwesomeIcon
          icon={faCopy}
          className='ml-2'
          data-toggle="tool-tip" title={("Copy")}
          // onClick={() => navigator.clipboard.writeText(rowData)}
          onClick={() => { this.copyToClipboardIndividual(rowData) }}
          style={{ width: 18, color: z && z.clicked ? "blue" : "black" }}
        />
      </div>
    )
  }


  getNegativeNoticeSellers = () => {
    if (this.state.PropertyData && this.state.PropertyData.selectedSeller) {
      let url = `NsScoredProperties/GetNegativeNotice?estateName=${encodeURIComponent(this.state.PropertyData.selectedSeller.EstateName)}&estateID=${this.state.PropertyData.selectedSeller.EstateID}`
      fetchMethodRequest('GET', url)
        .then(async (response) => {
          if (response && response.dimSellers && response.negativeNotice !== null) {
            await this.setState({
              redirectSellerId: response.dimSellers[0].dimSellerID,
              negativePeriod: response.negativeNotice.noticePeriod
            })
          } else if (response && response['errorMessage'] === configMessages.warningMessage) {
            await this.setState({
              redirectSellerId: null,
              negativePeriod: null
            })
          } else {
            await this.setState({
              redirectSellerId: null,
              negativePeriod: null
            })
          }
        }).catch((err) => {
          return err;
        });
    }
  }


  getAccordionHeader(e) {
    return (
      <span className={"d-flex col-sm-12 pl-0 pr-0"}>
        <div className={" mr-auto px-0 accordian_custum_header hideBorder"} style={{ width: "25%" }}>
          <span>Editor</span>
          <span style={{ marginLeft: "40px", textAlign: "center", color: this.state.errorcolor }}>{this.state.errorResponse && this.props.type !== "Property Editor V2" ? this.state.errorMessage : ""}</span>

        </div>
        <>{this.props.getModaltoCreateProperty(this.state.PropertyData, this.state.selectedRows)}</>
      </span>
    )
  }

  isCloseClaimfromPE() {
    this.setState({
      createTask: "",
      createTaskAdd: ""
    })
  }

  getErrorMessages = () => {
    return (
      <span className={!this.props.dontShowTitle ? '' : "mx-auto"} style={{ maxWidth: "630px" }} >
        <p className={`${this.state.errorcolor === "red" ? "errorbgColor errorTextColor" : this.state.errorcolor === "green" ? "successbgColor successTextColor" : "bg-white"} topbar__center topBarImageAlignment px-3`} style={{ display: "block" }} >
          {this.state.errorResponse ? this.state.errorMessage : ""}
        </p>
        <p className="topbar__centerresponse topBarImageAlignment successbgColor successTextColor px-3" >{this.state.successResponse ? this.state.successMessage : null}</p>
        <p className="topbar__centerresponse topBarImageAlignment errorbgColor errorTextColor px-3" >{this.state.errorResponseHighProperty ? this.state.errorResponseHighPropertyMessage : null}</p>
        <p className="topbar__info topBarImageAlignment px-3 " style={{ color: "blue" }}>{!this.state.errorResponse && this.props.type !== "Property Editor" && this.props.type !== "Deal Sizing Property Editor" && this.state.propertyStatus && this.state.propertyStatus.length > 0 ? `The results are sorted by ${this.state.propertyStatus}` : null}</p>
        <p className="topbar__info topBarImageAlignment px-3 " style={{ color: "blue" }} >{this.state.softWarning ? this.state.softMessage : null}</p>
        <p className={this.state.errorcolor === "red" ? "errorbgColor errorTextColor px-3" : this.state.errorcolor === "green" ? "successbgColor successTextColor px-3" : "bg-white"}>
          {this.state.showSelectederror ? this.state.errormsg : null}
        </p>
        <p className={`${this.state.columnOrderError ? "successbgColor successTextColor" : "bg-white"} topbar__center topBarImageAlignment px-3`}>
          {this.state.columnOrderError ? this.state.columnOrderMsg : null}
        </p> <p className={`${this.state.columnOrderError ? "successbgColor successTextColor" : "bg-white"} topbar__center topBarImageAlignment px-3`}>
          {this.state.copiedA ? "Link Copied Successfully." : null}
        </p>
      </span>
    )
  }

  clearsortfield = () => {
    this.setState({
      isignoremultiSortField: false
    })
  }

  onTabOpen = async (e, n) => {
    // setTimeout(() =>
    //   this.updateDimensions(), 650);
  }

  getFilerField() {
    let settingsData = this.props.settingsData
    let isEditted = localStorage.getItem("ISEdited") ? true : false
    return (
      <div className='mb-1'>
        {
          <div className="" style={{ marginTop: 10 }}>

            <>
              <div >
                <div style={{ width: "100%" }}>
                  <span className={"d-block editor_header"}>
                    <Accordion multiple={true} activeIndex={[]} onTabOpen={this.onTabOpen} onTabClose={this.onTabOpen}>
                      <AccordionTab header={this.getAccordionHeader()}>
                        <div className='row' style={{ display: 'flex' }}>
                          {this.getEditorComponent()}
                        </div>
                      </AccordionTab>
                    </Accordion></span>
                  <Row className="d-flex" style={{ alignItems: "center" }}>
                    <span className='ml-2 errorColor' style={this.state.totalRecordsLength > 10000 && ((this.props.type === "Property Editor V2" && this.state.activeTab !== "SP" && this.state.activeTab !== "Results") || this.props.type === "Deal Sizing Property Editor") || this.state.showErrorMessage && this.props.type !== "Negative Notice List Viewer" ? { display: 'flex' } : { display: 'none' }}>
                      Please note the count of records need to be less than {this.props.type === "Properties" ? 1000 : 10000}, for you to see results.  Please select additional filter criteria and click Go.
                    </span>
                    {this.getErrorMessages()}
                    <span className="" style={{ color: "red", textAlign: "center" }}>
                      {this.state.redirectSellerId !== null && this.state.negativePeriod !== null ?
                        <span>This deal has negative notice period of<a className='px-1'
                          href={config.negativeNoticeUrl} target="_blank"><u>{this.state.negativePeriod}</u></a></span>
                        : null}
                    </span>
                  </Row>
                  <Nav tabs >
                    {
                      this.state.propertyEditorTabs && this.state.propertyEditorTabs.length > 0 ? this.state.propertyEditorTabs.map((item, index) => {
                        return (
                          <NavItem key={index}>
                            <NavLink
                              className={classnames({ active: this.state.activeTab === item.name })}
                              onClick={() => { this.toggletab(item.name); }}
                            >
                              <span data-toggle="tool-tip" title={item.tooltip ? item.tooltip : item.name} style={this.state.isDisabledTab ? { color: "lightgray" } : { color: item.color, }}>{item.name === "PV" ? "Positively Mapped " : item.name === "OR" ? "Other Reviewed " : item.name + " "}({this.state.Counts ? this.state.Counts[item.name] ? this.state.Counts[item.name] : 0 : 0})</span>
                              {item.name === "Results" ?
                                <img
                                  src={configImages.SortIcon}
                                  style={{ height: '16px', width: '24px', cursor: "pointer" }}
                                  className='pl-1 mb-2' size='lg'
                                  data-toggle="tool-tip" title="Multi Sort"
                                  onClick={(e) => this.props.openMultiSortModel(e,this.state.propertyEditorTabs)}
                                />
                                : null}
                            </NavLink>
                          </NavItem>
                        )
                      }) : null
                    }
                    {this.props.type === "Deal Sizing Property Editor" && this.state.PropertyData && this.state.PropertyData.selectedSeller ?
                      <span className='d-flex'>
                        < Button color="primary"
                          outline
                          size="sm"
                          style={{ height: 37 }}
                          className={"py-2 px-5 mb-0 mx-1"}
                          onClick={(e) => this.openSummaryStatsReport()}
                        >Summary Stats
                        </Button>
                      </span>
                      : null}
                    {/* <span className="mx-auto d-flex align-items-center 1" style={{ color: "black", height: 25, display: this.state.isCopiedCellValue ? "flex" : "none", backgroundColor: "rgb(209, 251, 193)" }}>{this.state.errorResponse ? this.state.errorMessage : ""}</span> */}
                    {this.getPaginator()}


                  </Nav>
                </div>
              </div>
            </>
            {/* } */}
          </div>
        }
      </div >
    )
  }
  //to remove datatable all Filters 
  getremoveFilters = async (type) => {
    await this.setState({
      clearFilters: true,
      page: 1,
      isLoading: true,
      first: 0
    })

    //removing inline filters
    let filterCriteria = this.state.filterCriteria
    var newArr = filterCriteria["criteria"].filter(object => {
      return object.add_Filter !== false;
    });
    filterCriteria["criteria"] = newArr
    filterCriteria["page"] = 1
    if (filterCriteria.globalSearch && filterCriteria.globalSearch.value && type) {
      delete filterCriteria.globalSearch
    }
    this.getTableFieldsOrder()
    this.setState({
      hasPropertyCoOwner: "",
      confirmedOnsite: "",
      hasPropertyCoOwner: "",
      propertyStatus: "",
      propertyStatusReason: "",
      confirmedOnsite: "",
      effectiveFromDate: "",
      reportedMissing: "",
      propertyStatus: "",
      matchMatchStatus: "",
      matchStatusReason: "",
      propertyReason: "",
      ocFilters: []
    })
    this.getTabChanges(filterCriteria)
  }

  handlefiltersinUI = () => {


  }

  setPropertyTrailViewerModal = async () => {
    await this.setState({
      PropertyTrailViewerModal: true
    })
  }

  closePropertyTrailViewerModal = async () => {
    await this.setState({
      PropertyTrailViewerModal: false
    })
  }

  handleOngoing = async () => {
    await this.setState({
      onGoing: true
    })
    this.getDataToExport()
  }

  getTotalCard() {
    if (this.state.viewType === 'list') {
      return (
        <Card style={{ paddingBottom: "10px", paddingTop: "0px", margin: 0 }} className={!this.props.className ? "customCard cardForListMargin m-0" : "cardForListMargin m-0"}>
          <CardBody className={this.props.type ? 'tableCardBody' : 'modalTablePadding'} style={{ borderRadius: "0px" }}>
            {this.getHeader()}
            {this.getTabInfo()}
            {/* <Row className="col-sm-12 px-0 claims-manager" style={{ justifyContent: "end" }}>{this.getPaginator()}</Row> */}
            {this.props.isCustomHeader ? this.props.getHeaderforSchedule() : ""}
            {!this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen'
              ? this.getDataTable()
              : this.state.displayViewOfForm === 'modal' && this.state.isShowTable ?
                this.getDataTable() : null}
            {this.state.isOpenFormModal && (this.props.type !== "List View" || this.props.type !== "Research Request Viewer" || this.props.type !== "Seller Negative Notice Viewer") ? this.getFormModal() : null}
            {this.props.type !== 'Regular Scoring Queue' && this.props.type !== 'Property Editor' ? <span className='float-right pt-2'>
              <div className='col-12 px-0 pb-1'>
                {/* {!this.state.isOpenFormModal ? this.getPaginator() : null} */}
              </div>
            </span> : ""}
          </CardBody>
        </Card >

      )
    } else if (this.state.viewType === 'grid') {
      return (
        <div>
          <Card
            className={' pb-2 cardForGridMargin'}>
            <CardBody className='tableCardBody pb-0' style={{ borderRadius: "0px" }}>
              {!this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen' ? this.getScreenHeader() : this.state.displayViewOfForm === 'modal' ? this.getHeader() : null}
              {this.getTabInfo()}
              {this.state.isOpenFormModal ? this.getFormModal() : null}
            </CardBody>
          </Card>
          {!this.state.isOpenFormModal && this.state.displayViewOfForm === 'screen' ? this.getGridView() : this.state.displayViewOfForm === 'modal' ? this.getGridView() : null}
        </div>
      )
    }
  }


  getArrayVal = (val) => {
    if (val) {
      val = val.replace(/\\n/g, ' ')
        .replace(/\\t/g, ' ')
        .replace(/"/g, '')
        .replace(/\[/g, '')
        .replace(/]/g, '')
        .replace(/{/g, '')
        .replace(/}/g, '')
        .replace(/,/g, ' , ')
        .replace(/:/g, ' : ')
        .replace(/\\/g, ' ')
        .replace(/"/g, '')
      // .replace(/,/g, ' ');
    }
    return val;
  }

  getvalfromObj = (val, field) => {
    if (val) {
      val = val[field]
    }
    return val;
  }

  slicedArray = (arrayVal) => {
    let arrLength;
    if (arrayVal && arrayVal.length)
      arrLength = arrayVal.length;
    if (arrayVal) {
      // val = 
      let statecp = []
      for (let sine in arrayVal) {
        statecp.push(arrayVal[sine] === "CA-N" ? "CA-N" : arrayVal[sine].slice(-2))
      }
      arrayVal = statecp.join(', ')
    }
    if (arrLength > 3) {
      arrayVal = arrayVal.slice(0, 10);
      arrayVal = arrayVal + "  (" + arrLength + ")";
    } else {
      arrayVal = arrayVal
    }
    return arrayVal;
  }

  flattenArray = (arrayVal, item) => {
    let val = '';
    let arrLength = ""
    if (this.props.type === "List View" && arrayVal && arrayVal.length > 3) {
      arrLength = arrayVal.length
      arrayVal = arrayVal.slice(0, 3);
    }
    if (arrayVal) {
      val = JSON.stringify(arrayVal);
      if (this.props.type !== "Weekly Recovery Plan" && this.props.type !== "Weekly Recovery Report"
        && this.props.type !== "Utilization - US Team" && this.props.type !== "Utilization - Integrity" &&
        this.props.type !== "Process Documents") {
        if (this.props.type === "List View" || this.props.type === "Research Request Viewer" || this.props.type === "Seller Negative Notice Viewer" || this.props.type === "RFI View") {
          if (arrLength > 3) {
            val = this.getArrayVal(val) + "  (" + arrLength + ")";
          } else {
            val = this.getArrayVal(val)
          }
        } else if (this.props.type === "ClaimsManagement" && arrayVal[0] && arrayVal[0].checkAmount) {
          let dollarUS = this.getFormattedCurrencyValue()
          val = arrayVal.map((arrayItem, index) =>
            <p data-toggle="tool-tip"
              title={arrayItem.checkAmount} onClick={() => this.openFormModal(item, 'edit', "Payments", arrayItem)}>{dollarUS.format(arrayItem.checkAmount)}</p>
          );
        } else if (this.props.type === "ClaimsManagement" || this.props.type === "Claims" || this.props.type === "State Attributes" || this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "HighValueProperties" || this.props.type === "OPRA2 Property Editor" || this.props.type === "Deal Sizing-Properties") {
          val = arrayVal.map((arrayItem) =>
            arrayItem !== null ?
              <p data-toggle="tool-tip"
                title={arrayItem.note}>{arrayItem.note}</p>
              : null
          );
        } else {
          val = this.getArrayVal(val)
        }
      } else if (this.props.type === "Process Documents") {
        val = arrayVal.map((arrayItem) =>
          <p className='mb-1' style={{ height: 20 }} data-toggle="tool-tip"
            title={arrayItem.documentName}><a style={{ color: '#0e4768', cursor: 'pointer' }} target="_blank" href={`${arrayItem.documentUrl}`}>{arrayItem.documentName}</a></p>
        );
      } else {
        val = arrayVal.map((arrayItem) => {
          if (this.props.type !== "Weekly Recovery Plan" && this.props.type !== "Weekly Recovery Report") {
            return (
              arrayItem.company === "Total" ?
                <p style={{ color: arrayItem.dealType === "BK" ? "brown" : "black", marginTop: 1 }} data-toggle="tool-tip"
                  title={arrayItem.company + " (" + arrayItem.spent.toFixed(1) + ")"}>{arrayItem.company + ": " + arrayItem.spent.toFixed(1) + " Hrs"}</p>
                :
                <p style={{ color: arrayItem.dealType === "BK" ? "brown" : "black", marginTop: 1 }} data-toggle="tool-tip"
                  title={arrayItem.company + " (" + arrayItem.spent.toFixed(1) + ")"}>{arrayItem.company + " (" + arrayItem.spent.toFixed(1) + ") "}</p>
            )
          } else {
            return (
              <p title={arrayItem.userName}>{arrayItem.userName}</p>
            )
          }
        }
        );
      }
    }

    return val;
  }

  timeConversionString(date) {
    return moment(date).tz(moment.tz.guess()).format(config.dbOnlyDateFormat);
  }

  setHeadersforxlsx = (tabName) => {
    let arrayFormatcolumns = []
    let data = JSON.parse(localStorage.getItem("tableFields_download"))
    let getHeadersfrom = data && data[tabName] ? data[tabName] : this.props.getSellerTableFields()
    for (var obj of getHeadersfrom) {
      if (obj.field !== "Actions" && obj.field !== "") {
        arrayFormatcolumns.push(obj.field)
      }
    }
    return arrayFormatcolumns
  }

  removeSelectedRows = async () => {
    this.setState({
      selectedRows: []
    })
  }

  getFormFields = () => {
    this.formModalRef.getFormFields()
  }


  getOpenShowHideColumnsModal() {
    return (
      <ShowHideColumnsModal
        isOpenShowHideColumnsModal={this.state.isOpenShowHideColumnsModal}
        closeShowHideColumnsModal={this.closeShowHideColumnsModal}
        tableFields={this.state.originalTableFields}
        type={this.props.type}
        viewBy={this.state.selectedViewType}
        originalTableFields={this.state.tableFields}
        tableFieldsToShow={this.state.tablefieldsToShow}
        isShownnFieldType={this.props.type === "Property Editor V2" || this.props.type === "Deal Sizing Property Editor" || this.props.type === "OPRA2 Property Editor"
          || this.props.type === "Properties" || this.props.type === "ClaimsManagement"
          || this.props.isShownnFieldType ? false : true}

      />
    )
  }

  getCreateTaskModal() {
    return (
      <CreateTaskModel
        openUserPasswordResetModal={this.state.createTaskModal}
        cancelReset={this.closeFormModal}
        cancelCreateTaskModal={this.cancelCreateTaskModal}
        filterData={this.state.filterCriteria}
        createTask={this.state.createTask ? this.state.createTask : this.state.createTaskAdd ? this.state.createTaskAdd : ""}
        taskstatePropertyId={this.state.taskstatePropertyId}
        previousTaskId={this.state.previousTaskId}
        unknownPropertyCount={this.state.unknownPropertyCount && this.state.unknownPropertyCount.length > 0 ? this.state.unknownPropertyCount.length : 0}
        // previousTaskId={[12345, 3456]}
        taskminValue={this.state.taskminValue}
        taskstates={this.state.taskstates}
        estateDetails={this.state.PropertyData.selectedSeller}
        taskopraPropertyId={this.state.taskopraPropertyId}
        selectedRows={this.state.selectedRows}
        count={this.state.taskopraPropertyId ? this.state.taskopraPropertyId.length : 0}
        // count={this.state.selectedRows && this.state.selectedRows.length > 0 ? this.state.selectedRows.length : 0}
        rowData={this.state.formType === "edit" ? this.state.taskrowData : "empty"}
        type={this.state.formType}
        columnValue={"Not Started"}
        getKanbanCards={this.getDataFromServer}
      />
    )
  }


  //Delete Modal
  getDeleteRowModal() {
    return (
      <DeleteRowModal
        openDeleteModal={this.state.openDeleteModal}
        closeDeleteModal={this.closeDeleteModal}
        selectActions={this.state.selectActions}
        deleteSelectedRow={this.deleteSelectedRow}
        confirmModalText={this.state.confirmModalText}
        type={this.props.type}
      />
    )
  }

  getSummaryStatsModal() {
    return (
      <SummaryStatsModal
        openSummaryStatsModal={this.state.openSummaryStatsModal}
        deal={this.state.PropertyData.selectedSeller}
        closeSummaryStatsModal={this.closeSummaryStatsReport}
        type={this.props.type}
      />
    )
  }

  //Session Expiry Modal
  getSessionExpiryModal() {
    return (
      <SessionExpiryModal
        SOpen={this.state.sessionExpiryModal}
      />
    )
  }

  getContactNoticeModal() {
    return (
      <ContactNoticeModal
        iscontactNoticeModal={this.state.iscontactNoticeModal}
      />
    )
  }

  getEditorComponent() {
    return (
      <EditorComponent
        onRef={(ref) => this.editorComponentRef = ref}
        activeTab={this.state.activeTab}
        checkDataisEdited={this.checkDataisEdited}
        commentEditedOrNo={this.commentEditedOrNo}
        OpenConfirmaionModelorSave={this.OpenConfirmaionModelorSave}
        selectedViewType={this.selectedViewType}
        optFields={this.state.optFields}
        // selectedViewType={this.state.selectedViewType}
        filterCriteria={this.state.filterCriteria}
        isDisabledTab={this.state.isDisabledTab}
        openShowHideColumnsModal={this.openShowHideColumnsModal}
        getDataFromServer={this.getDataFromServer}
        getErrorMessage={this.getErrorMessage}
        isLoading={this.getisLoading}
        selectedOpra={this.state.selectedOpra}
        fileForOptions={this.state.fileForOptions}
        selectedValidatedStatus={this.state.selectedValidatedStatus}
        settingsData={this.props.settingsData}
        type={this.props.type}
        apiUrl={this.state.apiUrlforExport}
        // selectednote={this.state.selectednote}
        PropertyData={this.state.PropertyData}
        screenPermissions={this.state.screenPermissions}
        totalRecordsLength={this.state.totalRecordsLength}
        selectedRows={this.state.selectedRows}
        getshowSelectederrormsg={this.getshowSelectederrormsg}
        allUsersData={this.state.allUsersData}
        removeSelectedRows={this.removeSelectedRows}
      />

    )
  }

  /**
     * To refresh the property loader data in missing money with refresh icon
     * @param {*} type 
     */
  refreshPropertyLoaderData = async () => {
    this.setDataCriteriaforOpportunity({
      criteria: [], limit: this.props.Limit ? this.props.Limit : localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : 15,
      page: 1,
    }, {})
  }

  getRulesModal() {
    return (
      <KanbanModal
        openUserPasswordResetModal={this.state.iskanbanModal}
        title={this.props.type === "Seller Negative Notice Viewer" ? "Seller Negative Notice" : this.props.type === "Research Request Viewer" ? "Research Request" : this.props.type === "RFI View" ? "RFI - Request for Information" : this.state.createTask === "Site Confirmation" ? "Site Confirmation" : null}
        cancelReset={this.closeFormModal}
        filterData={this.state.filterCriteria}
        cancelKanbanModal={this.cancelKanbanModal}
        rowData={this.state.formType === "edit" ? this.state.taskrowData : "empty"}
        taskstatePropertyId={this.state.taskstatePropertyId}
        previousTaskId={this.state.previousTaskId}
        count={this.state.taskopraPropertyId ? this.state.taskopraPropertyId.length : 0}
        taskminValue={this.state.taskminValue}
        taskstates={this.state.taskstates}
        estateDetails={this.state.PropertyData && this.state.PropertyData.selectedSeller ? this.state.PropertyData.selectedSeller : this.state.HighValueSeller ? this.state.HighValueSeller : null}
        taskopraPropertyId={this.state.taskopraPropertyId}
        screenType={"Property Editor V2"}
        DealSizing={this.props.type === "Deal Sizing Property Editor" ? true : false}
        type={this.state.formType}
        columnValue={"Not Started"}
        getKanbanCards={this.getDataFromServer}
        departmentArr={this.state.departmentArr}
        unassignedOnly={this.state.unassignedOnly}
      />
    )
  }

  render() {
    return (
      localStorage.getItem("loginCredentials") ?
        <div >
          <Loader loader={this.state.isLoading} progress={this.state.progress} className={this.props.isMatchproperties ? "progress-loader_match" : this.props.type === "Legal Entity Suffix" || this.props.type === "Legal Entity Abbreviation" || this.props.type === "State-UP Filing Category" || this.props.type === "State-UP Filing Sub Category" || this.props.type === "Available To File - Statuses" || this.props.type === "APA Type Configuration" ? "configuration" : "progress-loader"} />
          {this.props.tabType && this.state.width >= 577 ? this.getTabTypeCard() : this.state.width >= 577 && this.getTotalCard()}
          {this.state.openDeleteModal ? this.getDeleteRowModal() : null}
          {this.state.openSummaryStatsModal ? this.getSummaryStatsModal() : null}
          {this.state.iskanbanModal ? this.getRulesModal() : null}
          {this.state.createTaskModal ? this.getCreateTaskModal() : null}
          {this.state.isOpenShowHideColumnsModal ? this.getOpenShowHideColumnsModal() : null}
          {this.state.sessionExpiryModal ? <Redirect to="/log_in" /> : null}
          {this.state.sessionWarning ? this.getSessionWraningModal() : null}
          {this.state.expiredSessionModal ? this.expiredSessionModal() : null}
          {/* {this.state.forceLogout ? <Redirect to="/log_in" /> : ""} */}
          {this.state.isCompanyScreen ? <Redirect to="/company" /> : ""}
          {this.state.PropertyTrailViewerModal ? this.getPropertyTrailViewerModal() : null}
          {this.state.isnotesModal ? this.getNotesModal() : null}
          {this.state.openUnsaveModal ? this.getDeleteRowModalForNotes() : null}
          {this.state.goBackToClaims ? <Redirect to="/claims" /> : null}
          {this.state.isApplyFilter ? this.getConfirmaionModel() : null}
          {this.state.isopenConfirmaionModelforSave ? this.getConfirmaionModelforSave() : null}
        </div > :
        <div></div>
    );
  }
}

export default withTranslation('common')(PEDatatable);