import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import classNames from 'classnames';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import configImages from '../../../../config/configImages';
import Loader from '../../../App/Loader';

const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid gray"
};
// let newpassval = value => (value ? config.userPasswordRegex.test(value) ? undefined : 'Password must have at least 12 characters and contain at least 1 Uppercase and 1 special character.' : configMessage.fillField)

// config file
export default class MultiSortModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDisableGo: false,
            isLoading: false,
            beforePreview: this.props.bifurcationResponse.beforePreview,
            afterPreview: this.props.bifurcationResponse.afterPreview

        };
    }


    componentDidMount() {
        this.props.onRef(this);


    }

    componentWillUnmount = () => {
        this.props.onRef(null);
    }

    submitHandler = async () => {
        const { selectedValue } = this.props
        await this.setState({ isDisableGo: true, isLoading: true })
        this.props.cancelBifurcationPopModal(this.props.newStateClaimID ? this.props.newStateClaimID : "Opp", "false")

    }

    handleBifurcationProcess = () => {
        // https://opra2.dosystemsinc.com/taskdetails?id=6707c349897903f4f513d8b0&userId=638896bb23745f40e9bbaf74
    }




    // hari need to move to derived class or controller
    changeFieldValues = (item, column) => {
        if (column.field === "statePropertyID") {
            return <span style={{ color: this.props.selectedStids.includes(item[column.field]) ? "red" : "", backgroundColor: "lightgray" }}>{item[column.field]}</span>
        } else if (column.field === "oppClaimID" && column.isChangetextColor) {
            return <span style={{ color: item[column.field].split(".").length > 1 ? "red" : "" }}>{item[column.field]}</span>
        } else {
            if (item[column.field] && typeof item[column.field] !== "object") {
                return item[column.field];
            }
        }
    };


    render() {
        const modalClass = classNames({
            'modal-dialog--colored': this.state.colored,
            'modal-dialog--header': this.state.header,
        });
        const { isOpenBifurcationPopModal, selectedBgColor, selectedValue, tableHeader } = this.props
        const { } = this.state
        return (
            <div className="rnd_Modal multisortModal" style={{ all: "unset" }}>
                <Modal
                    isOpen={isOpenBifurcationPopModal}
                    fade={false}
                    className={`p-0 fullHeader multi_sort_Modal ${modalClass} rnd_modal`}
                    // style={style}
                    style={{ maxWidth: 1500 }}
                // size={{ width: this.state.width, height: this.state.height }}
                // position={{ x: this.state.x, y: this.state.y }}
                // onDragStop={(e, d) => {
                //     this.setState({ x: d.x, y: d.y });
                // }}
                // onResizeStop={(e, direction, ref, delta, position) => {
                //     this.setState({
                //         width: ref.style.width,
                //         height: ref.style.height,
                //         ...position
                //     });
                // }}
                // enableResizing={false}
                >
                    <ModalHeader className={" kanbanScreen"} style={{ border: "none", backgroundColor: selectedBgColor }}>
                        <div className="d-flex" style={{ textAlign: "center" }}>
                            <button className="lnr lnr-cross modal__close-btn" type="button" style={{ color: "black", right: 40 }}
                                onClick={() => this.props.cancelBifurcationPopModal()} />
                            <span />
                            <h4 className='' style={{ textAlign: "center" }}><b>{selectedValue}-Confirmation Page</b></h4>
                        </div>

                    </ModalHeader>
                    <ModalBody className={"modal__body mb-0 pt-0"} style={{ height: "100%" }}
                    >
                        <Loader loader={this.state.isLoading} />

                        <div className='d-flex align-items-center'>
                            <div className="card">
                                {/* header={`Before ${tableHeader}`} */}
                                <p><b>{`Before ${tableHeader}`} </b></p>
                                <DataTable className='mt-3' value={this.state.beforePreview} rowGroupMode="rowspan" sortField="oppClaimID" sortOrder={1} groupField="brand"
                                >
                                    <Column field="statePropertyID" header="State Property ID" body={this.changeFieldValues} bodyStyle={{ backgroundColor: "lightgray" }} />
                                    <Column field="propertyID" header="OPP Property ID" body={this.changeFieldValues} />
                                    <Column field="stateClaimID" header="State Claim ID" body={this.changeFieldValues} />
                                    <Column field="previousStateClaimID" header="Previous State Claim ID" body={this.changeFieldValues} />
                                    <Column field="oppClaimID" header="Opp Claim ID" body={this.changeFieldValues} />
                                    <Column field="previousOppClaimID" header="Previous Opp Claim ID" body={this.changeFieldValues} />
                                </DataTable>
                            </div>
                            <div className='mx-3 my-auto'>
                                <img
                                    src={selectedValue.includes("Merge") ? configImages.mergeIcon : configImages.splitIcon}
                                    style={{ width: 70, height: 70 }}
                                    className='m-2 p-2'
                                />
                            </div>

                            <div className="card">
                                <p><b>{`After ${tableHeader}`}</b> </p>
                                {/* header={`After ${tableHeader}`} */}
                                <DataTable className='mt-3' value={this.state.afterPreview} rowGroupMode="rowspan" sortField="oppClaimID" sortOrder={1} groupField="brand"
                                >
                                    <Column field="statePropertyID" header="State Property ID" body={this.changeFieldValues} bodyStyle={{ backgroundColor: "lightgray" }} />
                                    <Column field="propertyID" header="OPP Property ID" body={this.changeFieldValues} />
                                    <Column field="stateClaimID" header="State Claim ID" body={this.changeFieldValues} bodyStyle={{ backgroundColor: selectedBgColor }} />
                                    <Column field="previousStateClaimID" header="Previous State Claim ID" body={this.changeFieldValues} bodyStyle={{ backgroundColor: selectedBgColor }} />
                                    <Column field="oppClaimID" header="Opp Claim ID" isChangetextColor={true} body={this.changeFieldValues} bodyStyle={{ backgroundColor: selectedBgColor }} />
                                    <Column field="previousOppClaimID" header="Previous Opp Claim ID" body={this.changeFieldValues} bodyStyle={{ backgroundColor: selectedBgColor }} />
                                </DataTable>
                            </div>
                        </div>
                        <div className='row justify-content-center'>
                            <Button style={{ backgroundColor: "#4ea250", color: "white", marginTop: "25px", height: 30 }} onClick={() => this.submitHandler()} disabled={this.state.isDisableGo}>Go</Button>
                            <Button color="primary" outline style={{ marginTop: "25px", height: 30 }} onClick={() => this.props.cancelBifurcationPopModal()}>Close</Button>

                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}