import React from 'react';
import store from '../../../App/store';
import DataTables from '../../CommonDataTable/DataTable';
import Loader from '../../../App/Loader';
import apiCalls from '../../../../config/apiCalls';


// config file
export default class CronJobs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'CronJobs',
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }



  componentDidMount = () => {

  }

  getTableFields = () => {
    let data = [
      {
        "show": true,
        textAlign: 'left',
        // width: 80,
        field: 'cronName',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Cron Name',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        // width: 40,
        field: 'schedule',
        // "fieldType": 'number',
        "mobile": true,
        header: 'Schedule',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        // width: 40,
        field: 'description',
        // "fieldType": 'number',
        "mobile": true,
        header: 'Description',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        // width: 40,
        field: 'isDisabled',
        "fieldType": 'Boolean',
        "mobile": true,
        header: 'is Disable',
        filter: false,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: false,
      },
      {
        "show": true,
        textAlign: 'left',
        // width: 80,
        field: 'primaryContact',
        // fieldType: 'BoolText',
        "mobile": true,
        header: 'Primary Contact',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        "textAlign": "left",
        // "width": 40,
        "fieldType": 'Actions',
        "field": "",
        "header": "Actions",
        "filter": false,
        "displayInSettings": true,
        "sortable": false
      }
    ]
    return data;
  };


  getFormFields = () => {
    return ([

      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "cronName",
        "label": "Cron Name",
        "id": "name",
        "placeholder": "Cron Name",
        "required": true,
        "disable": true
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "schedule",
        "label": "Schedule",
        "id": "schedule",
        "placeholder": "Schedule",
        "required": true
      }, {
        //'show': false,
        "value": "",
        "type": "textarea",
        "name": "description",
        "label": "Description",
        "id": "description",
        "placeholder": "Description",
        "required": true
      }, {
        //'show': false,
        "value": "",
        "type": "switch",
        "name": "isDisabled",
        "label": "Is Disable",
        "id": "isDisabled",
        "placeholder": "isDisabled",
      },
      {
        //'show': false,
        "value": "",
        "type": "text",
        "name": "primaryContact",
        "label": "Primary Contact",
        "id": "Primary Contact",
        "placeholder": "Primary Contact",
        "required": true
      },
    ]);
  }


  submit = async (item) => {


  }

  render() {
    return (
      <span>
        <Loader loader={this.state.isLoading} />
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          exportRequried={false}
          printRequried={false}
          sortField={"cronName"}
          isSettings={"2"}
          addRequried={false}
          editRequired={true}
          deleteRequired={false}
          viewRequired={true}
          settingsRequired={false}
          filterRequired={true}
          dontShowTitle={true}
          gridRequried={false}
          sample={false}
          expandTableFields={this.expandTableFields}
          globalSearchFieldName='user'
          globalSearch={'Search'}
          isClearFilters={true}
          type='Cron Job'
          displayright={true}
          icon='user'
          routeTo='settings'
          displayViewOfForm='modal'
          apiResponseKey='cronJobSchedularConfigurations'
          apiUrl={apiCalls.Cronlist}
          entityType='employee'
        />
      </span>
    );
  }
}