import React from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import fetchMethodRequest from '../../../config/service';
// import Loader from '../../App/Loader';
import { color } from 'highcharts';
import "../../../scss/summaryStats.scss"

// config file
class SummaryStatsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount = async () => {
        this.getStatsFromServer()
    }

    getStatsFromServer = async () => {
        // this.setState({ isLoading: true })
        fetchMethodRequest('GET', `deals/summaryStats?dealId=${this.props.deal.DealID}`)
            .then(async (response) => {
                if (response) {
                    const result = response.reduce((acc, curr) => {
                        acc[curr._id] = curr.totalRecords;
                        return acc;
                    }, {});
                    let totalKnownValue = (result["50-100"] ? result["50-100"] : 0) + (result[">100"] ? result[">100"] : 0) + (result["<50"] ? result["<50"] : 0)
                    let totalValue = totalKnownValue + (result.unknown ? result.unknown : 0)
                    this.setState({ summaryStats: result, totalKnownValue: totalKnownValue, totalValue: totalValue, isLoading: false })
                }
            }).catch((err) => {
                return err;
            });
        // this.setState({ isLoading: false })
    }


    render() {
        const { t } = this.props;
        return (
            <div>
                {/* <Loader loader={this.state.isLoading} className={"progress-loader"} /> */}
                <Modal isOpen={this.props.openSummaryStatsModal} fade={false}
                    className={` modal-dialog--primary modal-dialog--header `}
                    style={{ maxWidth: "500px" }}
                >
                    <ModalHeader className="modal__header viewModalHeader" >
                        Summary of positively mapped properties
                        <button className="lnr lnr-cross modal__close-btn" type="button" style={{ color: "darkgray" }}
                            onClick={this.props.closeSummaryStatsModal} />
                    </ModalHeader>
                    <ModalBody>
                        <div className="scoring-table-container">
                            <table className="scoring-table">
                                <thead>
                                    <tr>
                                        <th>Known Value</th>
                                        <th>{this.state.totalKnownValue}</th>
                                    </tr>
                                </thead>
                                {this.state.summaryStats ?

                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: "right" }}>{"$50 - $100"}</td>
                                            <td>{this.state.summaryStats["50-100"] ? this.state.summaryStats["50-100"] : 0}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "right" }}>{"> $100"}</td>
                                            <td>{this.state.summaryStats[">100"] ? this.state.summaryStats[">100"] : 0}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "right" }}>{"< $50"}</td>
                                            <td>{this.state.summaryStats["<50"] ? this.state.summaryStats["<50"] : 0}</td>
                                        </tr>
                                    </tbody>
                                    : null}
                                {this.state.summaryStats ?
                                    <thead>
                                        <tr>
                                            <th>Unknown Value</th>
                                            <th>{this.state.summaryStats.unknown ? this.state.summaryStats.unknown : 0}</th>
                                        </tr>
                                    </thead>
                                    : null}
                                {this.state.summaryStats ?
                                    <tr>
                                        <td style={{ textAlign: "right" }}>{"Total"}</td>
                                        <td>{this.state.totalValue}</td>
                                    </tr>
                                    : null}
                            </table>
                        </div>
                    </ModalBody>
                </Modal>
            </div >
        );
    }
}
export default withTranslation('common')(SummaryStatsModal);
