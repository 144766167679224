import React from 'react';

import classNames from 'classnames';
import { Button, Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import configImages from '../../../../config/configImages';
import Loader from '../../../App/Loader';

const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid gray"
};
// let newpassval = value => (value ? config.userPasswordRegex.test(value) ? undefined : 'Password must have at least 12 characters and contain at least 1 Uppercase and 1 special character.' : configMessage.fillField)

// config file
export default class MultiSortModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isDisableGobtn: false,
            isShowErrorMsg: "",
            onlineClaimStatus: this.props.onlineClaimStatus,
            currentStClaimID: this.props.selectedProp ? this.props.selectedProp[0].claimID : ""
        };
    }

    componentDidMount() {
        this.props.onRef(this);


    }

    componentWillUnmount = () => {
        this.props.onRef(null);
    }
    cancelBifurcationPopModal = async (id) => {
        await this.setState({ isDisableGobtn: true, isLoading: true })
        this.props.cancelBifurcationPopModal(this.state.newStClaimID, "true")
        await this.setState({ isLoading: false })

    }

    isShowErrorMessage = (error) => {
        console.log("isShowErrorMsg",this.state.isShowErrorMsg)
        this.setState({ isShowErrorMsg: error,isDisableGobtn:false })

    }

    render() {
        const modalClass = classNames({
            'modal-dialog--colored': this.state.colored,
            'modal-dialog--header': this.state.header,
        });
        const { isOpenBifurcationPopModal, selectedBgColor, selectedValue, tableHeader } = this.props
        const { } = this.state
        return (
            <div className="rnd_Modal multisortModal" style={{ all: "unset" }}>

                <Modal
                    isOpen={isOpenBifurcationPopModal}
                    fade={false}
                    className={`p-0 fullHeader multi_sort_Modal ${modalClass} rnd_modal`}
                // style={style}
                // size={{ width: this.state.width, height: this.state.height }}
                // position={{ x: this.state.x, y: this.state.y }}
                // onDragStop={(e, d) => {
                //     this.setState({ x: d.x, y: d.y });
                // }}
                // onResizeStop={(e, direction, ref, delta, position) => {
                //     this.setState({
                //         width: ref.style.width,
                //         height: ref.style.height,
                //         ...position
                //     });
                // }}
                // enableResizing={false}
                >
                    <ModalHeader className={" kanbanScreen"} style={{ border: "none", backgroundColor: selectedBgColor }}>
                        <div className="d-flex" style={{ textAlign: "center" }}>
                            <button className="lnr lnr-cross modal__close-btn" type="button" style={{ color: "black", right: 40 }}
                                onClick={() => this.props.cancelBifurcationPopModal()} />
                            <span />
                            <h4 className='' style={{ textAlign: "center" }}><b>{selectedValue}-Confirmation Page</b></h4>
                        </div>

                    </ModalHeader>

                    <ModalBody className={"modal__body mb-0 pt-0"} style={{ height: "100%" }}
                    >
                        <Loader loader={this.state.isLoading} />
                        <p style={{color:"red"}}>{this.state.isShowErrorMsg ? this.state.isShowErrorMsg : null}</p>
                        <Row className="col-sm-12 mt-2  justify-content-center text-left">
                            <span className='w-50' style={{ display: "grid" }}>
                                <label className="mb-0">State Property ID(s) <span style={{ color: "red" }}></span>*</label>
                                <InputTextarea rows={3} value={this.props.selectedStids.join("\n")} disabled={true} onChange={(e) => { this.setState({ onlineClaimStatus: e.target.value, isSubmitted: false }) }}
                                    className='w-100' /></span>
                            {/* <p style={{ color: "red" }}>{!this.state.onlineClaimStatus && this.state.isSubmitted ? "Please fill the field" : null}</p> */}

                        </Row>
                        <Row className="col-sm-12 mt-2 d-flex justify-content-around  text-left">
                            <span className="col-sm-5 mt-2">
                                <span className="col-sm-10"><label className="mb-0">Current – State Claim ID</label>
                                    <InputText rows={6} value={this.state.currentStClaimID} onChange={(e) => { this.setState({ currentStClaimID: e.target.value, isSubmitted: false }) }} disabled={true}
                                        className='w-100' /></span>
                                {/* <p style={{ color: "red" }}>{!this.state.onlineClaimStatus && this.state.isSubmitted ? "Please fill the field" : null}</p> */}
                            </span>
                            <span style={{ marginTop: 10 }} >
                                <img
                                    src={selectedValue.includes("Merge") ? configImages.leftArrowgreenIcon : configImages.leftArrowIcon}
                                    style={{ width: 70, height: 40, marginTop: 10 }}
                                    className='m-2 p-2'
                                />
                                {/* <FontAwesomeIcon icon={faLongArrowAltRight} color={selectedBgColor}/> */}
                            </span>
                            <span className="col-sm-5 mt-2">
                                <span className="col-sm-5"><label className="mb-0">Enter – New State Claim ID <span style={{ color: "red" }}>*</span></label>
                                    <InputText rows={6} value={this.state.newStClaimID} onChange={(e) => { this.setState({ newStClaimID: e.target.value, isSubmitted: false }) }}
                                        className='w-100' /></span>
                            </span>
                        </Row>
                        <Row>
                            <div className='col-sm-7 pr-0'></div>
                            <div className='col-sm-5 pl-0 text-left'>
                                <p style={{ color: "red" }}>Note: If required, please update the corresponding Claim Status/Claim Reason/Claim Notes in the Claims Manager after completing the bifurcation transaction.</p>
                            </div>
                        </Row>
                        <div className='row justify-content-center'>
                            <Button style={{ backgroundColor: "#4ea250", color: "white", marginTop: "25px", height: 30 }} onClick={() => this.cancelBifurcationPopModal(this.state.newStClaimID, "true")} disabled={this.state.isDisableGobtn || !this.state.newStClaimID}>Go</Button>
                            <Button color="primary" outline style={{ marginTop: "25px", height: 30 }} onClick={() => this.props.cancelBifurcationPopModal()}>Close</Button>
                        </div>
                    </ModalBody>
                </Modal></div>
        );
    }
}