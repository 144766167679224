import React from 'react';
import store from '../../../App/store';
import apiCalls from '../../../../config/apiCalls'
import DataTables from '../../CommonDataTable/DataTable';
import { Button } from 'reactstrap';
import { InputText } from 'primereact/inputtext';
import fetchMethodRequest from '../../../../config/service';
import { MultiSelect } from 'primereact/multiselect';
import { faTimes, faStarOfLife } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import Loader from '../../../App/Loader';

// config file
export default class NextActionUserReportConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Property And Claim Status',
      claimStatusArray: [],
      filters: {
        state: null,
        status: null,
        reasons: null,
        nextActionUser: null,
        followUpDays: "",
        activeIndicator: true,
        dealType: null
      },
    };
    this.claimStatusArray = []
    this.claimReasonArray = []
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }

  componentDidMount = async () => {
    await this.getClaimStatusReason()
    await this.getMctSource()
    //Screen permisions value can be edit,view, no view
    // let screenPermissions = RolePermissions.screenPermissions('Employees');
  }
  closeCustomConfigForNextActionUserReport = async () => {
    await this.datatableref.closeCustomConfigForNextActionUserReport()
    await this.setState({ clickSaved: false, selectedRowsForConfig: null, })
    await this.emptyFilters()
  }
  checkValidation = async (body) => {
    console.log("boddydddd", body)
    return Object.values(body).some(value => value === null || value.length === 0);

  }
  SaveRecord = async () => {
    await this.setState({ isLoading: true, clickSaved: true })
    let body = this.state.filters
    let validated = await this.checkValidation(body)
    if (validated) {
      await this.setState({ isLoading: false })
      return
    }
    await fetchMethodRequest('POST', `${apiCalls.nextActionReportConfiguration}`, body).then(async (response) => {
      if (response && response.respCode && response.respCode === 200) {
        await this.datatableref.closeCustomConfigForNextActionUserReport()
        await this.setState({ clickSaved: false })
        await this.datatableref.getDataFromServer(this.datatableref.getFiltercriteria());
      }
      await this.setState({ isLoading: false })
    })
  }
  updateRecords = async () => {
    let body = [], filterObj = { nextActionUser: this.state.filters.nextActionUser, followUpDays: this.state.filters.followUpDays }, validated
    await this.setState({ isLoading: true, clickSaved: true })
    let filters = this.state.filters

    let selectedRows = this.state.selectedRowsForConfig
    // body._id = this.state.selectedRowsForConfig[0]._id
    if (this.state.selectedRowsForConfig.length > 1) {
      validated = await this.checkValidation(filterObj)
    } else {
      validated = await this.checkValidation(filters)

    }
    console.log("validddd", validated)
    if (validated) {
      await this.setState({ isLoading: false })
      return
    }
    for (let selectedRow of selectedRows) {
      if (this.state.selectedRowsForConfig.length > 1) {
        selectedRow = { ...selectedRow, ...filterObj }
      } else {
        selectedRow = { ...selectedRow, ...filters }
      }

      body.push(selectedRow)
    }
    console.log("setttttt", body)
    // body = { ...this.state.selectedRowsForConfig[0], ...body };

    await fetchMethodRequest('PUT', `${apiCalls.nextActionReportConfiguration}/multiUpdate`, body).then(async (response) => {
      if (response && response.respCode && response.respCode === 200) {
        await this.datatableref.closeCustomConfigForNextActionUserReport()
        await this.setState({ clickSaved: false, selectedRowsForConfig: null })
        await this.emptyFilters()
        await this.datatableref.getDataFromServer(this.datatableref.getFiltercriteria());
      }
      await this.setState({ isLoading: false })
    })
  }
  /**
      * 
      * @returns the mct source array for claims filter dropdown
      */
  getMctSource = async () => {
    let mctSourceArray = [], dataArray = [];
    let filterCriteria = {}
    filterCriteria.sortfield = "SourceInfo_Sort_Code, SourceInfo_Source"
    filterCriteria.direction = 'asc'
    await fetchMethodRequest('GET', `${apiCalls.mctSources}?filter=${JSON.stringify(filterCriteria)}&isDropdown=true`).then(async (response) => {
      if (response && response["claimSources"] && response["claimSources"].length > 0) {
        let mctSources = response["claimSources"]
        for (let mctSource of mctSources) {
          mctSourceArray.push({ label: mctSource.sourceInfo_Source, value: mctSource.sourceInfo_Source })
        }

        await this.setState({ mctSourceArray: mctSourceArray })
      }
    })
    return this.state.mctSourceArray;
  }
  /**
      * 
      * @returns the claim status reason array for claims filter dropdown
      */

  getClaimStatusReason = async () => {
    let claimStatusArray = [], claimReasonArray = [];
    let filterCriteria = {}
    filterCriteria['criteria'] = [{ key: 'activeIndicator', value: true, type: 'eq', add_Filter: true }, { key: 'claimStatusFlag', value: true, type: 'eq', add_Filter: true },
    { key: 'propertyStatusUsage', value: 'Property Editor', type: 'eq', add_Filter: true }
    ];
    filterCriteria.sortfield = "stageSequence, statusSequence, reasonSequence"
    filterCriteria.direction = "asc"
    await fetchMethodRequest('GET', `${apiCalls.propertyStatusReason}?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["propertyStatusReasons"] && response["propertyStatusReasons"].length > 0) {
        let propertyStatusReasons = response["propertyStatusReasons"]
        for (let propertyStatusReason of propertyStatusReasons) {
          if (claimStatusArray.findIndex((obj) => obj.value == propertyStatusReason.status) == -1) {
            claimStatusArray.push({ label: propertyStatusReason.status, value: propertyStatusReason.status })
          }
        }
        this.claimStatusArray = claimStatusArray
        this.claimReasonArray = claimReasonArray
        { console.log("thssss.", this.claimStatusArray) }
        this.setState({ claimStatusArray: claimStatusArray, claimReasonArray: claimReasonArray, propertyStatusReasons: propertyStatusReasons })
      }
    })
    return this.state.claimStatusArray;
  }
  //  Table Column Fields
  getTableFields = () => {
    let data = [
      //   {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 15,
      //   "fieldType": 'Actions',
      //   "field": "Actions",
      //   "header": "Actions",
      //   "filter": false,
      //   "displayInSettings": true,
      //   "sortable": false
      // },
      {
        textAlign: "center",
        width: 10,
        field: "",
        // fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        frozen: true,
        show: true,
        mobile: true,
        displayInSettings: false,
      }, {
        "show": true,
        textAlign: 'left',
        width: 40,
        field: 'status',
        fieldType: 'BoolText',
        "mobile": true,
        header: 'Claim Status',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 40,
        field: 'reasons',
        "fieldType": 'StrArray',
        "mobile": true,
        header: 'Claim Reason',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 40,
        field: 'state',
        // "fieldType": 'number',
        "mobile": true,
        header: 'Source',
        "fieldType": 'StrArray',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 40,
        field: 'dealType',
        // "fieldType": 'number',
        "mobile": true,
        header: 'Deal Type',
        "fieldType": 'StrArray',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },
      {
        "show": true,
        textAlign: 'left',
        width: 40,
        field: 'followUpDays',
        // "fieldType": 'number',
        "mobile": true,
        header: 'Next Action Date (Configured Days)',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      }, {
        "show": true,
        textAlign: 'left',
        width: 40,
        field: 'nextActionUser',
        // "fieldType": 'number',
        "mobile": true,
        header: 'Next Action User - Role (Default)',
        filter: true,
        "displayInSettings": true,
        "displayInFilter": true,
        sortable: true,
      },]

    return data;
  };

  onFilterChanges = async (key, value) => {

    let enteredValue = value
    this.setState(prevState => ({
      filters: { ...prevState.filters, [key]: enteredValue }
    }));
    if (key === 'status') {
      await this.onFilterChanges("reasons", "")
      if (value && value.length > 0) {
        value = [value]
        let selClaimStatus = {};
        for (let i = 0; i < value.length; i++) {
          selClaimStatus[value[i]] = value[i];
        }
        const claimReason = this.state.propertyStatusReasons.filter((obj) => selClaimStatus[obj.status]).map((res) => ({ label: res.reason, value: res.reason }));
        this.setState({ claimReasonArray: claimReason });
      } else {
        this.setState({ reasons: "", claimReasonArray: [] });
      }
    }
  }
  setRowsEmpty = async () => {
    await this.setState({ selectedRowsForConfig: null })

  }
  setValuesForEdit = async (selectedRows) => {
    await this.setState({ selectedRowsForConfig: selectedRows })
    if (selectedRows.length === 1) {
      let sel = selectedRows[0]
      // 
      let obj = { status: sel.status, reasons: sel.reasons, dealType: sel.dealType, followUpDays: sel.followUpDays, nextActionUser: sel.nextActionUser, state: sel.state, activeIndicator: sel.activeIndicator }
      await this.setState({ filters: obj })
      let value = selectedRows[0].status
      if (value) {
        value = [value]
        let selClaimStatus = {};
        for (let i = 0; i < value.length; i++) {
          selClaimStatus[value[i]] = value[i];
        }
        const claimReason = this.state.propertyStatusReasons.filter((obj) => selClaimStatus[obj.status]).map((res) => ({ label: res.reason, value: res.reason }));
        this.setState({ claimReasonArray: claimReason });
      } else {
        this.setState({ reasons: "", claimReasonArray: [] });
      }
    } else {
      await this.setState({
        filters: {
          state: null,
          status: null,
          reasons: null,
          nextActionUser: null,
          followUpDays: null,
          followUpDays: "",
          activeIndicator: true,
          dealType: null
        }
      })
    }
  }
  emptyFilters = async () => {
    await this.setState({
      filters: {
        state: null,
        status: null,
        reasons: null,
        nextActionUser: null,
        followUpDays: null,
        followUpDays: "",
        activeIndicator: true,
        dealType: null
      }
    })
  }
  getNextActionConfig = () => {
    const { filters: { reasons, status, state, activeIndicator, followUpDays, dealType, nextActionUser } } = this.state;
    return <>
      <div style={{ fontWeight: "600" }}>{this.state.selectedRowsForConfig ? "Edit -  Projected Next Action Date and Next Action User" : "Add -  Projected Next Action Date and Next Action User"}</div>
      <div className='col-sm-12 d-flex col-12 pl-0 pr-0 keylist mb-3 mt-1' style={{ borderColor: "black" }}>
        {this.state.selectedRowsForConfig && this.state.selectedRowsForConfig.length > 1 ? <div className='row col-lg-8'>

          <div className='col-sm-6 d-flex'>
            <div className='col-sm-3 mt-2'>
              {'Next Action Date'}
              <span >
                <FontAwesomeIcon icon={faStarOfLife}
                  className='pl-1'
                  color='red'
                  data-toggle="tool-tip"
                  title="Mandotary Field"
                  style={{ width: 11 }}
                /></span>
              <div className='configureColor'>
                {"(Configured Days)"}
              </div>
            </div>
            <div className='col-sm-9'>
              <InputText
                type="search"
                name='followUpDays'
                id='followUpDays'
                keyfilter={"pint"}
                style={{ width: "100%" }}
                value={followUpDays}
                onChange={(e) => this.onFilterChanges("followUpDays", e.target.value)}
                placeholder={''}
              // size="1"
              />
              {(followUpDays == null || followUpDays == "") && this.state.clickSaved ?
                <div className="mb-1" style={{ height: '10px' }}>
                  {<span className="form__form-group-error">{"Please fill the field"}</span>}
                </div>
                : null}
            </div>
          </div>
          <div className='col-sm-6 d-flex'>
            <div className='col-sm-3'>
              {'Next Action User - Role'}
              <span >
                <FontAwesomeIcon icon={faStarOfLife}
                  className='pl-1'
                  color='red'
                  data-toggle="tool-tip"
                  title="Mandotary Field"
                  style={{ width: 11 }}
                /></span>
              <div className='configureColor'>
                {"(Default)"}
              </div>
            </div>
            <div className='col-sm-9'>
              <Dropdown className='NextActionDate'
                type={'text'}
                id="nextActionUser"
                value={nextActionUser}
                placeholder={""}
                name="nextActionUser"
                style={{
                  minWidth: "100%",
                  maxWidth: "100%",
                  height: 32
                }}
                options={[{ label: "Claim Initiator", value: "Claim Initiator" }, { label: "Integrity", value: "Integrity" }, { label: "CoreOpsManager", value: "CoreOpsManager" }]}
                onChange={(e) => this.onFilterChanges("nextActionUser", e.value)}
              />
              {(nextActionUser == null || nextActionUser == "") && this.state.clickSaved ?
                <div className="mb-1" style={{ height: '10px' }}>
                  {<span className="form__form-group-error">{"Please fill the field"}</span>}
                </div>
                : null}
            </div>
          </div>
        </div> :
          <div className='row col-lg-8'>
            <div className='col-sm-6 mb-1 d-flex'>
              <div className='col-sm-3 mt-2'>
                {'Claim Status'}
                <span >
                  <FontAwesomeIcon icon={faStarOfLife}
                    className='pl-1'
                    color='red'
                    data-toggle="tool-tip"
                    title="Mandotary Field"
                    style={{ width: 11 }}
                  /></span>
              </div>
              <div className='claimFilterForm col-sm-9'>
                {/* <MultiSelect
                name={"Claim Status Filter"}
                filter={true}
                value={status}
                style={{ width: "100%", height: 32 }}
                maxSelectedLabels={1}
                options={this.claimStatusArray}
                onChange={(e) => this.onFilterChanges('status', e.value)}
              /> */}
                <Dropdown className='NextActionDate'
                  type={'text'}
                  id="claimStatusFilter"
                  value={status}
                  placeholder={""}
                  name="claimStatusFilter"
                  style={{
                    minWidth: "100%",
                    maxWidth: "100%",
                    height: 32
                  }}
                  options={this.claimStatusArray}
                  onChange={(e) => this.onFilterChanges('status', e.value)}
                />
                {(status == null || status == "") && this.state.clickSaved ?
                  <div className="mb-1" style={{ height: '10px' }}>
                    {<span className="form__form-group-error">{"Please fill the field"}</span>}
                  </div>
                  : null}
              </div>
            </div>
            <div className='col-sm-6 mb-1 d-flex'>
              <div className='col-sm-3  mt-2'>
                {'Claim Reason'}
                <span >
                  <FontAwesomeIcon icon={faStarOfLife}
                    className='pl-1'
                    color='red'
                    data-toggle="tool-tip"
                    title="Mandotary Field"
                    style={{ width: 11 }}
                  /></span>
              </div>
              <div className=' col-sm-9'>
                <MultiSelect
                  name={"Claim Status Filter"}
                  filter={true}
                  value={reasons}
                  style={{ width: "100%", height: 32 }}
                  maxSelectedLabels={1}
                  options={this.state.claimReasonArray}
                  onChange={(e) => this.onFilterChanges('reasons', e.value)}
                />

                {(reasons == null || reasons.length === 0) && this.state.clickSaved ?
                  <div className="mb-1" style={{ height: '10px' }}>
                    {<span className="form__form-group-error">{"Please fill the field"}</span>}
                  </div>
                  : null}
              </div>
            </div>
            <div className='col-sm-6 mb-1 d-flex'>
              <div className='col-sm-3  mt-2'>
                {'Source/State'}
                <span >
                  <FontAwesomeIcon icon={faStarOfLife}
                    className='pl-1'
                    color='red'
                    data-toggle="tool-tip"
                    title="Mandotary Field"
                    style={{ width: 11 }}
                  /></span>
              </div>
              <div className='col-sm-9'>
                <MultiSelect
                  name={"Claim Reason Filter"}
                  filter={true}
                  value={state}
                  style={{ width: "100%", height: 32 }}
                  maxSelectedLabels={1}
                  options={this.state.mctSourceArray}
                  onChange={(e) => this.onFilterChanges('state', e.value)}
                />
                {(state == null || state.length === 0) && this.state.clickSaved ?
                  <div className="mb-1" style={{ height: '10px' }}>
                    {<span className="form__form-group-error">{"Please fill the field"}</span>}
                  </div>
                  : null}
              </div>
            </div>
            <div className='col-lg-6 d-flex'>
              <div className='col-sm-3  mt-2'>
                {'Deal Type'}
                <span >
                  <FontAwesomeIcon icon={faStarOfLife}
                    className='pl-1'
                    color='red'
                    data-toggle="tool-tip"
                    title="Mandotary Field"
                    style={{ width: 11 }}
                  /></span>
              </div>
              <div className='col-sm-9'>
                <MultiSelect
                  name={"Deal Type Filter"}
                  filter={true}
                  value={dealType}
                  style={{ width: "100%", height: 32 }}
                  maxSelectedLabels={1}
                  options={[{ label: "BK", value: "BK" }, { label: "CR", value: "CR" },]}
                  onChange={(e) => this.onFilterChanges('dealType', e.value)}
                />
                {(dealType == null || dealType.length === 0) && this.state.clickSaved ?
                  <div className="mb-1" style={{ height: '10px' }}>
                    {<span className="form__form-group-error">{"Please fill the field"}</span>}
                  </div>
                  : null}
              </div>
            </div>
            <div className='col-sm-6 d-flex'>
              <div className='col-sm-3 mt-2'>
                {'Next Action Date'}
                <span >
                  <FontAwesomeIcon icon={faStarOfLife}
                    className='pl-1'
                    color='red'
                    data-toggle="tool-tip"
                    title="Mandotary Field"
                    style={{ width: 11 }}
                  /></span>
                <div className='configureColor'>
                  {"(Configured Days)"}
                </div>
              </div>
              <div className='col-sm-9'>
                <InputText
                  type="search"
                  name='followUpDays'
                  id='followUpDays'
                  keyfilter={"pint"}
                  style={{ width: "100%" }}
                  value={followUpDays}
                  onChange={(e) => this.onFilterChanges("followUpDays", e.target.value)}
                  placeholder={''}
                // size="1"
                />
                {(followUpDays == null || followUpDays == "") && this.state.clickSaved ?
                  <div className="mb-1" style={{ height: '10px' }}>
                    {<span className="form__form-group-error">{"Please fill the field"}</span>}
                  </div>
                  : null}
              </div>
            </div>
            <div className='col-sm-6 d-flex'>
              <div className='col-sm-3'>
                {'Next Action User - Role'}
                <span >
                  <FontAwesomeIcon icon={faStarOfLife}
                    className='pl-1'
                    color='red'
                    data-toggle="tool-tip"
                    title="Mandotary Field"
                    style={{ width: 11 }}
                  /></span>
                <div className='configureColor'>
                  {"(Default)"}
                </div>
              </div>
              <div className='col-sm-9'>
                <Dropdown className='NextActionDate'
                  type={'text'}
                  id="nextActionUser"
                  value={nextActionUser}
                  placeholder={""}
                  name="nextActionUser"
                  style={{
                    minWidth: "100%",
                    maxWidth: "100%",
                    height: 32
                  }}
                  options={[{ label: "Claim Initiator", value: "Claim Initiator" }, { label: "Integrity", value: "Integrity" }, { label: "CoreOpsManager", value: "CoreOpsManager" }]}
                  onChange={(e) => this.onFilterChanges("nextActionUser", e.value)}
                />
                {(nextActionUser == null || nextActionUser == "") && this.state.clickSaved ?
                  <div className="mb-1" style={{ height: '10px' }}>
                    {<span className="form__form-group-error">{"Please fill the field"}</span>}
                  </div>
                  : null}
              </div>
            </div>
          </div>
        }

        <div className='col-sm-4 ' style={{ textAlign: "-webkit-center" }}>
          <div className='col-sm-5 d-flex ml-2 ' style={{
            justifyContent: "space-between",
          }}>
            <div className='mt-1'>
              {'Active/Inactive Status'}
            </div>
            <InputSwitch
              checked={activeIndicator}
              onChange={(e) => this.onFilterChanges("activeIndicator", e.value)}
            />
          </div>
          {this.state.selectedRowsForConfig ? <Button style={{ fontSize: 18, width: "37%", "background-color": "green" }}
            onClick={() => this.updateRecords()}
            className={` text-white p-2 p-button-rounded claim_detail_sub_navbar_buttons mt-3 ml-3 d-block`}
          >{"Update"}</Button> : <Button style={{ fontSize: 18, width: "37%", "background-color": "green" }}
            onClick={() => this.SaveRecord()}
            className={` text-white p-2 p-button-rounded claim_detail_sub_navbar_buttons mt-3 ml-3 d-block`}
          >{"Save"}</Button>}

          <Button style={{ fontSize: 18, width: "37%" }}
            // disabled={this.state.isDisableSavebtn}
            onClick={() => this.closeCustomConfigForNextActionUserReport()}
            className={`bg-primary text-white p-2 p-button-rounded claim_detail_sub_navbar_buttons mt-1 ml-3`}
          >{"Cancel"}</Button>

        </div>
      </div >


    </>
  }
  render() {
    return (
      <span>
        <Loader loader={this.state.isLoading} />
        <DataTables
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          // additionalFlag={"&hideInOpraUIFlagRecords=true"}
          formFields={this.getFormFields}
          exportRequried={false}
          printRequried={false}
          sortField={"createdAtUtc"}
          addRequried={true}
          editRequired={true}
          isClearFilters={true}
          deleteRequired={false}
          viewRequired={true}
          excelDownload={true}
          settingsRequired={false}
          filterRequired={true}
          gridRequried={false}
          setRowsEmpty={this.setRowsEmpty}
          setValuesForEdit={this.setValuesForEdit}
          nextActionReport={true}
          sample={false}
          customForm={true}
          getNextActionConfig={this.getNextActionConfig}
          expandTableFields={this.expandTableFields}
          globalSearchFieldName='user'
          globalSearch={'Search'}
          topDisplayTitle={"Next Action User Report Configuration"}
          type='NextActionUserReportConfig'
          displayright={true}
          icon='user'
          routeTo='statusReason'
          displayViewOfForm='modal'
          apiResponseKey="nextActionReportConfigurations"
          apiUrl={apiCalls.nextActionReportConfiguration}
          entityType='employee'
        />
      </span>
    );
  }
}