import ReactDOM from 'react-dom';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import apiCalls from '../../../config/apiCalls'
import config from '../../../config/config';
import DealActions from './PresetBtnActions'
import dateFormats from '../../UI/FormatDate/formatDate';
import { Calendar } from 'primereact/calendar';
import DeleteRowModal from './DeleteRowModal';
import Loader from '../../App/Loader';
import { faTimes, faQuestionCircle, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import DealShowHideColumnsModal from './DealShowHideColumnsModal';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import configMessages from '../../../config/configMessages';
import fetchMethodRequest from '../../../config/service';
import { Button } from 'reactstrap';
import PaginatorComponent from '../../Cruds/CommonDataTable/PaginatorComponent';
import ConfirmationModal from './ConfirmationModal';
import NotesModal from './NotesModal'
import ClaimManagementNew from '../ClaimManagementNew';
import ConfirmationModalforFilter from './isApplyFilter';
import ExcelReact from '../CommonDataTable/ExcelReact'
import AddressFields from '../DealMgmt/DealMgmtFields/Address';
import NamesFields from '../DealMgmt/DealMgmtFields/Names';
import ExpiredSessionModal from '../CommonModals/ExpiredSessionModal';
import Reload from '../../reload'

export default class EditDataTable extends Component {

  constructor(props) {
    super(props);
    let loginCredentials = localStorage.loginCredentials ? JSON.parse(localStorage.loginCredentials) : false;
    this.state = {
      dataChangeOptionswithFilter: [
        { "label": "Unsort", "value": 'unsort' },
        { "label": "Sort by ASC", "value": 'asc' },
        { "label": "Sort by DSC", "value": 'desc' },
        { "label": "Filter", "value": 'filter' },
        { "label": "Clear Filter", "value": 'clear_filter' }
      ],
      dataChangeOptionswithoutFilter: [
        { "label": "Unsort", "value": 'unsort' },
        { "label": "Sort by ASC", "value": 'asc' },
        { "label": "Sort by DSC", "value": 'desc' }
      ],

      // tablefieldsToShow: [],
      allTableValue: [],
      history: [],
      undoArray: [],
      Preferences: [],
      selectedRows: [],
      calenderDate: "",
      deleArray: [],
      disableRedoButton: true,
      calenderDate: "",
      disableUndoButton: true,
      editedFieldoptions: null,
      first: 0,
      rows: loginCredentials.pageLimit ? loginCredentials.pageLimit :
        localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption"))
          : 10,
      activeTab: this.props.activeTab,
      filterCriteria: this.props.filterCriteria,
      tablefieldsToShow: this.props.getTableFields ? this.props.getTableFields() : [],
      selectedAll: false,
      showClaimUpdateButton: false
    };
    this.inputRef = React.createRef();
    this.inputValueRef = React.createRef();
    this.editRef = React.createRef();
    this.calenderRef = React.createRef();
    this.checkedItems = {};
    this.tBody = null;
    this.allClaimsData = [];
    this.lastScrollPosition = 0;
    this.scrollValue = (window.innerHeight / 4);
    this.numOfRowsToShow = 20;
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(null);
    }

  }

  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }

    // Calculating number of rows to load based on screen size
    if (this.props.type === "ClaimsManagement") {
      this.setDynamicScrollValues();
      this.setState({ rows: config.claimManagerDefaultRows })
    } else if (this.props.type === "ClaimsManagementPropertyDetails") {
      this.setDynamicScrollValues();
      this.setState({ rows: localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : 10, })
    }
    Reload.reload()//for testing new Changes are present
    const noContext = document.getElementsByClassName('datatable-editing-demo');
    let i
    if (noContext && noContext.length > 1) {
      i = 1
    } else {
      i = 0
    }
    noContext[i].addEventListener("contextmenu", (e) => {
      e.preventDefault();
      this.copyContentonRightClick(e)
    });



    let self = this
    var isCtrl = false, isAlt = false;
    if (!this.props.noShowHideColOpt) {
      this.setState((prevState) => ({
        dataChangeOptionswithFilter: [...prevState.dataChangeOptionswithFilter, { "label": "Hide Column", "value": 'hide' },
        { "label": "Show Column", "value": 'show' },],
        dataChangeOptionswithoutFilter: [...prevState.dataChangeOptionswithoutFilter, { "label": "Hide Column", "value": 'hide' },
        { "label": "Show Column", "value": 'show' },]
      }))
    }
    // window.addEventListener('load', function () {
    // Short cut keys functionality for keyboard

    window.addEventListener('keydown', async function (e) {
      if (self.props && self.props.type && self.props.type !== "ClaimsManagement") {
        if (e.keyCode == 17) {
          isCtrl = true;
          e.preventDefault();
        }
        else if (e.keyCode == 18) {
          isAlt = true;
          e.preventDefault();
        }
        if (e.keyCode == 83 && isCtrl) {
          // e.preventDefault();
          isCtrl = false
          self.editorValidator()
          e.preventDefault();
        }
        else if (e.keyCode == 90 && isCtrl) {
          let disable = self && self.actions_presets ? await self.actions_presets.sendUndoButtonStatus() : true
          if (!disable) {
            // isCtrl = false
            self.undo()
            e.preventDefault();
          }
        }
        else if (e.keyCode == 89 && isCtrl) {
          let disable = self && self.actions_presets ? await self.actions_presets.sendRedoButtonStatus() : true
          if (!disable) {
            // isCtrl = false
            self.redo()
            e.preventDefault();
          }
        }
        else if ((e.keyCode == 97 || e.keyCode == 65) && isAlt) {
          isAlt = false
          self.addRow()
          e.preventDefault();

        }
        else if ((e.keyCode == 82) && isAlt) {
          isAlt = false
          self.refreshGrid()
          e.preventDefault();
        }

        else if ((e.keyCode == 68) && isAlt) {
          isAlt = false
          self.duplicateSelectRows()
          e.preventDefault();

        }
        else if ((e.keyCode == 97 || e.keyCode == 65) && isCtrl && !self.state.isInFocus) {
          isCtrl = false
          self.selectAllRecords()
          e.preventDefault();

        }

        else if (isCtrl && e.keyCode !== 17) {
          isCtrl = false
        }
        else if (isAlt && e.keyCode !== 18) {
          isAlt = false
        }
      }

    });
    // });

    window.addEventListener('resize', () => {
      this.setDynamicScrollValues(true);
    });

  }

  cancelNotes = async (type, id) => {
    this.setState({
      isnotesModal: false,
      openpaymentsModal: false,
      selectedRowPayments: ""
    })

  }
  /**
   * Copy the content on right click
   */
  copyContentonRightClick = async (event) => {
    if (event && event.target) {
      event.preventDefault();
      if (event.target.closest('td') && event.target.innerHTML.length !== 0 && !event.target.innerHTML.includes("p-checkbox-icon p-clickable")) {
        this.clipToCopyBoard(event.target.innerHTML)
        this.showErrorMessage('Copied', "", "success")
        setTimeout(() => this.showErrorMessage(''), 2000)
      }
    }
  };

  //Copy Function
  clipToCopyBoard(data) {
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(data)

    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isshowToasterMsg) {
      if (toasterMessage) {
        clearTimeout(toasterMessage);
      }
      var toasterMessage = setTimeout(() => this.setState({ isshowToasterMsg: false, showToasterMsg: '' }), 5000);

    }
    if (this.props.type === "ClaimsManagement" && this.props.updateLimit && prevState.rows !== this.state.rows) {
      this.props.updateLimit(this.state.rows);
    }
    // if (localStorage.getItem('loggedTime')) {
    //   let loggedTime = localStorage.getItem('loggedTime')
    //   if (timeout) {
    //     clearTimeout(timeout)
    //   }
    //   timeout = setTimeout(() => this.setState({ expiredSessionModal: true, openedMultiTabs: true }), loggedTime);
    // }
    if (!this.tBody && this.props.type === "ClaimsManagement") {
      const elements = document.getElementsByClassName('p-datatable-scrollable-body');
      if (elements && elements.length > 0) {
        this.tBody = elements[0];
        this.tBody.addEventListener('scroll', () => {
          if (this.tBody.scrollTop > (this.lastScrollPosition + this.scrollValue) && (this.allClaimsData.length > this.state.allTableValue.length)) {
            this.lastScrollPosition = this.tBody.scrollTop;
            let tableData = JSON.parse(JSON.stringify(this.state.allTableValue));
            let count = 0;
            do {
              if (this.allClaimsData[tableData.length]) {
                tableData.push(this.allClaimsData[tableData.length]);
              }
              count++;
            } while (count < this.numOfRowsToShow);
            this.setState({ allTableValue: tableData });
            if (this.state.selectedAll) {
              this.selectOrUnSelectAllCheckBox(true);
            }
          }

        })
      }
    }
  }

  /**
   * setting Number of rows to load and append, scroll height to append data based on screen height.
   * @param {Boolean} [fromResize] 
   */
  setDynamicScrollValues = (fromResize) => {
    this.scrollValue = (window.innerHeight / 4); // calculating scroll amount to append data
    const fitRows = Math.ceil((window.innerHeight - 380) / 34); // calculating how many rows will fit   
    const scrollRows = Math.ceil(this.scrollValue / 34);  // calculating additional rows required for allowing scroll functionality
    this.numOfRowsToShow = fitRows + scrollRows;
    // Checking whether appending additional rows required on zoom out/in event.  
    if (fromResize && this.numOfRowsToShow > this.state.allTableValue.length) {
      let tableData = JSON.parse(JSON.stringify(this.state.allTableValue));
      let addRowsCount = this.numOfRowsToShow - this.state.allTableValue.length;
      for (let i = addRowsCount; i > 0; i--) {
        if (this.allClaimsData[tableData.length]) {
          tableData.push(this.allClaimsData[tableData.length]);
        }
      }
      this.setState({ allTableValue: tableData });
      if (this.state.selectedAll) {
        this.selectOrUnSelectAllCheckBox(true);
      }

    }
  }

  cancelexpiredSessionModal = async () => {
    await this.setState({
      expiredSessionModal: false
    })
  }
  expiredSessionModal() {
    return (
      <ExpiredSessionModal
        openConfirmationModal={this.state.expiredSessionModal}
        cancelSessionWarningModal={this.cancelexpiredSessionModal}
        getData={this.getDataFromServer}

      />
    )
  }

  /**
   * getTableFields for columns after getting the data for dropdown options
   * @param {Array} fields 
   */
  getTableFieldsforTableColumns = async (fields) => {

    await this.setState({
      tablefieldsToShow: JSON.parse(JSON.stringify(fields))
    })
  }

  /**
   * if the record is edited for that disabling the selection option
   * @param {Array} selectedRows 
   * @param {Array} updatedRecords 
   */
  disableInlineEdit = (selectedRows, updatedRecords) => {
    let editIcon = updatedRecords ? updatedRecords : this.state.updatedRecords
    if (editIcon && editIcon.length > 0 && selectedRows.length > 0) {
      for (var i = 0; i < editIcon.length; i++) {
        if (document.getElementsByClassName("p-selection-column")[i]) {
          document.getElementsByClassName("p-selection-column")[i].style.pointerEvents = "none"
        }
      }
    } else if (editIcon && editIcon.length > 0 && selectedRows.length === 0) {
      for (var i = 0; i < editIcon.length; i++) {
        if (document.getElementsByClassName("p-selection-column")[i]) {
          document.getElementsByClassName("p-selection-column")[i].style.pointerEvents = "visible"
        }
      }
    }
  }
  getMctSource = async () => {
    let PropertyIdArray = []
    await this.setState({ isLoading: true })
    let body = {}
    body.OPPClaimID = this.props.Rowdetails.oppClaimID
    body.ConnectedProperties = []
    for (var obj of this.state.selectedRows) {
      body.ConnectedProperties.push({ propertyID: obj.propertyID })
    }
    body.hasH__Claims = body.ConnectedProperties.length
    await fetchMethodRequest('POST', `${apiCalls.RemoveFromConnectedClaim}`, body).then(async (response) => {
      if (response && response.respCode && response.respCode === 200) {
        let details = await this.props.getClaimRecord(this.props.Rowdetails.oppClaimID)

        for (let detail of details) {
          PropertyIdArray.push(detail.propertyID)
        }
        // await this.setState({ PropertyIdArray: PropertyIdArray })
        if (PropertyIdArray && PropertyIdArray.length > 0) {
          await this.props.setPropertyIdAfterDelete(PropertyIdArray)
        }
        await this.props.toggle("", {
          criteria: [],
          sortfield: "statePropertyId",
          direction: "asc",
          limit: this.props.type === "ClaimsManagementPropertyDetails" && localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : this.props.type === "ClaimsManagementPropertyDetails" ? 10 : config.claimManagerDefaultRows
        })        // } else {
        //   await this.setState({ properties: [] })
        // }
        await this.props.showMessage(response.respMessage)

      }
      await this.setState({ isLoading: false })
    })
  }
  clearCriteria = async () => {
    await this.setState({
      filterCriteria: JSON.parse(JSON.stringify({
        limit: localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : 10,
        page: 1,
        criteria: [],
      }))
    })
  }
  UpdateFollowUpDetails = async () => {
    let empty = {}, checkEmptyFlag = false
    await this.setState({ isLoading: true })
    let properties = this.state.allTableValue
    let body = {}
    empty.ids = []
    for (var obj of properties) {
      if (obj.isedited) {
        body = { sourceState: obj.sourceState, oppClaimID: obj.oppClaimID, _id: obj.followUpId, followUpNumber: obj.followUpNumber, followUpStatus: obj.followUpStatus, FollowUpNotes: obj.followUpNotes, followUpAnalyst: obj.followUpAnalyst }
        empty.ids.push({ sourceState: obj.sourceState, oppClaimID: obj.oppClaimID, _id: obj.followUpId, followUpNumber: obj.followUpNumber, followUpStatus: obj.followUpStatus, FollowUpNotes: obj.followUpNotes, followUpAnalyst: obj.followUpAnalyst })
        if (obj.followUpStatus === "Completed" && (obj.followUpNotes === "" || obj.followUpNotes === null)) {
          checkEmptyFlag = true
        }
      }
    }
    if (empty && empty.ids && empty.ids.length === 0) {
      await this.props.showMessage({ respMessage: "Please click on update after changing any values." })
      await this.setState({ isLoading: false })
      return
    }
    if (checkEmptyFlag) {
      await this.props.showMessage({ respMessage: "Notes should be Mandatory if FollowUp Status is Completed" })
      await this.setState({ isLoading: false })
      return
    }
    await fetchMethodRequest('PUT', `${apiCalls.followUps}/${body._id}`, body).then(async (response) => {
      if (response && response.respCode && response.respCode === 200) {
        await this.props.showSuccessMessage({ respMessage: response.respMessage })
        await this.props.toggle("", {
          criteria: [],
          sortfield: "followUpNumber,followUpUpdateDate",
          direction: "desc",
          limit: 20
        })
      }
      await this.setState({ isLoading: false })
    })
  }
  UpdateMinMaxValues = async () => {
    let PropertyIdArray = [], flag = false, checkEmptyFlag = false
    await this.setState({ isLoading: true, maxCondition: false, maxMinCondition: "" })
    await this.props.setHitUpdateButtonFromPropertyDetails(true)
    let properties = this.state.allTableValue
    let body = {}
    body.claim_id = this.props.Rowdetails._id
    body.ids = []
    for (var obj of properties) {
      if (obj.isedited) {
        if (obj.propertyMinValueAmount === "" || obj.propertyMaxValueAmount === "") {
          checkEmptyFlag = true
        }
        else if (parseFloat(obj.propertyMinValueAmount) > parseFloat(obj.propertyMaxValueAmount)) {
          flag = true
        }
        body.ids.push({ _id: obj._id, propertyMinValueAmount: obj.propertyMinValueAmount, propertyMaxValueAmount: obj.propertyMaxValueAmount })
      }

    }
    if (flag) {
      await this.setState({ maxCondition: true, maxMinCondition: "Min Value of some properties is greater than Max value. Please check" })
      // await this.props.showMessage({ respMessage: "Min Value of some properties is greater than Max value. Please check" }, "errorView")
      await this.setState({ isLoading: false })
      return
    }
    //  else if (checkEmptyFlag) {
    //   await this.props.showMessage({ respMessage: "Min and Max values cannot be empty" })
    //   await this.setState({ isLoading: false })
    //   return
    // }
    if (body && body.ids && body.ids.length === 0) {
      await this.setState({ maxCondition: true, maxMinCondition: "Please click on update after changing min and max values of any record." })
      // await this.props.showMessage({ respMessage: "Please click on update after changing min and max values of any record." }, "errorView")
      await this.setState({ isLoading: false })
      return
    }
    await fetchMethodRequest('POST', `${apiCalls.UpdatePropertyMinMaxValue}`, body).then(async (response) => {
      if (response && response.respCode && response.respCode === 200) {
        await this.props.getClaimRecord(this.props.Rowdetails.oppClaimID)

        // for (let detail of this.state.dynamicConnectedProperties) {
        //   PropertyIdArray.push(detail.propertyID)
        // }
        // await this.setState({ PropertyIdArray: PropertyIdArray })
        // if (PropertyIdArray && PropertyIdArray.length > 0) {
        await this.props.toggle("", {
          criteria: [],
          sortfield: "statePropertyId",
          direction: "asc",
          limit: this.props.type === "ClaimsManagementPropertyDetails" && localStorage.getItem("defaultPageOption") ? parseInt(localStorage.getItem("defaultPageOption")) : this.props.type === "ClaimsManagementPropertyDetails" ? 10 : config.claimManagerDefaultRows
        })
        // }
        await this.props.showMessage({ respMessage: response.respMessage })
        // await this.props.getToggle("close")
        // await this.props.getDataFromServer(this.props.Object1, this.props.filter, "Preset")

      }
      await this.setState({ isLoading: false })
    })
  }
  checkMaxAndMinValues = async (props, updatedTableValue) => {
    if (updatedTableValue[props.rowIndex]["propertyMinValueAmount"] && typeof (updatedTableValue[props.rowIndex]["propertyMinValueAmount"]) === "string") {
      updatedTableValue[props.rowIndex]["propertyMinValueAmount"] = updatedTableValue[props.rowIndex]["propertyMinValueAmount"].replace(/[^a-zA-Z0-9.]/g, '')
    }
    if (updatedTableValue[props.rowIndex]["propertyMaxValueAmount"] && typeof (updatedTableValue[props.rowIndex]["propertyMaxValueAmount"]) === "string") {
      updatedTableValue[props.rowIndex]["propertyMaxValueAmount"] = updatedTableValue[props.rowIndex]["propertyMaxValueAmount"].replace(/[^a-zA-Z0-9.]/g, '')
    }
    if (updatedTableValue[props.rowIndex]["propertyMinValueAmount"] && (updatedTableValue[props.rowIndex]["propertyMaxValueAmount"] === "" || updatedTableValue[props.rowIndex]["propertyMaxValueAmount"] === null)) {
      await this.setState({ maxCondition: true, maxMinCondition: "max Value should not be empty if min value exists. Please check" })
      if (document.getElementById(`propertyMaxValueAmount-${props.rowIndex}`)) {
        document.getElementById(`propertyMaxValueAmount-${props.rowIndex}`).style.backgroundColor = "#f39292"
        document.getElementById(`propertyMaxValueAmount-${props.rowIndex}`).parentElement.parentElement.parentElement.style.backgroundColor = "#f39292"
      }
    }
    else if (updatedTableValue[props.rowIndex]["propertyMinValueAmount"] && updatedTableValue[props.rowIndex]["propertyMaxValueAmount"] && parseFloat(updatedTableValue[props.rowIndex]["propertyMinValueAmount"]) > parseFloat(updatedTableValue[props.rowIndex]["propertyMaxValueAmount"])) {
      await this.setState({ maxCondition: true, maxMinCondition: "Min Value of some properties is greater than Max value. Please check" })
      if (document.getElementById(`propertyMaxValueAmount-${props.rowIndex}`)) {
        document.getElementById(`propertyMaxValueAmount-${props.rowIndex}`).style.backgroundColor = "#f39292"
        document.getElementById(`propertyMaxValueAmount-${props.rowIndex}`).parentElement.parentElement.parentElement.style.backgroundColor = "#f39292"

      } if (document.getElementById(`propertyMinValueAmount-${props.rowIndex}`)) {
        document.getElementById(`propertyMinValueAmount-${props.rowIndex}`).style.backgroundColor = "#f39292"
        document.getElementById(`propertyMinValueAmount-${props.rowIndex}`).parentElement.parentElement.parentElement.style.backgroundColor = "#f39292"
      }
    } else {
      await this.setState({ maxCondition: false, maxMinCondition: "" })
      if (document.getElementById(`propertyMaxValueAmount-${props.rowIndex}`) && document.getElementById(`propertyMaxValueAmount-${props.rowIndex}`).parentElement && document.getElementById(`propertyMaxValueAmount-${props.rowIndex}`).parentElement.parentElement && document.getElementById(`propertyMaxValueAmount-${props.rowIndex}`).parentElement.parentElement.parentElement) {
        document.getElementById(`propertyMaxValueAmount-${props.rowIndex}`).parentElement.parentElement.parentElement.style.backgroundColor = "#d4ecfa"
      } if (document.getElementById(`propertyMinValueAmount-${props.rowIndex}`) && document.getElementById(`propertyMinValueAmount-${props.rowIndex}`).parentElement && document.getElementById(`propertyMinValueAmount-${props.rowIndex}`).parentElement.parentElement && document.getElementById(`propertyMinValueAmount-${props.rowIndex}`).parentElement.parentElement.parentElement) {
        document.getElementById(`propertyMinValueAmount-${props.rowIndex}`).parentElement.parentElement.parentElement.style.backgroundColor = "#d4ecfa"
      }
      if (document.getElementById(`propertyMaxValueAmount-${props.rowIndex}`)) {
        document.getElementById(`propertyMaxValueAmount-${props.rowIndex}`).style.backgroundColor = "white"
      } if (document.getElementById(`propertyMinValueAmount-${props.rowIndex}`)) {
        document.getElementById(`propertyMinValueAmount-${props.rowIndex}`).style.backgroundColor = "white"
      }
    }
    return true
  }
  /**
   * invoked when the cell value changes 
   * @param {Object} item 
   * @param {Object} props 
   * @param {String} value 
   * @param {String} type 
   */
  onEditorValueChange = async (item, props, value, type, options) => {
    let updatedTableValue = [...props.value];
    // updatedTableValue[value.rowIndex][props.field] = item ? item : ""
    var updatedRecords = updatedTableValue.filter(object => {
      return object.isedited === "yes";
    });

    if (document.getElementById(`${props.field}-${props.rowIndex}`) && document.getElementById(`${props.field}-${props.rowIndex}`).parentElement && document.getElementById(`${props.field}-${props.rowIndex}`).parentElement.parentElement.style.backgroundColor) {
      document.getElementById(`${props.field}-${props.rowIndex}`).parentElement.parentElement.style.removeProperty("background-color");
    }
    //for text field
    if (document.getElementById(`${props.field}-${props.rowIndex}`) && document.getElementById(`${props.field}-${props.rowIndex}`).parentElement && document.getElementById(`${props.field}-${props.rowIndex}`).parentElement.parentElement.parentElement.style.backgroundColor) {
      document.getElementById(`${props.field}-${props.rowIndex}`).parentElement.parentElement.parentElement.style.removeProperty("background-color");
    }


    if (item.editField === "date") {
      value = dateFormats.formatDate(
        value,
        config.dbDateFormat, "nhg")
      value = value
    }
    if (props.field === "LocationNameLabel" && this.props.type === "Address") {
      let getLegacyConnectedName = options.filter((obj) => obj.BusinessNamesID === value.BusinessNamesID)
      if (getLegacyConnectedName && getLegacyConnectedName[0]) {
        updatedTableValue[props.rowIndex]["LegacyConnectedNameLabel"] = getLegacyConnectedName[0].legacyConnectedName
        updatedTableValue[props.rowIndex]["LegacyConnectedName"] = getLegacyConnectedName[0].BusinessName
        updatedTableValue[props.rowIndex]["LegacyConnectedNameID"] = getLegacyConnectedName[0].BusinessNamesID
        updatedTableValue[props.rowIndex]["LocationName"] = getLegacyConnectedName[0].BusinessName
        updatedTableValue[props.rowIndex]["LocationNameID"] = getLegacyConnectedName[0].BusinessNamesID

      }

    }
    // if (props.field === "NameConnection" && this.props.type === "Names") {
    //   await this.setState({
    //     relevantSellerNames: item.options
    //   })
    // }

    if (props.field === "CompanyID") {
      let getID = item.options.find(elem => elem.value === value)
      if (getID) {
        updatedTableValue[props.rowIndex]["BusinessID"] = getID.BusinessID
      }
    }
    if (props.field === "LegacyConnectedNameLabel") {
      let getBusinessNameID = item.options.find(elem => elem.BusinessName === value.BusinessName)
      if (getBusinessNameID) {
        updatedTableValue[props.rowIndex]["LegacyConnectedName"] = value.BusinessName
        updatedTableValue[props.rowIndex]["LegacyConnectedNameID"] = value.BusinessNamesID
      }
    }

    this.disableInlineEdit(updatedTableValue, updatedRecords)
    updatedTableValue[props.rowIndex][props.field] = value;
    if (updatedTableValue[props.rowIndex].isAdded) { } else {
      updatedTableValue[props.rowIndex]["isedited"] = "yes";
    }
    if (this.props.showPreset && this.props.showPreset === "true") {
    } else {
      await this.actions_presets.updateValue(
        {
          testng: "edit",
          editedRowField: props.field,
          editedRowIndex: props.rowIndex,
          editedField: value === "Green" ? "Actual" : value === "Brown" ? "Actual + Estimated" : value === "Light Brown" ? "Estimated" : value,
          selectedFieldHeader: item.header,
          editedFieldoptions: item.options ? item.options : null,
          editType: item.editField ? item.editField : "isDisabled",
          editColumnField: item

        }
      )
      this.actions_presets.enableUndoButton()
    }
    // this.setState({ fieldEdited: props.field })
    if (item && (item.editField === "dropdown" || item.editField === "multiDropdown")) {
      await this.setState({
        [item.field]: value
      })
      if (props.field === "LocationNameLabel" && this.props.type === "Address") {
        await this.setState({ legacyConnectedNameLabel: value.legacyConnectedNameLabel })
      }
    }
    if (item && item.maxLength && item.maxLength < value.length) {
      await this.setState({
        isShowError: true,
        showFieldName: `${item.field}-${props.rowIndex}`
      })
    } else if (item && item.maxLength && item.maxLength > value.length && this.state.isShowError) {
      await this.setState({
        isShowError: true,
        showFieldName: ``
      })
    }
    if (this.props.showPreset && this.props.showPreset === "true") {
      await this.checkMaxAndMinValues(props, updatedTableValue)
    }
  }

  //check whether the records are updated 
  isUpdatedTableValue() {
    let tableData = this.state.allTableValue
    var updatedRecords = tableData.filter(object => {
      return object.isedited === "yes" || object.isAdded === true;
    });
    return updatedRecords
  }

  /**
   * get the value from the list call
   * @param {String} value 
   * @param {Number} count 
   */
  getValueforTable = async (value, count, redirected) => {
    this.allClaimsData = value;
    if (this.props.type === "ClaimsManagement") {
      this.lastScrollPosition = 0;
      if (this.tBody) {
        this.tBody.scrollTop = 0;
      }
      let elements = document.getElementsByClassName('cudtom-checkbox-cm');
      if (elements && elements.length > 0) {
        elements.forEach((elem) => {
          elem.checked = false;
        })
      }
      this.checkedItems = {};
    }
    await this.setState({
      updatingIndex: null,
      // isshowToasterMsg:false,
      // allTableValue:value,
      allTableValue: this.props.type === "ClaimsManagement" ? this.allClaimsData.slice(0, this.numOfRowsToShow) : value,
      responseaddressnames: JSON.parse(JSON.stringify(value)),
      totalRecordsLength: count,
      selectedAll: false
    })
    if (this.props.redirected) {
      await this.onCheck({
        "_id": this.props.claimNewID.trim(),
        "companyID": this.props.companyID,
        "oppClaimID": this.props.oppClaimID,
        // "connectedProperties": [
        //   {
        //     "propertyID": 100038780798,
        //     "statePropertyID": 16319238
        //   },
        //   {
        //     "propertyID": 99987788061,
        //     "statePropertyID": 16212999
        //   },
        //   {
        //     "propertyID": 99921819039,
        //     "statePropertyID": 1000973805
        //   }
        // ],
      })
    }

  }

  /**
   * text field value
   * @param {Object} productKey 
   * @param {Object} props 
   * @param {Object} item 
   * @returns 
   */
  inputTextEditor(productKey, props, item) {
    return <span style={this.state.isShowError ? { height: "50px", display: "grid" } : { height: "32px", }}><InputText type={item.inputType ? item.inputType : "text"}
      ref={(ref) => { this.inputRef = ref }}
      id={`${props.field}-${props.rowIndex}`}
      defaultValue={item && item.fieldType && item.fieldType === "MultiColors" && props.rowData[item.field] === "Green" ? "Actual" : item && item.fieldType && item.fieldType === "MultiColors" && props.rowData[item.field] === "Brown" ? "Actual + Estimated" : item && item.fieldType && item.fieldType === "MultiColors" && props.rowData[item.field] === "Light Brown" ? "Estimated" : props.rowData[item.field]}
      keyfilter={item.keyfilter ? item.keyfilter : null}
      disabled={this.props.disabled ? this.props.disabled : item.disabled ? item.disabled : false}
      onChange={(e) => this.onEditorValueChange(item, props, e.target.value)} />
      <span style={{ fontSize: "10px", color: "red" }}>
        {this.state.isShowError && this.state.showFieldName === `${props.field}-${props.rowIndex}` ? `Please Enter the maximum ${item.maxLength} length ` : ""}
      </span>
    </span>;
  }

  /**
   * get the api response for the download func
   * @param {String} url 
   * @param {String} responsekey 
   */
  getExcelFile = async (url, responsekey) => {
    fetchMethodRequest("GET", `${url}&type=exportToCsv`)
      .then(async (response) => {
        let key = responsekey
        if (response && response[responsekey] && response[responsekey].length > 0) {
          let obj = [{
            name: "Names",
            headers: Object.keys(response[key][0]),
            data: await this.formatChange(response[key])
          }]
          this.setState({
            sheets: obj
          })

        } else {
          this.setState({
            sheets: []
          })
        }
      }).catch((err) => {
        return err;
      });

  }

  /**
   * 
   * @param {Object} filterCriteria 
   */
  handlefiltersafterreorder = (filterCriteria) => {//set the inline filters
    this.setState({ filterCriteria });
    let criteria = filterCriteria.criteria;
    let dropDownFilters = criteria.filter(object => {
      return object.add_Filter === true
        && object.claimBookmarkStatus == false;
    });
    let gridFilters = criteria.filter(object => {
      return object.add_Filter !== true
      // || object.claimBookmarkStatus !== true;
    });
    gridFilters = gridFilters.concat(dropDownFilters);
    for (var obj of gridFilters) {
      let val = obj.key
      let value = this.props.isSettings ? (this.props.isSettings + val) : this.state.activeTab ? this.state.activeTab + val : val;
      const column = this.state.tablefieldsToShow.find(col => col.field === obj.key)
      const columnDom = document.getElementsByClassName(`p-column-filter-${value}`)[0];
      if (column && columnDom && columnDom.style) {
        const dateFieldTypes = ['Date', 'dateTime'];
        const isDateType = column.fieldType && dateFieldTypes.includes(column.fieldType)
        const filterType = isDateType ? column.fieldType : column.filterType ? column.filterType : 'text';
        columnDom.style.display = "block"
        columnDom.children[0].value = obj.type === "nexists" ? "in" : obj.type === "exists" ? "nin" : isDateType ? obj.type.replace("date", "") : filterType === "multiSelect" ? "multi" : obj.type;
        obj.value = typeof (obj.value) === 'string' ? decodeURIComponent(obj.value) : obj.value;
        if (isDateType && obj.value && obj.value !== "Invalid date") {
          this.setState({ [val]: new Date(obj.value) });
        } else if (filterType === "multiSelect" && Array.isArray(obj.value)) {
          this.setState({ [val]: obj.value });
        } else if (!['exists', 'nexists'].includes(obj.type)) {
          columnDom.children[1].value = obj.value;
        }
      }
    }
  }

  /**
   * 
   * @param {Array} itemdata 
   * @returns updated values
   */
  updateDealIDToData(itemdata) {
    let modifiedData = [];
    itemdata.forEach((item, index) => {

      if (this.state.first) {
        item.Sno = (index + 1) + this.state.first;
      } else {
        item.Sno = index + 1;
      }
      item.DealID = this.props.selectedDealID
      modifiedData.push(item);

    });
    return modifiedData;
  }
  setDate = async (date) => {
    let dateConverted = moment(date).utcOffset(dateFormats.isDstObserved(date) ? '-05:00' : '-06:00');
    return dateFormats.formatDate(
      dateConverted,
      "yyyy/MM/DD");
  }
  /**
     * 
     * @param {Array} itemdata 
     * @returns updated values
     */
  setRfiTasks = async (itemdata) => {
    let modifiedData = [];
    itemdata.forEach((item, index) => {
      if (item && item.rfiTasks && item.rfiTasks.length > 0) {
        item.rfiTasks.forEach(async (rfiItem, i) => {

          item[`RFI TaskId${i + 1}`] = rfiItem.taskID
          if (rfiItem.rfiDate) {
            let time = await this.setDate(rfiItem.rfiDate)
            item[`RFI Date${i + 1}`] = time;
          }
          if (rfiItem.rfiResponseDate) {
            let responseTime = await this.setDate(rfiItem.rfiResponseDate)
            item[`RFI Response Date${i + 1}`] = responseTime;
          }
        });
      }
      if (item) {
        item.claimValueComputeColor = item.claimValueComputeColor === "Green" ? "Actual" : item.claimValueComputeColor === "Brown" ? "Actual + Estimated" : item.claimValueComputeColor === "Light Brown" ? "Estimated" : "No Connected Properties"
      }
      modifiedData.push(item);
    });

    return modifiedData;
  }


  /**
   * 
   * @param {Array} itemdata 
   * @param {Array} tableFields 
   * @param {String} dateFormat 
   * @returns Date formated array 
   */
  updateDateFormat = async (itemdata, tableFields, dateFormat) => {
    let modifiedData = [];
    for (let i = 0; i < itemdata.length; i++) {
      for (let k = 0; k < tableFields.length; k++) {
        if ("dateTime" === tableFields[k]['fieldType']) {
          if (itemdata[i][tableFields[k]['field']] !== null) {
            if (this.props.removeUtc) {
              let time = moment(itemdata[i][tableFields[k]['field']])
              // .utcOffset(dateFormats.isDstObserved(itemdata[i][tableFields[k]['field']]) ? '-05:00' : '-06:00');
              itemdata[i][tableFields[k]['field']] =
                dateFormats.formatDate(
                  time,
                  dateFormat, "NS");
            } else {
              let time = moment(itemdata[i][tableFields[k]['field']]).utcOffset(dateFormats.isDstObserved(itemdata[i][tableFields[k]['field']]) ? '-05:00' : '-06:00');
              itemdata[i][tableFields[k]['field']] =
                dateFormats.formatDate(
                  time,
                  dateFormat);
            }
          }
        }
      }
      modifiedData.push(itemdata[i])
    }
    return modifiedData;
  }

  /**
   * Download Excel for names and address based on preference
   * @param {Object} namePreferences 
   * @param {Object} addressPreferences 
   * @param {Array} strArray 
   */
  getExcelFileForDownload = async (namePreferences, addressPreferences, strArray, preferences, activeTab, allClaimRows, propertyUrl, mode) => {
    await this.setState({ isLoading: true })
    let format = "yyyy/MM/DD"
    let url, obj
    let addressTabFields = this.addressFieldsref.getaddressFields()
    let namesTableFields = this.namesFieldsref.getnamesFields()
    // let claimManagementFields = this.claimManagementNewref.getTableFields()

    if (activeTab === "ClaimsManagement") {
      // url = `${apiCalls.claims}?type=exportToCsv&filter={"criteria":${JSON.stringify(this.props.exportToCSVCriteria)}}`
      url = this.props.filteredURL ? this.props.filteredURL + `${mode && mode === "Internal Use" ? "&exportMode=InternalUse" : "&exportMode=ExternalUse"}` + "&type=exportToCsv" : `${apiCalls.claims}?type=exportToCsv&filter={"criteria":[{"key":"ASSET_TRACKING_STATUS","value":["Active"],"type":"in","add_Filter":true}]}${mode && mode === "Internal Use" ? "&exportMode=InternalUse" : "&exportMode=ExternalUse"}`;
      // url = `${apiCalls.claims}?type=exportToCsv&filter={"criteria":${this.props.selectedDealID ? `[{key:"CompanyID",value:${this.props.selectedDealID},type:"eq",add_Filter:true}]` : "[]"}}`
      // url = `seller/addressnames/export?type=exportToCsv&nameFilter={"criteria":[],"sortfield":"BusinessName","direction":"asc"}&addressFilter={"criteria":[],"sortfield":"CompanyID","direction":"asc"}`
    } else if (activeTab === "PropertyDetails" || activeTab === "followUpDetailsTable" || activeTab === "stateUPDetailsTable") {
      await this.setState({ propertyActiveTab: activeTab })

      url = propertyUrl ? propertyUrl + "&type=exportToCsv" : null;

    } else {
      url = `seller/addressnames/export?str=${strArray}&type=exportToCsv&nameFilter={"criteria":[],"sortfield":"BusinessName","direction":"asc"}&addressFilter={"criteria":[],"sortfield":"CompanyID","direction":"asc"}`
    }
    fetchMethodRequest("GET", url)
      .then(async (response) => {
        // let key = responsekey

        if (response) {
          if (activeTab === "ClaimsManagement") {
            let updatedClaimResponse = response.claims
            updatedClaimResponse = await this.setRfiTasks(updatedClaimResponse)
            let updateDateformatforClaims = await this.updateDateFormat(updatedClaimResponse, this.props.getTableFields(), format)

            if (response && response.maxRfIs && response.maxRfIs > 0) {
              for (let i = 0; i <= response.maxRfIs - 1; i++) {
                if (mode === "Internal Use") {
                  preferences.push({
                    "field": `RFI TaskId${i + 1}`, "show": true, "label": `RFI ${i + 1} Task ID`, "width": 70
                  },
                  )
                }
                preferences.push(
                  {
                    "field": `RFI Date${i + 1}`, "show": true, "label": `RFI ${i + 1} Date`, "width": 70
                  },
                  {
                    "field": `RFI Response Date${i + 1}`, "show": true, "label": `RFI ${i + 1} Response Date`, "width": 70
                  },
                )
              }
            }
            obj = [{
              name: "Claims",
              headers: preferences,
              data: await this.formatChange(updateDateformatforClaims)
            },
            ]
          } else if (activeTab === "PropertyDetails") {
            let updatedClaimResponse = response.ns_scored_propertys
            let data = []
            updatedClaimResponse.forEach((item) => {
              for (let key in item) {
                if (key === "matchStatusNotes") {
                  if (item[key] && item[key].length > 0) {
                    for (var obj of item[key]) {
                      if (obj.note) {
                        data.push(obj.note)
                      }
                    }
                    item[key] = this.getArrayVal(JSON.stringify(data))
                  } else {
                    item[key] = null
                  }
                }
              }
              for (let key in item) {
                if (item[key] && item[key].name) {
                  item[key] = item[key].name;
                }

                if (key === "matchStatusNotes") {
                  if (item[key] && item[key].length > 0) {
                    for (var obj of item[key]) {
                      if (obj.note) {
                        data.push(obj.note)
                      }
                    }
                    item[key] = this.getArrayVal(JSON.stringify(data))
                    data = []
                  } else {
                    item[key] = null
                  }
                }
              }
            });
            for (let update of updatedClaimResponse) {
              update.OppClaimID = allClaimRows.oppClaimID
              update.StateClaimID = allClaimRows.stateClaimID

            }

            // let updateDateformatforClaims = await this.updateDateFormat(updatedClaimResponse, claimManagementFields, "MM-DD-YYYY")

            obj = [{
              name: "PropertyDetails",
              headers: preferences,
              data: await this.formatChange(updatedClaimResponse)
            },
            ]
          } else if (activeTab === "followUpDetailsTable") {
            let updatedClaimResponse = response.followUpHistories
            updatedClaimResponse = await this.updateDateFormat(updatedClaimResponse, this.props.getTableFields(), format)

            // for (let update of updatedClaimResponse) {
            //   update.OppClaimID = allClaimRows.oppClaimID
            //   update.StateClaimID = allClaimRows.stateClaimID

            // }

            // let updateDateformatforClaims = await this.updateDateFormat(updatedClaimResponse, claimManagementFields, "MM-DD-YYYY")

            obj = [{
              name: "followUpDetailsTable",
              headers: preferences,
              data: await this.formatChange(updatedClaimResponse)
            },
            ]
          } else if (activeTab === "stateUPDetailsTable") {
            let updatedClaimResponse = response.stateUPOnlineClaimStatuss
            updatedClaimResponse = await this.updateDateFormat(updatedClaimResponse, this.props.getTableFields(), format)

            // for (let update of updatedClaimResponse) {
            //   update.OppClaimID = allClaimRows.oppClaimID
            //   update.StateClaimID = allClaimRows.stateClaimID

            // }

            // let updateDateformatforClaims = await this.updateDateFormat(updatedClaimResponse, claimManagementFields, "MM-DD-YYYY")

            obj = [{
              name: "stateUPDetailsTable",
              headers: preferences,
              data: await this.formatChange(updatedClaimResponse)
            },
            ]
          } else {
            let updatednamesResponse = await this.updateDealIDToData(response.nameResponse.addressnames)
            let updatedaddressResponse = await this.updateDealIDToData(response.addressResponse.addresses)
            let updateDateformatforNames = await this.updateDateFormat(updatednamesResponse, namesTableFields, format)
            let updateDateformatforaddress = await this.updateDateFormat(updatedaddressResponse, addressTabFields, format)
            obj = [{
              name: "Names",
              headers: namePreferences,
              data: await this.formatChange(updateDateformatforNames)
            },
            {
              name: "Addresses",
              headers: addressPreferences,
              data: await this.formatChange(updateDateformatforaddress)
            }
            ]
          }
          await this.setState({
            sheets: obj,
            downloadSheet: true
          })
          document.getElementsByClassName("excel-React")[0].children[0].click()
          await this.setState({ isLoading: false })

        }
        else {
          this.setState({
            sheets: [],
            isLoading: false
          })
        }
      }).catch((err) => {
        return err;
      });
  }

  /**
   * @param {String} arrayVal 
   * @returns Formatting the results to stringify when the value is in array format
   */
  flattenArrayforFormat = (arrayVal) => {
    let val = "";
    if (arrayVal) {
      val = JSON.stringify(arrayVal);
      val = val
        .replace(/"/g, "")
        .replace(/\[/g, "")
        .replace(/]/g, "")
        .replace(/{/g, "")
        .replace(/}/g, "")
        .replace(/,/g, " , ")
        .replace(/:/g, " : ");
    }
    return val;
  };
  getArrayVal = (val) => {
    if (val) {
      val = val.replace(/\\n/g, ' ')
        .replace(/\\t/g, ' ')
        .replace(/"/g, '')
        .replace(/\[/g, '')
        .replace(/]/g, '')
        .replace(/{/g, '')
        .replace(/}/g, '')
        .replace(/,/g, ' , ')
        .replace(/:/g, ' : ')
        .replace(/\\/g, ' ')
        .replace(/"/g, '')
      // .replace(/,/g, ' ');
    }
    return val;
  }
  changeDealNameFormatForExcel = (val) => {
    var y
    if (val) {
      var split = val.split(" - ")
      let x = split[1]
      y = x.split(" (")
    }
    return y[0]
  }

  /**
   * @param {Array} data 
   * @returns Formatting the results to stringify when the value is in object or stringify format
   */
  formatChange = async (data) => {
    data.forEach(async (item) => {
      var notearry = []
      for (let key in item) {
        if (item[key] && item[key].name) {
          item[key] = item[key].name;
        }
        // if (key === "matchStatusNotes") {
        //   if (item[key] && item[key].length > 0) {
        //     for (var obj of item[key]) {
        //       if (obj.note) {
        //         notearry.push(obj.note)
        //       }
        //     }
        //     if (data.length > 0) {
        //       item[key] = await this.getArrayVal(JSON.stringify(notearry))
        //     }
        //   }
        // } else
        if (typeof item[key] == "object") {
          item[key] = await this.flattenArrayforFormat(item[key])
        }
        if (this.props.type === "ClaimsManagement" && key === "estateName" && item[key].includes(" - ") && item[key].includes(" (")) {
          item[key] = await this.changeDealNameFormatForExcel(item[key])
        }
      }
    });
    return data
  }

  formatDatakeyforNamesAddress = (e, item, index) => {
    let tableValue = [...e.value];
    let allUsersData = this.state.allTableValue

    let getBusinessNameID
    getBusinessNameID = item.options.findIndex(elem => elem.value === tableValue[e.rowIndex][item.field])
    let keyfield
    if (!getBusinessNameID || getBusinessNameID === -1) {
      if (item.field === "LegacyConnectedNameLabel") {
        keyfield = "LegacyConnectedNameID"
      } else {
        keyfield = "LocationNameID"
      }
      getBusinessNameID = item.options.findIndex(elem => elem.BusinessNamesID === tableValue[e.rowIndex][keyfield])
    }

    if (getBusinessNameID >= 0) {
      let val = item.options[getBusinessNameID]
      allUsersData[e.rowIndex][e.field] = val
      this.setState({
        [item.field]: val,
        allTableValue: allUsersData
      })
    }
  }
  /**
   * invoked when ever the cell edit view is 
   * @param {Object} e 
   * @param {Array} item 
   */
  onEditorFocus(e, item, index) {
    if (this.props.nonEditable) return;
    if (this.state.updatingIndex && this.state.updatingIndex !== `${e.rowIndex}-${index}`) {
      let prevEditField = this.state.updatingIndex.split("-")
      var selectedRow2 = document.getElementsByTagName("tr")[parseInt(prevEditField[0]) + 1]
      if (selectedRow2 && selectedRow2.children) {
        selectedRow2.children[parseInt(prevEditField[1])].style.removeProperty("border");
        selectedRow2.children[parseInt(prevEditField[1])].style.removeProperty("borderRadius");
        selectedRow2.children[parseInt(prevEditField[1])].style.removeProperty("background-color");
        selectedRow2.children[parseInt(prevEditField[1])].style.removeProperty("-webkit-box-shadow");
      }
    }
    let tableValue = [...e.value];

    // this.setState({
    //   previousField: tableValue[e.rowIndex][e.field], fieldEdited: e.field, isInFocus: true, updatingIndex: JSON.parse(JSON.stringify(`${e.rowIndex}-${index}`))
    // })

    if (item && (item.editField === "dropdown" || item.editField === "multiDropdown")) {
      if (item.dataKey) {
        this.formatDatakeyforNamesAddress(e, item, index)
      } else {
        this.setState({
          [item.field]: item.editField === "multiDropdown" && typeof tableValue[e.rowIndex][e.field] === "string" ? tableValue[e.rowIndex][e.field].split(",") : tableValue[e.rowIndex][e.field]
        })
      }
    }
    if (item && item.editField === "date") {
      this.setState({
        calenderDate: tableValue[e.rowIndex][e.field] && tableValue[e.rowIndex][e.field].length >= 10 ? new Date(JSON.parse(JSON.stringify(tableValue[e.rowIndex][e.field]))) : null
      })
    }
    if (this.props.showPreset && this.props.showPreset === "true") {
    } else {
      this.actions_presets.updateValue(
        {
          testng: "focus",
          editedRowField: e.field,
          editedRowIndex: e.rowIndex,
          columnindex: index,
          selectedFieldHeader: item.header,
          editedField: e.field === "LegacyConnectedNameLabel" ? this.state[item.field] : item.editField ? tableValue[e.rowIndex][e.field] : "",
          editedFieldoptions: item.options ? item.options : null,
          editType: item.editField ? item.editField : "isDisabled",
          editColumnField: item
          // editField: item.editField ? item.editField : "isDisabled",
          // fieldType: item.fieldType ? item.fieldType : "text"
        }
      )
    }
  }


  removeBackgroundstyle = () => {
    // }
    if (this.state.updatingIndex) {
      let prevEditField = this.state.updatingIndex.split("-")
      var selectedRow2 = document.getElementsByTagName("tr")[parseInt(prevEditField[0]) + 1]
      if (selectedRow2 && selectedRow2.children) {
        selectedRow2.children[parseInt(prevEditField[1])].style.removeProperty("border");
        selectedRow2.children[parseInt(prevEditField[1])].style.removeProperty("borderRadius");
        selectedRow2.children[parseInt(prevEditField[1])].style.removeProperty("background-color");
        selectedRow2.children[parseInt(prevEditField[1])].style.removeProperty("-webkit-box-shadow");
      }
    }
  }
  updateTableData = () => {
    this.setState({ allTableValue: this.state.allTableValue })

  }

  /**
   * 
   * @param {Object} e 
   * @param {Array} item 
   */
  //invoked when ever the cell editor is clicked
  onEditorSubmit = async (e, item) => {
    let enter = moment(e.rowData[e.field], 'MM/DD/YYYY', true).isValid()
    // var element = document.getElementsByClassName("p-multiselect-label")[0]
    // if (element) {
    //   element.classList.remove("p-multiselect-label-empty");
    // }
    let enter1 = new Date(e.rowData[e.field])
    let allUsersData = this.state.allTableValue
    if (!(enter1 > 0 || enter) && item.fieldType === "dateTime") {

      allUsersData[e.rowIndex][e.field] = ""
      this.setState({ allTableValue: allUsersData })
    } else if (item.fieldType === "dateTime") {
      let allUsersData = await this.state.allTableValue
      // allUsersData[e.rowIndex][e.field] = enter1 ? enter1 : enter ? enter : null
      let enter1DateFormate = String(enter1.getMonth() + 1).padStart(2, '0') + "-" + String(enter1.getDate()).padStart(2, '0') + "-" + enter1.getFullYear()
      allUsersData[e.rowIndex][e.field] = enter1 ? enter1DateFormate : enter ? enter : null

      await this.setState({ allTableValue: allUsersData })
    }
    if (item.editField === "dropdown" || item.editField === "multiDropdown" || item.fieldType === "dateTime") {
      allUsersData[e.rowIndex][e.field] = this.state[item.field] && this.state[item.field].value ? this.state[item.field].value : this.state[item.field] ? this.state[item.field] : allUsersData[e.rowIndex][e.field]
      await this.setState({ [item.field]: "", calenderDate: "", allUsersData: allUsersData })

    }
    // if (!this.state.isClickSaved) {
    // this.actions_presets.updateValue(
    //   {
    //     testng: "submit",
    //     editedRowField: e.field,
    //     editedRowIndex: e.rowIndex,
    //     editedField: item.editField ? allUsersData[e.rowIndex][e.field] : "",
    //     editedFieldoptions: item.options ? item.options : null,
    //     editType: item.editField ? item.editField : "isDisabled",
    //     // editField: item.editField ? item.editField : "isDisabled",
    //     // fieldType: item.fieldType ? item.fieldType : "text"

    //   }
    // )
    // }
    // let history = this.state.history
    // if (e && e.field) {
    //   if (this.state.previousField === e.rowData[e.field]) {

    //   } else {
    //     if (this.state.previousField === null && e.rowData[e.field] === null) {

    //     } else if (this.state.fieldEdited === e.field && this.state.previousField !== e.rowData[e.field]) {
    //       if (e.rowData[e.field] && item.editField) {
    //         history.push({ historyIndex: e.rowIndex, historyField: e.field, historyValue: e.rowData[e.field], previousField: this.state.previousField })
    //       }
    //     }
    //   }
    //   await this.setState({ history: history, historyIndex: 0, isInFocus: false, previousField: "" })
    // }
    // if (this.actions_presets) {
    //   this.actions_presets.enableRedoButton()
    // }
    // if (history && history.length > 0 && this.actions_presets) {
    //   this.actions_presets.disableUndoButton()
    // }

  }

  /**
   *  Change the value based on the editor field
   * @param {Object} e 
   * @param {Number} index 
   * @param {String} field 
   * @param {String} type 
   */
  setValueInGrid = async (e, index, field, type) => {
    if (type === "date") {
      e = dateFormats.formatDate(e, "MM/DD/YYYY", "nhg")
    }
    if (e) {
      let allrecords = this.state.allTableValue && this.state.allTableValue.length > 0 ? this.state.allTableValue : [];
      allrecords[index][field] = e;
      document.getElementById(`${field}-${index}`).innerHTML = e;
      if (allrecords[index] && allrecords[index].isedited) {
      } else {
        allrecords[index]["isedited"] = "yes"
      }
      if (type !== "text") {
        this.setState({ allTableValue: allrecords })
      }
    }
  }

  /**
   * Show the toasterMsgfrom the apicalls
   * @param {String} msg 
   * @param {String} type 
   */
  getResponseMsgfromServer = (msg, type) => {
    this.setState({
      isshowToasterMsg: true,
      showToasterMsg: msg,
      backgroundColor: type === "errorView" ? "#fcd3D2" : "#e6eed1",
      color: type === "errorView" ? "#811211" : "#5e6919",
      // color:"#811211"
      isLoading: false
    })
  }
  // /**
  //  *   Change the datatype based on the editor field
  //  * @param {String} productKey 
  //  * @param {Object} options 
  //  * @param {Array} item 
  //  * @returns the required component based on edit field
  //  */
  // editorbasedField = (productKey, options, item) => {
  //   if (this.props.type === "followUpNumber") {
  //     return <div>{
  //       item.editField === "dropdown" ?
  //         this.dropDownEditor(options, item) :
  //         item.editField === "text" && options.rowData["followUpStatus"] === "Completed" ?
  //           this.inputTextEditor("allTableValue", options, item) :
  //           options.rowData[item.field]
  //     }
  //     </div>
  //   } else {
  //     return <div>{item.editField === "text" ?
  //       this.inputTextEditor("allTableValue", options, item) :
  //       item.editField === "dropdown" ?
  //         this.dropDownEditor(options, item) :
  //         item.editField === "multiDropdown" ?
  //           this.multidropDownEditor(options, item) :
  //           item.editField === "date" ?
  //             //  && this.state.previousField !== options.rowData[item.field]
  //             this.getDatePicker(options, item) :
  //             <span>{item.fieldType === "dateTime" ? dateFormats.formatDate(options.rowData[item.field], "MM-DD-YYYY") : item.fieldType === "NoteArray" && options.rowData[item.field] && options.rowData[item.field][0] && options.rowData[item.field][0].note ? <span>{options.rowData[item.field][0].note}</span> : options.rowData[item.field]}</span>}
  //     </div>
  //   }
  // }

  /**
   *   Change the datatype based on the editor field
   * @param {String} productKey 
   * @param {Object} options 
   * @param {Array} item 
   * @returns the required component based on edit field
   */
  editorbasedField = (productKey, options, item) => {
    return <div>{item.editField === "text" ?
      this.inputTextEditor("allTableValue", options, item) :
      item.editField === "dropdown" ?
        this.dropDownEditor(options, item) :
        item.editField === "multiDropdown" ?
          this.multidropDownEditor(options, item) :
          item.editField === "date" ?
            //  && this.state.previousField !== options.rowData[item.field]
            this.getDatePicker(options, item) :
            <span>{item.fieldType === "dateTime" ? dateFormats.formatDate(options.rowData[item.field], "MM-DD-YYYY") : item.fieldType === "NoteArray" && options.rowData[item.field] && options.rowData[item.field][0] && options.rowData[item.field][0].note ? <span>{options.rowData[item.field][0].note}</span> : options.rowData[item.field]}</span>}
    </div>
  }

  /**
   * Clear the history array
   */
  clearHistory = async () => {
    this.setState({ history: [] })
  }

  /**
   *  delete Records from the db 
   */
  deleteSelectedRow = async () => {
    this.closeOrderModal()
    this.setState({
      selectedRows: []
    })
    var deletenewRec = []
    let body = {}
    body.selectedIds = []
    let uniqueField = this.props.uniqueDBField

    for (var item of this.state.deleteArrayindbandlocal) {
      if (item[uniqueField] && item[uniqueField] !== 0 && item[uniqueField] !== "Auto generated") {//for saved records 
        body.selectedIds.push(item[uniqueField])
      } else {//for new records 
        deletenewRec.push(item.uniqueID)
      }
    }
    //soft delete for new records
    if (deletenewRec && deletenewRec.length > 0) {
      let allTableValues = this.state.allTableValue
      // for (var newReco of deletenewRec){
      for (var deleteIndex of deletenewRec) {
        var index = allTableValues.findIndex(a => a.uniqueID === deleteIndex)
        index !== -1 && allTableValues.splice(index, 1)
      }
    }
    await this.removeMandatoryColorsfromrecords()
    if (body.selectedIds.length > 0) {
      this.deleteMethodtocalldb(body)
    }
  }



  /**
   *    remove bg color for the mandatory fields
   */
  removeMandatoryColorsfromrecords = () => {
    let errorColorFields = document.getElementsByClassName("p-editable-column")
    errorColorFields.forEach((item, index) => {
      if (item && item.style && item.style.backgroundColor === "rgb(243, 146, 146)") {
        item.style.backgroundColor = ""
      }
    });
  }

  /**
   *      delete method to send the ids to delete
   */
  deleteMethodtocalldb = async (body) => {
    await this.setState({ isLoading: true })
    let multiDEleteApi = this.props.multiDeleteUrl
    fetchMethodRequest('POST', `${multiDEleteApi}`, body).then(async (response) => {
      if (response && response.respMessage) {
        // let propertyStatuses = response[key]
        let history = this.state.history
        let obj = { DeletedRows: true, DeletedObjects: this.state.historyDeleteArray }
        history.push(obj)
        this.setState({ history: history, })
        await this.actions_presets.enableRedoButton()
        await this.actions_presets.disableUndoButton()
        this.removeMandatoryColorsfromrecords()
        await this.refreshGrid("Delete")
        this.setState({
          isshowToasterMsg: true,
          showToasterMsg: response.respMessage,
          backgroundColor: "#e6eed1",
          color: "#5e6919",
          isLoading: false
        })
      } else if (response && response.errorMessage) {
        this.setState({
          isNotSuccess: true,
          isshowToasterMsg: true,
          showToasterMsg: response.errorMessage,
          backgroundColor: "#fcd3D2",
          color: "#811211"

        })
      }
    })
  }
  closeSaveModelPopUp = async () => {
    let elem = document.getElementById(`cb-${this.state.selectedEvent._id}`);
    if (elem) {
      elem.checked = true;
    }
    await this.setState({ openUnsaveModal: false })
    await this.props.closeSaveModelPopUp()
  }
  closeTaskAddModel = async () => {
    await this.setState({ openUnsaveModal: false, edited: "no" })
    await this.onCheck(this.state.selectedEvent)
  }
  // Delete Modal
  getDeleteRowModalForNotes() {
    return (
      <DeleteRowModal
        openDeleteModal={this.state.openUnsaveModal}
        closeDeleteModal={this.closeSaveModelPopUp}
        deleteSelectedRow={this.closeTaskAddModel}
        unSaved={true}
        notesUnsaved={true}
        confirmModalText={"You have unsaved notes."}
        type={this.props.type}
      />
    )
  }

  /**
  * Column order for Deal Names
  */
  getOpenShowHideColumnsModal() {
    return (
      <DealShowHideColumnsModal
        isOpenShowHideColumnsModal={this.state.isOpenShowHideColumnsModal}
        closeShowHideColumnsModalForDeal={this.closeShowHideColumnsModalForDeal}
        tableFields={this.state.Preferences}
        type={this.props.type}
        viewBy={this.state.selectedViewType}
        originalTableFields={this.state.Preferences}
        isShownnFieldType={this.props.type === "Property Editor" || this.props.type === "OPRA2 Property Editor"
          || this.props.type === "Properties" || this.props.type === "ClaimsManagement"
          || this.props.isShownnFieldType ? false : true}

      />
    )
  }

  /**
   * changing or closing the show hide option
   * @param {String} type 
   * @param {Objects} columnFields 
   * @param {Objects} changedTableFields 
   */
  closeShowHideColumnsModalForDeal = async (type, columnFields, changedTableFields) => {
    if (type && type === "confirm") {
      this.getTableFieldsOrder(changedTableFields)
      this.setState({ Preferences: changedTableFields })
    } else {
      this.getTableFieldsOrder(changedTableFields)
      this.setState({ Preferences: changedTableFields })
    }
    this.actions_presets.updateTableFields(changedTableFields)
    await this.setState({
      isOpenShowHideColumnsModal: false,
      isLoading: false,
    })
  }

  /**
   * 
   * @param {String} type 
   * @returns get all presets from the db based on screen
   */
  loadAllPresets = async (type) => {
    let filterCriteria = {}
    let presets = []
    let screen = this.props.type === "Address" ? "Addresses" : this.props.type
    filterCriteria['criteria'] = [{ key: 'Screen', value: screen, type: 'eq' }];
    await fetchMethodRequest('GET', `preset?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response["presets"] && response["presets"].length > 0) {
        presets = response["presets"]

        // this.setState({ viewsforScreens: presets })
      }
      await this.setState({
        presets: presets
      })
      if (response.defaultPreset && response.defaultPreset !== null) {
        await this.setState({
          defaultPresetForUser: response.defaultPreset,
        })
        if (!type) {
          this.getDataFromServerToSetPreset()

        } else if (type === "save") {
          await this.getDataFromServerToSetPreset()

        }
      }

    })

    // await this.changeColorforrequiredFields()//for changing the color of the cell if it is mandatory
    return this.state.viewsforScreens;
  }

  /**
   * on changing pagination
   * @param {Object} event 
   */

  onPageChange = async (event) => {
    let filterCriteria = this.state.filterCriteria;
    if (event && event.rows) {
      let currentPage = event.page + 1;
      filterCriteria['limit'] = event.rows;
      filterCriteria['page'] = currentPage;
      this.setState({
        rows: event.rows,
        page: event.page,
        first: event.first,
        filterCriteria: filterCriteria,
        isLoading: true
      })
    }
    // this.handlePercentage("1")
    this.props.toggle(this.state.activeTab, filterCriteria, "Preset")
    this.setState({ isLoading: false })
  }

  getPaginator() {
    return (
      <div className='w-100 d-flex justify-content-end' style={{ height: 25 }}>
        {this.state.isshowToasterMsg && this.props.type === "ClaimsManagement" ?
          <div className='flex-auto mx-auto '><span className="px-4 my-2" style={{
            backgroundColor: this.state.backgroundColor, color: this.state.color, display: "flex", justifyContent: "center",
            width: "fit-content", marginLeft: "auto", marginRight: "auto",
          }}>
            {this.state.showToasterMsg}</span></div>
          : null}
        <div style={{ float: "right" }}>
          <PaginatorComponent
            totalRecords={this.state.totalRecordsLength}
            first={this.state.first}
            rows={this.state.rows}
            onPageChange={this.onPageChange}
            isWeb={true}
          />
        </div>
      </div>
    )
  }

  resetPaginator = () => {
    this.setState({
      totalRecordsLength: 0,
      first: 0,
      page: 1
    });
  }


  /**
   * Handle Table fields to show in datatable
   * @param {Object} fields 
   */
  getTableFieldsOrder = async (fields) => {
    let columns = fields
    let tempTableFields = [];
    await this.setState({
      isLoading: true,
    });
    let staticTableFields = await this.props.getTableFieldsforData()
    if (columns) {
      for (let i = 0; i < columns.length; i++) {
        for (let j = 0; j < staticTableFields.length; j++) {
          if (columns[i].field === staticTableFields[j].field) {
            let pushItem = {}
            pushItem = JSON.parse(JSON.stringify(staticTableFields[j]));
            pushItem.show = columns[i].show;
            pushItem.displayInSettings = columns[i].displayInSettings;
            tempTableFields.push(pushItem);
            break;
          }
        }
      }
    }
    await this.setState({
      tablefieldsToShow: tempTableFields,
      Preferences: fields,
    });
  }

  changebackgroundColortoDefault() {
    var element = document.getElementsByClassName("isRequired");
    for (let i = 0; i < element.length; i++) {
      var cell = document.getElementsByClassName("isRequired")[i]
      if (cell.parentNode.style && cell.parentNode.style.backgroundColor) {
        cell.parentNode.style.removeProperty("background-color");
      }
    }
  }

  setTableFieldsOrderEmpty = async () => {
    await this.setState({ dynamicColumns: [] })
  }
  /**
   * 
   * @param {String} name 
   */
  getDataFromServerToSetPreset = async (name) => {
    await this.setState({ isLoading: true })
    await this.changebackgroundColortoDefault()
    let DefaultPreset
    if (name) {
      this.actions_presets.closeModelOnDefault()
      DefaultPreset = name
    } else {
      DefaultPreset = this.state.defaultPresetForUser ? this.state.defaultPresetForUser : null
    }
    let presets = this.state.presets
    if (DefaultPreset) {
      let currentPreset = presets.find(elem => elem.name === DefaultPreset)
      if (currentPreset && currentPreset.preferences) {
        await this.getTableFieldsOrder(currentPreset.preferences)
        // await this.setState({
        //   Preferences: currentPreset.preferences,
        //   isLoading: false
        // })

        this.actions_presets.setActivePreset(DefaultPreset)

      }
    }
    await this.setState({ isLoading: false })
  }
  /**
   * 
   * @param {String} tab 
   */
  setActiveTab = async (tab) => {
    this.setState({ activeTab: tab, isshowToasterMsg: false })
  }
  getDeleteRowModal() {
    return (
      <DeleteRowModal
        openDeleteModal={this.state.openDeleteModal}
        closeDeleteModal={this.closeOrderModal}
        // selectActions={this.state.selectActions}
        deleteSelectedRow={this.deleteSelectedRow}
        confirmModalText={"Are you sure you want to delete the seleted rows?"}
        type={"Sellers"}
      />
    )
  }

  closeOrderModal = async () => {
    await this.setState({
      saveOrderModal: false,
      showOrderModal: false,
      openDeleteModal: false,
      selectedRows: [],
      deleArray: [],
    })
  }

  /**
   * 
   * @returns Component that is used for Download excel sheet
   */
  getExcelDownload = () => {
    let todaydate = dateFormats.formatDate(
      new Date(),
      "MM/DD/YYYY", "nhg")
    return (
      <span className='excel-React d-none'>
        <ExcelReact EstateName={this.props.selectedDeal ? `${this.props.selectedDeal} - ${todaydate} - "Names and Addresses"` : this.state.propertyActiveTab === "PropertyDetails" ? "propertyDetails" : this.state.propertyActiveTab === "followUpDetailsTable" ? "Follow Up History" : this.state.propertyActiveTab === "stateUPDetailsTable" ? "State UP History" : "Claims"}
          sheets={this.state.sheets} type="Sellers"
        />
      </span>
    )
  }


  /**
   * before downloading the Excel need to confirm that changes are mate to the table or not
   */
  isDownloadExcel = async () => {
    let updatedRecords = await this.isUpdatedTableValue()
    if (updatedRecords && updatedRecords.length > 0) {
      await this.setState({
        isopenColumnModal: true,
        isDownload: true,
        updatedRecordslength: updatedRecords.length
      })
    }
  }


  /**
   * 
   * @returns  return PresetBtn and Action Buttons 
   */
  getPresetBtnActions() {

    return (
      <div>
        <DealActions
          onRef={(ref) => this.actions_presets = ref}
          totalRecords={this.state.totalRecordsLength}
          filteredValues={this.filteredValues}
          updateTableData={this.updateTableData}
          getTableFieldsforTableColumns={this.getTableFieldsforTableColumns}
          submitedRecordsCount={this.isUpdatedTableValue()}
          duplicateRows={this.duplicateSelectRows}
          isEditableGrid={this.props.isEditableGrid ? this.props.isEditableGrid : false}
          getResponseMsgfromServer={this.getResponseMsgfromServer}
          selectingRecords={this.selectAllRecords}
          getremoveFilters={this.getremoveFilters}
          deletingRow={this.deleteRow}
          addingRow={this.props.type === "ClaimsManagement" ? this.openClaimDetails : this.addRow}
          isClickSaved={this.state.isClickSaved}
          undoClick={this.undo}
          strArray={this.props.strArray}
          type={this.props.type ? this.props.type : ""}
          getDataFromServerToSetPreset={this.getDataFromServerToSetPreset}
          getTableFieldsOrder={this.getTableFieldsOrder}
          loadAllPresets={this.loadAllPresets}
          setValueInGrid={this.setValueInGrid}
          selectedRecords={this.state.selectedRows}
          getExcelDownload={this.getExcelDownload}
          getExcelFile={this.getExcelFile}
          setTableFieldsOrderEmpty={this.setTableFieldsOrderEmpty}
          getExcelFileForDownload={this.getExcelFileForDownload}
          redoClick={this.redo}
          openDeleteModal={this.props.openDeleteModal}
          activeTab={this.state.activeTab}
          refresh={this.refreshGrid}
          history={this.state.history}
          editedFieldoptions={this.state.editedFieldoptions}
          isDownloadExcel={this.isDownloadExcel}
          savingFunc={this.editorValidator}
          deleteSelectedRow={this.props.deleteSelectedRow}
          closeOrderModal={this.props.closeOrderModal}
        />
      </div>
    )
  }

  isConfirmFilter = async (val, type) => {
    if (val && val.filters) {
      let fieldName = Object.keys(val.filters)[0];
      if (val.filters[fieldName].value.length === 0 || val.filters[fieldName].value.length === undefined) {
        await this.setState({ isApplyFilter: true, filteredCol: val })
        this.getConfirmaionModelforFilter()
      } else {
        await this.onFilterChange(val)
      }
    } else {
      this.setState({ isLoading: false, isApplyFilter: false, })
      if (type) {
        this.onSeletFilterType(val, type)
        //  this.handleFilterInputField(val, "clear")
      }
    }
  }

  //after selecting type 
  onSeletFilterType(value, type) {
    value = this.props.activeTab ? this.state.activeTab.toString() + value : value
    let selectedcolumn = document.getElementsByClassName(`p-column-filter-${value}`)
    if (selectedcolumn[0].getElementsByClassName(`p-column-filter`)[0]) {
      if (!type) {
        selectedcolumn[0].getElementsByClassName(`p-column-filter`)[0].focus()
      } else {
        selectedcolumn[0].getElementsByClassName(`p-column-filter`)[0].blur()
      }
    }
  }

  getNotesModal() {
    return (
      <NotesModal
        openNotesModal={this.state.isnotesModal}
        cancelReset={this.cancelNotes}
        saveClaimNotes={this.saveClaimNotes}
        claimNotesEdit={this.state.claimNotesEdit}
        notesSno={this.state.notesSno}
        Comments={this.state.Comments}
        type={"Notes"}
      />
    )
  }
  getConfirmaionModel = () => {

    return (
      <ConfirmationModal
        openConfirmationModal={this.state.isopenColumnModal}
        closeConfirmationModal={this.closeShowHideColumnsModal}
        shownText={this.state.updatedRecordslength > 0 ? "Do you want to continue with unsaved changes?" : "Are you sure you want to close before Saving?"}
      // confirm={this.submit}
      />


    )
  }

  getConfirmaionModelforFilter = () => {
    return (
      <ConfirmationModalforFilter
        openConfirmationModal={this.state.isApplyFilter}
        closeConfirmationModal={this.isConfirmFilter}
        onFilterChange={this.onFilterChange}
        filterInput={this.state.filteredCol}
        handleFilterInputField={this.handleFilterInputField}
        shownText={this.state.updatedRecordslength > 0 ? "Do you want to continue with unsaved changes?" : "Are you sure you want to close before Saving?"}
      />
    )
  }

  /**
   * closing the Names and Address Modals
   * @param {String} type 
   */
  closeShowHideColumnsModal = async (type) => {
    await this.setState({
      isopenColumnModal: false,
      isshowToasterMsg: false,
      showToasterMsg: ""
    })
    this.actions_presets.handleDownloadclick(false)
    if (type === "close") {
      if (this.state.updatedRecordslength > 0 && this.state.isDownload) {
        // await this.getExcelDownload()
        this.actions_presets.handleDownloadclick(true)
        document.getElementsByClassName("right_buttons")[0].children[1].children[0].click()
        this.actions_presets.handleDownloadclick(false)
        this.setState({ isDownload: false })
      } else if (this.state.updatedRecordslength > 0 && this.state.isRefresh) {
        this.getremoveFilters()
        this.props.toggle(this.state.activeTab, "refresh")
        this.setState({ isRefresh: false, allTableValue: [] })

      }
      // this.props.closeShowHideColumnsModal('close', null)
    }

  }

  /**
   * invoke when Alt + R button is clicked or refresh button is clicked
   * @param {String} type 
   */
  refreshGrid = async (type) => {
    let updatedRecords = await this.isUpdatedTableValue()
    this.actions_presets.updateValue(
      {
        testng: "refresh",
        editedRowField: "",
        editedRowIndex: "",
        editedField: "",
        editType: "text",
        selectedFieldHeader: "",
        editColumnField: ""
      }
    )
    if (!type) {
      this.actions_presets.enableRedoButton()
      this.actions_presets.enableUndoButton()
    }
    if (updatedRecords && updatedRecords.length > 0) {
      await this.setState({
        isopenColumnModal: true,
        isRefresh: true,
        editedRowField: "",
        updatedRecordslength: updatedRecords.length
      })
    } else {
      await this.setState({
        isshowToasterMsg: false,
        showToasterMsg: "",
        allTableValue: []
      })
      this.getremoveFilters(type)
      // this.props.toggle(this.state.activeTab, "refresh", "Preset", type)
    }
  }


  /**
  * Undo Functionality for the editing
  */

  undo = async () => {
    let allrecords = this.state.allTableValue && this.state.allTableValue.length > 0 ? this.state.allTableValue : []
    let history = this.state.history
    let historyIndex = this.state.historyIndex ? this.state.historyIndex : history.length
    if (historyIndex === 1) {
      if (history[historyIndex - 1].DeletedRows) {
        if (history[historyIndex - 1].DeletedObjects.length > 0)
          for (let row of history[historyIndex - 1].DeletedObjects) {
            row.isRevoked = "true"
            allrecords.unshift(row)
          }
      } else {

        allrecords = JSON.parse(JSON.stringify(this.state.responseaddressnames))
      }
      await this.setState({ historyIndex: historyIndex - 1 });
      this.actions_presets.enableUndoButton()
    } else {
      let index = historyIndex - 1
      var last = history[historyIndex - 1]
      if (last && last.historyField) {
        allrecords[last.historyIndex][last.historyField] = last.previousField;
        for (let i = 0; i < historyIndex - 1; i++) {
          if (history[i].historyIndex === last.historyIndex) {
            allrecords[last.historyIndex]["isedited"] = "yes";
          } else {
            allrecords[last.historyIndex]["isedited"] = "no";
          }
        }
      } else if (last && last.addRowIndex) {
        allrecords.shift()
      } else if (last && last.DeletedRows) {
        if (last.DeletedObjects.length > 0)
          for (let row of last.DeletedObjects) {
            row.isRevoked = "true"
            allrecords.unshift(row)
          }
      }
      await this.setState({ historyIndex: index })
    }
    this.setState({ allTableValue: allrecords, });
    this.actions_presets.disableRedoButton()
  }

  /**
  * Redo Functionality for the editing
  */
  redo = async () => {
    let allrecords = this.state.allTableValue && this.state.allTableValue.length > 0 ? this.state.allTableValue : []
    let history = this.state.history
    let historyIndex = this.state.historyIndex ? this.state.historyIndex : 0
    let index = historyIndex + 1
    var last = history[historyIndex]
    // if (last && last.addRowIndex) {
    //     allrecords.pop()
    // } else
    // if (historyIndex === 1) {
    //     this.setState({ disableUndoButton: false, addressnames: allrecords, historyIndex: historyIndex, });
    // } else {
    if (last && last.historyField) {
      allrecords[last.historyIndex][last.historyField] = last.historyValue;
      allrecords[last.historyIndex]["isedited"] = "yes";

    } else if (last && last.addRowIndex) {
      allrecords.unshift(last.addRowIndexObj)
    } else if (history[historyIndex].DeletedRows) {
      if (history[historyIndex].DeletedObjects.length > 0)
        for (let row of history[historyIndex].DeletedObjects) {
          allrecords.splice(allrecords.findIndex(a1 => a1.BusinessNamesID === row.BusinessNamesID), 1)
        }
    }
    await this.setState({ allTableValue: allrecords, historyIndex: index });
    this.actions_presets.disableUndoButton()
    if (index === history.length) {
      this.actions_presets.enableRedoButton()
    }
    // }
  }


  checkvalidationsforORCase(obj, type) {
    const dataset = obj
    let emptyObj = [], subobj = []
    let requiredFieldsforNamesTab = type === 'OR' ? this.props.anyOneField : this.props.requiredFields
    emptyObj.push(requiredFieldsforNamesTab)
    const [a, b] = emptyObj
      .map(keys => keys.reduce((r, key) => {
        r[key] = dataset[key] ? dataset[key] : ""
        return r;
      }, {}))

    return a;

  }

  /**
  * when the Save button is clicked or Ctrl+S is clicked the data is validated for the newly records
  */
  editorValidator = async () => {
    this.setState({
      isClickSaved: true,
      isshowToasterMsg: false,
    })
    this.actions_presets.updateValue(
      {
        testng: "saving names",
        editedRowField: "",
        editedRowIndex: "",
        editedField: "",
        editType: "text",
        selectedFieldHeader: "",
        editColumnField: ""
      }
    )
    document.getElementsByClassName("button_func")[0].click()
    let requiredFieldsforNamesTab
    let errorFields = []
    var errorHeadersforORCase = [], errorMessageforORCase = ""
    requiredFieldsforNamesTab = this.props.requiredFields
    if (this.props.type === "Names") {
      let fieldIndex = this.state.tablefieldsToShow.findIndex((obj) => obj.field === "LegacyConnectedNameLabel")
      if (fieldIndex !== -1 && (this.state.tablefieldsToShow[fieldIndex].options === undefined || this.state.tablefieldsToShow[fieldIndex].options.length === 0))
        requiredFieldsforNamesTab.splice(5, 1)
    }
    let uniqueField = this.props.uniqueDBField

    let allrecords = this.state.allTableValue
    let body = {}, existNames = [], newNames = [], UndoNames = [], errorNames = []

    for (let allrecord of allrecords) {
      if (allrecord.IssueName) {
        allrecord.IssueName = allrecord.IssueName.toString();
      }
      if (allrecord.isRevoked === "true") {
        UndoNames.push(allrecord)
      }
      else if (allrecord.isAdded) {
        allrecord.DealID = this.props.selectedDealID
        newNames.push(allrecord)
      } else if (allrecord.isedited === "yes") {
        existNames.push(allrecord)
      }
    }


    if (existNames && existNames.length > 0) {
      for (var obj of existNames) {
        if (this.props.validationType === "and") {
          for (var item of requiredFieldsforNamesTab) {
            item = item.charAt(0).toUpperCase() + item.slice(1);
            if (obj[item] === "" || obj[item] === null) {
              if (!errorFields.includes(obj[uniqueField])) {
                errorFields.push(obj[uniqueField])
                errorNames.push(item)
              }
            }
          }
        } else {
          Object.filter = (obj, predicate) =>
            Object.keys(obj)
              .filter(key => predicate(obj[key]))
              .reduce((res, key) => (res[key] = obj[key], res), {});
          let data = await this.checkvalidationsforORCase(obj, 'OR')
          var filteredforOr = Object.filter(data, data => data === "");
          let allRequired = await this.checkvalidationsforORCase(obj, 'AND')
          var filteredforAnd = Object.filter(allRequired, allRequired => allRequired === "");
          if (Object.keys(filteredforAnd).length > 0 || Object.keys(filteredforOr).length === Object.keys(data).length) {
            errorFields.push(obj.uniqueID)
            let orMandatoryFields = []
            if (Object.keys(filteredforOr).length === Object.keys(data).length) {
              orMandatoryFields = Object.keys(filteredforOr)
              for (var obj of orMandatoryFields) {
                let index = this.state.tablefieldsToShow.findIndex(item => item.field === obj)
                if (index !== -1) {
                  errorHeadersforORCase.push(this.state.tablefieldsToShow[index].header)
                }
              }
              errorMessageforORCase = `  and at least one of the fields ${errorHeadersforORCase}`
            }
            errorNames = [...Object.keys(filteredforAnd)]
          }
        }

      }
    }
    if (newNames && newNames.length > 0) {
      for (var obj of newNames) {
        if (this.props.validationType === "and") {
          for (var item of requiredFieldsforNamesTab) {
            if (obj[item] === "" || obj[item] === null) {
              errorFields.push(obj.uniqueID)
              errorNames.push(item)
            }
          }
        } else {
          Object.filter = (obj, predicate) =>
            Object.keys(obj)
              .filter(key => predicate(obj[key]))
              .reduce((res, key) => (res[key] = obj[key], res), {});
          let data = await this.checkvalidationsforORCase(obj, 'OR')
          var filteredforOr = Object.filter(data, data => data === "");
          let allRequired = await this.checkvalidationsforORCase(obj, 'AND')
          var filteredforAnd = Object.filter(allRequired, allRequired => allRequired === "");
          if (Object.keys(filteredforAnd).length > 0 || Object.keys(filteredforOr).length === Object.keys(data).length) {
            errorFields.push(obj.uniqueID)

            let orMandatoryFields = []
            if (Object.keys(filteredforOr).length === Object.keys(data).length) {
              orMandatoryFields = Object.keys(filteredforOr)
              for (var obj of orMandatoryFields) {
                let index = this.state.tablefieldsToShow.findIndex(item => item.field === obj)
                if (index !== -1) {
                  errorHeadersforORCase.push(this.state.tablefieldsToShow[index].header)
                }
              }
              errorMessageforORCase = `  and at least one of the fields ${errorHeadersforORCase}`
            }
            errorNames = [...Object.keys(filteredforAnd)]
          }
        }
      }
    }

    let type = this.props.type === "Address" ? "Addresses" : this.props.type
    body[`${"exist" + type}`] = existNames
    body[`${"new" + type}`] = newNames
    body[`${"Undo" + type}`] = UndoNames

    if (errorFields && errorFields.length === 0) {
      if (this.props.type === "Names" && body.newNames.length > 0) {
        for (let allrecord of body.newNames) {
          allrecord.BusinessNamesID = 0
        }
      }
      this.saveNames(body)
    } else {
      var errorHeaders = []
      for (var obj of errorNames) {
        let index = this.state.tablefieldsToShow.findIndex(item => item.field === obj)
        if (index !== -1) {
          errorHeaders.push(this.state.tablefieldsToShow[index].header)
        }
      }
      errorNames = errorHeaders.join(', ')
      errorNames = errorNames + errorMessageforORCase
      this.setState({
        isshowToasterMsg: true,
        showToasterMsg: `SAVE FAILED: Please fill in the following fields: ${errorNames} must have values`,
        backgroundColor: "#fcd3D2",
        color: "#811211",
        isClickSaved: false
      })
      this.changeColorforrequiredFields()//for changing the color of the cell if it is mandatory
    }
  }

  /*
  for changing the color of the cell if it is mandatory
  */
  changeColorforrequiredFields = () => {
    let ErrorFieldsCount = document.getElementsByClassName("isRequired").length
    for (let i = 0; i < ErrorFieldsCount; i++) {
      if (document.getElementsByClassName("isRequired")[i].innerHTML.length > 0) {
        document.getElementsByClassName("isRequired")[i].parentNode.removeProperty("background-color");
      } else {
        document.getElementsByClassName("isRequired")[i].parentNode.style.backgroundColor = "#f39292"
      }
    }

  }

  /**
   * 
   * @param {String} message 
   * @param {String} type 
   */
  showErrorMessage = async (message, type) => {
    if (message && message.length > 0) {
      await this.setState({
        isshowToasterMsg: true,
        showToasterMsg: message,
        backgroundColor: type === "error" ? "#fcd3D2" : "#e6eed1",
        color: type === "error" ? "#811211" : "#5e6919",
      })
    } else {
      // await this.setState({
      //   isshowToasterMsg: false,
      //   showToasterMsg: "",
      //   backgroundColor: "#d1fbc1",
      // })
    }
  }

  /**
   * 
   * @param {*} body 
   * @returns Save Data to server(multi records)
   */
  saveNames = (body) => {

    this.setState({ isLoading: true })
    // this.handlePercentage()
    let multiSaveApi = this.props.multiSaveUrl

    return fetchMethodRequest('PUT', `${multiSaveApi}`, body)
      .then(async (response) => {
        if (response && response.respMessage) {
          // await this.setState({ addressnames: response.allResponses, errorResponse: true, errorMessage: response.respMessage, errorcolor: "green" })
          await this.setState({
            isshowToasterMsg: true,
            showToasterMsg: response.respMessage,
            backgroundColor: "#e6eed1",
            color: "#5e6919",
            history: [],
            editedField: "",
            isClickSaved: false,
            selectedBussinessName: ""
          })
          this.newAddedRowlength = 0
          this.actions_presets.enableRedoButton()
          this.actions_presets.enableUndoButton()
          if (this.props.refreshData) {
            this.props.refreshData()
          }
          this.props.toggle(this.state.activeTab, "refresh", "Preset")
        } else if (response && response.errorMessage) {
          this.setState({
            isshowToasterMsg: true,
            showToasterMsg: response.errorMessage,
            backgroundColor: "#fcd3D2",
            color: "#811211",
            isClickSaved: false
          })
        } else if (response && response['errorMessage'] === configMessages.warningMessage) {
          await this.setState({
            sessionWarning: true,
            isClickSaved: false
          })
        }
        await this.setState({ isLoading: false })
      }).catch((err) => {
        return err;
      });
  }

  /**
  *  Adding setclaim details tab open 
  */
  openClaimDetails = async () => {
    await this.props.openClaimDetails()
  }

  /**
   *  Adding new Row to server
   and storing the newly added record in history for undo and redo functionality
   
   */
  addRow = async () => {
    document.getElementsByClassName("active_preset")[0].click()
    this.removeBackgroundstyle()
    this.actions_presets.updateValue(
      {
        testng: "add",
        editedRowField: "",
        editedRowIndex: "",
        editedField: "",
        editedFieldoptions: null,
        editType: "",
        selectedFieldHeader: "",
        editColumnField: ""

        // editField: item.editField ? item.editField : "isDisabled",
        // fieldType: item.fieldType ? item.fieldType : "text"

      }
    )

    let data
    let tableValue = this.state.allTableValue
    data = this.props.newRecordEmptyObj
    if (this.newAddedRowlength) {
      this.newAddedRowlength = this.newAddedRowlength + 1
    } else {
      this.newAddedRowlength = 1
    }
    data.uniqueID = this.newAddedRowlength
    data.isAdded = true

    tableValue.unshift(data)
    let length = tableValue.length
    this.setState({
      allTableValue: JSON.parse(JSON.stringify(tableValue)),
      addRow: true,
      isSubmitted: false,
    })

    // let history = this.state.history
    // let undoArray = this.state.undoArray
    // let obj = { addRowIndex: length + 1, addRowIndexObj: JSON.parse(JSON.stringify(data)) }
    // history.push(obj)
    // undoArray.push(obj)
    // this.setState({ history: history, undoArray: undoArray, })
    // this.actions_presets.enableRedoButton()
    // if (history && history.length > 0) {
    //   // this.actions_presets.disableUndoButton()
    // }
  }

  /**
   *  Delete Data from the db
  and storing the deleted array for undo and redo
   * @param {String} e 
   */

  deleteRow = (e) => {
    let deleteArrayindbandlocal = []
    if (this.state.selectedRows && this.state.selectedRows.length === 0) {
      this.setState({
        isshowToasterMsg: true,
        showToasterMsg: "Error:Please Select the records to delete first",
        backgroundColor: "#fcd3D2",
        color: "#811211",
      })
    } else {
      var deleArrayindb = [], historyDeleteArray = []
      this.closeOrderModal()
      let uniqueField = this.props.uniqueDBField
      for (var obj of this.state.selectedRows) {
        // if (obj && obj.BusinessNamesID.length) {
        deleArrayindb.push(obj[uniqueField] && obj[uniqueField] !== 0 && obj[uniqueField] !== "Auto generated" ? obj[uniqueField] : obj.uniqueID)
        deleteArrayindbandlocal.push(obj)
        historyDeleteArray.push(obj)
      }
      // }
    }
    this.setState({
      isNotSuccess: false,
      deleArray: deleArrayindb,
      historyDeleteArray: historyDeleteArray,
      deleteArrayindbandlocal: deleteArrayindbandlocal,
      isshowToasterMsg: this.state.updatedRecords && this.state.updatedRecords.length > 0 ? true : false,
      showToasterMsg: "Make Sure to save the unsaved changes",
      backgroundColor: "#fcd3D2",
      color: "#811211",
      openDeleteModal: this.state.updatedRecords && this.state.updatedRecords.length > 0 ? false : true,
    })
  }


  /**
   *   selectAllRecords using DOM 
   */
  selectAllRecords = async () => {
    await document.getElementsByClassName("p-checkbox-icon p-clickable")[0].click()
  }


  /**
  *  Duplicate the selected Row under the selected record by changing the unique id
  and added the uniqueID using the length variable
  */
  duplicateSelectRows = async () => {
    let copiedRows = []
    let addedRows = this.state.selectedRows
    let key = "allTableValue"
    let uniqueField = this.props.uniqueDBField
    let userData = JSON.parse(JSON.stringify(this.state[key]))
    for (var obj of addedRows) {
      let index
      if (obj[uniqueField]) {
        index = userData.findIndex(item => item[uniqueField] === obj[uniqueField])
      } else if (obj["uniqueID"]) {
        index = userData.findIndex(item => item["uniqueID"] === obj["uniqueID"])
      }
      let data = obj
      // delete data[uniqueField]
      if (this.props.type === "Names") {
        data[uniqueField] = "Auto generated"
      } else {
        delete data[uniqueField]
      }

      data.DataSourceID = 4
      data.uniqueID = this.state.uniqueID ? this.state.uniqueID + 1 : this.state.allTableValue.length + 1
      data.isAdded = true
      // userData.unshift(data)
      userData.splice(index + 1, 0, data);
      await this.setState({ uniqueID: data.uniqueID })
    }
    await this.setState({ [key]: userData, selectedRows: [], uniqueID: null })
    if (this.editdt) {
      this.editdt.getValueforTable(userData)
    }
  }

  /**
   *   invokes when the user added the value and click in enter
   * @param {Object} e 
   * @param {String} type 
   */
  filteredValues = async (e, type) => {
    if (e && e.key === "Enter" || type === "refresh") {
      if (e) {
        e.preventDefault();
      }
      let filter = this.state.filterCriteria

      // filter['sortfield'] = "BusinessName"
      // filter['direction'] = "asc"
      filter['page'] = 1
      if (e && e.target && e.target.value && e.target.value.length > 0) {
        filter['globalSearch'] = {}
        filter['globalSearch']['value'] = e.target.value
        filter['globalSearch']['type'] = "user"

      } else {
        delete filter['globalSearch']
      }
      await this.setState({ isLoading: true, first: 0, page: 1, filterCriteria: filter })
      this.props.toggle(this.state.activeTab, filter, "Preset")
      await this.setState({ isLoading: false })
    }
    // "globalSearch": {"value": "100", "type": "user" }
  }


  /**
   *  
   * @param {Array} item 
   * @returns Added the highlights when the criteria is added
   */
  rowClassName = (item) => {
    let uniqueField = this.props.uniqueDBField
    // item.IsKey = true
    if (item && this.state.deleArray && this.state.deleArray.length > 0 && !this.state.isNotSuccess && (this.state.deleArray.includes(item.uniqueID) || this.state.deleArray.includes(item[uniqueField]))) {
      return {
        'p-deletedRow': this.state.deleArray.includes(item.uniqueID) || this.state.deleArray.includes(item[uniqueField])
      }
    }
    if (item && this.state.deleArray !== undefined && this.state.deleArray.length > 0 && this.state.isNotSuccess && (this.state.deleArray.includes(item[uniqueField]) || this.state.deleArray.includes(item[uniqueField]))) {
      return {
        'p-errordeletedRow': this.state.deleArray.includes(item[uniqueField] || item.uniqueID)
      }
    }
    if (item && item.isAdded && !this.state.deleArray.includes(item.uniqueID)) {
      return {
        'p-highlighteddarkRow': item.isAdded === true
      }
    }
    if (item && item.isedited) {
      return {
        'p-isEditedRow': item.isedited === "yes"
      }
    }
    if (item && item.isRevoked) {
      return {
        'p-revokedRow': item.isRevoked === "true"
      }
    }
    if (item && item.claimValueComputeColor && item.claimValueComputeColor === "Green") {
      return {
        'p-greenhighlightedRow': item.claimValueComputeColor === "Green"
      }
    } if (item && item.claimValueComputeColor && item.claimValueComputeColor === "Light Brown") {
      return {
        'p-lightBrownhighlightedRow': item.claimValueComputeColor === "Light Brown"
      }
    } if (item && item.claimValueComputeColor && item.claimValueComputeColor === "Brown") {
      return {
        'p-brownhighlightedRow': item.claimValueComputeColor === "Brown"
      }
    }
    if (item && item.followUpColor && item.followUpColor === "Green") {
      return {
        'p-greenFollowhighlightedRowHistory': item.followUpColor === "Green"
      }
    } if (item && item.followUpColor && item.followUpColor === "Yellow") {
      return {
        'p-yellowhighlightedRow': item.followUpColor === "Yellow"
      }
    } if (item && item.followUpColor && item.followUpColor === "Red") {
      return {
        'p-redhighlightedRow': item.followUpColor === "Red"
      }
    }
  }
  /**
   * 
   * @param {Object} props 
   * @param {Array} item 
   * @returns Open the date picker component on edit field date
   */
  getDatePicker = (props, item) => {
    return (
      < >
        <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
          appendTo={document.body}
          monthNavigator={true}
          yearNavigator={true}
          yearRange="1940:2530"
          readOnlyInput={true}
          // minDate={this.props.type.includes("Scheduler") ? new Date() : ""}
          name={item.field}
          placeholder='mm/dd/yyyy'
          disabled={this.props.disabled ? this.props.disabled : false}

          dateFormat="mm/dd/yy"
          value={this.state.calenderDate ?
            this.state.calenderDate :
            props.value[props.rowIndex][item.field] && props.value[props.rowIndex][item.field].length > 0 ?
              new Date(props.value[props.rowIndex][item.field]) : null}
          // value={props.value && props.value[props.rowIndex] && props.value[props.rowIndex][item.field] && (props.value[props.rowIndex][item.field].length >= 10 || props.value[props.rowIndex][item.field].length === undefined) ? new Date(props.value[props.rowIndex][item.field]) : props.value && props.value[props.rowIndex] && props.value[props.rowIndex][item.field] && props.value[props.rowIndex][item.field].length < 11 ? props.value[props.rowIndex][item.field] : ""}
          onChange={(e) => this.onDateChange(props, e, 'type', item)}
          onSelect={(e) => this.onDateChange(props, e, '', item)}
        />
      </>

    )
  }


  /**
   *   invokes when the Date filter applies in the table
   * @param {Object} props 
   * @param {Object} e 
   * @param {String} type 
   * @param {Array} data 
   */
  onDateChange = (props, e, type, data) => {
    let value
    if (e && e.value && !type) {
      this.onEditorValueChange(data, props, e.value)
      this.setState({ calenderDate: e.value })
    } else {
      // let allUsersData = [...props.value];
      // // if (e.value && e.value.length === undefined) {
      // //   value = dateFormats.formatDate(
      // //     e.value,
      // //     "MM/DD/YYYY", "nhg")
      // // } else {
      // //   value = e.target.value && e.target.value.length >= 10 ?
      // //     new Date(e.target.value) :
      // //     e.target.value
      // // }
      // console.log("Hellllll", value)
      // if (e.value && e.value.length > 0) {
      //   console.log("Hello  ", value, e.value)
      //   this.setState({ calenderDate: e.value })
      //   if (e.value && e.value.length === 10) {
      //     let fullDate = new Date(e.value)
      //     console.log("fullDate", fullDate)
      //     this.onEditorValueChange(data, props, fullDate)
      //   }
      // }
    }
  }


  IssueCodeTemplate(option) {
    return (
      <div className="p-clearfix">

        <span style={{ paddingRight: "5px" }}> {option.label}</span>
        <FontAwesomeIcon icon={faQuestionCircle} color="blue" title={option.title} />

      </div>
    );
  }
  colorTemplateCode(option) {
    return (
      <div className="p-clearfix">
        <span style={{ paddingRight: "5px", backgroundColor: option.value === "Light Brown" ? "#FFF2CC" : option.value === "Brown" ? "#FFC7CE" : option.value === "Green" ? "#E2EFDA" : option.value, color: option.value === "Light Brown" ? "black" : "black" }}> {option.label}</span>
      </div>
    );
  }




  /**
   *   Editor for dropdown component that is used for the cell editing
   * @param {Object} props 
   * @param {Array} item 
   * @returns 
   */
  dropDownEditor = (props, item) => {
    return (
      < >
        {props && props.rowData && props.rowData.isAdded && item.isAdd && item.field === "CompanyID" || item.field !== "CompanyID" ?
          <Dropdown
            value={this.state[item.field] ? this.state[item.field] : props.rowData[item.field]}
            appendTo={document.body}
            id={`${props.field}-${props.rowIndex}`}
            options={item.options ? item.options : this.props.nextActionUserArray ? this.props.nextActionUserArray : []}
            optionLabel={item.dataKey ? 'label' : null}
            dataKey={item.dataKey ? item.dataKey : null}
            optionValue={item.dataKey ? "value" : null}        // options={item.field === "crRound" ? selectRoundOptions : item.field === "dealType" ? dealTypes : item.field === "analysts" ? this.state.filteredAnalysts : this.props.businessNames}
            onChange={(e) => this.onEditorValueChange(item, props, e && e.value ? e.value : e.target.value, '', item.options ? item.options : [])}
            // style={{ width: 150 }}
            filter={true}
            disabled={this.props.disabled ? this.props.disabled : false}
          // placeholder={item.header}
          />
          : <span>{props.rowData[item.field]}</span>}
      </>
    )
  }



  /**
  *   Editor for dropdown component that is used for the cell editing
  * @param {Object} props 
  * @param {Array} item 
  * @returns 
  */
  multidropDownEditor = (props, item) => {
    return (
      < >
        {props && props.rowData && props.rowData.isAdded && item.isAdd && item.field === "CompanyID" || item.field !== "CompanyID" ?

          <MultiSelect
            value={this.state[item.field] ? this.state[item.field] : typeof props.rowData[item.field] === "string" ? props.rowData[item.field].split(",") : props.rowData[item.field]}
            appendTo={document.body}
            className={"tableMultiDropdown"}
            id={`${props.field}-${props.rowIndex}`}
            options={item.options ? item.options : []}
            disabled={this.props.disabled ? this.props.disabled : false}
            style={{ height: 33 }}
            itemTemplate={props.field === "IssueName" ? this.IssueCodeTemplate : null}
            // options={item.field === "crRound" ? selectRoundOptions : item.field === "dealType" ? dealTypes : item.field === "analysts" ? this.state.filteredAnalysts : this.props.businessNames}
            onChange={(e) => this.onEditorValueChange(item, props, e.value)}
          // style={{ width: 150 }}
          // filter={true}
          // placeholder={item.header}
          />
          : <span>{props.rowData[item.field]}</span>}
      </>
    )
  }


  /**
   *   invokes when the column drag and drop happens 
   * @param {Object} e 
   */
  onColReorder = (e) => {
    let tablefieldsToShow = this.state.tablefieldsToShow
    let removeObj = tablefieldsToShow[e.dragIndex]
    tablefieldsToShow.splice(e.dragIndex, 1);
    tablefieldsToShow.splice(e.dropIndex, 0, removeObj);
    tablefieldsToShow = tablefieldsToShow
    if (this.actions_presets && this.actions_presets.updateTableFields) {
      this.actions_presets.updateTableFields(tablefieldsToShow)
    }
    this.setState({
      tablefieldsToShow: tablefieldsToShow
    })

  }


  /**
   *   Calender Component that is use for filter in table
   * @param {Array} item 
   * @returns 
   */
  CalenderComponent = (item) => {
    return (
      < >
        <Calendar style={{ zindex: 1004, width: '100%', lineHeight: 1.15, height: '25px' }}
          appendTo={document.body}
          inputClassName={`p-column-filter p-column-${item.field}`}
          name={item.field}
          dateFormat="mm/dd/yy"
          value={this.state[item.field]}
          onSelect={(e) => this.onFilterdateChange(e, item.field)} />
      </>
    )
  }
  // //Custum Date Column Filter Onchange
  onFiltermultiChange = async (e, field, type, dropdown) => {
    let value
    if (!type && !dropdown && e.value !== null) {
      value = e.value.toLocaleDateString('zh-Hans-CN', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    } else {
      value = e
    }
    if (e.length > 0 && !type) {
      let filters = {}, newFil = {}, filteredObj = {};
      newFil[field] = {
        value: value,
        matchMode: dropdown ? dropdown : "string"
      };

      filters[field] = newFil[field]
      filteredObj.filters = filters
      await this.setState({
        [field]: e.value ? e.value : value ? value : ""
      })
      if (value && value.length === 0) {
        this.handleFilterInputField(field, "clear")

      } else {
        await this.setState({
          addedMatchforCompany: true
        })
        //          this.getAdditionalFilter()
        await this.onFilterChange(filteredObj)
        await this.setState({
          addedMatchforCompany: false
        })
      }
    } else {
      await this.setState({
        [field]: e.value !== null ? e.value : e === "string" ? e : ""
      })
      if (value.length === 0) {
        let index = this.state.filterCriteria["criteria"].findIndex(obj => obj.key === type)
        this.state.filterCriteria["criteria"].splice(index, 1)
        // document.getElementById("companySearch").value = ""
        //          this.getAdditionalFilter()
        await this.props.toggle(this.state.activeTab, this.state.filterCriteria, 'filter')

      }
    }
  }

  /**
   * invokes when the date filter applies
   * @param {Object} e 
   * @param {String} field 
   */
  onFilterdateChange = async (e, field) => {
    let value = e.value.toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
    let filters = {}, newFil = {}, filteredObj = {};
    newFil[field] = {
      value: value,
      matchMode: "string"
    };
    filters[field] = newFil[field]
    filteredObj.filters = filters
    await this.setState({
      [field]: e.value
    })
    this.onFilterChange(filteredObj)
  }
  onRowClick(event) {

    this.rowIndex = event.index;
  }

  setFilterCriteriaOnClear = async () => {
    await this.setState({
      filterCriteria: {
        criteria: [{ "key": "ASSET_TRACKING_STATUS", "value": ["Active"], "type": "in", "add_Filter": true }], "sortfield": "updatedAtUtc",
        "direction": "desc"
      },
    })
  }
  /**
   * 
   * @param {String} value 
   * @returns the trimmed value trimming spaces
   */
  trimmedValue = (value, field) => {
    if (value === "true" || value === "false") {
      return JSON.parse(value)
      // return true
    } else {
      let val = value.trim()
      if (val.includes(".") || field === "claimLatestEstimatedValue" || field === "claimEstimatedValueAtFiling" || field === "claimAmountPaid") {
        val = val.replace(/[^0-9.]+/g, '')
        return parseFloat(val)
      }
      return parseInt(val)
    }
  }

  /**
   * on filter search get data from server
   * @param {Object} event 
   * @param {String} type 
   * @param {String} screen 
   */
  // on search get data from server
  onFilterChange = async (event, type, screen) => {
    if (event && event.persist) {
      event.persist();
    }
    this.setState({
      isLoading: true,
      selectedRows: '',
      selectedKey: event.key === "Enter" || event.filters ? true : false,
      isDisabledTab: true,
      isshowToasterMsg: false,
      showToasterMsg: ""
    })
    let fieldName = '', filterCriteria = this.state.filterCriteria ? this.state.filterCriteria : { criteria: [] },
      selectedFilterValue, selectedFilter, selecterFilterType, isDateFilter = false;
    if (event) {
      if (event.filters && !type) {
        if (Object.keys(event.filters) && Object.keys(event.filters)[0]) {
          fieldName = Object.keys(event.filters)[0];
        }
        let field = event.filters;
        selectedFilter = field[fieldName];
        selecterFilterType = this.state.addedMatchforCompany ? selectedFilter.matchMode : document.getElementsByClassName(`p-column-filterMode-${fieldName}`)[0] ? document.getElementsByClassName(`p-column-filterMode-${fieldName}`)[0].value :
          "eq"

        selectedFilterValue = selectedFilter && selectedFilter.value && typeof selectedFilter.value === "string" ? encodeURIComponent(selectedFilter.value) : selectedFilter.value !== "string" ? selectedFilter.value : selectedFilter ? selectedFilter : null;
        if (selecterFilterType === "multi") {//for multiSelect encode is not needed
          selectedFilterValue = selectedFilter.value
        }

        if (selecterFilterType === "nin" || selecterFilterType === "in") {
          selectedFilterValue = "nexists"
        }
        if (event && event.filters && event.filters[fieldName].matchMode === "clear") {//for clearing column filter menu
          selectedFilterValue = null
        }
        if (selectedFilterValue && (fieldName === 'date' || fieldName === 'LastUpdated' || fieldName === 'updated' || fieldName === 'MostRecent10KFormDate' || fieldName === 'created' || fieldName === 'updatedAtUtc' || fieldName.includes("Date") || fieldName.includes("date") && fieldName !== "validatedBy" && field !== "updatedBy" && fieldName.includes('Time'))) {
          if (selecterFilterType !== "nin" && selecterFilterType !== "in") {
            selecterFilterType = "date" + selecterFilterType
            selectedFilterValue = moment(decodeURIComponent(selectedFilterValue)).format("YYYY-MM-DD", "NS");

          }
          isDateFilter = true;
        }
        if (selecterFilterType === "in") {
          selecterFilterType = "nexists"
        } else if (selecterFilterType === "nin") {
          selecterFilterType = "exists"
        }

      } else {
        fieldName = event && event.target && event.target.name ? event.target.name : "globalSearch";
        selectedFilterValue = event
        type = "eq"
      }
      if (selectedFilterValue === null) {
        let index
        let searchField = fieldName === "LegacyConnectedNameLabel" ? "LegacyConnectedName" : fieldName === "LocationNameLabel" ? "LocationName" : fieldName
        index = filterCriteria["criteria"].findIndex(obj => obj.key === searchField && (obj.add_Filter === false || searchField === "claimStatus" || searchField === "claimReason" || searchField === "claimValueComputeColor" || searchField === "propertyStatus" || searchField === "propertyStatusReason"))
        if (index !== -1) filterCriteria.criteria.splice(index, 1);
        filterCriteria.page = "1"
        await this.setState({
          filterCriteria: filterCriteria,
          first: 0,
          page: 1,
          selectFilterValue: "",
        });

        if (this.state.selectedKey) {
          await this.props.toggle(this.state.activeTab, filterCriteria, "", 'filter')
        }
        // }
      } else if ((selectedFilterValue && selectedFilterValue.length && ((fieldName === "globalSearch" && selectedFilterValue.length > 0) || (fieldName !== "globalSearch" && selectedFilterValue.length >= 1)))) {

        if (fieldName === 'globalSearch') {
          await this.setState({
            selectFilterValue: event
          })
          if (filterCriteria) {
            filterCriteria.page = "1"
          }
          this.setState({ first: 0 })
          filterCriteria.globalSearch = {
            value: encodeURIComponent(selectedFilterValue),
            type: this.props.globalSearchFieldName
          };
          if (event && event.target && event.target.value && event.target.value.length === 0) {
            delete filterCriteria.globalSearch;
          };
        } else {
          if (filterCriteria && filterCriteria.page) {
            filterCriteria.page = 1
          }
          let searchField = fieldName === "LegacyConnectedNameLabel" ? "LegacyConnectedName" : fieldName === "LocationNameLabel" ? "LocationName" : fieldName

          if (filterCriteria['criteria'] && filterCriteria['criteria'].length === 0) {
            filterCriteria['criteria'].push({
              key: searchField,
              value: event.filters[fieldName].matchMode === "num" && !isDateFilter && selectedFilterValue !== "nexists" && selectedFilterValue !== "exists" ? this.trimmedValue(decodeURIComponent(selectedFilterValue), fieldName) : selectedFilterValue === "nexists" && selectedFilterValue === "exists" && selecterFilterType === "nexists" && selecterFilterType === "nexists" ? "" : selectedFilterValue,
              // value: [""],
              type: selecterFilterType === "multi" ? "in" : selecterFilterType,
              add_Filter: this.state.addedMatchforCompany ? true : false,
              claimBookmarkStatus: false
            });
          } else {
            let obj, index;
            index = filterCriteria["criteria"].findIndex(obj => obj.key === searchField && (obj.add_Filter === false || searchField === "propertyStatus" || searchField === "propertyStatusReason"))
            if (index !== -1) {
              filterCriteria['criteria'].splice(index, 1)
            }
            filterCriteria['criteria'].push({
              key: searchField,//for claims we need add .note
              value: event.filters[fieldName].matchMode === "num" && !isDateFilter && selectedFilterValue !== "nexists" && selectedFilterValue !== "exists" ? this.trimmedValue(decodeURIComponent(selectedFilterValue), fieldName) : selectedFilterValue === "nexists" && selectedFilterValue === "exists" && selecterFilterType === "nexists" && selecterFilterType === "nexists" ? "" : selectedFilterValue,
              type: selecterFilterType === "multi" ? "in" : selecterFilterType,
              add_Filter: this.state.addedMatchforCompany ? true : false,
              claimBookmarkStatus: false
            });

            // }
          }
          await this.setState({
            filterCriteria: filterCriteria,
            page: 1,
            first: 0
          });
        }
        // if (this.state.selectedKey) {

        this.state.filterCriteria["criteria"] = [...this.state.filterCriteria["criteria"]]

        if (this.state.selectedKey) {

          await this.props.toggle(this.state.activeTab, filterCriteria, "", 'filter')
        }        // }
      } else {
        if (selectedFilterValue.length === undefined && !isDateFilter) {
          let obj = filterCriteria.criteria.find(x => x.key === fieldName && x.add_Filter === false);
          let index = filterCriteria.criteria.indexOf(obj);
          filterCriteria.criteria.splice(index, 1);
          // }

          filterCriteria.page = "1"
          await this.setState({
            filterCriteria: filterCriteria,
            page: "1",
            first: 0,
            selectFilterValue: ""
          });

          if (this.state.selectedKey) {
            await this.props.toggle(this.state.activeTab, filterCriteria, "", 'filter')
          }
        }

      }
      this.setState({
        isLoading: false,
        progress: 0
      })
    }
  }

  /**
   * 
   * @param {String} type 
   * @returns 
   */
  setLoading = async (type) => {
    if (!type) {
      await this.setState({ isLoading: true })
    } else {
      await this.setState({ isLoading: false })

    }
  }

  getFormattedCurrencyValue = () => {
    let dollarUS = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: config.currency,
    });
    return dollarUS
  }
  changeNote = (Comments) => {
    this.setState({
      isnotesModal: true,
      Comments: Comments
    })
  }
  getNotesDetails(val, column, data, index) {
    let emptyNoteCheck = false
    for (let note of data) {
      if (note.note !== null) {
        emptyNoteCheck = true
      }
    }
    return (
      <div style={{ display: "flex", width: column.bodyStyle.width }}>
        <div className="d-flex" style={{ width: 50 }}>
          {val.length > 0 && emptyNoteCheck ?
            <span className="text-right" style={{ display: "flex" }}>
              {/* <FontAwesomeIcon icon={faCopy}
                            className='pl-1'
                            data-toggle="tool-tip"
                            // validate={[required]}
                            title="click to copy this Notes"
                            style={{ width: 20, color: "red" }}
                            onClick={() => this.clipToCopyBoard(val[0].props.title)}
                        /> */}
              <FontAwesomeIcon icon={faExternalLinkAlt}
                className='pl-1'
                data-toggle="tool-tip"
                // validate={[required]}
                title="click to see all Notes"
                style={{ width: 20, color: "grey" }}
                onClick={() => this.changeNote(data)}
              />
            </span>
            : null}
        </div>
        <div style={val.length > 0 ? { display: "flex", width: column.bodyStyle.width - 50 } : {}}>
          <span className="kanban_title" data-toggle="tool-tip"
            title={val}
          >
            {val}
          </ span >
        </div>

      </div>)
  }
  flattenArray = (arrayVal, item) => {
    let val = '';
    let arrLength = ""
    if (arrayVal) {
      val = JSON.stringify(arrayVal);
      if (val) {
        val = arrayVal.map((arrayItem) =>
          arrayItem !== null ?
            <p data-toggle="tool-tip"
              title={arrayItem.note}>{arrayItem.note}</p>
            : null
        );
      }
    }

    return val;
  }

  getTableFieldItem(field) {
    for (let i = 0; i < this.state.tablefieldsToShow.length; i++) {
      if (this.state.tablefieldsToShow[i].field === field) {
        return this.state.tablefieldsToShow[i];
      }
    }
    return null;
  }
  /**
   * @param {Object} item 
   * @param {String} column 
   * @returns styles and changes for the span 
   */
  changeFieldValues = (item, column) => {
    let requiredFields

    let requiredFieldsArray = [...this.props.anyOneField, ...this.props.requiredFields]
    requiredFields = requiredFieldsArray;

    let tableItem = this.getTableFieldItem(column.field);

    if (column.field === "issueNote" || column.field === "IssueNote" || column.field === "NoteText" || column.field === "GeneralNote") {
      let htmlString = item[column.field];
      return <div data-toggle="tool-tip"
        dangerouslySetInnerHTML={{ __html: htmlString }}
        title={htmlString} id={`${column.field}-${column.rowIndex}`}>

      </div>
    } else if (tableItem.fieldType === "dateOnly") {
      return dateFormats.formatDate(item[column.field], "MM/DD/YYYY");
    } else if (tableItem.fieldType === "NoteArray") {
      let val;
      val = this.flattenArray(item[column.field]);
      if (tableItem.openNotes && item[column.field]) {
        let data = this.getNotesDetails(val, column, item[column.field])
        return <>{data}</>
      } else {
        return <span style={tableItem.style} data-toggle="tool-tip"
          title={val}
        >
          {val}
        </ span >
      }

    }
    else if (tableItem.fieldType === "Array") {
      return <div id={`${column.field}-${column.rowIndex}`}
        className={requiredFields.includes(column.field) && (item[column.field] === "" || item[column.field] === null) && (item.isedited || item.isAdded) ? "isRequired" : ""}>{item[column.field] && item[column.field].length > 0 ? item[column.field].toString() : item[column.field]}</div>
    }
    else if (tableItem.fieldType === "formatCurrency") {
      let dollarUS = this.getFormattedCurrencyValue()
      let val
      if (item[column.field]) {
        item[column.field] = JSON.parse(item[column.field])
        val = item[column.field].toFixed(0)
        val = dollarUS.format(val)
        val = val.split(".")[0]
      } else {
        val = item[column.field]
      }
      return <span style={(column.field === "PropertyKnownMaxValueAmountSum" || column.field === "PropertyEstimatedMaxValueAmountSum") && this.props.type === "UP Opportunity – V2" && item["EstateName"] === "Total" ? { fontWeight: "bold" } : {}}>
        {item[column.field] !== undefined && item[column.field] !== null && item[column.field] !== "" && val !== "$NaN" ? val : "N/A"}
      </span >
    } else if (tableItem.fieldType === "formatCurrencywithdec") {
      let dollarUS = this.getFormattedCurrencyValue()
      let val
      if (item[column.field] !== undefined && item[column.field] !== null && item[column.field] !== "") {
        item[column.field] = JSON.parse(item[column.field])
      }
      val = dollarUS.format(item[column.field])
      // let val = item[column.field]
      return <span style={(column.field === "PropertyKnownMaxValueAmountSum" || column.field === "PropertyEstimatedMaxValueAmountSum") && this.props.type === "UP Opportunity – V2" && item["EstateName"] === "Total" ? { fontWeight: "bold" } : {}}>
        {item[column.field] !== undefined && item[column.field] !== null && item[column.field] !== "" && val !== "$NaN" ? val : "N/A"}
      </span >
    }

    else if (column.field === "NoteDate" || column.field === "SOSLastFilingDate" || column.field === "DateCheckedforSOSLastFilingDate" || column.field === "DateCheckedforSOSOfficers" || column.field === "DatePreSalesUPRefresh" || column.field === "DateIntegrityPreSaleResearch" || column.field === "DateIntegrityPostSaleResearch" || column.field === "IncorporationDate" || column.field === "InactivityDate") {
      let x = dateFormats.formatDate(item[column.field], "MM/DD/YYYY");
      return <div id={`${column.field}-${column.rowIndex}`}>{x}</div>
    }
    else if (tableItem.fieldType === "MultiColors") {
      let val = ""
      if (item) {
        val = item[column.field] === "Green" ? "Actual" : item[column.field] === "Brown" ? "Actual + Estimated" : item[column.field] === "Light Brown" ? "Estimated" : "No Connected Properties"
      }
      return <span>
        {val ? val : null}
      </span>
    }
    else if ((column.field === "BusinessName" || column.field === "EntityName") && item.IsKey) {
      return <div style={{ fontWeight: "bold" }} id={`${column.field}-${column.rowIndex}`}>
        {item[column.field]}
      </div >
    } else if ((column.field === "NameAIStatus" || column.field === "IsKey" || column.field === "IsHeadquarters" || column.field === "LegacyIsComplete" || column.field === "LegacyIsUseless" || column.field === "LegacyGroomingFlag")) {
      if (item[column.field]) {
        return <div id={`${column.field}-${column.rowIndex}`}>
          {"Yes"}
        </div >
      } else {
        return <div id={`${column.field}-${column.rowIndex}`}>
          {"No"}
        </div >
      }

    } else if (tableItem.fieldType === "Actions") {
      if (this.props.getActions) {
        return this.props.getActions(item);
      }
    } else if (tableItem.fieldType === 'checkBox') {
      return <input type='checkbox' style={{ height: 'unset' }} onChange={(e) => { this.onCheck(item) }} class='cudtom-checkbox-cm' id={`cb-${item._id}`} />
    }
    else {
      return <div id={`${column.field}-${column.rowIndex}`}
        //  style={item["followUpColor"] && item["followUpColor"] === "Green" && tableItem.fieldColor ? { background: '#74ca88' } : item["followUpColor"] && item["followUpColor"] === "Yellow" && tableItem.fieldColor ? { background: '#f3f383' } : item["followUpColor"] && item["followUpColor"] === "Red" && tableItem.fieldColor ? { background: '#FDEFE7' } : null}
        className={requiredFields.includes(column.field) && (item[column.field] === "" || item[column.field] === null) && (item.isedited || item.isAdded) ? "isRequired" : ""}>
        {item[column.field]}</div>
    }

    // }

  }

  /**
   *  the selectedRows for the table
   * @param {Object} event 
   */
  onSelectRowsUpdate = async (event) => {
    if (this.props.showPreset && this.props.showPreset === "true") {
      this.props.setRowCount(event.value)
    } else {
      this.actions_presets.updateValue(
        {
          testng: "selectrous",
          editedRowField: "",
          editedRowIndex: "",
          editedField: null,
          editedFieldoptions: null,
          editType: "text",
          selectedFieldHeader: "",
          editColumnField: ""

        })
    }
    await this.setState({ selectedRows: event.value })
    if (this.props.type === "ClaimsManagement") {
      this.props.setEditedRows(event.value)
    }
    // await this.getActions()
  }
  /**
  *  the selectedRows for the table
  * @param {Object} event 
  */
  onSelectRowsUpdateForClaims = async (event) => {
    this.actions_presets.updateValue(
      {
        testng: "selectrous",
        editedRowField: "",
        editedRowIndex: "",
        editedField: null,
        editedFieldoptions: null,
        editType: "text",
        selectedFieldHeader: "",
        editColumnField: ""

      })
    await this.setState({ selectedRows: event.value })
    this.props.setEditedRowsEmpty()

    // await this.getActions()
  }

  /**
   *   column Hide and show functionality from the header option
   * @param {String} val 
   * @param {String} field 
   */
  columnHandlingfromHeader(val, field) {
    let columns = this.state.Preferences
    let index = columns && columns.findIndex(obj => obj.field === field)
    if (val === "hide") {
      if (index !== -1) columns[index].show = columns[index].show === true ? false : true;
    }
    this.getTableFieldsOrder(columns)
    this.setState({
      isLoading: false, Preferences: columns

    })
    this.actions_presets.updateTableFields(columns)
  }

  /**
   *   column Hide and show functionality from the header option
   */
  openShowHideColumnsModal() {
    this.setState({
      isOpenShowHideColumnsModal: true,
    })
  }


  /**
   * invokes when the option is selected from the header
   * @param {Object} e 
   * @param {String} field 
   */
  handleDataChangingOption = async (e, field) => {
    if (e && e.selectedOption) {
      if (e.selectedOption === "unsort" || e.selectedOption === "asc" || e.selectedOption === "desc") {
        await this.sortChange(e)
      } else if (e.selectedOption === "hide") {
        await this.columnHandlingfromHeader(e.selectedOption, e.sortField)
      } else if (e.selectedOption === "show") {
        await this.openShowHideColumnsModal()
      } else if (e.selectedOption === "filter") {
        await this.handleFilterInputField(e.sortField)
        // this.AddingFilterInput(e.selectedOption, field)
      } else if (e.selectedOption === "clear_filter") {
        await this.handleFilterInputField(e.sortField, "clear")
        // this.AddingFilterInput(e.selectedOption, field)
      }
    }
  }


  /**
   * invokes when unsort,sort by asc,sort by desc
   * @param {Object} event 
   */
  sortChange = (event) => {
    this.setState({ selectedRows: '' })
    let sortCount = this.state.sortCount;
    if (event && event['sortField']) {
      sortCount = sortCount === 0 ? sortCount + 1 : 0;
      let sortField = event.selectedOption === "unsort" ? this.props.defSortField : event['sortField'];
      let filterCriteria = this.state.filterCriteria;
      filterCriteria['direction'] = event.selectedOption === "unsort" ? "asc" : event.selectedOption;
      filterCriteria['sortfield'] = sortField;

      this.setState({
        sortCount: sortCount,
        filterCriteria: filterCriteria,
        isshowToasterMsg: false,
        showToasterMsg: ""
      });
      this.props.toggle(this.state.activeTab, filterCriteria, "Preset", "Sort")
      // this.getActivities('', filterCriteria, { key: "fieldName", value: "selectedFilterValue" });
    }
  }

  /**
   *   change the style display show and none when the filter is clicked and clear filter is selected
   * @param {String} val 
   * @param {String} type 
   */
  handleFilterInputField(val, type) {
    let filters = {}, newFil = {}, filteredObj = {};
    if (type) {
      newFil[val] = {
        value: "",
        matchMode: "clear"
      };
      filters[val] = newFil[val]
      filteredObj.filters = filters
      let value = this.state.activeTab ? this.state.activeTab + val : val
      let data = document.getElementsByClassName(`p-column-filter-${value}`)
      if (data[0] && data[0].getElementsByClassName(`p-column-filter`)[0]) {
        data[0].getElementsByClassName(`p-column-filter`)[0].value = ""
      }
      document.getElementsByClassName(`p-column-filter-${value}`)[0].style.display = "none"
      if (this.state.activeTab === "5" && document.getElementsByClassName(`p-column-filter-${value}`)[1] !== undefined) {
        document.getElementsByClassName(`p-column-filter-${value}`)[1].style.display = "none"

      }
      this.setState({
        [val]: "",
      })

      document.getElementsByClassName(`p-column-filterMode-${val}`)[0].selectedIndex = 0
      document.getElementsByClassName(`p-column-filter-${value}`)[0].style.display = "none"
      this.onFilterChange(filteredObj)
    } else {
      let value = this.state.activeTab ? (this.state.activeTab.toString() + val) : val
      if ((document.getElementsByClassName(`p-column-filter-${value}`) && document.getElementsByClassName(`p-column-filter-${value}`)[0]) !== undefined) {
        if (document.getElementsByClassName(`p-column-filter-${value}`)[0].style.display === "none") {
          document.getElementsByClassName(`p-column-filter-${value}`)[0].style.display = "block"
          if (this.state.activeTab === "5" && document.getElementsByClassName(`p-column-filter-${value}`)[1] !== undefined) {
            document.getElementsByClassName(`p-column-filter-${value}`)[1].style.display = "block"

          }
          // if (val === "claimValueComputeColor") {

          // }


        } else if (document.getElementsByClassName(`p-column-filter-${value}`)[0].style.display === "block") {
          document.getElementsByClassName(`p-column-filter-${value}`)[0].style.display = "none"
          if (this.state.activeTab === "5" && document.getElementsByClassName(`p-column-filter-${value}`)[1] !== undefined) {
            document.getElementsByClassName(`p-column-filter-${value}`)[1].style.display = "none"
          }
        }
      }

    }
  }
  /**
   * 
   * @param {string} edited 
   * function to save the note is edited or not for slecting and unselecting records
   */
  commentEditedOrNo = async (edited) => {
    await this.setState({ edited: edited })
  }
  clearDropDownOptions = async () => {
    let deleteData = {};
    await this.state.tablefieldsToShow.forEach(column => {
      if (['Date', 'dateTime', 'multiSelect'].includes(column.fieldType || column.filterType)) {
        deleteData[column.field] = column.filterType === "multiSelect" ? [] : null;
      }
    });
    this.setState({
      ...deleteData,
      filterCriteria: { criteria: [] },
      propertyStatus: "",
      propertyStatusReason: "",
      claimStatus_LastCheckedDate: "",
      claimStatus_LastActivityDate: "",
      followUpDate: "",
      followUpCompletedDate: ""
    })
  }

  /**
   * to remove datatable all Filters 
   * @param {String} type 
   */
  getremoveFilters = async (type) => {
    await this.setState({
      clearFilters: true,
      page: 1,
      first: 0,
      isshowToasterMsg: false,
      showToasterMsg: ""
    })
    let filteredNo = document.getElementsByClassName("p-column-filterComponent").length;
    for (let i = 0; i <= filteredNo; i++) {
      if (document.getElementsByClassName("p-column-filter")[i] && document.getElementsByClassName("p-column-filter")[i].value)
        if (document.getElementsByClassName("p-column-filter")[i].value !== "") {
          document.getElementsByClassName("p-column-filter")[i].value = ""
          let getName = document.getElementsByClassName("p-column-filter")[i].name
          this.setState({
            [getName]: ""
          })
        }
      if (document.getElementsByClassName("p-column-filterComponent")[i] && document.getElementsByClassName("p-column-filterComponent")[i].style.display === "block") {
        document.getElementsByClassName("p-column-filterComponent")[i].style.display = "none"
      }
    }
    this.setState({
      claimStatus: "",
      claimReason: "",
      propertyStatus: "",
      propertyStatusReason: "",
      claimValueComputeColor: ""

    })
    //removing inline filters
    let filterCriteria = this.state.filterCriteria
    var newArr = filterCriteria["criteria"].filter(object => {
      return object.add_Filter === true;
    });
    let index;
    index = newArr.findIndex(obj => obj.key === "claimStatus" && obj.add_Filter === true)
    if (index !== -1) {
      newArr.splice(index, 1)
    }
    let indexReason;
    indexReason = newArr.findIndex(obj => obj.key === "claimReason" && obj.add_Filter === true)
    if (indexReason !== -1) {
      newArr.splice(indexReason, 1)
    }
    let indexColor;
    indexColor = newArr.findIndex(obj => obj.key === "claimValueComputeColor" && obj.add_Filter === true)
    if (indexColor !== -1) {
      newArr.splice(indexColor, 1)
    }
    let indexStatus;
    indexStatus = newArr.findIndex(obj => obj.key === "propertyStatus" && obj.add_Filter === true)
    if (indexStatus !== -1) {
      newArr.splice(indexStatus, 1)
    }
    let indexStatusReason;
    indexStatusReason = newArr.findIndex(obj => obj.key === "propertyStatusReason" && obj.add_Filter === true)
    if (indexStatusReason !== -1) {
      newArr.splice(indexStatusReason, 1)
    }
    filterCriteria["criteria"] = newArr
    filterCriteria["page"] = 1
    await this.setState({
      filterCriteria: filterCriteria,
      page: 1,
      first: 0
    })
    if (this.props.refreshData) {
      this.props.refreshData()
    }
    this.props.toggle(this.state.activeTab, filterCriteria, "Preset", type)
  }

  MultiSelectComponent(item, delta) {
    return (
      <div className='customColor' >
        <MultiSelect
          value={this.state[item.field]}
          appendTo={document.body}
          options={item.field === "claimStatus" ? this.props.claimStatusArray : item.field === "claimReason" ? this.props.claimReasonArray : item.field === "claimValueComputeColor" ? this.props.colorValues : item.field === "propertyStatus" ? this.props.propertyStatusArray : item.field === "propertyStatusReason" ? this.props.propertyStatusReasonArray : []}
          style={{ width: item.width - 55, marginRight: 3 }}
          maxSelectedLabels={2}
          filter={true}
          itemTemplate={item.field === "claimValueComputeColor" ? this.colorTemplateCode : null}
          // options={item.field === "crRound" ? selectRoundOptions : item.field === "dealType" ? dealTypes : item.field === "analysts" ? this.state.filteredAnalysts : this.props.businessNames}
          onChange={(e) => this.setState({ [item.field]: e.value })}
        />

        <FontAwesomeIcon
          color='green'
          icon={faCheckCircle}
          data-toggle="tool-tip"
          className='my-2 mx-0'
          onClick={(e) => this.onFiltermultiChange(this.state[item.field], item.field, "", "multi")}
        />
        {/* <Select /> */}
        <div>
        </div>
      </div>)
  }


  getColumns(e, d) {
    const { t } = this.props
    const self = this;
    self.e = e;
    self.d = d;
    let tablefieldsToShow = this.state.tablefieldsToShow
    if (tablefieldsToShow && tablefieldsToShow.length > 0) {
      return tablefieldsToShow.map((item, i) => {
        let column = (item.show &&
          <Column
            key={item.field + i}
            id={item.field + i}
            expander={item.field === "expander" ? true : false}
            style={{
              maxwidth: item.width,
              padding: 2,
            }}
            onEditorSubmit={(e) => this.onEditorSubmit(e, item)}
            onEditorFocus={(e) => this.onEditorFocus(e, item, i)}
            editor={this.props.nonEditable || item.fieldType === 'checkBox' ? null : (props) => (this.props.type === "followUpNumber" && !props.rowData["mostRecentFlag"]) ? props.rowData[item.field] : this.editorbasedField('allTableValue', props, item)}
            onColReorder={this.onColReorder}
            columnKey={item.field}
            item={item}
            bodyStyle={{
              textOverflow: item.field === "expander" ? 'none' : 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',

              textAlign: item.field === 'status' || item.field === 'role' ? 'center' : item.textAlign
            }}
            field={item.field}
            header={item.fieldType === 'checkBox' ? this.getCheckBox() : (item.header)}
            columnMandatory={this.props.type === "Names" && item.field === "LegacyConnectedNameLabel" && (item.options && item.options.length === 0 || item.options === undefined) ? false : item.required ? item.required : false}
            activeTab={this.state.activeTab}
            body={self.changeFieldValues}
            headerStyle={{
              padding: "6px 15px", fontWeight: 500, width: item.width, fontSize: 13,
              color: config.blackColor, backgroundColor: config.templateColor, textAlign: 'left'
            }}

            shownField={this.state.activeTab}
            dataChangeOptions={item.filter ? this.state.dataChangeOptionswithFilter : this.state.dataChangeOptionswithoutFilter}
            filterMatchMode={item.filterType === "num" ? "num" : "string"}
            filter={item.filter ? item.filter : false}
            filterElementnonText={item.fieldType === 'Date' || item.fieldType === 'dateTime' ? this.CalenderComponent(item) : item.filterType === "multiSelect" ? this.MultiSelectComponent(item) : null}
            filterElement={
              item.filterType === "dropdown" || item.filterType === "multiSelect" ?
                <select name="viewroom" id="viewroom" className={`mb-2 p-1 p-column-filterMode p-column-filterMode-${item.field}`} style={{ width: "100%" }} >
                  {item.filterType === "dropdown" ? <option value="eq">Equals</option> : <option value="multi">Includes</option>}
                </select> :
                item.filter && (item.filterType === "num" || item.fieldType === "dateTime") ?
                  <select className={`mb-2 p-1 p-column-filterMode p-column-filterMode-${item.field}`} style={{ width: "100%" }} onChange={(e) => this.onSeletFilterType(item.field)}>
                    <option value="eq">Equals</option>
                    <option value="lt">Less Than</option>
                    <option value="gt">Greater Than</option>
                    <option value="lte">Less Than or Equals</option>
                    <option value="gte">Greater Than or Equals</option>
                    {/* <option value="nin">Exists</option>
                  <option value="in">Not Exists</option> */}
                  </select> : item.filter && item.filterType !== "num" ?
                    <select className={`mb-2 p-1 p-column-filterMode p-column-filterMode-${item.field}`} style={{ width: "100%" }} onChange={(e) => this.onSeletFilterType(item.field)}>
                      <option value="regexOr">Includes</option>
                      <option value="eq">Equals</option>
                      <option value="sw">Starts With</option>
                      <option value="ew">Ends With</option>
                      <option value="nregexOr">Excludes</option>
                      {/* <option value="nin">Exists</option>
                    <option value="in">Not Exists</option> */}
                    </select>
                    : null
            }
            isAllowInt={item.allowInt ? true : false}
            sortable={item.sortable ? true : false}
            filterPlaceholder={item.placeholder ? item.placeholder : ''}
            selectionMode={item.selectionMode}
          />
        )
        return column;
      })
    }
  }

  getCheckBox = () => {
    return <input type='checkBox' style={{ height: 'unset' }} onChange={() => { this.onSelectAll() }} checked={this.state.selectedAll} />
  }

  /**
   * 
   * @returns function to call Datatable file
   */
  getDataTable() {
    let self = this;
    return (
      <div className={`card pb-0 ${this.props.table_name ? this.props.table_name : ""}`}>
        <DataTable
          ref={(el) => this.dt = el}
          metaKeySelection={false}
          value={this.state.allTableValue}
          totalRecords={this.state.totalRecordsLength}
          paginator={false}
          lazy={true}
          dataKey={!this.state.expandAll ? "" : "DealID"}
          resizableColumns={true}
          columnResizeMode="expand"
          onColReorder={this.onColReorder}
          onSort={this.handleDataChangingOption}
          // onSort={this.sortChange}
          globalFilter={this.state.globalFilter}
          rowClassName={this.rowClassName}
          onFilter={this.isConfirmFilter}
          // onRowClick={(e) => this.onRowClick(e)}
          scrollable={true}
          // selection={false}
          scrollHeight={this.props.scrollHeight ? this.props.scrollHeight : "1000px"}
          emptyMessage={"No Records"}
          sortMode="single"
          reorderableColumns={this.props.reorderableColumns === "false" ? false : true}
          // sortField="fname"
          // sortOrder={-1}
          // selectionMode={'multiple'}
          onSelectionChange={(e) => this.onSelectRowsUpdate(e)}
          selection={this.state.selectedRows}
          loading={this.state.isLoading}
          style={this.state.activitiesData && this.state.activitiesData.length === 0 ?
            { textAlign: 'center', marginTop: 12 }
            : { marginTop: `${this.props.type === "ClaimsManagement" ? '0.25rem' : '12px'}` }}
        >
          {self.getColumns()}
        </DataTable>
      </div>
    )
  }
  onScroll = (element) => {
    console.log(element.scrollTop, element)
  }


  /**
   * claims manager select all checkbox functionality
   */
  onSelectAll = (clickByIcon) => {
    let checked = false;
    if (clickByIcon) {
      this.props.setEditedRows([]);
      this.checkedItems = {};
      this.setState({ selectedAll: false })
    }
    else if (!this.state.selectedAll) {
      this.props.setEditedRows(this.allClaimsData);
      this.allClaimsData.forEach((item) => {
        this.checkedItems[item._id] = item;
      });
      checked = true;
    } else {
      this.props.setEditedRows([]);
      this.checkedItems = {};
    }
    this.selectOrUnSelectAllCheckBox(checked);
    if (!clickByIcon) {
      this.setState(prevState => ({ selectedAll: !prevState.selectedAll }));
    }
    this.actions_presets.updateValue(
      {
        testng: "selectrous",
        editedRowField: "",
        editedRowIndex: "",
        editedField: null,
        editedFieldoptions: null,
        editType: "text",
        selectedFieldHeader: "",
        editColumnField: ""

      })
  }

  selectOrUnSelectAllCheckBox = (checked) => {
    let elements = document.getElementsByClassName('cudtom-checkbox-cm');
    if (elements && elements.length > 0) {
      elements.forEach((elem) => {
        elem.checked = checked;
      })
    }
  }

  /**
   * claims manager checkbox functionality.
   * @param {Objet} item - row data
   */
  onCheck = async (item) => {
    if (this.state.edited === "yes") {
      //to open model if note is edited and trying to close by click on icon
      await this.setState({ openUnsaveModal: true, selectedEvent: item })
      return
    }
    // if (Object.keys(this.checkedItems).length === 0) {
    //   var index = this.state.allTableValue.findIndex(a => a._id === item._id)
    //   setTimeout(() => {
    //     this.tBody.scrollTop = index > 10 ? Math.ceil((index + 1) * 34) : Math.ceil((index) * 34)
    //   }, 1000);
    // }
    let elem = document.getElementById(`cb-${item._id}`);
    if (Object.keys(this.checkedItems).length === 0) {
      setTimeout(() => {
        elem.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }, 500);
    }
    if (this.checkedItems[item._id]) {
      if (elem) {
        elem.checked = false;
      }
      delete this.checkedItems[item._id];
      if (this.state.selectedAll === true) {
        this.setState({ selectedAll: false });
      }
    } else {
      if (elem) {
        elem.checked = true;
      }
      this.checkedItems[item._id] = item;
    }
    if (Object.values(this.checkedItems).length <= 2) {
      this.props.setEditedRows(Object.values(this.checkedItems));
    }

    this.actions_presets.updateValue(
      {
        testng: "selectrous",
        editedRowField: "",
        editedRowIndex: "",
        editedField: null,
        editedFieldoptions: null,
        editType: "text",
        selectedFieldHeader: "",
        editColumnField: ""

      });

  }

  getSelectedTable = () => {
    return Object.values(this.checkedItems);
  }



  render() {
    return (
      <div >
        <Loader loader={this.state.isLoading} className="screen-loader" />
        <span >{!this.props.hidePreset && this.getPresetBtnActions()}</span>
        {this.props.type !== "ClaimsManagement" ?
          <span className="px-4 my-2" style={this.state.isshowToasterMsg ? {
            backgroundColor: this.state.backgroundColor, display: "flex", justifyContent: "center", height: 25, color: this.state.color,
            width: "fit-content", marginLeft: "auto", marginRight: "auto",
          } : { height: 40 }}>
            {this.state.isshowToasterMsg ? this.state.showToasterMsg : ""}</span>
          : null}
        {this.state.maxCondition ?
          <span style={{
            display: "flex", justifyContent: "center", height: 25,
            width: "fit-content", marginLeft: "auto", marginRight: "auto",
          }} className='errorbgColor errorTextColor px-3'>
            {this.state.maxMinCondition}
          </span>
          : null}
        {this.state.openDeleteModal ?
          <div className="row px-2 mt-2" style={this.state.openDeleteModal ? { backgroundColor: "#fcd3D2", color: "#811211", display: "flex", justifyContent: "center", height: 30, width: "fit-content", marginLeft: "auto", marginRight: "auto", alignItems: "center" } : { display: "none" }}>
            <span>Are you sure you want to delete the selected row(s)?</span>
            <FontAwesomeIcon icon={faCheckCircle}
              className='pl-1 '
              color='green'
              data-toggle="tool-tip"
              title="click to delete"
              style={{ float: "right", height: 24, width: 24 }}
              onClick={() => this.deleteSelectedRow()}
            />
            <FontAwesomeIcon icon={faTimes}
              className='pl-1 ml-2'
              color='red'
              data-toggle="tool-tip"
              title="remove field"
              style={{ float: "right", height: 20, width: 20 }}
              onClick={() => this.closeOrderModal()}
            />
          </div>
          : null}
        <div className="datatable-editing-demo">

          {this.props.type === "ClaimsManagement" || this.props.showAbovePage ?
            <div className="mt-1 p-1 d-flex justify-content-end bg-white">
              {this.getPaginator()}
            </div>
            :
            null}
          {this.getDataTable()}
          {this.props.type === "ClaimsManagement" || this.props.dontShowBelowPage ? null : this.getPaginator()}
        </div>
        {this.state.isopenColumnModal ?
          this.getConfirmaionModel() : null
        }
        {this.state.isnotesModal ? this.getNotesModal() : null}
        {this.state.expiredSessionModal ? this.expiredSessionModal() : null}
        {this.state.downloadSheet ? this.getExcelDownload() : null}
        {this.state.openUnsaveModal ? this.getDeleteRowModalForNotes() : null}
        {this.state.isOpenShowHideColumnsModal ? this.getOpenShowHideColumnsModal() : null}
        {this.state.isApplyFilter ? this.getConfirmaionModelforFilter() : null}
        <AddressFields
          onRef={(ref) => this.addressFieldsref = ref}

        />
        <NamesFields
          onRef={(ref) => this.namesFieldsref = ref}
          dimSellerId={this.props.selectedDealID}
        />
        {/* <ClaimManagementNew
          onRef={(ref) => this.claimManagementNewref = ref}
        /> */}
      </div>
    );
  }
}