import React, { useEffect, useRef, useState } from "react";
import { Dialog } from 'primereact/dialog';
import { NotesPopup } from "./NotesPopup";
import { Button } from 'reactstrap';
import { Checkbox } from 'primereact/checkbox';
import dateFormats from '../../../UI/FormatDate/formatDate';
import * as documentsAPIs from '../../../../shared/documentsApis';
import { AddressEvid } from '../../DocumentUpload/components/AddressEvid';
import { FillNotesPopup } from "./FillNotesPopup";
import { promises } from "dns";

interface props  {
    showSelection : boolean,
    hideSelection : Function,
    selectedCategory:string,
    documentList: Array<any>,
    getDocumentsData:Function,
    getChainOfTitle:Function,
    updateBypassedCat:Function,
    updateAlreadyLoadedSecCategories:Function,
    reloadCategory:Function,
    loadedDocuments:any,
    onClickDelete:Function,
    selCotFiles:Array<any>
    
}

export const DocumentSelectPopup = ({ showSelection, hideSelection, selectedCategory, documentList,getDocumentsData,getChainOfTitle,loadedDocuments,updateBypassedCat,updateAlreadyLoadedSecCategories,reloadCategory,onClickDelete,selCotFiles }: props) => {
    const [selectionData, setSelectionData] = useState<Array<any>>([]);
    const [showNotes, setShowNotes] = useState<boolean>(false);
    const [selectedFiles, setSelectedFiles] = useState<Array<any>>([]);
    const [selectedFilesNumbers, setSelectedFilesNumbers] = useState<Array<any>>([]);    
    const [disableLoadButton, setDisableLoadButton] = useState<boolean>(true);
    const [isSectionLoaded, setIsSectionLoaded] = useState<boolean>(false)
    const [showFillNotes, setShowFillNotes] = useState<boolean>(false); 
    const [selectedNotes, setSelectedNotes] = useState<Array<any>>([]);
    const [otherLoadedDocsDetails, setOtherLoadedDocsDetails] = useState<any>({});    
    const [newNotes, setNewNotes] = useState<string>('');      
    const selDocType = useRef<string>('');
    const isBypassNote = useRef<boolean>(false);
    const loginUserDetails = useRef<any>(localStorage.getItem('loginCredentials') ? JSON.parse(localStorage.getItem('loginCredentials') || ''):'');

    useEffect(() => {
      prepareSelectionData();    
      return () => {
        setSelectionData([]);
      }
    }, [selectedCategory]);

    useEffect(() => {  
        if (selectionData.length > 0) {
            checkToEnableLoadButton();
        }    
    }, [selectedFiles,selectionData]);

    useEffect(() => {
      if(selCotFiles && selCotFiles.length > 0){
        let selFiles = JSON.parse(JSON.stringify(selectedFiles));        
        setSelectedFiles([...selFiles,...selCotFiles]);       
        setSelectedFilesNumbers(selFiles.map((e:any) => e.documentNumber));
      }     
    }, [selCotFiles]);
    
    
    /**
     * Setting already loaded documents, already selected data & formatting data on opening of document selection popup
     */
    const prepareSelectionData = () => {
        const selData: Array<any> = [];
        let loadedDocs:Array<any> = [];
        let loadedDocsDetails:any = {};
        if (sessionStorage.getItem('claimPktDetails')) {
            const claimPktDetails = JSON.parse(sessionStorage.getItem('claimPktDetails') || '');
            if (Array.isArray(claimPktDetails)) {                
                claimPktDetails.forEach((elem: any,i:number) => {
                    if (elem.documents && elem.documents.length > 0) {
                        if (elem.category !== selectedCategory) {
                            elem.documents.forEach((file: any) => {
                                if(file.isLoadedInTheCategory){
                                loadedDocsDetails[file.documentNumber] = {
                                    category: elem.subCategory,
                                    docType: elem.documentType,
                                    documentNumber:file.documentNumber,
                                    documentId:file.documentId
                                }
                            }
                            })
                        } else {
                            loadedDocs.push(elem.documents.map((e:any) => e.documentNumber))
                        }

                       
                    }
                    if (elem.category === selectedCategory) {
                        selData.push({ name: elem.documentType, files: documentList.filter((e) => e.documentTypeName === elem.documentType) ,description:elem.description, notes:elem.documentNotes , isBypassed:elem.isFileBypassed,isMandatory:elem.isMandatory });
                        // selData[selData.length - 1].isFileLoaded = selData[selData.length - 1].files.some((file:any) => loadedDocuments[file.documentNumber] && loadedDocuments[file.documentNumber].category !== selectedCategory);
                    }
                });
            }
        }
        const loadedDocsFlat = (loadedDocs.flat()).filter((e) => e.documentType !== documentsAPIs.CHAIN_OF_TITLE_DOC_TYPE);        
        setSelectedFiles(documentList.filter((e:any) => loadedDocsFlat.includes(e.documentNumber)));
        setSelectedFilesNumbers(loadedDocsFlat);
        for (let i = 0; i < selData.length; i++) {
            selData[i].isFileLoaded = selData[i].files.some((file: any) => loadedDocsDetails[file.documentNumber]);
            selData[i].isBypassed = selData[i].files.some((file: any) => loadedDocsDetails[file.documentNumber]) ? false : selData[i].isBypassed;
        }
        setIsSectionLoaded(checkSectionLoaded(selData));
        setSelectionData(selData);
        setOtherLoadedDocsDetails(loadedDocsDetails); 
              
    }

    /**
     * To check entire section is already loaded
     * @param selData 
     * @returns boolean section loaded or not
     */
    const checkSectionLoaded = (selData: Array<any>) : boolean  => {
        for (let i = 0; i < selData.length; i++) {
            if(!selData[i].isFileLoaded){
                return false;
            }
        }
        return true;
    }
    
    /**
     * To set selected files on user selection
     * @param file 
     */
    const onSelectAndUnselectFile = (file: any) => {        
        let selFiles = JSON.parse(JSON.stringify(selectedFiles));
        const fileIndex = selFiles.findIndex((e: any) => e.documentNumber === file.documentNumber);
        if (fileIndex > -1) {
            selFiles = selFiles.filter((e:any) => e.documentNumber !== file.documentNumber);
        } else {
            selFiles.push(file);
        }       
        setSelectedFiles(selFiles);       
        setSelectedFilesNumbers(selFiles.map((e:any) => e.documentNumber));
        
    }

    /**
     * To set bypass selection on user selection
     * @param document 
     * @param i 
     */
    const onSelectUnselectBypassDoc = (document: any, i: number) => {
        let selData = JSON.parse(JSON.stringify(selectionData));
        selDocType.current = document.name;
        if (selData[i].isBypassed) {
            selData[i].isBypassed = false;
            setSelectionData(selData);
        } else {
            if (selData[i].notes[selData[i].notes.length - 1] && !selData[i].notes[selData[i].notes.length - 1].noteAddedOnDate) {
                selData[i].isBypassed = true;
                setSelectionData(selData);
            } else {
                isBypassNote.current = true;
                setShowFillNotes(true);
            }

        }
    }    

    /**
     * Save note functionality on adding notes
     */
    const onClickSaveNotes = () => {
        let selData = JSON.parse(JSON.stringify(selectionData));        
        for (let i = 0; i < selData.length; i++) {
            if (selData[i].name === selDocType.current || selData[i].name === documentsAPIs.MAPPED_NAME_ADDRESS_EVID) {
                selData[i].notes.push({                    
                    "noteAddedBy": loginUserDetails.current && loginUserDetails.current.displayName ? loginUserDetails.current.displayName : '',
                    "noteAddedById": loginUserDetails.current && loginUserDetails.current.userId ? loginUserDetails.current.userId : '',
                    "notes": newNotes
                });                
                if(isBypassNote.current){
                    selData[i].isBypassed = true;
                }
                break;
            }
        }
        setSelectionData(selData);
        setShowFillNotes(false);
        setShowNotes(false);
        setNewNotes('');
    }    

    /**
     * For enabling disabling load button based on user actions
     */
    const checkToEnableLoadButton = () => {
        let enableLoadButton = true;
        for (let i = 0; i < selectionData.length; i++) {
            if (!selectionData[i].isBypassed) {
                const isDocTypeFileSelected = checkFileSelectedForType(selectionData[i].files, selectedFiles);
                if (!isDocTypeFileSelected) {
                    enableLoadButton = false;
                    break;
                }
            }                              
        }        
        setDisableLoadButton(!enableLoadButton);
    }

    /**
     * To check files selected for every type in that section
     * @param files 
     * @param selFiles 
     * @returns boolean files selected or not
     */
    const checkFileSelectedForType = (files:Array<any>,selFiles:Array<any>) => {        
        for (let i = 0; i < selFiles.length; i++) {
            for (let j = 0; j < files.length; j++) {
                if (selFiles[i].documentNumber === files[j].documentNumber) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * On user click Load documents after selecting documents or bypass. 
     */
    const onClickLoad = async () => {                
        let claimPktDetails: Array<any> = sessionStorage.getItem('claimPktDetails') ? JSON.parse(sessionStorage.getItem('claimPktDetails') || '') : [];
        const prevLoadedFiles:Array<any> = await getPrevLoadedFiles(claimPktDetails);
        const deletedFiles:Array<any> = await getDeletedFiles(prevLoadedFiles,selectedFiles);        
        claimPktDetails = await updateBypassAndNotes(claimPktDetails,selectionData);           
        sessionStorage.setItem('claimPktDetails',JSON.stringify(claimPktDetails));

        updateBypassedCat(selectedCategory, checkIfAllTheDocTypesByPassed(selectionData)); // update bypass categories in claim packet for showing to user
        updateAlreadyLoadedSecCategories(selectedCategory,isSectionLoaded); // update already loaded section categories in claim packet for showing to user
        
        if (prevLoadedFiles.length === 0 && selectedFiles.length > 0) {
            getDocumentsData(selectedFiles); // category loading files first time
        } else if (prevLoadedFiles.length > 0 && selectedFiles.length === 0) {
            onClickDelete(selectedCategory); // files are deleting from that category - deleting that category
        } else if (checkIfCategoryNeedToLoad(prevLoadedFiles, selectedFiles)) {       
            // File selected now are different from the loaded files in claim packet - reloading that category    
            reloadCategory(selectedCategory, JSON.parse(JSON.stringify(selectedFiles)),deletedFiles);
        } else { 
            // File selected now are same as the loaded files in claim packet - updates notes & closes the popup           
            getDocumentsData([]);
        }        
    }

    const getPrevLoadedFiles = (claimPktDetails: Array<any>): Promise<Array<any>> => {
        let prevLoadedFiles: Array<any> = [];
        claimPktDetails.forEach((doc) => {
            if (doc && doc.category === selectedCategory && doc.documents && doc.documents.length > 0) {
                prevLoadedFiles.push(...doc.documents)
            }
        });
        return Promise.resolve(prevLoadedFiles);
    }

    const getDeletedFiles = (loadedFiles: Array<any>, selFiles: Array<any>): Promise<Array<any>> => {
        let deletedFiles: Array<any> = loadedFiles.filter((file: any) => selFiles.findIndex((e) => file.documentNumber == e.documentNumber) == -1 && file.isLoadedInTheCategory);
        return Promise.resolve(deletedFiles);
    }

    const deleteFilesFromOtherCategories = (claimPktDetails: Array<any>, deletedFiles: Array<any>): Promise<Array<any>> => {
        const deletedFilesObj:any = Object.fromEntries(deletedFiles.map(e => [e.documentNumber,true]))
        for (let i = 0; i < claimPktDetails.length; i++) {
            if (claimPktDetails[i].documents && claimPktDetails[i].documents.length > 0) {
                claimPktDetails[i].documents = claimPktDetails[i].documents.filter((e: any) => !deletedFilesObj[e.documentNumber]);
            }
        }
        return Promise.resolve(claimPktDetails);
    }

    
    /**
     * To update session storage with latest user selected data
     * @param claimPktDetails 
     * @param selectionData 
     * @returns Promise with claim packet details
     */
    const updateBypassAndNotes = (claimPktDetails: Array<any>, selectionData: Array<any>): Promise<Array<any>> => {
        selectionData.forEach((data: any) => {
            const docTypeIndex = claimPktDetails.findIndex((e: any) => e.docType === data.name);
            if (docTypeIndex > -1 && claimPktDetails[docTypeIndex] && claimPktDetails[docTypeIndex].documentNotes) {
                //Updating details into session storage
                claimPktDetails[docTypeIndex].isFileBypassed = data.isBypassed;
                claimPktDetails[docTypeIndex].documentNotes = data.notes;
                // For handling chain of title documents & updating documents into claim details session storage as they are pulled from different source
                if (data.name === 'MAPPED NAME CHAIN OF TITLE') {
                    claimPktDetails[docTypeIndex].documents = selCotFiles.map((e: any) => {
                        return {
                            documentId: e._id,
                            documentNumber: e.documentNumber,
                            isLoadedInTheCategory: otherLoadedDocsDetails[e.documentNumber] ? false : true,
                            DocumentSourceId: e.documentSourceId,
                            DocumentSourceTypeName: e.documentSourceTypeName,
                            DocumentTagId: e.documentTagId,
                            DocumentTagName: e.documentTagName,
                            BusinessNameId: e.businessNameId,
                            BusinessLocationId: e.businessLocationId,
                            MappedNameID: e.mappedNameID,
                            documentRelevanceId: e.documentRelevanceId

                        }
                    });
                } else {
                    claimPktDetails[docTypeIndex].documents = documentList.filter((file: any) => otherLoadedDocsDetails[file.documentNumber] || selectedFilesNumbers.includes(file.documentNumber))
                        .map((e: any) => {
                            return {
                                documentId: e._id,
                                documentNumber: e.documentNumber,
                                isLoadedInTheCategory: otherLoadedDocsDetails[e.documentNumber] ? false : true,
                                DocumentSourceId: e.documentSourceId,
                                DocumentSourceTypeName: e.documentSourceTypeName,
                                DocumentTagId: e.documentTagId,
                                DocumentTagName: e.documentTagName,
                                BusinessNameId: e.businessNameId,
                                BusinessLocationId: e.businessLocationId,
                                MappedNameID: e.mappedNameID,
                                documentRelevanceId: e.documentRelevanceId

                            }
                        });
                }

            }
            if (selectedFiles.length === 0 && !isSectionLoaded) {
                claimPktDetails[docTypeIndex].documents = [];
            }
        });
        return Promise.resolve(claimPktDetails);
    }   

    /**
     * To determine if the section needed to reload or not
     * @param laodedDocs 
     * @param selectedDocs 
     * @returns boolean to reload or not
     */
    const checkIfCategoryNeedToLoad = (laodedDocs:Array<any>,selectedDocs:Array<any>) => {        
        let selDocsObj:any = {};
        let loadedDocsObj:any = {};
        selectedDocs.forEach((e:any) =>{
            selDocsObj[e.documentNumber] = true;
        });
        laodedDocs.forEach((e:any) =>{
            loadedDocsObj[e.documentNumber] = true;
        });        
        for (let i = 0; i < laodedDocs.length; i++) {
            if(!selDocsObj[laodedDocs[i].documentNumber]){
              return true;  
            }
        }
        for (let j = 0; j < selectedDocs.length; j++) {
            if(!loadedDocsObj[selectedDocs[j].documentNumber]){
                return true;
            }
        }
        return false
    }

    const checkIfAllTheDocTypesByPassed = (selData:Array<any>) => {
        for(let i=0;i<selData.length;i++){
            if(!selData[i].isBypassed){
                return false;
            }
        }
        return true;
    }

    const onClickAddNote = (docType:string,isBypassNotes:boolean) => {
        selDocType.current = docType;
        isBypassNote.current = isBypassNotes;
        setShowFillNotes(true)
    }

    const onClickNotes = (docType:string,notes:Array<any>) => {
        selDocType.current = docType;
        setSelectedNotes(notes); 
        setShowNotes(true) 
    }

    

    const saveSelectedFiles = (selFiles: Array<any>) => {        
        setSelectedFiles(selFiles);
        setSelectedFilesNumbers(selFiles.map((e) => e.documentNumber))
    }
    
    return <>
        
        <Dialog header={selectedCategory} visible={showSelection} style={{ width: '95vw' }} onHide={() => { hideSelection(false) }}>

            {selectedCategory === documentsAPIs.ADDRESS_EVIDENCE ?
                <AddressEvid
                    documents={documentList}
                    getDocumentsData={getDocumentsData}
                    showModal={showSelection}
                    closeModal={() => hideSelection(false)}
                    loadedDocuments={otherLoadedDocsDetails}                    
                    onSelectUnselectBypassDoc={onSelectUnselectBypassDoc}
                    selectionData={selectionData}
                    isSectionLoaded={isSectionLoaded}
                    onClickAddNote={onClickAddNote}
                    onClickNotes={onClickNotes}
                    disableLoadButton={disableLoadButton}
                    onClickLoadAddrEvid = {onClickLoad}
                    updateSelectedFiles={saveSelectedFiles}
                    selectedFilesNumbers={selectedFilesNumbers}


                /> : <>
                    <div>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>
                                        S.No
                                    </th>
                                    <th>
                                        Document Type
                                    </th>
                                    <th>
                                        File Name
                                    </th>
                                    <th style={{ width: '140px' }}>
                                        Bypass Req Doc 
                                    </th>
                                    <th style={{ width: '550px' }}>
                                        Claim Packet Notes
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectionData.map((documentType: any, i: number) => {
                                    return <tr>
                                        <td>{i + 1}</td>
                                        <td>
                                            {documentType.name} {documentType.isMandatory ? <span className="text-danger">(M)</span> : ''}<br />
                                            <span className="text-danger">{documentType.description}</span>
                                        </td>
                                        <td>
                                            {documentType.name === 'MAPPED NAME CHAIN OF TITLE' ? <Button color='primary' className='mb-0' outline onClick={() => getChainOfTitle(false,null,otherLoadedDocsDetails,selectedFilesNumbers)} disabled={documentType.isBypassed}>{`Chain Of Title (${selCotFiles.length})`}</Button> :
                                                <>{documentType.files.length > 0 ?
                                                    documentType.files.map((file: any) => {
                                                        return <div className="d-flex align-items-center mb-1">
                                                            {!otherLoadedDocsDetails[file.documentNumber] && <Checkbox checked={selectedFilesNumbers.includes(file.documentNumber) && !documentType.isBypassed} onChange={() => { onSelectAndUnselectFile(file) }} disabled={documentType.isBypassed}></Checkbox>}
                                                            <span>
                                                                <div className='pl-2 d-flex justify-content-between'>
                                                                    <div>{file.fileName} &nbsp; (Created On : {dateFormats.formatDate(file.createdDateUTC, "MM/DD/YYYY hh:mm A", "nhg")})</div>
                                                                </div>
                                                                {otherLoadedDocsDetails[file.documentNumber] && <div className="ml-2">(Document already loaded for {otherLoadedDocsDetails[file.documentNumber].docType})</div>}
                                                            </span>
                                                        </div>


                                                    })
                                                    :
                                                    <div>No documents Found</div>
                                                }
                                                </>
                                            }
                                        </td>
                                        <td className="text-center" style={{ width: '140px' }}>
                                            <Checkbox onChange={() => { onSelectUnselectBypassDoc(documentType, i) }} checked={documentType.isBypassed} disabled={documentType.isMandatory || documentType.isFileLoaded || selectedFiles.findIndex((e: any) => e.documentTypeName === documentType.name) > -1}></Checkbox>
                                        </td>
                                        <td className="notes-cell" style={{ width: '550px' }}>
                                            {documentType.notes[documentType.notes.length - 1] ?
                                                <div onClick={() => {onClickNotes(documentType.name,documentType.notes)}}>
                                                    <div className="font-weight-bold">{documentType.notes[documentType.notes.length - 1].noteAddedOnDate ? dateFormats.formatDate(documentType.notes[documentType.notes.length - 1].noteAddedOnDate, "MM/DD/YYYY", "nhg") : `${new Date().getMonth() + 1}/${new Date().getDate()}/${new Date().getFullYear()}`}:{documentType.notes[documentType.notes.length - 1].noteAddedBy}</div>
                                                    <span >{documentType.notes[documentType.notes.length - 1].notes}</span>
                                                </div>
                                                :
                                                <Button color='primary' outline onClick={() => {onClickAddNote(documentType.name,false)}}>Add Notes</Button>
                                            }

                                        </td>
                                    </tr>

                                })}
                            </tbody>
                        </table>
                    </div>
                     {isSectionLoaded && <div className="mt-2 text-center "> <h5>Required documents needed for {selectedCategory} are already present in the Claim Packet</h5></div>}           
                    <div className="text-center pt-3">
                        <Button color='primary' outline disabled={disableLoadButton && !isSectionLoaded} onClick={() => onClickLoad()}>Load</Button>
                        <Button color='primary' outline onClick={() => hideSelection(false)}>Cancel</Button>
                    </div>
                </>
            }

        </Dialog>
    {showNotes &&
        <NotesPopup
            selectedNotes={selectedNotes}
            showNotes={showNotes}
            hideNotes={() => setShowNotes(false)}
            onClickSaveNotes={onClickSaveNotes}
            newNotes={newNotes}
            setNewNotes={setNewNotes}
        /> 
    }      
        {showFillNotes &&
            <FillNotesPopup
                showFillNotes={showFillNotes}
                hideNotesPopup={() => { setShowFillNotes(false) }}
                onClickSaveNotes={onClickSaveNotes}
                newNotes={newNotes}
                setNewNotes={setNewNotes}
            />
        }
    </>
}